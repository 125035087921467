<template>
  <component :is="panel || 'DIV'" style="height: 100%">

    <slot>
      静态面板
      <div>{{ value }}</div>
      <div>{{ type }}</div>
      <div>{{ config }}</div>
      <div>{{ dataconfig }}</div>
    </slot>
  </component>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  components: panels,
  props: {
    value: Object,
    type: String,
    config: Object,
    dataconfig: Object,
    panel: String,
  },



};
</script>