<template>
  <el-dialog
    class="noselect"
    :title="config.Title || title || '提示'"
    :visible="value === 1"
    :width="config.Width || '550px'"
    :append-to-body="true"
    :lock-scroll="true"
    :destroy-on-close="true"
    :close-on-click-modal="config.CloseByClick === true"
    :before-close="handleClose"
  >
    <slot></slot>
    <span slot="footer" class="dialog-footer">
      <template v-if="config.Buttons">
        <el-button
          @click="handleClick(button)"
          :key="index"
          v-for="(button, index) in config.Buttons"
          >{{ button.Title }}</el-button
        >
      </template>
      <template v-if="!loading">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleOk">确 定</el-button>
      </template>
      <div v-else-if="config.Progress">
        <el-progress
          :text-inside="true"
          :stroke-width="config.StrokeWidth || 26"
          :percentage="numns"
        ></el-progress>
      </div>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    loading: Boolean,
    numns: Number
  },
  methods: {
    handleClose(e) {
      //this.$emit("close", {});
      if (this.config.OnClose) {
        this.config.OnClose();
      }
    },
    handleClick(button) {
      //this.$emit("clickbutton", { name: e.Name });
      if (button && button.OnClick) {
        button.OnClick();
      }
    },
    handleOk(e) {
      if (this.config.OnOk) {
        this.config.OnOk();
      }
    },
    handleCancel(e) {
      if (this.config.OnCancel) {
        this.config.OnCancel();
      }
    }
  }
};
</script>