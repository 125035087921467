 
import Router from "vue-router";
import defaultPage from "../projects/components/default/index.vue";
import list from "../projects/components/list/index.vue";
import parent from "../projects/components/parent/index.vue";
import add from "../projects/components/add/index.vue";
import modify from "../projects/components/modify/index.vue";
import child from "../projects/components/child/index.vue";
import indexPage from "../projects/components/indexPage/index.vue";
import detail from "../projects/components/detail/index.vue";
import home from "../projects/components/home/index.vue";
import homelist from "../projects/components/home/list.vue";
import homedetail from "../projects/components/home/detail.vue";
 
export default new Router({
  routes: [
    { path: '/', component: defaultPage },
    {
      path: "/index", redirect: '/home', component: indexPage, children: [
        { path: '/list', component: list, children: [{ path: "/list/detail", component: detail },{ path: "/list/child", component: child },{ path: "/list/add", component: add },{ path: "/list/modify", component: modify }] },       
        { path: '/parent', component: parent, children: [{ path: "/parent/detail", component: detail },{ path: "/parent/child", component: child },{ path: "/parent/add", component: add },{ path: "/parent/modify", component: modify }] },       
        { path: '/home', component: home, children: [{ path: '/home/list', component: homelist },{ path: '/home/detail', component: homedetail }] },
        { path: '/driver', component: home, children: [{ path: '/driver/detail', component: homedetail}, { path: '/driver/list', component: homelist }] },
        { path: '/company', component: home, children: [{ path: '/company/detail', component: homedetail}, { path: '/company/list', component: homelist }] },
        { path: '/test', component: home, children: [{ path: '/test/detail', component: homedetail}, { path: '/test/list', component: homelist }] },
        { path: '/paper', component: home, children: [{ path: '/paper/detail', component: homedetail}, { path: '/paper/list', component: homelist }] },
        { path: '/manager', component: home, children: [{ path: '/manager/detail', component: homedetail}, { path: '/manager/list', component: homelist }] },
        { path: '/setting', component: home, children: [{ path: '/setting/detail', component: homedetail}, { path: '/setting/list', component: homelist }] }
      ]
    } 
  ]
})