<template>
  <mx-entity
    mode="list"
    :type="config.Type"
    :parent="parent"
    :role="config.Role"
    :role-field="config.RoleField"
    ref="entity"
    @change="handleMetaChange"
  >
    <div class="flex-row top" v-if="Config">
      <div style="width: 400px">
        <mx-form
          style="
            padding: 10px;
            background-color: #fff;
            margin: 5px 10px 0px 5px;
          "
          class="s4"
          type="Condition"
          v-if="Config.Condition"
          :config="Config.Condition"
          :value="Config.Condition.Value"
          :columns="Config.Condition.Columns"
          theme="one"
          ref="condition"
          @change="handleSearch(1)"
        >
          <mx-element
            type="Button"
            theme="search2"
            style="margin-left: 5px"
            @click="handleSearch"
            slot="button"
          ></mx-element>
        </mx-form>
        <mx-load-list
          v-if="Config.List"
          :params="Config.List.Params"
          :type="Config.List.Entity"
          :role="Config.List.Role"
          :role-field="Config.List.RoleField"
          :data-handler="Config.List.DataHandler"
          :page-size="Config.List.PageSize || 0"
          ref="listloader"
          @loading="handleLoading"
          :auto-load="true"
        >
          <template slot-scope="res">
            <div style="overflow-x: auto">
              <div
                style="
                  padding: 10px;
                  background-color: #fff;
                  margin: 5px 10px 5px 5px;
                "
                :key="res.timespan"
                class="s4"
              >
                <mx-list
                  :key="listkey"
                  v-if="res.data"
                  type="ListPanel"
                  :config="Config.List"
                  :theme="Config.List.Theme"
                  :columns="Config.List.Columns"
                  :value="res.data"
                  :panel="Panel"
                >
                  <mx-element
                    type="TableTdLinks"
                    :config="
                      CreateTitleLinks(scope.data, scope.config, scope.index)
                    "
                    slot-scope="scope"
                    slot="column-title"
                    @click="handleFunction"
                  ></mx-element>
                  <mx-element
                    type="ListButtons"
                    :options="CreateActions(scope.data)"
                    slot="button"
                    slot-scope="scope"
                    @click="handleFunction"
                  ></mx-element>
                </mx-list>
                <mx-element
                  style="margin-top: 10px; font-size: 16px"
                  type="Pager"
                  :value="res.page"
                  :config="{ MaxSize: res.total, PageSize: res.pageSize }"
                  @change="handlePager"
                ></mx-element>
              </div>
            </div>
          </template>
        </mx-load-list>
      </div>
      <div></div>
      <slot></slot>
    </div>
    <mx-element
      type="Dialog"
      :config="DialogConfig"
      v-if="DialogConfig"
      :key="dialogKey"
      :value="1"
      :title="DialogConfig.Title"
    >
      <mx-panel
        :name="DialogConfig.ParentPanelName"
        v-if="DialogConfig.ParentPanelName && DialogConfig.ParentType"
        :type="DialogConfig.ParentType"
        :panel="DialogConfig.ParentPanelName"
        :value="DialogConfig.Parent.Parent"
        :role="DialogConfig.Role"
        :role-field="DialogConfig.RoleField"
      ></mx-panel>
      <mx-panel
        :name="DialogConfig.PanelName"
        v-if="DialogConfig.PanelName"
        :type="DialogConfig.Type"
        :panel="DialogConfig.PanelName"
        :parent="DialogConfig.Parent"
        :role="DialogConfig.Role"
        :role-field="DialogConfig.RoleField"
      ></mx-panel>
      <mx-form
        type="DialogForm"
        :config="DialogConfig"
        :value="DialogConfig.Value"
        :columns="DialogConfig.Columns"
        :theme="DialogConfig.Theme"
        ref="dialogform"
      ></mx-form>
    </mx-element>
  </mx-entity>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    config: Object,
    parent: Object,
    panel: String
  },
  data() {
    return {
      Condition: {},
      SortIndex: 0,
      Loading: false,
      DialogConfig: null,
      dialogKey: new Date().getTime(),
      listkey: new Date().getTime(),
      ListSearchParam: null,
      Config: null,
      Panel: "",
      Meta: null
    };
  },
  computed: {
    IsShowCondition() {
      return (
        this.Config.Condition &&
        this.Config.Condition.Columns.filter(c => {
          return !c.NoShow;
        }).length > 0
      );
    }
  },
  methods: {
    handleMetaChange(val) {
      this.Meta = val;
      if (this.Meta && this.Meta.List) {
        let Config = this.$refs.entity.init(
          this.parent,
          this.$matrix.GetUser(val.Role)
        );
        this.$emit("meta-change", val);
        this.Config = Config.List;
        if (this.Config) {
          let setting =
            this.$matrix.GetLocalStorage("page_setting", true) || {};
          let key =
            this.$route.path +
            "_" +
            this.Config.Type +
            "_" +
            this.Config.List.RoleField;
          setting[key] = setting[key] || {};
          this.Panel = setting[key].Panel;
          this.$emit("panel-change", this.Panel);
          this.$emit("config-change", Config);
        }
      }
    },
    CreateActions(data) {
      return this.Config.DataAction.call(this, data);
    },
    CreateTitleLinks(data, column, index) {
      let config = this.Config.TitleLinks.call(this, data);
      config.Column = column;
      config.Index = index;
      config.Data = data;
      return config;
    },
    handleShowPanel(panel) {
      if (panel !== this.Panel) {
        this.Panel = panel;
        this.$emit("panel-change", panel);
        let setting = this.$matrix.GetLocalStorage("page_setting", true) || {};
        let key =
          this.$route.path +
          "_" +
          this.Config.Type +
          "_" +
          this.Config.List.RoleField;
        setting[key] = setting[key] || {};
        setting[key].Panel = panel;
        this.$matrix.SetLocalStorage("page_setting", setting, true);
        this.listkey = new Date().getTime();
        if (this.Panel && panels[this.Panel] && panels[this.Panel].loadparams) {
          this.handleSearch(1);
        }
      }
    },

    handleSortChange(index) {
      this.SortIndex = index;
      this.handleSearch(1);
    },
    handlePager(val) {
      this.handleSearch(val);
    },
    handleSearch(page) {
      page = page || 1;
      let getParams = () => {
        if (!this.Panel && this.ListSearchParam === null) {
          this.ListSearchParam = this.Config.List.Columns.reduce((t, item) => {
            if (["Link", "Object", "Array"].indexOf(item.Type) > -1) {
              t["Get" + item.Name] = true;
            }
            return t;
          }, {});
        }
        let value = this.ListSearchParam || {};
        if (
          this.Config.Condition.SortFields &&
          this.Config.Condition.SortFields[this.SortIndex]
        ) {
          value.Orders = this.Config.Condition.SortFields[this.SortIndex].Value;
        }
        if (this.$refs.condition) {
          value = Object.assign(value, this.$refs.condition.GetValue());
        }
        let panel = this.Panel;
        if (panels[panel] && panels[panel].loadparams) {
          value = Object.assign(
            value,
            panels[panel].loadparams.call(this, this.Config.List)
          );
        }
        return value;
      };

      if (this.$refs.listloader) {
        this.$refs.listloader.Load(page, getParams());
      }
      //查询
    },

    handleFunction(val) {
      if (val) {
        if (val.Url) {
          this.$matrix.Goto(val.Url);
        } else if (val.Path) {
          let paths = this.$route.path.split("/");
          let path = `/${paths[1]}/${val.Path}`;
          let params = [];
          let q = Object.assign({}, this.$route.query, val.Params);
          Object.keys(q).forEach(key => {
            if (
              key.indexOf("_role") > -1 &&
              q[key] === this.$matrix.GetCurrentRoleName()
            ) {
            } else {
              params.push(`${key}=${q[key]}`);
            }
          });
          this.$matrix.Goto(path + "?" + params.join("&"));
        } else if (val.Method) {
          switch (val.Method) {
            case "Export":
              break;
            case "Delete":
              this.$matrix
                .Confirm(val.Confirm || "删除数据警告，一旦删除数据将无法回复")
                .then(res => {
                  this.$matrix
                    .Post3(
                      val.Config.Type,
                      val.Config.Method,
                      val.Config.Params,
                      val.Config.Role,
                      val.Config.RoleField
                    )
                    .then(res => {
                      this.handleSearch(this.$refs.listloader.GetPage());
                      this.$matrix.ShowToast(val.Message || "保存成功!");
                    })
                    .catch(e => {
                      this.$matrix.ShowError(e);
                    });
                });
              break;
          }
        } else if (val.Dialog) {
          let dialogConfig = val.Dialog(
            this.parent,
            this.$matrix.GetUser(this.Config.Role)
          );
          /*******检查是否有自定义面板*** */
          let panelName = "";
          if (dialogConfig.Method.toLowerCase() === "add") {
            panelName =
              dialogConfig.Type +
              ".Add" +
              "." +
              (dialogConfig.RoleField || dialogConfig.Role);
            panelName = panelName.replace(/\./g, "_");
            if (!panels[panelName]) panelName = "";
          }

          let parentPanelName = "";
          let parentType = "";
          if (dialogConfig.Method.toLowerCase() === "add") {
            let parentTypes = dialogConfig.Type.split(".");
            parentTypes.pop();
            parentType = parentTypes.join(".");
            parentPanelName =
              dialogConfig.Type +
              "." +
              (dialogConfig.RoleField || dialogConfig.Role);
            parentPanelName = parentPanelName.replace(/\./g, "_");

            if (!panels[parentPanelName]) parentPanelName = "";
          }
          /********** */
          if (
            dialogConfig.Columns.filter(f => {
              return !f.NoShow;
            }).length > 0 ||
            panelName
          ) {
            dialogConfig.PanelName = panelName;
            dialogConfig.ParentPanelName = parentPanelName;
            dialogConfig.ParentType = parentType;

            dialogConfig.OnOk = function() {
              let dosome = () => {
                let errors = this.$refs.dialogform.GetError();
                if (!errors) {
                  let foo = () => {
                    let value = this.$refs.dialogform.GetValue();
                    if (dialogConfig.Value.Id) {
                      value = this.$refs.dialogform.GetChange();
                      if (!value || Object.keys(value).length === 0) {
                        return this.$matrix.ShowError(
                          "数据没有发生改变，不能保存"
                        );
                      }
                      value.Id = dialogConfig.Value.Id;
                    }
                    this.$matrix
                      .Post3(
                        dialogConfig.Type,
                        dialogConfig.Method,
                        value,
                        dialogConfig.Role,
                        dialogConfig.RoleField
                      )
                      .then(res => {
                        this.DialogConfig = null;
                        this.$matrix.ShowToast(
                          dialogConfig.Message || "保存成功!"
                        );
                        this.handleSearch(this.$refs.listloader.GetPage());
                      })
                      .catch(e => {
                        this.$matrix.ShowError(e);
                      });
                  };
                  if (dialogConfig.Confirm) {
                    this.$matrix.Confirm(dialogConfig.Confirm).then(res => {
                      foo();
                    });
                  } else {
                    foo();
                  }
                }
              };
              let check = false;
              if (dialogConfig.ParentPanelName) {
                let panel = panels[dialogConfig.ParentPanelName];
                if (panel && panel.check) {
                  check = true;
                  panel
                    .check(dialogConfig.Parent.Parent, dialogConfig)
                    .then(res => {
                      dosome();
                    })
                    .catch(e => {
                      if (e) this.$matrix.ShowError(e);
                    });
                }
              }
              if (!check) dosome();
            }.bind(this);
            dialogConfig.OnClose = () => {
              this.DialogConfig = null;
            };
            dialogConfig.OnCancel = () => {
              this.DialogConfig = null;
            };
            this.dialogKey = new Date().getTime();
            dialogConfig.Width = dialogConfig.Columns.reduce((width, item) => {
              if (item.Type === "Html") width = "700px";
              if (item.DialogWidth) width = item.DialogWidth;
              return width;
            }, undefined);
            this.DialogConfig = dialogConfig;
          } else if (dialogConfig.Method.toLowerCase() === "add") {
            let message =
              dialogConfig.Confirm || "您正在" + dialogConfig.Title + ",请确认";
            this.$matrix
              .Confirm(message)
              .then(res => {
                this.$matrix
                  .Post3(
                    dialogConfig.Type,
                    dialogConfig.Method,
                    dialogConfig.Value,
                    dialogConfig.Role,
                    dialogConfig.RoleField
                  )
                  .then(res => {
                    this.DialogConfig = null;
                    this.handleSearch(1);
                    this.$matrix.ShowToast(dialogConfig.Message || "保存成功!");
                  })
                  .catch(e => {
                    this.$matrix.ShowError(e);
                  });
              })
              .catch(e => {
                this.$matrix.ShowError(e);
              });
          }
        }
      }
    },
    handleLoading(state) {
      this.Loading = state;
    }
  }
};
</script>
