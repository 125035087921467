<template>
  <div class="panel10">
    <div class="flex-row start middle noselect borderend">
      <div style="width: 42px">
        <i class="el-icon-s-unfold" style="font-size: 24px"></i>
      </div>
      <div class="scrollbox" style="flex: 1">
        <div
          class="flex-row start middle tabbar"
          :style="{ '--left': left, '--width': width }"
        >
          <div
            v-for="(item, index) of options"
            :key="index"
            class="scrollbox-item"
            :class="index === value ? 'active' : ''"
            @click="handleClick($event, index)"
          >
            {{ item[config.label] }}
          </div>
        </div>
      </div>
      <div v-if="options && options[value] && options[value].ListView">
        <el-link @click="handleClickMore"
          >获取更多<i :class="getIcon()"
        /></el-link>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { label: "Title" };
      }
    },
    icon: String
  },
  data() {
    return { left: 10, width: "70px" };
  },
  methods: {
    getIcon() {
      return "el-icon-arrow-right";
    },
    handleClick(e, index) {
      let { width, left } = e.target.getBoundingClientRect();
      let parentRect = e.target.parentNode.getBoundingClientRect();
      this.left = left - parentRect.left + 10 + "px";
      this.width = width - 20 + "px";
      this.$emit("change", index);
    },
    handleClickMore() {
      this.$matrix.GotoListView(
        this.$route,
        this.options[this.value].ListView,
        this.options[this.value].Role || "",
        this.options[this.value].RoleField || "",
        this.options[this.value].ListParams
      );
    }
  }
};
</script>
<style scoped>
.scrollbox {
  width: calc(100% - 40px);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}
.scrollbox-item {
  word-break: keep-all;
  font-size: 18px;
  font-weight: 550;
  padding: 12px 5px;
  cursor: pointer;
}
.active {
  color: red;
}
.scrollbox::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
.scrollbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.scrollbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
.borderend {
  position: relative;
}

.borderend::after {
  content: " ";
  position: absolute;
  left: 10px;
  bottom: 0;
  right: 10px;
  border-bottom: 1px solid #ebebeb;
  color: #ebebeb;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
.tabbar {
  position: relative;
}
.tabbar::before {
  width: var(--width);
  height: 2px;
  content: " ";
  position: absolute;
  bottom: 0px;
  left: var(--left);
  background-color: red;
  border-radius: 24px;
  transition: all 0.4s;
  transition-timing-function: ease-in-out;
}
</style>
