<template>
    <div class="list">
        <div v-for="(col, index) in columns" class="menu flex-row" @click="handleClick(col, index)" :style="itemStyle" :key="index">
            <span class="line1 f14" :class="Isfit(col, index) ? 'active' : ''">{{ col.Title }}</span>
            <slot :config="col" :index="index" v-if="col.Config.Fields"></slot>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        columns: Array,
        query: Object
    },
    data() {
        return { ActiveIndex: -1 }
    },
    computed: {
        IsH() {
            let v = this.config.views.find(item => {
                return item.IsH
            })
            if (v) return v.IsH
        },
        ColumnConfig() {
            let config = {}
            this.columns.forEach(col => {
                if (col.ColumnConfig) {
                    config = Object.assign(config, col.ColumnConfig)
                }
            })
            return config
        },
        Cls() {
            if (this.ColumnConfig.Direction === 'v') {
                return ['flex-row start middle wrap']
            } else {

            }
        },
        itemStyle() {
            return { "--activeColor": this.ColumnConfig.Color || '#eff6fe' }
        }
    },
    watch: {
        query(val) {
            if (val && val.menu) {
                if (val.menu !== this.query.CurrentTitle) {
                    let item = this.columns.find(col => { return col.Title === val.menu })
                    if (item) {
                        this.handleClick(item, 0)
                    }
                }
            }
        }
    },
    methods: {
        Isfit(config, index, current) {
            if (this.query && this.query.CurrentTitle) {
                return this.query.CurrentTitle === config.Title
            }
        },
        handleClick(item, index) {
            if (item.ListView) {
                this.$matrix.GotoListView(
                    this.$route,
                    item.ListView,
                    item.Role,
                    item.RoleField,
                    item.ListParams
                )
            } else {
                this.$emit('itemclick', item)
            }
        }
    },
    created() {
        if (this.columns) {
            this.columns.forEach((col, index) => {
                if (col.Active) {
                    this.handleClick(col, index)
                }
            })
        }
    }
}
</script>
<style scoped>
.menu-box {
    background-color: #f7f7f7;
}

.menu {
    padding: 10px;
    margin-top: 1px;
    cursor: pointer;
    text-align: left;
}

.menu .active {
    color: #2d8cf0;
    /* background-color: var(--activeColor); */

}
</style>
