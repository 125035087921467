import { render, staticRenderFns } from "./TabLabel.vue?vue&type=template&id=396e4ece&scoped=true&"
import script from "./TabLabel.vue?vue&type=script&lang=js&"
export * from "./TabLabel.vue?vue&type=script&lang=js&"
import style0 from "./TabLabel.vue?vue&type=style&index=0&id=396e4ece&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "396e4ece",
  null
  
)

export default component.exports