
export default { 
    Style:{
     "mx-app": {
        Css: { width: "100%", overflowX: "auto" }
       }
    },
    Config:{
        Default: { Size: 'small', PageSize: 15 },
    },
    StaticField:{
        
    } 
}