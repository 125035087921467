<template>
  <el-icon :class="ThemeConfig.Icon" :style="buttonStyle">{{ title }} </el-icon>
</template>
<script>
const themes = {
  default: function() {
    return {
      theme: "default",
      component: "element-text",
      ButtonType: "button"
    };
  },
  number: function() {
    return {
      theme: "number",
      component: "element-text"
    };
  },
  submit: function() {
    return {
      theme: "submit",
      component: "element-text"
    };
  },
  search: {
    theme: "search",
    Icon: "el-icon-search",
    ButtonType: "primary",
    ShowLoading: true
  },
  search2: {
    theme: "search",
    Icon: "el-icon-search"
  },
  sort: {
    theme: "sort",
    Icon: "el-icon-sort",
    ButtonType: "info",
    ShowLoading: true
  },
  import: {
    theme: "import",
    Icon: "el-icon-upload2"
  },
  export: {
    theme: "export",
    Icon: "el-icon-download"
  },
  add: {
    theme: "add",
    Icon: "el-icon-circle-plus-outline",
    ButtonType: "primary"
  },
  enable: {
    theme: "enable",
    Icon: "el-icon-edit-outline",
    ButtonType: "primary"
  },
  modify: {
    theme: "modify",
    Icon: "el-icon-edit",
    ButtonType: "primary"
  },
  delete: {
    theme: "delete",
    Icon: "el-icon-delete",
    ButtonType: "danger"
  },
  submit: {
    theme: "submit",
    Icon: "el-icon-refresh-right",
    ButtonType: "primary",
    ShowLoading: true
  },
  clear: {
    theme: "clear",
    Icon: "el-icon-delete",
    ButtonType: "danger",
    ShowLoading: true
  },
  reflash: {
    Icon: "el-icon-refresh",
    ButtonType: "danger"
  },
  login: {
    Label: "登录",
    theme: "login",
    Icon: "el-icon-user",
    ButtonType: "primary",
    ShowLoading: true
  },
  child: {
    theme: "child",
    Icon: "el-icon-magic-stick"
  },
  setting: {
    Icon: "el-icon-setting"
  },
  record: {
    Icon: "el-icon-document"
  },
  report: {
    Icon: "el-icon-s-data"
  }
};
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    options: Array,
    icon: String,
    theme: String
  },
  computed: {
    buttonStyle() {
      switch (this.size) {
        case "mini":
          return {};
        default:
          return { fontSize: "18px" };
      }
    },
    ThemeConfig() {
      return themes[(this.theme || "default").toLowerCase()] || {};
    }
  }
};
</script>