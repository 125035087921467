<template>
  <div class="flex-row wrap start st">
    <div v-for="(col, index) in Columns" :key="'cell' + index" :class="col.Class" :style="col.Style" v-show="GetShowState(col)">
      <div :class="col.TitleClass" class="flex-row top" :style="col.TitleStyle">
        <div :style="col.TitleReqStyle" v-if="col.ShowReq">*</div>
        <div :style="GetTitleStyle(col)">{{ col.Title }}</div>
        <div style="width: 20px; padding: 5px" v-if="col.IsV">
          <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
            <i class="el-icon-warning" style="color: red"> </i>
          </el-tooltip>
        </div>
      </div>
      <div :style="col.FieldStyle" class="box">
        <div v-if="col.FieldState && !GetShowState(col)" class="mask">-</div>
        <mx-field style="width: 100%" v-show="!col.FieldState || GetShowState(col)" :config="col" :value="value" ref="field" :edit="true" :type="col.Type" :name="col.Name" @change="handleChange" :checkable="true"> </mx-field>
        <div style="color: #777; font-size: 14px; margin-top: 5px" v-if="col.Description && !GetFieldError(col)"><i class="el-icon-info"></i> {{ col.Description }}</div>
        <div v-if="showerr !== false" class="line errorTitle">
          <template v-if="GetFieldError(col)">
            <i class="el-icon-err"></i>
            {{ GetFieldError(col).message }}</template
          >
        </div>
      </div>
      <div style="padding: 5px 0">
        <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
          <i class="el-icon-warning" style="color: red"> </i>
        </el-tooltip>
      </div>
    </div>
    <div v-for="(col, index) in FullColumns" :key="'full' + index" :class="col.Class" :style="col.Style" v-show="GetShowState(col)">
      <div :class="col.TitleClass" class="flex-row top" :style="col.TitleStyle">
        <div :style="col.TitleReqStyle" v-if="col.ShowReq">*</div>
        <div :style="GetTitleStyle(col)">{{ col.Title }}</div>
        <div style="width: 20px; padding: 5px" v-if="col.IsV">
          <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
            <i class="el-icon-warning" style="color: red"> </i>
          </el-tooltip>
        </div>
      </div>
      <div :style="col.FieldStyle" class="box">
        <div v-if="col.FieldState && !GetShowState(col)" class="mask">-</div>
        <mx-field style="width: calc(100% - 5px);margin-left:5px" v-show="!col.FieldState || GetShowState(col)" :config="col" :value="value" ref="field" :edit="true" :type="col.Type" :name="col.Name" @change="handleChange" :checkable="true"> </mx-field>
        <div class="line errorTitle"   v-if="GetFieldError(col)">
          <template >
            <i class="el-icon-warning" style="color: red"> </i>
            {{ GetFieldError(col).message }}</template
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
export default {
  mixins: [form.component],
  computed: {
    Columns() {
      return this.columns
        .filter((c) => {
          return !(['Html', 'Image', 'Images', 'File', 'Files', 'Json', 'Role'].indexOf(c.Type) > -1 || c.Length >= 200 || c.ForOneLine)
        })
        .map((c) => {
          c.RoleField = this.config.RoleField //2023-3-31
          c.Role = this.config.Role //2023-3-31
          c.Class = 'flex-row top start'
          c.Style = { width: '50%', margin: '0' }
          c.TitleReqStyle = {
            color: c.Req ? 'red' : '#fff',
            padding: '12px 4px'
          }
          c.TitleStyle = {
            textAlign: 'left',
            fontSize: '16px',
            padding: c.Title.length > 4 ? '4px 5px 4px 0' : '5px 5px 5px 0'
          }
          c.ShowReq = true
          if (['Boolean', 'Bool'].indexOf(c.Type) > -1 || c.ForOneCell) {
            c.FieldStyle = { flex: 1 }
            c.TitleStyle.padding = '5px 5px 5px 0'
          } else {
          }
          return c
        })
    },
    FullColumns() {
      return this.columns
        .filter((c) => {
          return ['Html', 'Image', 'Images', 'File', 'Files', 'Json', 'Role'].indexOf(c.Type) > -1 || c.Length >= 200 || c.ForOneLine
        })
        .map((c) => {
          if (c.ForOneLine) {
            c.Class = 'flex-row top middle'
          } else {
            c.Class = 'flex-row top start'
            c.FieldStyle = 'padding:0px 5px 0 0'
          }
          c.Style = { width: '100%', margin: '0 0 5px 0' }
          c.TitleReqStyle = {
            color: c.Req ? 'red' : '#fff',
            padding: '12px 4px'
          }
          c.TitleStyle = { 
             fontSize: '16px',
            textAlign: 'left',
            padding: '4px 5px 4px 0'
          }
          if (c.ForOneLine) {
          }
          c.ShowReq = true
          return c
        })
    }
  },
  methods: {
    GetTitleStyle(col) {   
      let FieldWidth=4
      let Field=this.Columns.find(c=>{
        return c.FieldWidth
      })
      if(Field) FieldWidth=Field.FieldWidth
      if (col.ForOneLine) {
        return {}
      }
      if (['Boolean', 'Bool'].indexOf(col.Type) > -1 || col.ForOneCell) {
        return {
          whiteSpace: 'nowrap'
        }
      } else
        return {
          width: FieldWidth * 16 + 'px',
          display: 'inline-block',
          textAlignLast: 'justify',
          lineHeight: 1,
          padding: '8px 0'
        }
    },

    handleChange2(val) {
      if (val.name && this.Errors) {
        this.Errors = this.Errors.filter((e) => {
          return e.name !== val.name
        })
      }
    },
    GetFieldError(col) {
      if (this.Errors && this.Errors.length > 0) {
        return this.Errors.find((e) => {
          return e.name === col.Name
        })
      }
    },
    GetSelfValue() {
      return Object.assign({}, this.value, this.GetValue({}))
    }
  }
}
</script>
<style scoped>
.errorTitle {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  height: 16px;
}

.title {
  word-break: keep-all;
  /* 不换行 */
  white-space: nowrap;
  /* 不换行 */
}

.panel10lr {
  padding: 2px 0px;
}

.box {
  flex: 1;
  text-align: left;
  padding: 5px;
}

.box .mask {
  opacity: 0.5;
  background-color: #eee;
  padding: 5px;
}
</style>
