<template>
  <div class="flex-row start" v-if="options && options.length > 0">
    <Button
      v-for="(button, index) in options"
      :key="index"
      :name="button.Name"
      :theme="button.Theme"
      :icon="button.Icon"
      :title="button.Title"
      @click="handleClick(button)"
      style="margin: 0 5px 0 0"
      :disabled="button.Disabled"
      :button-type="button.Disabled ? '' : 'primary'"
    >
    </Button>
  </div>
</template>
<script>
import Button from "../Button";
export default {
  components: { Button },
  props: {
    title: String,
    icon: String,
    name: String,
    theme: String,
    options: Array
  },
  methods: {
    handleClick(button) {
      this.$emit("click", button);
    }
  }
};
</script>