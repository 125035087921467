<template>
  <span>
    <mx-column-data
      :config="config"
      :dataconfig="dataconfig"
      @change="handleChange"
      v-show="false"
    ></mx-column-data
    >{{ Data }}
  </span>
</template>
<script>
export default {
  props: {
    config: Object,
    index: Number,
    dataconfig: Object
  },
  data() {
    return { Data: "" };
  },
  methods: {
    handleChange(val) {
      if (val && val.Data) {
        this.Data = val.Data[0].IdCount;
      }
    }
  }
};
</script>
