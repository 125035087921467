import EntityComponent from './Entity/index.vue';
import NavComponent from './Nav/index.vue';
import DateLineComponent from './DateLine/index.vue';
import StepComponent from './Step/index.vue';
import DetailComponent from './Detail/index.vue';
import FileComponent from './File/index.vue';
import ImageComponent from './Image/index.vue';
import TreeComponent from './Tree/index.vue';
import LoadingComponent from './Loading/index.vue';
import LoadMoreComponent from './LoadMore/index.vue';
import EmptyComponent from './Empty/index.vue';
import ColumnFrameComponent from './ColumnFrame/index.vue';
import TableTdLinksComponent from './TableTdLinks/index.vue';
import MessageComponent from './Message/index.vue';
import ListButtonsComponent from './ListButtons/index.vue';
import SwitchComponent from './Switch/index.vue';
import SwitchButtonComponent from './SwitchButton/index.vue';
import FormTitleComponent from './FormTitle/index.vue';
import TitleComponent from './Title/index.vue';
import TabTitleComponent from './TabTitle/index.vue';
import PagerComponent from './Pager/index.vue';
import LinksComponent from './Links/index.vue';
import IconsComponent from './Icons/index.vue';
import IconComponent from './Icon/index.vue';
import DropdownComponent from './Dropdown/index.vue';
import DialogComponent from './Dialog/index.vue';
import ChildPageTitleComponent from './ChildPageTitle/index.vue';
import ButtonsComponent from './Buttons/index.vue';
import ButtonComponent from './Button/index.vue';
import BreadComponent from './Bread/index.vue'
export default { 
    Name: "elements",
    Title: "元素",
    Value: [
        { Name: "Entity",Component: EntityComponent },
        { Name: "Nav",Component: NavComponent },
        { Name: "DateLine",Component: DateLineComponent },
        { Name: "Step",Component: StepComponent },
        { Name: "Detail",Component: DetailComponent },
        { Name: "File",Component: FileComponent },
        { Name: "Image",Component: ImageComponent },
        { Name: "Tree",Component: TreeComponent },
        { Name: "Loading",Component: LoadingComponent },
        { Name: "LoadMore",Component: LoadMoreComponent },
        { Name: "Empty",Component: EmptyComponent },
        { Name: "ColumnFrame",Component: ColumnFrameComponent },
        { Name: "TableTdLinks",Component: TableTdLinksComponent },
        { Name: "Message",Component: MessageComponent },
        { Name: "ListButtons",Component: ListButtonsComponent },
        { Name: "Switch",Component: SwitchComponent },
        { Name: "SwitchButton",Component: SwitchButtonComponent },
        { Name: "FormTitle",Component: FormTitleComponent },
        { Name: "Title",Component: TitleComponent },
        { Name: "TabTitle",Component: TabTitleComponent },
        { Name: "Pager",Component: PagerComponent },
        { Name: "Links",Component: LinksComponent },
        { Name: "Icons",Component: IconsComponent },
        { Name: "Icon",Component: IconComponent },
        { Name: "Dropdown",Component: DropdownComponent },
        { Name: "Dialog",Component: DialogComponent },
        { Name: "ChildPageTitle",Component: ChildPageTitleComponent },
        { Name: "Buttons",Component: ButtonsComponent },
        { Name: "Button",Component: ButtonComponent },
        { Name: "Bread",Component: BreadComponent }
    ]
}