<template>
  <div :style='config.InnerStyle'>
    <div class="line2">{{ TextValue || value}}{{config.BackStyle}}</div>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view(Number)]
};
</script>