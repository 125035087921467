<template>
  <div v-show="!col.FieldState || GetShowState(col)" :class="col.Class" :style="col.Style">
    <div style="padding: 10px 0; font-weight: 700; text-align: left">
      <span :style="col.TitleReqStyle" v-if="col.Req">*</span>{{ col.Title }}
    </div>
    <mx-field
      v-show="!col.FieldState || GetShowState(col)"
      :config="col"
      :value="value"
      ref="field"
      :edit="config.Edit !== false"
      :type="col.Type"
      :name="col.Name"
      @change="handleChange"
      :checkable="true"
      :style="col.FieldStyle"
    >
    </mx-field>
    <div style="padding: 10px 0">
      <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
        <i class="el-icon-warning" style="color: red"> </i>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    errors: Array,
    field: Object,
    value: Object,
    config: Object,
    showerr: Boolean
  },
  computed: {
    col() {
      let c = {}
      c.RoleField = this.config.RoleField //2023-3-31
      c.Role = this.config.Role //2023-3-31
      c.Class = 'panel3'
      c.Style = { textAlign: 'left', padding: '20px 20px 20px 40px', margin: '5px' }
      c.TitleReqStyle = {
        color: this.field.Req ? 'red' : '#fff',
        padding: '4px',
        width: '4px'
      }
      c.TitleStyle = {
        textAlign: 'left',
        width: '300px',
        padding: this.field.Title.length > 4 ? '4px 10px 4px 0' : '10px 10px 10px 0'
      }
      c.ShowReq = true
      if (['Boolean', 'Bool'].indexOf(this.field.Type) > -1 || this.field.ForOneCell) {
        c.FieldStyle = { flex: 1 }
        c.TitleStyle.padding = '10px 10px 10px 0'
      } else {
        c.FieldStyle = {  }
      }
      return Object.assign({}, this.field, c)
    }
  },
  methods: {
    GetShowState(col) {
      return this.$parent.GetShowState(col)
    },
    handleChange(e) {
      this.$emit('change', e)
    },
    GetFieldError(col) {
      if (this.errors && this.errors.length > 0) {
        return this.errors.find((e) => {
          return e.name === col.Name
        })
      }
    },
    GetTitleStyle(col) {
      if (col.ForOneLine) {
        return {}
      }
      if (['Boolean', 'Bool'].indexOf(col.Type) > -1 || col.ForOneCell) {
        return {
          whiteSpace: 'nowrap'
        }
      } else
        return {
          width: 20 * 16 + 'px',
          display: 'inline-block',
          textAlignLast: 'right',
          lineHeight: 1
        }
    },
    GetValue(value) {
      return this.$refs.field.GetValue(value)
    },
    GetOrigin(value) {
      return this.$refs.field.GetOrigin(value)
    },
    GetChange(value) {
      return this.$refs.field.GetChange(value)
    },
    GetError(value) {
      return this.$refs.field.GetError(value)
    }
  }
}
</script>
