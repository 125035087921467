<template>
    <div class="flex-row middle nav list border-end">
        <div>
            <img src="/static/system/logo.png" height="20" width="20" style="border: 0" />
        </div>
        <div style="line-height: 1;font-size:20px;font-weight:700">{{ Title }}</div>
        <div class="flex-row">
            <div class="f14">系统公告:</div>
            <div> <vue-seamless-scroll :data="value" class="warp" :class-option="ScrollConfig">
                    <div class="flex-row text   active f14" v-for="(item, index) in Message" :key="index">
                        · {{ item.Title }}
                    </div>
                </vue-seamless-scroll>
            </div>
        </div>
        <div style="flex: 1"></div>
        <div class="flex-row list top">
            <div class="flex-column pointer" v-for="(item, index) in RightMenus" :key="index">
                <div :class="item.Icon" style="font-size:18px;color:#999"></div>
                <div style="color:#999" class="f14">{{ item.Title }}</div>

            </div>
        </div>
        <div>
            <div v-if="User">
                <el-dropdown trigger="click" @command="handleCommand" placement="bottom">
                    <div class="flex-row list">
                        <img src="@/assets/logo.png" alt="" class="faceimage">
                        <div class="flex-column left">
                            <div class="active"> {{ User.NickName }}</div>
                            <div class="gray">管理员</div>

                        </div>
                    </div>

                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="ModifyPassword">修改密码</el-dropdown-item>
                        <el-dropdown-item command="UserCenter" v-show="false">用户中心</el-dropdown-item>
                        <el-dropdown-item v-for="(r, index) in OtherUsers" :command="'SetRole_' + r.name"
                            :divided="index === 0" :key="index">切换{{ r.title }}</el-dropdown-item>
                        <el-dropdown-item divided command="Logout">退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div v-else>
                <a href="javascript:void(0);" @click="OnLogin">去登录</a>
            </div>
        </div>
    </div>
</template>
<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    props: {
        options: Array,
        value: Number,
        config: {
            type: Object,
            default: () => {
                return { label: 'Title' }
            }
        }
    },
    components: {
        vueSeamlessScroll,
    },
    computed: {
        OtherUsers() {
            let users = [
                /*  { title: "管理员", name: "Manager" },
        { title: "用户", name: "Users" }*/
            ]
            if (this.Role) {
                return users.filter((u) => {
                    return u.name !== this.Role
                })
            }
        },
        User() {
            return this.$matrix.GetUser()
        },
        Title() {
            return "酒销云-旗舰版" || this.$matrix.Config.Title
        },
        Message() {
            return [{ Title: "酒销云v1.0版本于2023年5月1日正式发布！" }, { Title: "商城模块新增批量上架" }]
        },
        RightMenus() {
            return [{
                Title: "消息",
                Icon: "el-icon-message"
            }, {
                Title: "待办",
                Icon: "el-icon-message-solid"
            }, {
                Title: "客服",
                Icon: "el-icon-phone"
            }, {
                Title: "反馈",
                Icon: "el-icon-edit-outline"
            }]
        },
        ScrollConfig() {
            return {
                step: 0.3,
            };
        },
    },
    methods: {
        OnLogin() {
            this.$matrix.GotoLogin()
        },
        handleCommand(command) {
            if (!command) return
            switch (command) {
                case 'Logout':
                    this.$matrix.Logout()
                    this.$matrix.GotoLogin()
                    break
                case 'ModifyPassword':
                    this.$matrix.ModifyPassword()
                    break
            }
        }
    }
}
</script>
<style scoped>
.nav {
    padding: 10px;
    transition: all;
    height: 40px;
    background-color: #ffffff6e;
    color: #7c4622;
    position: relative;
}

.nav::after {
    content: "  ";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px
}

.ring {
    border-radius: 50%;
    background-color: #8fd1e1;
    color: #fff;
    width: 20px;
    height: 20px;
    padding: 2px;
    font-size: 16px;
    margin-right: 3px;
}

.username {
    color: #7c4622;
    cursor: pointer;
}

.faceimage {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: 1px solid #eee
}

.gray {
    color: gray;
}

.active {
    color: #2d8cf0
}

.pointer {
    cursor: pointer;
}

.warp {
    height: 20px;
    overflow: hidden;
}
</style>
