<template>
    <el-dialog style="text-align: left" :title="config.Title" :visible="true" :width="dialogWidth"
        :before-close="handleClose" :close-on-click-modal="false">
        <mx-panel :type="ParentField.Link" panel="ChildForm" :config="ParentConfig"
            v-if="ParentField && config.Parent.Parent && $matrix.hasPanel(ParentField.Link,'ChildForm')  && config.Parent.Parent.Id" :value="config.Parent.Parent">
        </mx-panel>
        <mx-element type="FormTitle" :title="Entity.Title" class="panel4" v-if="children.length > 0">
        </mx-element>
        <mx-entity :mode="config.Method" :type="config.Type" :parent="config.Parent" :role="config.Role"
            :role-field="config.RoleField" ref="entity" :value="config.Value">
            <div class="formbox">
                <mx-form :type="mainFormType" :config="config" :value="TempValue ? TempValue : config.Value"
                    :columns="config.Columns" :key="formkey" ref="main" theme="v" @change="OnEntityChange"></mx-form>
            </div>
            <div v-for="child of children" :key="child.Name" v-show="visiabled[child.Name] !== false"
                style="margin: 0px 10px">
                <mx-element type="FormTitle" :title="child.Title" class="panel4">
                </mx-element>
                <div class="box" style="pading: 10px" v-if="config.Method === 'add'">
                    <div v-show="
                        child.DeleteEnabled &&
                        deleted &&
                        deleted[child.Name] === false
                    " class="mask">
                        <div class="vcenter">
                            {{ child.Title }}已经停用,数据不会被存储
                        </div>
                    </div>
                    <template v-if="child.IsList">
                        <mx-load-list v-if="child.DataLoad" :params="child.DataLoad.Params" :type="child.DataLoad.Type"
                            :role="child.DataLoad.Role" :role-field="child.DataLoad.RoleField"
                            :data-handler="child.DataHandle" :page-size="0" @loading="handleLoading($event, child)"
                            :auto-load="true">
                            <template slot-scope="res">
                                <mx-list :key="formkey" :type="child.ListType" :config="child"
                                    :value="TempValue ? GetChildData(child) : child.CreateList(child, res.data)"
                                    :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name"
                                    :options="res.data">
                                </mx-list>
                            </template>
                        </mx-load-list>
                        <mx-list :key="formkey" v-else :type="child.ListType" :config="child"
                            :value="TempValue ? GetChildData(child) : child.CreateList(child, child.Options)"
                            :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name" :options="child.Options">
                        </mx-list>
                    </template>
                    <mx-form style="padding: 40px 20px 10px 20px" :key="formkey" v-else type="Edit" :config="child"
                        :value="TempValue ? GetChildData(child, true) : child.Form.Value" :columns="child.Form.Columns"
                        :theme="child.Theme" :ref="child.Name">
                    </mx-form>
                </div>
                <div class="box" style="pading: 10px" v-else>
                    <template v-if="child.IsList">
                        <mx-load-list :type="child.Type" :page-size="0" :role="child.Role" :auto-load="true"
                            :role-field="child.RoleField" :params="getChildLoadParams(child, config.Value)">
                            <template slot-scope="list">
                                <div v-show="
                                    child.DeleteEnabled &&
                                    deleted &&
                                    deleted[child.Name] === false
                                " class="mask">
                                    <div class="vcenter">
                                        {{ child.Title }}已经停用,数据不会被存储
                                    </div>
                                </div>
                                <mx-load-list v-if="child.DataLoad" :params="child.DataLoad.Params"
                                    :type="child.DataLoad.Type" :role="child.DataLoad.Role"
                                    :role-field="child.DataLoad.RoleField" :data-handler="child.DataHandle" :page-size="0"
                                    @loading="handleLoading($event, child)" :auto-load="true">
                                    <template slot-scope="res">
                                        <mx-list :type="child.ListType" :config="child" :value="
                                            list.data ||
                                            child.CreateList(
                                                child,
                                                res.data
                                            )
                                        " :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name"
                                            :options="res.data">
                                        </mx-list>
                                    </template>
                                </mx-load-list>
                                <mx-list v-else :type="child.ListType" :config="child"
                                    :value="child.OnlyAdd ? [] : list.data || []" :columns="child.Form.Columns"
                                    :theme="child.Theme" :ref="child.Name" :options="child.Options">
                                </mx-list>
                            </template>
                        </mx-load-list>
                    </template>
                    <template v-else>
                        <mx-load-list v-if="!child.OnlyAdd" :type="child.Type" :page-size="1" :role="child.Role"
                            :auto-load="true" :role-field="child.RoleField"
                            :params="getChildLoadParams(child, config.Value)" :data-handler="OnLoaded(child)">
                            <template slot-scope="list">
                                <div v-show="
                                    child.DeleteEnabled &&
                                    deleted &&
                                    deleted[child.Name] === false
                                " class="mask" :key="list.timespan">
                                    <div class="vcenter">
                                        {{ child.Title }}已经停用,数据不会被存储
                                    </div>
                                </div>
                                <template v-if="list.data[0] && list.data[0].Id">
                                    <mx-form v-show="
                                        child.Form.Columns.filter((f) => {
                                            return !f.NoShow
                                        }).length > 0
                                    " style="padding: 40px 20px 10px 20px" :key="formkey" type="Edit" :config="child"
                                        :value="list.data[0]" :columns="child.Form.Columns" :theme="child.Theme"
                                        :ref="child.Name">
                                    </mx-form>
                                    <div style="padding: 20px" v-if="
                                        child.Form.Columns.filter((f) => {
                                            return !f.NoShow
                                        }).length === 0
                                    ">
                                        {{ child.Title }} 不容许变更数据
                                    </div>
                                </template>
                                <mx-form v-else style="padding: 40px 20px 10px 20px" :key="formkey" type="Edit"
                                    :config="child" :value="child.Form.AddForm.Value" :columns="child.Form.AddForm.Columns"
                                    :theme="child.Theme" :ref="child.Name">
                                </mx-form>
                            </template>
                        </mx-load-list>

                        <mx-form v-else style="padding: 40px 20px 10px 20px" :key="formkey" type="Edit" :config="child"
                            :value="child.Form.Value" :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name">
                        </mx-form>
                    </template>
                </div>
            </div>
        </mx-entity>
        <div v-if='TempDataRecord' class="info">
            你有一条{{ TempDataRecord.time }}保存的记录，是否继续编辑 <a href="javascript:void(0)" @click="LoadData">继续编辑</a>
        </div>
        <div slot="footer" class="flex-row">
            <div style="padding-left: 30px" v-if="config.OnDelete">
                <el-button @click="handleDelete">删 除</el-button>
            </div>
            <div style="flex: 1"></div>
            <div>
                <el-button type="info" @click="handleSaveTemp" v-if="TempData">保 存</el-button>
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="handleOk" v-if="TempData">提 交</el-button>
                <el-button type="primary" @click="handleOk" v-else="TempData">确 定</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        config: Object,
        number: Number
    },
    data() {
        return { visiabled: {}, deleted: null, formkey: new Date().getTime(), TempDataRecord: null, TempValue: null }
    },
    computed: {
        Entity() {
            return this.$matrix.Models[this.config.Type]
        },
        mainFormType() {
            return 'Edit'
        },
        dialogWidth() {
            return this.children.length > 0 ? '1024px' : '760px'
        },
        children() {
            if (this.config.Children) {
                return this.config.Children.filter((c) => {
                    return (
                        c.Form.Columns.filter((c) => {
                            return !c.NoShow
                        }).length > 0
                    )
                })
            }
            return []
        },
        Context() {
            return this.config.Context || {}
        },
        ParentField() {
            if (this.config.Parent) {
                return this.$matrix.Models[this.config.Type].Fields.find(f => { return f.Name === this.config.Parent.ParentField })
            }
        },
        TempData() {
            if (this.config.Parent && this.config.Parent.Parent) {
                return this.$matrix.Models[this.config.Type].Config.TempData && this.config.Method === 'add'
            }
        },
        ParentPanelName() {
            return this.ParentField.Link + '.' + 'ChildForm'
        },
        ParentConfig() {
            return { Type: this.ParentField.Link, FormType: this.config.Type, Role: this.config.Role, RoleField: this.config.RoleField }
        },
        TempDataKey() {
            if (this.config.Parent && this.config.Parent.Parent) {
                return "TempData_" + this.config.Type + "_" + this.config.Parent.Parent.Id
            }
        }
    },
    methods: {
        GetChildData(child, isfirst) {
            let datas = this.TempValue._Childrens.find(d => {
                return d[child.Name]
            })
            if (isfirst) {

                if (datas) return datas[child.Name][0]

            } else {
                if (datas) return datas[child.Name]
            }
        },
        OnEntityChange(e) {
            for (let child of this.children) {
                let com = this.$refs[child.Name]
                if (Array.isArray(com)) com = com[0]
                if (com && com.OnParentChange) com.OnParentChange()
            }
            this.visiabled = this.SetVisibile(e)
        },
        handleLoading(e, child) { },
        OnLoaded(e, child) { },
        handleClose(e) {
            if (this.config && this.config.OnClose) {
                this.config.OnClose()
            } else this.$matrix.CloseModelDialog(this.config.Type, this.number)
        },
        handleDelete(e) {
            this.$matrix
                .Confirm(
                    '您正要删除一条数据，一旦删除，数据可能无法恢复请确认!'
                )
                .then((res) => {
                    if (res) {
                        if (this.config && this.config.OnDelete) {
                            this.config.OnDelete()
                        } else this.$matrix.CloseModelDialog(this.config.Type, this.number)
                    }
                })
        },
        handleOk(e) {
            if (this.config && this.config.OnSuccess) {
                if (this.$refs.main) {
                    try {
                        let value = this.$refs.entity.GetFormValue(this.$refs, this.config.Value)
                        if (this.TempDataKey) {
                            this.$matrix.SetLocalStorage(this.TempDataKey, null, true)
                        }
                        this.config.OnSuccess(value)

                    } catch (e) {
                        this.$matrix.ShowError(e)
                    }
                } else {
                    this.config.OnSuccess({})
                }
            } else this.$matrix.CloseModelDialog(this.config.Type, this.number)
        },
        handleSaveTemp(e) {
            let value = this.$refs.entity.GetFormValue(this.$refs, this.config.Value, true)
            if (this.TempDataKey) {
                this.$matrix.SetLocalStorage(this.TempDataKey, { data: value, time: this.$matrix.formatDateTime(new Date()) }, true)
                this.$matrix.ShowToast('您的信息已经临时保存在本地，您可以在下次编辑时将他加载进来')
            }
        },
        getChildLoadParams(child, data) {
            let params = child.Form.Columns.reduce((t, item) => {
                if (['Link', 'Object', 'Array'].indexOf(item.Type) > -1) {
                    t['Get' + item.Name] = true
                }
                return t
            }, {})
            if (child.Form.AddForm && child.Form.AddForm.Columns) {
                params = child.Form.AddForm.Columns.reduce((t, item) => {
                    if (['Link', 'Object', 'Array'].indexOf(item.Type) > -1) {
                        t['Get' + item.Name] = true
                    }
                    return t
                }, params)
            }
            return Object.assign(
                { Parent: { Id: data.Id, Type: data.Type } },
                params
            )
        },
        GetMainFormValue() {
            let value = this.$refs.main.GetValue({})
            let entity = this.$matrix.Models[this.config.Type]
            let defaultValue = this.$matrix.Put({}, entity.Fields)

            return Object.assign({}, defaultValue, this.config.Value, value)
        },
        SetVisibile(data) {
            let vs = {}
            let entity = this.$matrix.Models[this.config.Type]
            let defaultValue = this.$matrix.Put({}, entity.Fields)

            this.children.forEach(c => {
                if (c.ParentState) {
                    vs[c.Name] = this.$matrix.Include(c.ParentState, Object.assign(defaultValue, data))
                }
                else {
                    vs[c.Name] = true
                }
            })
            return vs
        },
        LoadData(data) {
            this.TempValue = this.TempDataRecord.data
            this.formkey = new Date().getTime()
            let key = "TempData_" + this.config.Type + "_" + this.config.Parent.Parent.Id
            //this.$matrix.SetLocalStorage(key, null, true)
            this.TempDataRecord = null
        }
    },
    created() {
        this.visiabled = this.SetVisibile(this.config.Value)

        if (this.TempData) {
            let key = "TempData_" + this.config.Type + "_" + this.config.Parent.Parent.Id
            let data = this.$matrix.GetLocalStorage(key, true)
            //console.log(data, 111)
            this.TempDataRecord = data
        }
    }
}
</script>
<style scoped>
.box {
    position: relative;
}

.info {
    padding: 20px;
    background-color: #f7f7f7;
    color: red;
    margin: 0 5px
}

.formbox {

    margin: 0px 10px 10px 10px;
    padding: 10px 0 0 10px;
    border: 1px solid #f7f7f7;
}
</style>