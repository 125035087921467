import Detail from './detail'
import Edit from './edit'
import EditRadio from './editradio'
import EditDiv from './editdiv'

export default {
  components: [
    { Name: 'Detail', Component: Detail },
    { Name: 'Edit', Component: Edit },
    { Name: 'EditRadio', Component: EditRadio },
    { Name: 'EditDiv', Component: EditDiv }

  ],
  Is () {
    if (this.edit) {
      let EditStyle = this.config
        ? this.config.EditStyle || this.config.Theme || ''
        : '';
        console.log(EditStyle,'111')
      switch (EditStyle) {
        case 'Radio':
          return 'EditRadio'
        case 'Div':
          return 'EditDiv'
      }
      return 'Edit'
    }
    return 'Detail'
  }
}
