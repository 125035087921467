<template>
  <div ref="box">
    <el-table
      v-if="showStyle"
      :data="items"
      row-key="path"
      default-expand-all
      fit
      :show-header="false"
      max-height="400px"
      :style="showStyle"
      :cell-style="{ verticalAlign: 'top', paddingLeft: '10px' }"
    >
      <el-table-column prop="label" label="实体" width="200">
        <span slot-scope="row">
          {{ row.row.label }}
        </span>
      </el-table-column>
      <el-table-column label="权限">
        <div slot-scope="row" style="font-size:16px">
          <div class="tip flex-row middle">
            <div @click="selectAll(row.row)" style="padding:5px 10px">
              全选
            </div>
          </div>
          <div>
            <el-checkbox
              v-model="roles[row.row.path + '_query']"
              v-if="row.row.query"
              @change="OnDataChange"
              ><span class="title">查询</span></el-checkbox
            >
            <template v-if="row.row.edit">
              <el-checkbox
                v-model="roles[row.row.path + '_add']"
                v-if="row.row.query"
                @change="OnDataChange"
                ><span class="title">新增</span></el-checkbox
              >
              <el-checkbox
                v-model="roles[row.row.path + '_modify']"
                v-if="row.row.query"
                @change="OnDataChange"
                ><span class="title">编辑</span></el-checkbox
              >
              <el-checkbox
                v-model="roles[row.row.path + '_del']"
                v-if="row.row.query"
                @change="OnDataChange"
                ><span class="title">删除</span></el-checkbox
              >
            </template>
          </div>
          <div v-if="row.row.acts">
            <el-checkbox
              v-model="roles[act.path + '_add']"
              v-for="(act, index) in row.row.acts"
              :key="index"
              @change="OnDataChange"
              ><span class="title">{{ act.label }}</span>
            </el-checkbox>
          </div>
          <div v-if="row.row.childdatas" v-show="false">
            <div v-for="(childdata, index) in row.row.childdatas" :key="index">
              <div class="title">{{ childdata.label }}</div>
              <el-checkbox
                v-model="roles[childdata.path + '_query']"
                v-if="childdata.query"
                @change="OnDataChange"
                ><span class="title">查询</span></el-checkbox
              >
              <template v-if="childdata.edit">
                <el-checkbox
                  v-model="roles[childdata.path + '_add']"
                  v-if="childdata.query"
                  @change="OnDataChange"
                  ><span class="title">新增</span></el-checkbox
                >
                <el-checkbox
                  v-model="roles[childdata.path + '_modify']"
                  v-if="childdata.query"
                  @change="OnDataChange"
                  ><span class="title">编辑</span></el-checkbox
                >
                <el-checkbox
                  v-model="roles[childdata.path + '_del']"
                  v-if="childdata.query"
                  @change="OnDataChange"
                  ><span class="title">删除</span></el-checkbox
                >
              </template>
            </div>
          </div>
        </div>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import Json from ".";
export default {
  props: {
    config: Object,
    value: String
  },
  data() {
    return { checked: false, showStyle: null, roles: {}, selection: {} };
  },
  computed: {
    items() {
      return this.$matrix.GetEntitys(
        this.config.Role || this.$matrix.GetCurrentRoleName()
      );
    }
  },
  methods: {
    selectAll(item) {
      const ms = ["_query", "_add", "_modify", "_del"];
      let isCancel = false;
      if (this.selection[item.path]) {
        isCancel = true;
      }
      if (!isCancel) {
        ms.forEach(m => {
          if (this.roles[item.path + m]) isCancel = true;
        });
      }
      if (!isCancel && item.acts) {
        item.acts.forEach(m => {
          if (this.roles[m.path]) isCancel = true;
        });
      }
      if (isCancel) {
        this.selection[item.path] = false;
        ms.forEach(m => {
          this.roles[item.path + m] = false;
        });
        if (item.acts) {
          item.acts.forEach(m => {
            this.roles[m.path] = false;
          });
        }
      } else {
        this.selection[item.path] = true;
        ms.forEach(m => {
          this.roles[item.path + m] = true;
        });
        if (item.acts) {
          item.acts.forEach(m => {
            this.roles[m.path] = true;
          });
        }
      }
      this.$emit("change", JSON.stringify(this.roles));
    },
    init(value) {
      let res = {};
      try {
        if (value) res = JSON.parse(value);
      } catch (e) {}
      let foo = item => {
        if (res[item.path] === undefined) {
          res[item.path] = true;
        }
        if (item.query && res[item.path + "_query"] === undefined) {
          res[item.path + "_query"] = true;
        }
        if (item.edit && res[item.path + "_add"] === undefined) {
          res[item.path + "_add"] = true;
        }
        if (item.edit && res[item.path + "_modify"] === undefined) {
          res[item.path + "_modify"] = true;
        }
        if (item.edit && res[item.path + "_del"] === undefined) {
          res[item.path + "_del"] = true;
        }
        if (item.acts) {
          item.acts.forEach(act => {
            if (res[act.path] === undefined) {
              res[act.path] = true;
            }
          });
        }
        if (item.children) {
          item.children.forEach(c => {
            foo(c);
          });
        }
      };
      this.items.forEach(item => {
        foo(item);
      });
      return res;
    },
    OnDataChange(data) {
      this.$emit("change", JSON.stringify(this.roles));
    }
  },
  created() {
    this.roles = this.init(this.value);
    //this.Data = JSON.stringify(this.roles);
    this.$emit("change", JSON.stringify(this.roles));
  },
  mounted() {
    this.$nextTick(() => {
      this.showStyle = { width: this.$refs.box.clientWidth + "px" };
    });
  }
};
</script>
<style scoped>
.tip {
  font-size: 14;
  color: gray;
  background-color: #f7f7f7;
  cursor: pointer;
}
</style>
