<template>
    <div style="padding: 3px 1px">
        <div v-if="TableFormConfig && Categorys" :class="Categorys.length > 0 ? 'border' : ''">
            <div class="flex-row st" v-if="Titles && Titles.length > 0">
                <div v-for="t in Titles" class="title" style="width: 150px" :key="t">
                    {{ t }}
                </div>
                <div class="title" style="width: 150px">
                    {{ TableFormConfig.Field.Title }}
                </div>
                <div style="flex: 1">
                    <div class="title">数据</div>
                    <div class="flex-row st" v-if="DataTitles && DataTitles.length > 0">
                        <div style="flex: 1" class="title" v-for="t in DataTitles" :key="t">
                            {{ t }}
                        </div>
                    </div>
                </div>
            </div>
            <Row :key="cat.Id" v-for="cat in Categorys" :config="cat" :level="0" :fields="Fields" ref="row"
                :lookupdatas="LookUpDatas" :lookups="LookUps"></Row>
            <mx-element type="Empty" title="请配置选项" v-if="Categorys.length === 0" />
        </div>
    </div>
</template> 
<script>
import list from 'matrix.node.ui/master/list'
import Row from './TableFormRow'
export default {
    mixins: [list.component, list.options],
    data() {
        return { Categorys: null, LookUpDatas: [] }
    },
    components: {
        Row
    },
    computed: {
        Fields() {
            return this.columns.filter((f) => {
                return f.Name !== this.config.EnumField.Name && !f.NoShow
            })
        },
        TableFormConfig() {
            let TableFormConfig
            if (this.config) {
                let field = this.$matrix.Models[this.config.Type].Fields.find(
                    (t) => {
                        return t.Name === this.config.EnumField.Name
                    }
                )
                TableFormConfig = JSON.parse(
                    JSON.stringify(field.TableForm || {})
                )

                TableFormConfig.Field = field
                TableFormConfig.CategoryField = this.$matrix.Models[
                    field.Options.Entity
                ].Fields.find((t) => {
                    return t.Name === TableFormConfig.CategoryField
                })
            }
            return TableFormConfig
        },
        Titles() {
            let titles = []
            if (this.TableFormConfig) {
                if (this.TableFormConfig.CategoryField) {
                    let level = this.TableFormConfig.CategoryField.Options.Level
                    if (level) {
                        for (let i = 0; i < level; i++) {
                            switch (i) {
                                case 0:
                                    titles.push(
                                        '一级' +
                                        this.TableFormConfig.CategoryField
                                            .Title
                                    )
                                    break
                                case 1:
                                    titles.push(
                                        '二级' +
                                        this.TableFormConfig.CategoryField
                                            .Title
                                    )
                                    break
                                case 2:
                                    titles.push(
                                        '三级' +
                                        this.TableFormConfig.CategoryField
                                            .Title
                                    )
                                    break
                                default:
                                    titles.push(
                                        i +
                                        1 +
                                        '级' +
                                        this.TableFormConfig.CategoryField
                                            .Title
                                    )
                                    break
                            }
                        }
                    }
                }
            }
            return titles
        },
        DataTitles() {
            if (
                this.TableFormConfig.Field &&
                this.TableFormConfig.Field.LookUp
            ) {
                return this.TableFormConfig.Field.LookUp.map((t) => {
                    return t.Title
                }).concat('数据')
            }
        },
        LookUps() {
            if (this.TableFormConfig) {
                if (this.TableFormConfig.Field) {
                    if (this.TableFormConfig.Field.LookUp) {
                        return this.TableFormConfig.Field.LookUp
                    }
                }
            }
        }
    },
    methods: {
        GetError(errors) {
            errors = errors || []
            this.$refs.row
                .forEach((r) => {
                    let errs = r.GetError()

                    if (errs && errs.length > 0)
                        errors = errors.concat(errs)
                })
            return errors
        },
        GetValue() {
            let values = []
            let res = this.$refs.row.forEach((r) => {
                values = values.concat(r.GetValue())
            })

            return values
        },
        GetChange() {
            let values = []
            let res = this.$refs.row.forEach((r) => {
                values = values.concat(r.GetChange())
            })
            return values
        },
        GetLookUp(config) {
            let LookValue = null
            if (config && config.LookUp && config.LookUp.length > 0) {
                let getParentValue = () => {
                    let data = {}
                    let selffoo = this.$matrix.FindParentFunction(
                        this,
                        'GetSelfValue'
                    )
                    if (selffoo) {
                        data = selffoo()
                    }
                    if (!data.Parent || !data.Parent.Id) {
                        let parentfoo = this.$matrix.FindParentFunction(
                            this,
                            'GetMainFormValue'
                        )
                        if (parentfoo) {
                            data.Parent = parentfoo()
                        }
                    }
                    return data
                }
                let parent = getParentValue()
                LookValue = this.$matrix.Put(parent, config.LookUp)
                let errors = this.$matrix.Check(LookValue, config.LookUp)
                if (errors) {
                    return
                }
                return LookValue
            } else {
                return {}
            }
        },
        OnParentChange(e) {
            if (
                this.LookUps &&
                this.LookUps.find((config) => {
                    return (
                        config.LookUp &&
                        config.LookUp.find((l) => {
                            return (
                                (l.Name && l.Name.indexOf('Parent.') > -1) ||
                                (l.Path && l.Path.indexOf('Parent.') > -1)
                            )
                        })
                    )
                })
            ) {
                this.LoadLookUpDatas()
            }
        },
        LoadLookUpDatas() {
            if (this.TableFormConfig.Field) {
                if (this.TableFormConfig.Field.LookUp) {
                    let pos = []
                    for (let c of this.TableFormConfig.Field.LookUp) {
                        //console.log(c, 11)
                        let field = this.TableFormConfig.Field
                        let lookUpValue = {}
                        if (c.LookUp) {
                            lookUpValue = this.GetLookUp(c)
                        }
                        if (!lookUpValue) {
                            pos.push(Promise.resolve({ Items: [] }))
                        } else {
                            let params = Object.assign(
                                { Page: 1, PageSize: 0 },
                                lookUpValue,
                                c.Params
                            )
                            pos.push(
                                this.$matrix.AjaxGet4(
                                    this.Context,
                                    c.Class || c.Entity,
                                    'GetList',
                                    params,
                                    this.config.Role,
                                    this.config.RoleField
                                )
                            )
                        }
                        Promise.all(pos).then((datas) => {
                            this.LookUpDatas = datas
                        })
                    }
                }
            }
        }
    },
    created() {
        if (this.TableFormConfig) {
            this.$matrix
                .LoadField(
                    this.TableFormConfig.CategoryField,
                    this.config.Role,
                    this.config.RoleField,
                    1,
                    0,
                    {},
                    this.Context
                )
                .then((datas) => {
                    let getChild = (parent, top) => {
                        parent.LabelOptions = this.options
                            .filter((op) => {
                                return (
                                    op[this.TableFormConfig.CategoryField.Name]
                                        .Id === parent.Id
                                )
                            })
                            .map((t) => {
                                let val = this.value.find((d) => {
                                    return (
                                        d[this.TableFormConfig.Field.Name]
                                            .Id === t.Id
                                    )
                                })

                                return {
                                    Option: t,
                                    Value: val || this.CreateNewData(t)
                                }
                            })
                        if (parent.LabelOptions.length > 0)
                            top.LabelQty += parent.LabelOptions.length
                        let children = datas.filter((d) => {
                            return d.Parent.Id === parent.Id
                        })
                        if (children.length > 0) {
                            parent.children = children.map((c) => {
                                return getChild(c, top)
                            })
                            top.MaxLevel += 1
                        }
                        return parent
                    }

                    this.Categorys = datas
                        .filter((data) => {
                            return data.Parent.Id === 0
                        })
                        .map((data) => {
                            data.LabelQty = 0
                            data.MaxLevel = 1
                            return getChild(data, data)
                        })
                        .filter((data) => {
                            return data.LabelQty > 0
                        })
                })

            this.LoadLookUpDatas()
        }
    }
}
</script>
<style scoped>
.box {
    padding: 5px 12px;
    cursor: pointer;
    border: 1px solid red;
    margin: 0 0 10px 10px;
    color: #999;
}

.border {
    border-right: 1px solid #777;
    border-top: 1px solid #777;
}

.title {
    padding: 10px 5px;
    border-left: 1px solid #777;
    border-bottom: 1px solid #777;
}
</style>