<template>
	<div class="flex-row middle box start list   ">
		<div style="font-size:20px;font-weight:600">
			{{ title }}
		</div>
		<slot></slot>
	</div>
</template>
<script>
export default {
	props: {
		value: Number,
		config: Object,
		title: String,
		icon: String
	}
};
</script>
<style scoped>
.box {
	padding: 20px 0px;
	position: relative;
}
</style>
