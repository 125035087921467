<template>
  <mx-element
    type="ChildPageTitle"
    v-show="detail"
    :title="Meta.Title"
    :key="detail.Type"
    v-if="parent && parent.Type"
  >
    <div
      v-if="Config"
      class="flex-row end middle"
      style="padding-right: 20px"
      slot="button"
    >
      <mx-element
        v-for="(act, index) of Config.List.TypeAction"
        :key="index"
        :type="act.Type"
        :title="act.Title"
        :name="act.Name"
        :options="act.Options"
        @click="handleFunction"
      >
      </mx-element>
      <mx-element
        v-if="
          Config.List.Condition.SortFields &&
            Config.List.Condition.SortFields.length > 0
        "
        type="Dropdown"
        :options="Config.List.Condition.SortFields"
        :value="SortIndex"
        @change="handleSortChange"
        :config="toolbarConfig"
        icon="sort"
        ref="sortbutton"
      />
      <mx-element
        :key="ListType + '_Panel'"
        style="margin-left: 5px"
        type="SwitchButton"
        @click="handleShowPanel"
        :value="ListType"
        theme="panel"
        ref="showpanelButton"
        title="显示数据面板"
        v-if="GetListPanel(Config.RoleField)"
      />
    </div>
    <mx-page
      style="margin-top: 1px"
      type="Child"
      :config="detail"
      :parent="parent"
      :key="timespan"
      :is-child="isChild"
      :master="master"
      @meta-change="OnMetaChange"
      @config-change="OnConfigChange"
      ref="listcomp"
    >
    </mx-page>
  </mx-element>
</template>
<script>
import detail from "matrix.node.ui/page/detail";
import panels from "@/projects/panels/index";
export default {
  mixins: [detail("child")],
  name: "child",
  props: {},
  data() {
    return {
      Meta: {},
      Config: null,
      roleFieldKey: new Date().getTime(),
      Panel: "",
      ListType: 1,
      SortIndex: 0
    };
  },
  computed: {
    toolbarConfig() {
      return { Icon: true };
    }
  },
  methods: {
    OnMetaChange(val) {
      this.Meta = val;
    },
    OnConfigChange(val) {
      this.Config = val;
    },
    handleSortChange(val) {
      this.$refs.listcomp.execute("handleSortChange", val);
    },
    handleFunction(val) {
      this.$refs.listcomp.execute("handleFunction", val);
    },
    handleListPanelChange(val) {
      this.ListType = !val ? 1 : 0;
    },
    handleShowPanel() {
      this.$refs.listcomp.execute(
        "handleShowPanel",
        this.ListType == 1 ? this.GetListPanel(this.Config.RoleField) : ""
      );
    },
    GetListPanel(RoleFieldName) {
      RoleFieldName = RoleFieldName || this.Config.Role;
      let name =
        this.master.Type.replace(/\,/g, "_") + "_List_" + RoleFieldName;
      if (panels[name]) return name;
      return;
    }
  }
};
</script>
