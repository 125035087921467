<template>
  <component
    :is="Is"
    :value="value"
    :config="config"
    :title="title"
    :options="options"
    :icon="icon"
    @change="handleChange"
    @click="handleClick"
  >
    <slot></slot>
  </component>
</template>
<script>
import ColumnNone from "./None";
import ColumnTab from "./Tab";
import ColumnTabLabel from "./TabLabel";
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    icon: String,
    theme: { type: String, default: "" },
    options: Array
  },
  components: { ColumnNone, ColumnTab, ColumnTabLabel },
  computed: {
    Is() {
      switch (this.theme.toLowerCase()) {
        case "tab":
          return "ColumnTab";
        case "tablabel":
          return "ColumnTabLabel";
        default:
          return "ColumnNone";
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
    handleClick(val) {
      this.$emit("click", val);
    }
  }
};
</script>
