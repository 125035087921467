<template>
  <div class="noselect" style="padding: 10px">
    <div v-for="(item, index) in options" :key="item.Id || index" style="margin: 5px" class="flex-row start middle panel">
      <div style="flex: 1">
        <mx-panel v-if="Panel" :type="config.EnumField.Entity" panel="ListEdit" :value="item"></mx-panel>
        <span v-else style="font-size: 16px">{{ GetTitle(item) }}</span>
      </div>
      <div style="padding: 10px 0 0 0">
        <mx-form type="Simple" :columns="Fields" :value="GetData(item)" :ref="'field' + index"
          :config="{ HideTitle: true }" :style="{ visibility: item._delete ? 'hidden' : '' }">
        </mx-form>
      </div>
      <div style="margin-left: 10px">
        <el-switch v-model="item._delete" active-color="gray" inactive-color="blue">
        </el-switch>
        <span>{{ item._delete ? '删除' : '有效' }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import list from 'matrix.node.ui/master/list'
import panels from '@/projects/panels/index'
export default {
  mixins: [list.component, list.options],
  computed: {
    Fields() {
      return this.columns.filter((f) => {
        return f.Name !== this.config.EnumField.Name && !f.NoShow
      })
    },
    Panel() {
      let name = (this.config.EnumField.Entity + '.ListEdit')
        .split('.')
        .join('_')

      if (panels[name]) return name
      return ''
    }
  },
  methods: {
    GetValue() {
      let result = []
      for (let i = 0; i < this.options.length; i++) {
        let item = this.options[i]
        let com = this.$refs['field' + i]
        if (Array.isArray(com)) com = com[0]
        let data = this.GetData(this.options[i])
        if (item._delete) {
          if (data.Id) {
            data._delete = true
            result.push(data)
          }
        } else {
          let error = com.GetError()
          if (!error) {
            let val = com.GetValue()
            data = Object.assign(data, val)
            result.push(data)
          }
        }
      }
      return result
    },
    GetChange() {
      let result = []
      for (let i = 0; i < this.options.length; i++) {
        let item = this.options[i]
        let com = this.$refs['field' + i]
        if (Array.isArray(com)) com = com[0]
        let data = this.GetData(this.options[i])
        if (item._delete) {
          if (data.Id) {
            data._delete = true
            result.push(data)
          }
        } else {
          let error = com.GetError()
          if (!error) {
            let change = com.GetChange()
            if (change && Object.keys(change).length > 0) {
              if (data.Id) change.Id = data.Id
              else {
                change = Object.assign({}, data, change)
              }
              result.push(change)
            }
          }
        }
      }
      return result.length > 0 ? result : null
    }
  }
}
</script>
<style scoped>
.box {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid red;
  margin: 0 0 10px 10px;
  color: #999;
}
</style>
