<template>
  <div :style='config.InnerStyle'>
    <div class="line2">{{value}}<slot></slot></div>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view(String)]
};
</script>