<template>
  <div
    :title="title"
    @click="handleClick"
    :class="Data ? 'up' : 'down'"
    class="bt"
  >
    <i :class="ThemeConfig.IconClass" style="font-size: 18px"></i>
  </div>
</template>
<script>
const themes = {
  default: {
    IconClass: "el-icon-s-grid"
  },
  panel: {
    IconClass: "el-icon-s-grid"
  }
};
export default {
  props: {
    title: String,
    icon: String,
    name: String,
    theme: String,
    value: [String, Number]
  },
  data() {
    return { Data: true };
  },
  methods: {
    handleClick() {
      this.Data = !this.Data;
      this.$emit("click", this.name);
    },
    getIconClass(icon) {
      //console.log(this.icon);
      return icon;
    }
  },
  computed: {
    ThemeConfig() {
      return themes[(this.theme || "default").toLowerCase()] || {};
    }
  },
  created() {
    this.Data = this.value === 1;
  }
};
</script>
<style scoped>
.bt {
  height: 18px;
  width: 18px;
  padding: 10px;
  cursor: pointer;
}
.up {
  background-color: #fff;
  border: 1px solid #ddd;
}
.down {
  background-color: #409eff;
  border: 1px solid #ddd;
  color: #fff;
}
</style>
