<template>
  <mx-element
    type="ColumnFrame"
    theme="none"
  >
    <template v-for="(col, index) in columns">
      <div :key="index">
        <slot
          :config="col"
          :index="index"
        ></slot>
      </div>
    </template>
  </mx-element>
</template>
<script>
export default {
    props: {
        config: Object,
        columns: Array
    }
}
</script>
