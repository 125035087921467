<template>
  <div class="panel3">
    <div :id="'charts' + Id" style="min-height: 296px"></div>
    <mx-column-data
      :config="config"
      :dataconfig="dataconfig"
      @change="handleChange"
    ></mx-column-data>
  </div>
</template>
<script>
import pie from "./echartpie";
import echat from "./echart.js";
export default {
  components: {},
  props: {
    config: Object,
    index: Number,
    dataconfig: Object
  },
  mixins: [echat],
  computed: {},
  data() {
    return { List: null, Data: null };
  },

  methods: {
    create(category, data) {
      return pie.create.call(this, category, data);
    },
    handleChange(data) {
      let groupfield = data.GroupField[0] || "";
      let foo = this.$matrix.GetTitleFun(groupfield, this.config.Type);
      this.show(
        data.Data.map(d => {
          return foo(d);
        }),
        data.Data.map(d => {
          return d[data.Fields];
        })
      );
    }
  }
};
</script>
