<template>
  <div :style="config.InnerStyle" class="line2">{{ Text }}</div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view([Boolean,Number])],
  computed: {
    Text() {
      if (this.config.NoText) return "";
      if (this.config.Options) {
        let Option = this.config.Options.find((t) => {
          return t.Id === this.value;
        });
        if (Option) return Option.Title;
      }
      return this.value ? "是" : "否";
    },
  },
};
</script>