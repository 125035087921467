import Detail from "./detail";
import Edit from "./edit";
import Password from "./password";
import TextArea from "./textarea";
export default {
  components: [
    { Name: "Detail", Component: Detail },
    { Name: "Edit", Component: Edit },
    { Name: "Password", Component: Password },
    { Name: "TextArea", Component: TextArea },
  ],
  Is(edit, config) {

    if (edit) {
      switch (config.EditStyle) {
        case "Password":
          return "Password";
      }
     if (this.config.Length >= 200 && !this.config.OneLine && !this.oneLine) return "TextArea";
      return "Edit";
    }
    return "Detail";
  }
};
