<template>
  <div class="box">
    <div v-if="options && options" class="flex-row start wrap">
      <div
        v-for="(item, index) in options"
        :key="(item ? item.Id : '') || index"
        class="file-box"
      >
        <div class="file">
          <el-upload
            class="file-display"
            :multiple="false"
            :accept="Exts"
            :action="Url"
            :headers="Headers"
            list-type="text"
            :show-file-list="false"
            :on-success="onSuccess(item)"
            :before-upload="onBeforeUpload(item, index)"
            :on-progress="onProgress"
          >
            <template v-if="!GetActiveState(item)">
              <div class="file-empty"></div>
              <div class="flex-row start" style="margin-top: 5px">
                <div
                  style="flex: 1"
                  class="line2 f14 filename"
                  :style="{ color: GetReq(item) ? 'red' : '' }"
                >
                  上传{{ GetTitle(item) }}
                </div>
              </div>
            </template>
            <template v-else>
              <div class="file-upload"></div>
              <div class="flex-row start" style="margin-top: 5px">
                <div style="flex: 1" class="line2 f14 filename">
                  {{ GetTitle(item) }}
                </div>
              </div>
            </template>
          </el-upload>
          <div style="height: 16px; margin-top: 10px" class="noselect">
            <el-progress
              :stroke-width="16"
              :text-inside="true"
              :percentage="percentage"
              v-show="uploading && index === currentIndex"
            ></el-progress>
            <div
              v-if="GetActiveState(item) && !uploading"
              style="text-align: center"
            >
              <span
                v-if="IsEdit"
                type="text"
                class="btn"
                size="mini"
                @click="handleEdit(item)"
                ><i class="el-icon-edit"></i>编辑</span
              >
              <span
                type="text"
                class="btn"
                size="mini"
                @click="handleDelete(item)"
                ><i class="el-icon-delete"></i>删除</span
              >
              <span
                type="text"
                class="btn"
                size="mini"
                @click="handleDownload(item)"
                ><i class="el-icon-download"></i>下载</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex-row start wrap st">
      <div class="file-box">
        <div class="file">
          <el-upload
            v-show="!percentage"
            class="file-display"
            :multiple="false"
            :accept="Exts"
            :action="Url"
            :headers="Headers"
            list-type="text"
            :show-file-list="false"
            :on-success="onSuccessData"
            :before-upload="onBeforeUploadData"
            :on-progress="onProgress"
          >
            <template>
              <div class="file-empty"></div>
              <div class="flex-row start" style="margin-top: 20px">
                <div style="flex: 1" class="line1 title ht">
                  上传文件
                </div>
              </div>
            </template>
          </el-upload>
          <div
            style="height: 16px"
            class="noselect"
            v-show="percentage && percentage < 100"
          >
            <el-progress
              :stroke-width="16"
              :text-inside="true"
              :percentage="percentage"
              v-show="uploading"
            >
            </el-progress>
          </div>
        </div>
      </div>
      <div
        v-for="(item, index) in Data"
        :key="(item ? item.Id : '') || index"
        class="file-box"
        v-show="item && !item._delete"
      >
        <div class="file">
          <div class="file-upload"></div>
          <div
            style="
              text-align: center;
              font-size: 12px;
              margin-top: 5px;
            "
            class="line1"
          >
            {{ GetTitle(item) || " " }}
          </div>
          <div style="height: 16px; margin-top: 5px" class="noselect">
            <div v-if="!uploading" style="text-align: center">
              <span
                v-if="IsEdit"
                type="text"
                class="btn"
                size="mini"
                @click="handleEditData(item, index)"
                ><i class="el-icon-edit"></i>编辑</span
              >
              <span
                type="text"
                class="btn"
                size="mini"
                @click="handleDeleteData(item, index)"
                ><i class="el-icon-delete"></i>删除</span
              >
              <span
                style="display: none"
                type="text"
                class="btn"
                size="mini"
                @click="handleDownloadData(item, index)"
                ><i class="el-icon-download"></i>下载</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-box">
      <p v-if="UpLoad.Exts">* 文件格式支持 {{ UpLoad.Exts.join(", ") }}</p>
      <p>* 文件大小不能超过 {{ MaxSize }}</p>
      <p v-if="Entity.Config.MinSize">
        * 至少需要上传 {{ Entity.Config.MinSize }} 条文件
      </p>
      <p v-if="Entity.Config.MaxSize">
        * 文件数不能超过 {{ Entity.Config.MaxSize }} 条
      </p>
      <p v-if="EnumField && EnumField.Req">
        *
        <span v-if="EnumField.NoReq"
          >除了<span style="color:red;font-weight:700">{{
            EnumField.NoReq
          }}</span
          >非必传，其他</span
        >文件都必须上传
      </p>
    </div>
  </div>
</template>
<script>
import list from "matrix.node.ui/master/list";
export default {
  mixins: [list.component, list.options],
  props: {
    config: Object,
    value: Array,
    columns: Array,
    theme: String
  },
  data() {
    return {
      uploading: false,
      showUpload: true,
      percentage: 0,
      currentIndex: -1
    };
  },
  computed: {
    MaxSize() {
      if (this.UpLoad) {
        let mx = this.UpLoad.MaxSize;
        let unit = (this.UpLoad.SizeUnit || "K") + "b";
        return mx + unit;
      }
    },
    Entity() {
      return this.$matrix.Models[this.config.Type];
    },
    IsEdit() {
      return (
        this.config.Form.Columns.filter(c => {
          return !c.NoShow && c.Name !== this.UpLoad.Name;
        }).length > 0
      );
    },
    Exts() {
      return (
        this.UpLoad.Exts || ["pdf", "doc", "docx", "xlxs", "xls", "jpg", "jepg"]
      )
        .map(e => {
          if (e[0] === ".") return e;
          return "." + e;
        })
        .join(",");
    },
    UpLoad() {
      return this.config.Upload;
    },
    Url() {
      return this.$matrix.Config.ImageSite + "/invoke";
    },
    Headers() {
      let Methods = (this.UpLoad.Method || this.UpLoad.Type + ".Upload").split(
        "."
      );
      let rolefield =
        this.UpLoad.RoleField ||
        this.UpLoad.Role ||
        this.$matrix.GetCurrentRoleName();
      if (
        this.UpLoad.RoleFields &&
        this.UpLoad.RoleFields[rolefield] !== undefined
      ) {
        rolefield = this.UpLoad.RoleFields[rolefield];
      }
      let head = Methods.shift();
      Methods.unshift(rolefield);
      Methods.unshift(head);
      return {
        Authorization: this.$matrix.CreateHeader(
          Methods.join("."),
          this.UpLoad.Role || this.$matrix.GetCurrentRoleName(),
          rolefield
        )
      };
    }
  },
  methods: {
    GetReq(item) {
      if (this.config.Upload.Req) {
        if (!this.EnumField.NoReq) return true;
        let title = this.GetTitle(item);
        if (this.EnumField.NoReq.split(",").indexOf(title) > -1) return false;
        return true;
      }
      return false;
    },
    onProgress(e) {
      this.percentage = e.percentage;
    },
    handleDelete(item) {
      if (item) {
        let data = this.GetActiveState(item);
        if (data) {
          data._delete = true;
        }
        this.Data = [].concat(this.Data);
      }
    },

    handleDeleteData(item, index) {
      let data = this.Data[index];
      if (data) {
        data._delete = true;
      }
      this.Data = [].concat(this.Data);
    },
    handleEdit(item) {
      if (item) {
        let data = this.GetActiveState(item);
        if (data) {
          this.$parent.ModifyByDialog(data).then(res => {
            if (res) {
              data = Object.assign(data, res);
              this.Data = [].concat(this.Data);
            }
          });
        }
      }
    },
    handleEditData(item, index) { 
      this.$parent.ModifyByDialog(item).then(res => {
        if (res) {
          this.Data[index] = res;
          this.Data[index] = Object.assign(item, res);
          this.Data = [].concat(this.Data); 
        }
      });
    },
    handleDownload(item) {
      let data = this.GetActiveState(item);
      if (data) {
        this.$matrix.DownloadFile(
          this.$matrix.Config.Site + data[this.UpLoad.Name],
          this.GetTitle(item)
        );
      }
    },
    handleDownloadData(data) {
      if (data) {
        this.$matrix.DownloadFile(
          this.$matrix.Config.Site + data[this.UpLoad.Name],
          this.GetTitle(item)
        );
      }
    },
    onSuccessData(res) {
      this.uploading = false;
      if (res.Decrypt) {
        res = this.$matrix.Decrypt(res.Decrypt);
      }
      if (res.Error) return this.$message.error("上传文件错误:" + res.Error);
      if (res.url) {
        let data = this.config.Form.CreateNew();
        data[this.UpLoad.Name] = res.url;
        if (res.name) {
          data.FileName = res.name;
        }
        if (res.size) {
          data.FileSize = res.size;
        }
        //console.log(res, this.UpLoad.Name, 111)
        if (this.IsEdit) {
          this.$parent.ModifyByDialog(data).then(res => {
            this.Data.push(res);
            this.Data = [].concat(this.Data);
          });
        } else {
          this.Data.push(data);
          this.Data = [].concat(this.Data);
        }
      }
    },
    onSuccess(item) {
      return function(res) {
        this.uploading = false;
        if (res.Decrypt) {
          res = this.$matrix.Decrypt(res.Decrypt);
        }
        if (res.Error) return this.$message.error("上传文件错误:" + res.Error);
        if (res.url) {
          if (item) {
            let data = this.GetActiveState(item);
            if (data) {
              data[this.UpLoad.Name] = res.url;
              if (res.name) {
                data.FileName = res.name;
              }
              if (res.size) {
                data.FileSize = res.size;
              }
              this.Data = [].concat(this.Data);
            } else {
              data = this.config.Form.CreateNew();
              data[this.UpLoad.Name] = res.url;
              if (res.name) {
                data.FileName = res.name;
              }
              if (res.size) {
                data.FileSize = res.size;
              }
              data = this.SetEnumField(item, data, this.config);
              this.Data.push(data);
              this.Data = [].concat(this.Data);
            }
          } else {
            data = this.config.Form.CreateNew();
            data[this.UpLoad.Name] = res.url;
            if (res.name) {
              data.FileName = res.name;
            }
            if (res.size) {
              data.FileSize = res.size;
            }
            if (this.IsEdit) {
              this.$parent.ModifyByDialog(data).then(res => {
                this.Data.push(res);
                this.Data = [].concat(this.Data);
              });
            } else {
              this.Data.push(data);
              this.Data = [].concat(this.Data);
            }
          }
        }
      }.bind(this);
    },
    onBeforeUploadData(file) {
      if (this.uploading) return false;

      if (this.UpLoad.MaxSize > 0) {
        let mx = this.UpLoad.MaxSize;
        let unit = this.UpLoad.SizeUnit || "k";
        if (unit.indexOf("k") === 0) {
          mx = mx * 1024;
        } else if (unit.indexOf("m") === 0) {
          mx = mx * 1024 * 1024;
        }
        if (file.size / 1024 > mx) {
          this.$matrix.ShowError(
            "文件大小不能超过" + mx + unit.toUpperCase() + "b!"
          );
          return false;
        }
      }

      if (file.name) {
        let extnames = file.name.split(".");
        if (extnames.length > 1) {
          let ext = (extnames[extnames.length - 1] || "").toLowerCase();
          let exts = (
            this.UpLoad.Exts || [
              "pdf",
              "doc",
              "docx",
              "xlxs",
              "xls",
              "jpg",
              "jepg"
            ]
          ).map(e => {
            if (e[0] === ".") return e;
            return "." + e;
          });
          if (exts.indexOf("." + ext) > -1) {
            this.uploading = true;
            this.percentage = 0;
            return true;
          }
        }
      }
      this.$matrix.ShowError("无法识别上传文件格式!");
      return false;
    },
    onBeforeUpload(item, index) {
      return function(file) {
        this.currentIndex = index;
        if (this.uploading) return false;
        if (this.UpLoad.MaxSize > 0) {
          if (file.size / 1024 / 1024 > this.UpLoad.MaxSize) {
            this.$matrix.ShowError(
              "文件大小不能超过" + this.UpLoad.MaxSize + "MB!"
            );
            return false;
          }
        }
        if (file.name) {
          let extnames = file.name.split(".");
          if (extnames.length > 1) {
            let ext = (extnames[extnames.length - 1] || "").toLowerCase();
            let exts = (
              this.UpLoad.Exts || [
                "pdf",
                "doc",
                "docx",
                "xlxs",
                "xls",
                "jpg",
                "jepg"
              ]
            ).map(e => {
              if (e[0] === ".") return e;
              return "." + e;
            });
            if (exts.indexOf("." + ext) > -1) {
              this.uploading = true;
              this.percentage = 0;
              return true;
            }
          }
        }
        this.$matrix.ShowError("无法识别上传文件格式!");
        return false;
      }.bind(this);
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #f7f7f7;
  padding: 2px 2px 0 0;
}

.file-box {
  background-color: #fff;
  margin: 0 0 2px 2px;
  width: calc(20% - 2px);
}

.file-empty {
  width: 100%;
  height: 64px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAA4hJREFUeF7tmz1PFEEYx/+zh1rKLX4AMQxEbDSxgFgIia2JDRijnVFkT43aGLUAjInG5hLJrUSN0QSNyhfQCiqpSGwwgQWxsPB174wVkdsxQ45Tz32ZXW5vZ725dp+bef6/fZ55eWaWoMl/pMn1QwFQERBAoHXc6tMyuAHgQMpgvXbKuFY6R2f8/PZNAX3C6oaD+ZQJ/9tdDXvsM/StlwZfAG0Fa5IRHE8zAMLw5FuOnogEIGta0wToSzMABswUDdpfNwC8QZmB1L6wugOwDSr11KmbFvvzBSkA9U4BFQEqBdQYoAZBmafBxGcBvneIE9AOhtml83TVq4/EAOiFhVEQbSRO8dW2CR7aw/SkW1/JATCXVgC2syEAADirLdnSxfZSbX+JANhuLu7KgCw3Sjzvx2FsXynX+UYKANwJ3bSeATjaCAgMWCoalEqVAusQCgujjGgH44RAGOa2aOT2p+GOz9IBiFO4aNuJjAGizjXCTgFQ22FVD1AFkUQqQnwPQDIYqVcRlZeyWBljQXV9aRZCMVWQ52yD7g8zeyQyC1ROj6bDOCpq65TRHyYKEgHAxTR1BHAATT8GiIZ03HaJpUDcwkTb100rD+BC1Z45p+1c132v//sWON3yWvZzgTZz4QiDdhlAD59K1zLOwI+hrq//HQBehPludL7zEsbHJJHZI3URkDUX7xDwI3uiA3gODdf9zv+DUidVAHTTegFgoEbUPDQMRoWQGgAe4jdYRIaQCgAB4jcFQXoAguIjQ5AaQEjxkSBICyCi+CqEDGs59CXX/jGVs4BuWj0AZoOc93vOGK4Uc/RWUBtSRkC2sHyMEOfpP84zZ6z2/JGv9tyLMOyebXQOpRJAa36lVdu2xusLe6sCCEbhOMQDgAmArxGqP9E6gpQRwFXoE1Yvc3CYgLSD4bGd63jpdgK9cQts/VZr2RkE0boBTNkGnQp6+/y5tADcnPcDICLWzUYB8CMn23ZYRYDLLZSgm6BBqaFSQKWADwE1Brh8L1C36/IMr8hWTNqn6IegPN14LsUgKOqskB1D3s7RS0K2lSs4bitBvw8igtr2HwTvWuOE4WxQI5t5HqbK7L5HEFvze/noCyDOMz/uEANuFg16VRSg2x5BdM0fCUBlTd4Nhgdg6BV1VMiOkEcOfo6Vhne/F7KvGP3eI6AjzJo/MoAwzqXRVuqr740AqgA0grLMffwCgPSnXzloqUwAAAAASUVORK5CYII=)
    no-repeat center;
  background-size: contain;
}

.file-upload {
  width: 100%;
  height: 64px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAeBJREFUeF7t29FtgzAQBuCDRkxR8VapaZdIl+g6TdfpEs0SbSr1DXUKlELFAxJBWNicf9e2/jwGsOHj7NxhUojh81VXvWkb4vv7pi2m7Z7r6rhv2iOir2mbV51ON/w3wNB/L/KKRogaYLghaIToAdAISQAgEZIBQCEkBYBASA7AN0KSAD4RkgXwhZA0gA+E5AG0CFkAaBCyAdiKkBXAFoTsAFwRsgRwQcgWwBYhawAbhOwB1hCiBUA/CxzbJ0CsT4UZAYEEohkC6OudrztENwcQACzACJgtvTkPgWGFZifydte0nz5u1nddPVxEnguRFx/trbWhjoC+6572P5fTWkcu28+3u0NRlu8ux2zdVw1gamDrCY3HhVqEJYB2DmAEGAQ5BJQCnAMCvYqjngRvRB595QBj0Ay5wK/IhzKIrA5XAwyJkHTdyVcuMOQAUpaHZBIhK+aId1JHQMTXZnVqBNAmQlbMEe+kjgDf1SDKylRl6gEA1SAKYanKVAOgagEUwjzDJIB2EmQEgKpBDgGQAOeAWZWpngQR1SDo5stSlakG8F0Noi7eVGWqAVAnHKpdAmjzgFB3CtUPI4ARcP2/ROfFUVRohmqXQ4BDgENg8X0oviRlmoRCrdlxEgwkYPoV+AMPDuRQMC5umAAAAABJRU5ErkJggg==)
    no-repeat center;
  background-size: contain;
}

.file {
  padding: 10px;
  display: block;
}

.btn {
  padding: 3px;
  cursor: pointer;
  color: #409eff;
  font-size: 14px;
}

.info-box {
  background-color: #fff;
  padding: 10px 20px;
  text-align: left;
}

.title {
  color: #409eff;
  font-size: 14px;
  font-weight: 700;
}

.filename {
  font-size: 14px;
  height: 28px;
  line-height: 1;
}
</style>
<style>
.file-display .el-upload {
  display: block;
  width: 100%;
}
</style>
