<template>
  <el-button
    :title="isIcon ? title : ''"
    :icon="getIconClass(ThemeConfig.Icon)"
    @click="handleClick"
    :style="buttonStyle"
    :plain="isPlain"
    :type="isLink ? 'text' : buttonType || ThemeConfig.ButtonType"
    :loading="loading"
    :size="size"
    :disabled="disabled"
    >{{ title }}</el-button
  >
</template>
<script>
const themes = {
  default: function() {
    return {
      theme: "default",
      component: "element-text",
      ButtonType: "button"
    };
  },
  number: function() {
    return {
      theme: "number",
      component: "element-text"
    };
  },
  submit: function() {
    return {
      theme: "submit",
      component: "element-text"
    };
  },
  search: {
    theme: "search",
    Icon: "el-icon-search",
    ButtonType: "primary",
    ShowLoading: true
  },
  search2: {
    theme: "search",
    Icon: "el-icon-search"
  },
  sort: {
    theme: "sort",
    Icon: "el-icon-sort",
    ButtonType: "info",
    ShowLoading: true
  },
  import: {
    theme: "import",
    Icon: "el-icon-upload2"
  },
  export: {
    theme: "export",
    Icon: "el-icon-download"
  },
  add: {
    theme: "add",
    Icon: "el-icon-circle-plus-outline",
    ButtonType: "primary"
  },
  enable: {
    theme: "enable",
    Icon: "el-icon-edit-outline",
    ButtonType: "primary"
  },
  modify: {
    theme: "modify",
    Icon: "el-icon-edit",
    ButtonType: "primary"
  },
  delete: {
    theme: "delete",
    Icon: "el-icon-delete",
    ButtonType: "danger"
  },
  submit: {
    theme: "submit",
    Icon: "el-icon-refresh-right",
    ButtonType: "primary",
    ShowLoading: true
  },
  clear: {
    theme: "clear",
    Icon: "el-icon-delete",
    ButtonType: "danger",
    ShowLoading: true
  },
  reflash: {
    Icon: "el-icon-refresh",
    ButtonType: "danger"
  },
  login: {
    Label: "登录",
    theme: "login",
    Icon: "el-icon-user",
    ButtonType: "primary",
    ShowLoading: true
  },
  child: {
    theme: "child",
    Icon: "el-icon-magic-stick"
  }
};
export default {
  props: {
    title: String,
    icon: String,
    name: String,
    theme: String,
    isLink: Boolean,
    isIcon: Boolean,
    isPlain: Boolean,
    loading: Boolean,
    disabled: Boolean,
    size: String,
    buttonType: String,
    config: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  methods: {
    handleClick() {
      this.$emit("click", this.name);
    },
    getIconClass(icon) {
      //console.log(this.icon);
      return icon;
    }
  },
  computed: {
    buttonStyle() {
      switch (this.size) {
        case "mini":
          return { padding: "10px" };
        default:
          return {
            height: "40px",
            padding: "10px 15px"
          };
      }
    },
    ThemeConfig() {
      return themes[(this.theme || "default").toLowerCase()] || {};
    }
  }
};
</script>
