<template>
  <div :style="config.InnerStyle" class="line2">
    <span :style="TextStyle"> {{ Text }}</span>
  </div>
</template>
<script>
const enum_colors = [
  '#1da308',
  '#ff6433',

]
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view([Boolean, Number])],
  computed: {
    Text() {
      if (this.config.NoText) return "";
      if (this.config.Options) {
        let Option = this.config.Options.find((t) => {
          return t.Id === this.value;
        });
        if (Option) return Option.Title;
      }
      return this.value ? "是" : "否";
    },
    TextStyle() { 
      if (this.config.noStyle) {
      }
      else { 
        return {
          color: this.value ? enum_colors[0] : enum_colors[1] || '#fff',
          fontSize: '16px'
        }
      }
    }
  },
};
</script>