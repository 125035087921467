import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DataPanel",
    ItemIs: "DataPanelItem",
    Is: "DataPanelFrame",
    Components: {
        DataPanelFrame: Frame,
        DataPanelItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({}, config.Config);
        if (dataconfig && !dataconfig.Fields) {
            dataconfig.Fields = "IdCount";
            dataconfig.PageSize = 0;
        }
        if (config.DataField) {
            if (!dataconfig.Params) {
                dataconfig.Params = {};                
            }
            dataconfig.Params["Get" + config.DataField] = true;
        }
        return dataconfig;
    }
}