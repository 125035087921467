
/*
  version : matrix node v4.0
*/
export default {
  Title: '驾安评',
  SubTitle: '管理中心',
  AppName: '驾安评',
  Role: "Manager",
  RoleClass: "Manager",
  OrgRole: "",
  OrgRoleClass: "",
  Root: "PC-Manager",
  Site: "https://wx.jiapingan.com",
  ImageSite: "https://wx.jiapingan.com",
  MainColor: '#37437f',
  SecondColor: '#f7f7f7',
  Key: 'fenixnolan19790313matrixnodev040',
  IV: '52638609@qq.com',
  IsAES: false,
  IsH5: true,
  Login: { borderRadius: "0px", Left: { leftbg: "/static/system/bg.jpg", hasMask: true, MainTitle: { Text: "驾安评", Style: { fontSize: "40px", color: "#fff" } }, LoginSubTitle: { Text: "司机评估评测", Style: { fontSize: "32px", color: "#fff" } } }, Right: { hasCode: false, LogoStyle: { width: "" } } },
  Theme: { DefaultColor: "#1055BC", Menu: { Width: 170, BackgroundColor: "#1055BC", Color: "#fff", ActiveColor: "" }, SubMenu: { BackgroundColor: "rgba(93, 135, 255, 0.1)", FontColor: "#1055BC" } },
}