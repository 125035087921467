<template>
  <div tip="表单-配置表单">
    <div :style="Style">
      <mx-element :title="config.Title" type="Title" />
      <mx-form
        v-if="Data && Form"
        :key="formkey"
        type="Setting"
        :config="config"
        :value="Data"
        :columns="Form.Columns"
        ref="form"
      >
      </mx-form>
    </div>
    <mx-column-data :config="config" :dataconfig="dataconfig" ref="loader" @change="OnLoaded">
      <template slot-scope="scope"> </template>
    </mx-column-data>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    index: Number,
    dataconfig: Object,
    panel: String,
    parent: Object
  },
  data() {
    return { Data: null, formkey: new Date().getTime() }
  },
  computed: {
    Style() {
      return Object.assign({ padding: '10px' }, this.config.Style)
    },
    Entity() {
      return this.$matrix.Models[this.config.Type]
    },
    Form() {
      if (this.Data)
        return this.$matrix.GetForm(this.Context, this.Data.Id ? 'modify' : 'add', this.config.Type, this.parent)
    },
    AddForm() {
      return this.$matrix.GetForm(this.Context, 'add', this.config.Type, this.parent)
    },
    ModifyForm() {
      return this.$matrix.GetForm(this.Context, 'modify', this.config.Type, this.parent)
    }
  },
  methods: {
    OnLoaded(data) {
      this.Data = data.Data[0] || this.AddForm.Value
    },
    Reload() {
      this.$refs.loader.Load(1)
    },
    GetDisabled(name) {
      if (name) {
        if (this.config.Roles && this.config.Roles[this.config.RoleField]) {
          return false === this.config.Roles[this.config.RoleField][this.config.Type + '_' + name]
        }
      }
      return false
    },
    OnSave(params, doing, done) {
      if (doing) {
        let error = this.$refs.form.GetError()
        if (!error) {
          doing(true)
          if (this.Data && this.Data.Id) {
            let changedata = this.$refs.form.GetChange()
            if (changedata && Object.keys(changedata).length > 0) {
              changedata.Id = this.Data.Id

              this.$matrix
                .AjaxGet3(this.config.Type, 'modify', changedata, this.config.Role, this.config.RoleField)
                .then((data) => {
                  this.Data = Object.assign({}, this.Data, changedata)
                  //this.formkey = new Date().getTime()
                  if (done) done(true)
                })
                .catch((e) => {
                  this.$matrix.ShowError(e)
                  if (done) done(true)
                })
            } else {
              if (done) done(true)
            }
          } else {
            let value = this.$refs.form.GetValue()
            this.$matrix
              .AjaxGet3(this.config.Type, 'add', value, this.config.Role, this.config.RoleField)
              .then((data) => {
                value.Id = data.Id
                this.Data = value
                //this.formkey = new Date().getTime()
                if (done) done(true)
              })
              .catch((e) => {
                this.$matrix.ShowError(e)
                if (done) done(true)
              })
          }
        } else {
          doing(false)
        }
      }
    },
    OnAddClick(e) {
      this.$matrix.AddData(
        this.Context,
        {},
        this.config,
        (data) => {
          this.$refs.loader.Load(1)
        },
        this.parent
      )
    },
    OnModifyClick(item) {
      let deletehandler = (res) => {
        this.IsDelete = true
        this.$refs.loader.Load(1)
      }
      this.$matrix.ModifyData(
        this.Context,
        item,
        this.config,
        (res) => {
          this.IsModify = true
          this.$refs.loader.Load(1)
        },
        this.GetDisabled('del') ? null : deletehandler
      )
    }
  }
}
</script>
