<template>
  <mx-view
    mode="view"
    :parent="parent"
    :role="config.Role"
    :role-field="config.RoleField"
    :name="name"
    ref="view"
    node="面板"
    @change="handleMetaChange($event)"
  >
    <template slot-scope="scope">
      <mx-layout
        v-if="config && config.layout"
        :layers="config.layout.Layer"
        :columns="getColumns(config.layout.Columns, scope.meta.Columns)"
        :config="config.layout.Config"
      >
        <slot slot-scope="cell">
          <mx-column
            style="min-height: 100%"
            :config="cell.column"
            :items="cell.column.Columns"
            :type="cell.column.Comp"
            @click="OnClick"
            :parent="parent"
            :parent-type="parentType"
            :theme="cell.column.Theme"
            :query="query"
            @event="OnEvent"
          >
          </mx-column>
        </slot>
      </mx-layout>
      <div v-else :class="config.ViewClass" :style="config.ViewStyle">
        <mx-column
          v-for="(col, index) in getColumns2(scope.meta.Columns)"
          :key="index"
          :config="col"
          :items="col.Columns"
          :type="col.Comp"
          :parent="parent"
          :parent-type="parentType"
          @click="OnClick"
          :theme="col.Theme"
          :query="query"
          :class="config.ViewColumnClass"
          :style="config.ViewColumnStyle"
          @event="OnEvent"
        >
        </mx-column>
      </div>
      <slot :meta="scope.meta"></slot>
    </template>
  </mx-view>
</template>
<script>
import base from 'matrix.node.ui/master/column'
export default {
  mixins: [base],
  methods: {
    OnEvent() { }
  }
}
</script>
