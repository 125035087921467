<template>
  <mx-element type="ChildPageTitle" :title="Title" :hide="FullScreen">
    <mx-page type="Home" :config="detail" :parent="parent" :key="timespan" :is-child="isChild" :name="Name" v-if="Name"
      theme="List" @meta-change="OnMetaChange">
    </mx-page>
  </mx-element>
</template>
<script>
import detail from "matrix.node.ui/page/detail";
export default {
  mixins: [detail("list")],
  props: {},
  data() {
    return { Title: "", FullScreen: false };
  },
  methods: {
    OnMetaChange(val) {
      this.Title = `${val.meta.Page.Page}`;
      let page = val.meta.Page
      if (page.Config && page.Config.FullScreen) {
        this.FullScreen = true
      }
    }
  },
  computed: {
    Name() {
      return this.$route.query.name;
    }
  }
};
</script>
