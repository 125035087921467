<template>
  <mx-column-data
    :config="config"
    :dataconfig="dataconfig"
    style="height: 100%"
    @change="OnChange"
  >
    <template>
      <slot :data="Data" :list="List">
        <Panel
          :config="config"
          :data="Data"
          :list="List"
          :index="index"
        ></Panel>
      </slot>
    </template>
  </mx-column-data>
</template>
<script>
import Panel from "./panel";
export default {
  props: {
    config: Object,
    index: Number,
    dataconfig: Object,
    panel: String
  },
  components: { Panel },
  data() {
    return { Data: 0, List: null };
  },
  methods: {
    OnChange(data) {
      let foo = function(value) {
        if (!value) return 0;
        if (typeof value === Number) return value;
        let v = parseFloat(value);
        if (isNaN(v)) return v;
        return Math.round(v * 1000) / 1000;
      };
      let name = Array.isArray(this.dataconfig.Fields)
        ? this.dataconfig.Fields[0]
        : this.dataconfig.Fields;
      if (data.Data.length === 0) {
      } else {
        this.Data = data.Data.reduce((t, item) => {
          return t + foo(item[name]);
        }, 0);
        let GroupField = Array.isArray(this.dataconfig.GroupFields)
          ? this.dataconfig.GroupFields[0]
          : this.dataconfig.GroupFields;
        if (GroupField) {
          data.Data.sort((a, b) => {
            if (a[name] > b[name]) return -1;
            else return 1;
          }); 
          GroupField = this.$matrix.Models[this.config.Type].Fields.find(t => {
            return t.Name === GroupField;
          });
          if (GroupField) {
            let fields = this.$matrix.GetTitleFields(GroupField.Link);
            if (fields && fields.length > 0) {
              this.List = data.Data.map(t => {
                let title = t[GroupField.Name][fields[0].Name];
                return { title: title, count: foo(t[name]) };
              });
            }
          }
        }
      }
      this.$emit("change", { Data: this.Data, List: this.List });
    }
  }
};
</script>
