<template>
	<span :style="config.InnerStyle">
		<span :style="TextStyle">{{ Text }}</span>
	</span>
</template>
<script>
const enum_colors = [
	'#7FFF00-green',
	'#A52A2A',
	'#B8860B',
	'#9400D3',
	'#2F4F4F',
	'#4B0082',
	'#F08080'
]
import base from 'matrix.node.ui/field.js'
export default {
	mixins: [base.view(Number)],
	computed: {
		Text() {
			let selectItem = this.Options.find((t) => {
				return t.Id === this.value
			})
			return selectItem ? selectItem.Title : ''
		},
		Options() {
			return this.config.Options || []
		},
		TextStyle() {  
			if (this.config.noStyle) {
			}
			else {
				if (this.value) {
					let cls = enum_colors
					if (this.config.Colors) cls = this.config.Colors
					let colors =
						cls[this.value % cls.length].split('-')
					return {
						backgroundColor: colors[0],
						borderRadius: '4px',
						color: colors[1] || '#fff',
						padding: '4px 8px',
						fontSize: '14px'
					}
				}
			}
		}
	}
}
</script>
