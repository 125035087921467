<template>
    <div class="list">
        <div v-for="(col, index) in columns" :key="index + ActiveIndex">
            <div class="menu flex-row r30" :style="itemStyle">
                <span class="line1"> {{ col.Title }}</span>
                <div :class="!noshow[index] ? 'active' : ''" class="el-icon-arrow-right" @click="handleClick(col, index)">
                </div>
                <slot :config="col" :index="index" v-if="col.Config.Fields"></slot>
            </div>
            <div class="sub-menu" :class="!noshow[index] ? 'active' : ''">

                <mx-page style="padding: 10px" type="Home" :config="col" :name="col.Title + '下级菜单'" theme="Panel"
                    @click="OnMenuClick" :query="{ CurrentTitle: CurrentTitle, menu: menu }">
                </mx-page>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        columns: Array,
        query: Object
    },
    data() {
        return { CurrentTitle: undefined, menu: '', ActiveIndex: -1, noshow: undefined }
    },
    watch: {
        query(val) {
            if (val) {
                this.menu = val.menu || ''
            }
        }
    },
    computed: {
        ColumnConfig() {
            let config = {}
            this.columns.forEach(col => {
                if (col.ColumnConfig) {
                    config = Object.assign(config, col.ColumnConfig)
                }
            })
            return config
        },
        Cls() {
            if (this.ColumnConfig.Direction === 'v') {
                return ['flex-row start middle wrap']
            } else {

            }
        },
        itemStyle() {
            return { "--activeColor": this.ColumnConfig.Color || '#37437f' }
        }
    },
    methods: {

        Isfit(config, index, current) {
            return (
                (config.ListParams &&
                    this.$matrix.Include(
                        config.ListParams,
                        this.$route.query
                    )) ||
                this.ActiveIndex === index
            )
        },
        handleClick(item, index) {
            this.noshow[index] = !this.noshow[index]
        },
        OnMenuClick(e) {
            this.CurrentTitle = e.view.Title
            this.$emit('itemclick', e.view)
        }

    },
    created() {
        if (this.columns) {
            let res = {}
            this.columns.forEach((col, index) => {
                res[index] = false
            })
            this.noshow = res;
        }
    }
}
</script>
<style scoped>
.menu-box {
    background-color: #f7f7f7;
}

.menu {
    padding: 10px;
    background-color: #fff;
    margin-top: 1px;
    cursor: pointer;
    border-radius: 50px;
}

.menu.active {
    color: #fff;
    background-color: var(--activeColor);
}

.sub-menu {
    max-height: 0;
    overflow: hidden;
    transition: max-height .45s;
}

.sub-menu.active {
    max-height: 400px;
}

.el-icon-arrow-right {
    transition: transform .3s;

}

.el-icon-arrow-right.active {
    transform: rotate(90deg);
}
</style>
