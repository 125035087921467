 <template>
  <div class="flex-row middle end">
    <template v-if="Recommand && Recommand.length > 0">
      <el-button
        type="text"
        v-for="item in Recommand"
        :key="item.Name"
        @click="handleClick(item)"
        >{{ item.Title }}
      </el-button>
    </template>
    <template v-if="NoRecommand.length > 0">
      <el-dropdown
        style="margin-left: 10px; padding: 0"
        trigger="click"
        @command="handleClick"
      >
        <el-button type="text">
          <span> <i class="el-icon-more"></i> </span
        ></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            icon="el-icon-link"
            v-for="item in NoRecommand"
            :key="item.Name"
            :command="item"
            :disabled="item.Disabled"
            >{{ item.Title }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </template>
  </div>
</template> 
<script>
import Button from "../Button";
export default {
  components: { Button },
  props: {
    title: String,
    icon: String,
    name: String,
    theme: String,
    options: Array,
    nums: { type: Number, default: 2 }
  },

  computed: {
    ValidateButtons() {
      return this.options.reduce((total, r) => {
        total = total.concat(r.Options);
        return total;
      }, []);
    },
    Recommand() {
      if (this.nums === 0)
        return this.ValidateButtons.filter(v => {
          return !v.Disabled;
        });
      return this.ValidateButtons.filter(v => {
        return (
          !v.Disabled &&
          (v.Title.length <= 2 || this.ValidateButtons.length < 3)
        );
      }).filter((v, index) => {
        return index < this.nums;
      });
      return [];
    },
    NoRecommand() {
      return this.ValidateButtons.filter(v => {
        return !this.Recommand.find(r => {
          return v.Name === r.Name;
        });
      });
    }
  },
  methods: {
    GetValidButtons(val) {
      return val.filter(v => {
        return !v.Disabled;
      });
    },
    handleClick(button) {
      this.$emit("click", button);
    }
  }
};
</script>