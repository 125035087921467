<template>
    <div>

        <div class="border-container" style="overflow: hidden;">
            <div class="name-title flex-row left">平均分横向对比分析</div>
            <div id="Pie3" style="width: 100%; height: 400px"></div>
            <span class="top-left border-span"></span>
            <span class="top-right border-span"></span>
            <span class="bottom-left border-span"></span>
            <span class="bottom-right border-span"></span>
        </div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: {
        Index: Number,
        data: Array,
        config: Object,
        query: Object
    },
    computed: {
        Idx() {
            return Math.floor(Math.random() * 10);
        },
    },
    methods: {
        myEcharts(data) {
            // 基于准备好的dom，初始化echarts实例

            var myChart = echarts.init(document.getElementById("Pie3"));

            var option
            if (data.length) {
                option = {
                    xAxis: {
                        type: 'category',
                        data: data.map(item => { return item.name })
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },

                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            data: data.map(item => { return item.value }),
                            type: 'bar',
                            barWidth: "20",
                            itemStyle: {
                                normal: {
                                    //这里是重点
                                    color: function (params) {
                                        //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                        var colorList = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#d48265'];
                                        return colorList[params.dataIndex]
                                    }
                                }
                            }
                        }
                    ]
                }
            } else {
                option = {
                    title: {
                        text: "暂无数据",
                        x: "center",
                        y: "center",
                        textStyle: {
                            fontSize: 14,
                            fontWeight: "normal",
                            color: "#fff",
                        },
                    },
                }

            }



            myChart.setOption(option);
        },
        LoadData() {

            this.$matrix.AjaxGet3("Test", 'GetList', {
                GetDriver: true,
                Groups: ['Driver', 'TotalScore'],
                Page: 1,
                PageSize: 0,
                DriverSearchParams: { GetCompany: true },
                Company: this.query.Company
            }, this.config.Role, this.config.RoleField).then(datas => {
                let Items = datas.Items.filter(item => { return item.Driver && item.Driver.Company && item.Driver.Company.Id }).reduce((t, n) => {
                    let res = t.find(item => {
                        if (item.Company && item.Company.Id) {
                            return item.Company.Id === n.Driver.Company.Id
                        }
                    })
                    if (!res) {
                        res = {
                            Company: n.Driver.Company,
                            Child: [],
                            TotalScore: n.TotalScore
                        }
                        t.push(res)
                    }
                    res.Child.push(n)
                    return t
                }, [])
                let data = []
                if (Items.length) {
                    data = Items.map(item => {
                        return {
                            name: item.Company.Name,
                            value: Math.round(item.Child.reduce((t, n) => { return t += n.TotalScore }, 0) / item.Child.length)
                        }
                    })
                }
                this.myEcharts(data);
            })






        }
    },

    mounted() {
        this.LoadData()
    },
};
</script>
<style scoped>
.border-container {
    position: relative;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, .15);
    box-shadow: inset 0 0 50px rgba(255, 255, 255, .1), 0 0 5px rgba(0, 0, 0, .3)
}

.border-container span.border-span {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    opacity: .5
}

.border-container span.top-left {
    top: -2px;
    left: -2px;
    border-top: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.top-right {
    top: -2px;
    right: -2px;
    border-top: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.border-container span.bottom-left {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.bottom-right {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.name-title {
    font-size: 16px;
    font-weight: bolder;
    color: #00ffff;
}
</style>