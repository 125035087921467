<template>
    <div>
    <table>taable</table>
 
    </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
import panels from '@/projects/panels/index'
export default {
    mixins: [base.edit(Object)],
    props: {
        isEnd: Boolean,
        isLoading: Boolean,
        options: Array
    },
    data() {
        return { Options: [] }
    },
    computed: {
        Panel() {
            let name = (this.config.Link + '.Field').replace(/\./g, '_')
            if (panels[name]) return name
            return ''
        },
        LabelField() {
            if (this.config && this.config.LabelField)
                return this.config.LabelField
            if (this.config && this.config.Options && this.config.Options.Label)
                return this.config.Options.Label
            return 'Title'
        },
        LabelFields() {
            let entity = this.$matrix.Models[this.config.Options.Entity]
            return entity.Fields.filter((f) => {
                return f.Label && f.Label.indexOf('Title') > -1
            })
        },
        Label() {
            if (this.value) {
                return this.value[this.LabelField]
            }
            return ''
        },
        SearchKey() {
            if (this.config.Options.Entity) {
                let entity = this.$matrix.Models[this.config.Options.Entity]
                if (entity) {
                    if (
                        entity.Fields.find((f) => {
                            return !!f.IsSearchFields
                        })
                    ) {
                        let names = entity.Name.split('.')
                        return names[names.length - 1] + 'Keywords'
                    }
                    let label = entity.Fields.find((f) => {
                        return f.Label && f.Label.indexOf('Label') > -1
                    })
                    if (label) {
                        return label.Name + 'Like'
                    }
                    let title = entity.Fields.find((f) => {
                        return f.Label && f.Label.indexOf('Title') > -1
                    })
                    if (title) {
                        return title.Name + 'Like'
                    }
                    return
                }
            }
        },
        Orders() {
            let entity = this.$matrix.Models[this.config.Options.Entity]
            if (entity) {
                return entity.Config.Orders || 'IdDESC'
            }
            return 'IdDESC'
        }
    },
    created() {
        this.InitOptions(this.options)
    },
    watch: {
        options(val) {
            this.InitOptions(val)
        }
    },
    methods: {
        GetTitle(item) {
            return (
                this.LabelFields.map((field) => {
                    return item[field.Name]
                })
                    .filter((val) => {
                        return !!val
                    })
                    .join(',') || '未知选项'
            )
        },
        ResetByLookUp() {
            if (this.Data !== this.config.EmptyValue) {
                this.Data = this.config.EmptyValue
                this.OnChange()
            }
            if (this.options) {
                this.$emit('load', { type: 'Clear' })
            }
        },
        InitOptions(options) {
            options = options || []
            if (
                this.value &&
                this.value.Id &&
                (options.length === 0 ||
                    !options.find((v) => {
                        return v.Id === this.value.Id
                    }))
            ) {
                options.push(this.value)
            }
            this.Options = options
        },
        OnLoadMore() {
            if (this.isLoading) return
            this._Reset = false
            this.$emit('load', {
                type: 'LoadMoreSelections'
            })
        },
        OnLoadKey(key) {
            if (this.SearchKey) {
                key = key.replace(/(^\s*)|(\s*$)/g, "")
                const handler = () => {
                    this._Reset = true
                    this.$emit('load', {
                        type: 'LoadSelections',
                        params: {
                            Page: 1,
                            PageSize: this.config.Options.PageSize || 10,
                            [this.SearchKey]: key,
                            Orders: this.Orders
                        }
                    })
                }
                if (this._SearchHandler) clearTimeout(this._SearchHandler)
                this._SearchHandler = setTimeout(handler, 500)
            }
        },

        OnLoad(str) {
            if (!this.options || this.options.length === 0) {
                if (this.isLoading) return
                this._Reset = true
                this.$emit('load', {
                    type: 'LoadSelections',
                    params: {
                        Page: 1,
                        PageSize: this.config.Options.PageSize || 10,
                        Orders: this.Orders
                    }
                })
            }
        },
        OnInputObject(val) {
            if (val) {
                if (!this.value || val !== this.value.Id) {
                    this.Data = this.Options.find((t) => {
                        return t.Id === val
                    })
                    this.OnChange()
                }
            } else {
                this.Data = this.config.EmptyValue
                this.OnChange()
            }
        }
    }
}
</script>
<style scoped>
.item {
    height: auto !important;
}
</style>