<template>
  <div>
    <mx-element
      type="TabTitle"
      :options="Configs"
      :value="Current"
      class="panel3"
      @change="handleCurrentChange"
    >
      <div
        v-if="Config && showTitleBar"
        :key="roleFieldKey"
        class="flex-row end middle"
        style="padding-right: 20px"
      >
        <mx-element
          v-for="(act, index) of Config.TypeAction"
          :key="index"
          :type="act.Type"
          :title="act.Title"
          :name="act.Name"
          :options="act.Options"
          @click="handleFunction"
        >
        </mx-element>
        <mx-element
          v-if="
            Config.Condition.SortFields &&
            Config.Condition.SortFields.length > 0
          "
          type="Dropdown"
          :options="Config.Condition.SortFields"
          :value="SortIndex"
          @change="handleSortChange"
          :config="toolbarConfig"
          icon="sort"
          ref="sortbutton"
        />
        <mx-element
          :key="ListType + '_Panel'"
          style="margin-left: 5px"
          type="SwitchButton"
          @click="handleShowPanel"
          :value="ListType"
          theme="panel"
          ref="showpanelButton"
          title="显示数据面板"
          v-if="GetListPanel(Config.RoleField)"
        />
      </div>
    </mx-element>
    <mx-page
      v-if="config"
      type="List"
      :config="config"
      :parent="parent"
      :role-field="config.RoleField"
      :key="timespan + '_' + roleFieldKey"
      v-show="!isChild"
      ref="listcomp"
      :panel="Panel"
      @panel-change="handleListPanelChange"
      :theme="listTheme"
      @meta-change="handleMetaChange"
    >
    </mx-page>
  </div>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    isChild: Boolean,
    type: String,
    parent: Object,
    timespan: [String, Number],
    config: Object
  },
  data() {
    return {
      Current: 0,
      SortIndex: 0,
      CurrentRoleField: null,
      Config: null,
      roleFieldKey: new Date().getTime(),
      Panel: "",
      ListType: 1,
      Configs: []
    };
  },
  computed: {
    entity() {
      return this.$matrix.Models[this.config.Type];
    },
    listTheme() {
      if (this.entity.Config.Node === "Category") return "category";
      return "";
    },
    showTitleBar() {
      if (this.entity.Config.Node === "Category" && this.entity.Config.Level)
        return false;
      return true;
    },
    toolbarConfig() {
      return { Icon: true };
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    }
  },
  watch: {
    current(val, old) {
      this.Configs = [val];
    }
  },
  methods: {
    handleFunction(val) {
      this.$refs.listcomp.execute("handleFunction", val);
    },
    handleListPanelChange(val) {
      this.ListType = !val ? 1 : 0;
    },
    handleShowPanel() {
      this.$refs.listcomp.execute(
        "handleShowPanel",
        this.ListType == 1 ? this.GetListPanel(this.Config.RoleField) : ""
      );
    },
    GetListPanel(RoleFieldName) {
      RoleFieldName = RoleFieldName || this.Config.Role;
      let name = this.type.replace(/\./g, "_") + "_List_" + RoleFieldName;
      if (panels[name]) return name;
      return;
    },

    handleCurrentChange(index) {
      this.Current = index;
    },

    handleMetaChange(val) {
      if (val && val.TypeClass) {
        this.Config = val.TypeClass(
          this.parent,
          this.$matrix.GetUser(this.config.Role)
        );
      }
    },
    handleSortChange(val) {
      this.$refs.listcomp.execute("handleSortChange", val);
    }
  },
  created() {
    if (this.config) this.Configs = [this.config];
  }
};
</script>