<template>
  <div :key="timespan" v-if="sitemap && sitemap.roleFields" :style="formStyle">
    <mx-element
      class="panel3 fixed"
      type="TabTitle"
      :options="menus"
      :value="action"
      @change="handlerSelect"
      theme="Group"
      style="border-bottom: 1px solid #f7f7f7; height: 63px"
    />
    <div
      class="flex-row start st"
      style="height: calc(100% - 64px)"
      v-show="!isChild"
    >
      <div
        class="panel3"
        style="width: 200px; height: calc(100% - 20px)"
        v-if="
          menus &&
          menus[action] &&
          menus[action].Children &&
          menus[action].Children.length > 0
        "
        v-show="!isSingleMenu"
      >
        <div
          v-for="(node, nindex) in menus[action].Children"
          :key="nindex"
          style="padding: 0px 20px"
        >
          <template v-if="node && node.Children && node.Children.length > 0">
            <div
              v-for="(item2, index) in node.Children"
              :key="index + '_' + nindex"
              class="menu"
              :class="
                index === menuindex && nindex === nodeindex ? 'menu_active' : ''
              "
              @click="OnClick(nindex, index)"
            >
              {{ item2.Title }}
            </div>
          </template>
        </div>
      </div>

      <entity
        style="width: calc(100% - 201px)"
        v-if="current"
        :style="contentStyle"
        :sitemap="sitemap"
        :timespan="timespan"
        :type="current.Type"
        :config="current"
        :is-child="isChild"
        :parent="parent"
      />

      <div v-else :style="contentStyle" class="panel20">
        你的授权不足，无法使用该功能
      </div>
    </div>
    <keep-alive include="child">
      <router-view class="router-view"></router-view>
    </keep-alive>
  </div>
  <div v-else class="panel20">你的授权不足</div>
</template>
<script>
import master from "matrix.node.ui/page/master";
import entity from "./entity";
export default {
  mixins: [master("list")],
  props: {},
  components: { entity },
  data() {
    return {
      action: 0,
      ChildState: false,
      menuindex: 0,
      nodeindex: 0
    };
  },
  updated() {
    if (this.isChild !== this.ChildState) {
      this.ChildState = this.isChild;
    }
  },
  computed: {
    isSingleMenu() {
      let count = 0;
      this.menus[this.action].Children.forEach(c => {
        count += c.Children.length;
      });
      return this.action === 0 && count <= 1;
    },
    user() {
      return this.$matrix.GetUser(this.master.Role);
    },
    contentStyle() {
      return {
        width:
          this.menus &&
          this.menus[this.action] &&
          this.menus[this.action].Children &&
          this.menus[this.action].Children.length > 0 &&
          !this.isSingleMenu
            ? "calc(100% - 200px)"
            : "100%"
      };
    },
    formStyle() {
      return { height: "100%" }; //this.$parent.$el.offsetHeight + "px"
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    },
    menus() {
      if (this.sitemap) {
        let model = this.$matrix.Models[this.sitemap.name];
        let user = this.$matrix.GetUser();
        return [
          {
            Id: 0,
            Title: this.sitemap.label,
            Group: "main",
            Children: [
              {
                Title: model.Title + "数据",
                Children: this.sitemap.roleFields
                  .map(t => {
                    t.Icon = "enable";
                    t.Type = this.sitemap.name;
                    return t;
                  })
                  .filter(f => {
                    let Roles = this.$matrix.GetUserRole(
                      f.Role,
                      user,
                      f.RoleField
                    );
                    return Roles.Check(model.Name, "query");
                  })
              },
              {
                Title: model.Title + "视图",
                Children: this.CurrentMeta.Instance.GetViewEntity(
                  this.sitemap.name,
                  this.master.Role,
                  this.master.Rolefield,
                  this.user
                ).filter(f => {
                  let Roles = this.$matrix.GetUserRole(
                    f.Role,
                    user,
                    f.RoleField
                  );
                  return Roles.Check(model.Name, "query");
                })
              }
            ].filter(c => {
              return c.Children && c.Children.length > 0;
            })
          },
          {
            Id: 1,
            Title: this.sitemap.entity.Title + "配置",
            Group: "setting",
            Icon: "setting",
            Children: this.CurrentMeta.Instance.GetConfigEntity(
              this.sitemap.name,
              this.master.Role,
              this.master.Rolefield,
              this.user
            )
              .reduce(
                (g, item, index) => {
                  if (item.Config.Node === "Category") {
                    g[0].Children.push(item);
                  }
                  return g;
                },
                [
                  { Title: "分类目录", Children: [] },
                  { Title: "数据配置", Children: [] }
                ]
              )
              .filter(c => {
                return c.Children && c.Children.length > 0;
              })
          },
          {
            Id: 2,
            Title: "作业记录",
            Group: "setting",
            Icon: "record",
            Children: this.CurrentMeta.Instance.GetRecordEntity(
              this.sitemap.name,
              this.master.Role,
              this.master.Rolefield,
              this.user
            )
              .filter(f => {
                let Roles = this.$matrix.GetUserRole(f.Role, user, f.RoleField);
                return Roles.Check(model.Name, "query");
              })
              .filter(c => {
                return c.Children && c.Children.length > 0;
              })
          },
          { Id: 3, Title: "数据统计", Group: "setting", Icon: "report" }
        ]
          .filter(c => {
            return c.Children && c.Children.length > 0;
          })
          .map((t, index) => {
            t.Id = index;
            return t;
          });
      }
    },
    sitemap() {
      return this.CurrentMeta.Instance.GetSiteMap("list", this.master.Type);
    },
    current() {
      if (this.menus[this.action] && this.menus[this.action].Children) {
        let cs = this.menus[this.action].Children;
        if (cs[this.nodeindex] && cs[this.nodeindex].Children) {
          return cs[this.nodeindex].Children[this.menuindex];
        }
      }
    }
  },
  watch: {
    "$route.query.action": function(val) {
      this.action = parseInt(val || 0);
      this.menuindex = 0;
      this.nodeindex = 0;
    },
    "$route.query.menu": function(val) {
      this.menuindex = parseInt(val || 0);
    },
    "$route.query.node": function(val) {
      this.nodeindex = parseInt(val || 0);
    }
  },
  methods: {
    OnClick(node, menu) {
      this.$matrix.Goto(
        "/list" +
          `?type=${this.master.Type}${
            this.action ? "&action=" + this.action : ""
          }${node ? "&node=" + node : ""}${
            menu ? "&menu=" + menu : ""
          }&t=${new Date().getTime()}`
      );
    },
    handlerSelect(index) {
      let item = this.menus[index];
      if (item.Id !== this.action) {
        this.$matrix.Goto(
          "/list?type=" +
            this.master.Type +
            "&action=" +
            item.Id +
            "&t=" +
            new Date().getTime()
        );
      }
    }
  },
  created() {
    this.action = parseInt(this.$route.query.action || 0);
    this.menuindex = parseInt(this.$route.query.menu || 0);
    this.nodeindex = parseInt(this.$route.query.node || 0);
  }
};
</script>
<style scoped>
.item {
  cursor: pointer;
  min-width: 64px;
  font-size: 16px;
  text-align-last: justify;
}
.menu {
  cursor: pointer;

  padding: 10px;
  display: block;
  margin: 10px 0 0 0;
  text-align: left;
}
.menu:hover,
.menu_active {
  background-color: #f7f7f7;
  color: red;
}
.active {
  color: red;
  position: relative;
}
.active::before {
  content: " ";
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  height: 4px;
  z-index: 999;
  background-color: red;
}
.menu_title {
  text-align: center;
  padding: 5px;
  font-weight: 550;
  font-size: 14px;
  color: #777;
  position: relative;
  display: flex;
}
.menu_title::after {
  content: " ";
  margin-top: 8px;
  margin-left: 10px;
  flex: 1;
  height: 1px;
  background-color: #eee;
  z-index: 1;
}
.menu_title::before {
  content: " ";
  flex: 1;
  margin-top: 8px;
  margin-right: 10px;
  right: 0;
  height: 1px;
  background-color: #eee;
  z-index: 1;
}
</style>
