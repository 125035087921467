<template>
  <div ref="scrollBlock" style="padding: 10px" class="panel4">
    <div style="margin: 0 auto; width: 200px">
      <slot><a href="javascript:void(0)">加载更多...</a></slot>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    ScrollEnd() {
      return this.$store.state.matrix.ScrollEnd;
    }
  },
  data() {
    return { IsInScreen: true };
  },
  watch: {
    ScrollEnd(val) {
      let el = this.$refs["scrollBlock"];
      if (el) {
        let ClientRect = el.getBoundingClientRect();
        if (ClientRect.top > window.innerHeight) {
          this.IsInScreen = false;
          this.$emit("change", this.IsInScreen);
          // console.log("不显示");
        } else {
          //console.log("显示");
          this.IsInScreen = true;
          this.$emit("change", this.IsInScreen);
        }
      }
    }
  },
  methods: {
    GetState() {
      return this.IsInScreen;
    }
  },
  mounted() {
    this.$nextTick(() => {
      let el = this.$refs["scrollBlock"];
      if (el) {
        let ClientRect = el.getBoundingClientRect();
        if (ClientRect.top > window.innerHeight) {
          this.IsInScreen = false;
          this.$emit("change", this.IsInScreen);
          // console.log("不显示");
        } else {
          this.IsInScreen = true;
          //  console.log("显示");
          this.$emit("change", this.IsInScreen);
        }
      }
    });
  }
};
</script>