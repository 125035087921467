<template>
    <div class="flex-column">
        <slot name="font"></slot>
        <div
            v-for="(col, index) in columns"
            :key="index"
            :class="GetFrameClass"
            :style="GetFrameStyle"
            v-show="GetShowState(col)"
        >
            <div :class="GetTitleClass(col)" :style="GetTitleStyle(col)">
                <div :style="GetReqStyle(col)">*</div>
                <div style="flex: 1" class="line2">
                    {{ col.Title }}
                    <i
                        class="el-icon-info"
                        style="color: blue"
                        v-if="col.Description"
                        @click="OnShowInfo(col)"
                    ></i>
                </div>
            </div>
            <div style="flex: 1">
                <mx-field
                    style="width: 100%"
                    :config="col"
                    :value="value"
                    ref="field"
                    :edit="true"
                    :type="col.Type"
                    :name="col.Name"
                    @change="handleChange"
                    :checkable="true"
                >
                </mx-field>
                <div
                    style="color: #777; font-size: 14px; margin-top: 5px"
                    v-if="ShowInfo && ShowInfo[col.Name] && col.Description"
                >
                    <i class="el-icon-info"></i> {{ col.Description }}
                </div>
            </div>
            <div :style="GetErrorStyle(col)">
                <template v-if="GetFieldError(col)">
                    <el-tooltip
                        class="item"
                        :content="GetFieldError(col).message"
                        placement="bottom"
                    >
                        <i class="el-icon-warning" style="color: red"> </i>
                    </el-tooltip>
                    <span :style="GetErrorTextStyle(col)">{{
                        GetFieldError(col).message
                    }}</span></template
                >
            </div>
        </div>
        <slot name="button"></slot>
    </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
export default {
    mixins: [form.component],
    props: {
        theme: String
    },
    data() {
        return { Errors: null, ShowInfo: null }
    },
    computed: {
        GetFrameClass() {
            switch (this.theme) {
                case 'v':
                    return 'flex-column left'
                default:
                    return 'flex-row top start'
            }
        },
        GetFrameStyle() {
            switch (this.theme) {
                case 'v':
                    return ''
                default:
                    return 'margin: 0 0 10px 0'
            }
        }
    },
    methods: {
        GetErrorTextStyle(col) {
            switch (this.theme) {
                case 'v':
                    return {
                        fontSize: '14px',
                        padding: '5px 0',
                        lineHeight: '24px'
                    }
                default:
                    return { display: 'none' }
            }
        },
        GetErrorStyle(col) {
            switch (this.theme) {
                case 'v':
                    return {
                        margin: '5px 0',
                        color: 'red',
                        backgroundColor: '#f7f7f7',
                        fontSize: '14px'
                    }
                default:
                    return { width: '20px', padding: '10px' }
            }
        },
        GetReqStyle(col) {
            if (col.Req) return { color: 'red' }
            switch (this.theme) {
                case 'v':
                    return { display: 'none' }
                default:
                    return { visibility: 'hidden' }
            }
        },
        GetTitleClass(col) {
            switch (this.theme) {
                case 'v':
                    return 'div14 flex-row top list-half'
                default:
                    return 'div14 flex-row top'
            }
        },
        GetTitleStyle(col) {
            switch (this.theme) {
                case 'v':
                    return {
                        margin: '5px 0',
                        fontWeight: 700
                    }
                default:
                    return {
                        width: 5 * 16 + 'px',
                        padding: '5px 5px 0 0',
                        lineHeight: 1,
                        fontWeight: 700,
                        color: col.Req ? 'red' : ''
                    }
            }
        },
        GetSelfValue() {
            return Object.assign({}, this.value, this.GetValue({}))
        },
        handleError(errors) {
            this.Errors = errors
        },
        handleChange2(val) {
            if (val.name && this.Errors) {
                this.Errors = this.Errors.filter((e) => {
                    return e.name !== val.name
                })
            }
        },
        GetFieldError(col) {
            if (this.Errors && this.Errors.length > 0) {
                return this.Errors.find((e) => {
                    return e.name === col.Name
                })
            }
        },
        OnShowInfo(col) {
            this.ShowInfo = Object.assign(this.ShowInfo || {}, {
                [col.Name]: !this.ShowInfo[col.Name]
            })
        }
    }
}
</script>
<style scoped>
.title {
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
    visibility: hidden;
}
</style>
