<template>
    <div class="flex-row st" style="width: 100%">
        <div style="width: 150px" class="title">{{ config.Name }}</div>
        <div style="flex: 1" v-if="config.children" class="frame">
            <TableFormRow style="padding: 0; margin: 0" :config="c" v-for="(c, i) in config.children" :key="i"
                :fields="fields" ref="row" :lookupdatas="lookupdatas" :lookups="lookups"></TableFormRow>
        </div>
        <div v-if="config.LabelOptions && config.LabelOptions.length > 0" style="flex: 1">
            <div :config="c" v-for="(c, i) in config.LabelOptions" :key="i" class="flex-row st">
                <div style="width: 150px" class="title">
                    {{ c.Option.Name }}
                </div>
                <div style="flex: 1; padding: 10px 5px 0 5px" class="title flex-row st">
                    <template v-if="lookups && lookups.length > 0">
                        <div style="flex: 1" class="flex-row st" v-for="(lookupfield, index) in lookups" :key="index">
                            <div class="datalabel">
                                {{
                                    GetLookUpValue(
                                        c,
                                        lookupfield,
                                        index,
                                        c.Option.Unit
                                                                )
                                }}
                            </div>
                        </div>
                    </template>
                    <div class="flex-row st" style="flex: 1">
                        <template v-if="fields">
                            <mx-form type="Simple" :columns="fields" :value="c.Value" :ref="'field' + i"
                                :config="{ HideTitle: true }" :showerr="true">
                            </mx-form>

                            <div class="datalabel" v-if="c.Option">
                                {{ c.Option.Unit }}
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'TableFormRow',
    props: {
        config: Object,
        fields: Array,
        lookupdatas: Array,
        lookups: Array
    },
    methods: {
        GetValue(val) {
            let value = []
            if (this.$refs.row) {
                this.$refs.row.forEach((r) => {
                    value = value.concat(r.GetValue())
                })
            }
            if (this.config.LabelOptions) {
                value = value.concat(
                    this.config.LabelOptions.map((op, index) => {
                        let com = this.$refs['field' + index]
                        if (com) {
                            if (Array.isArray(com)) {
                                com = com[0]
                            }
                            return com.GetValue(op.Value)
                        }
                    }).filter((e) => {
                        return !!e
                    })
                )
            }
            return value
        },
        GetChange() {
            let value = []
            if (this.$refs.row) {
                this.$refs.row.forEach((r) => {
                    value = value.concat(r.GetChange())
                })
            }
            if (this.config.LabelOptions) {
                value = value.concat(
                    this.config.LabelOptions.map((op, index) => {
                        let com = this.$refs['field' + index]
                        if (com) {
                            if (Array.isArray(com)) {
                                com = com[0]
                            }
                            if (op.Value && op.Value.Id) {
                                let change = com.GetChange()
                                if (change && Object.keys(change).length > 0) {
                                    if (op.Value.Id) change.Id = op.Value.Id
                                    return change
                                }
                            } else {
                                return com.GetValue(op.Value)
                            }
                        }
                    }).filter((e) => {
                        return !!e
                    })
                )
            }
            return value
        },
        GetError() {
            let errors = []
            if (this.$refs.row) {
                this.$refs.row.forEach((r) => {
                    let err = r.GetError()
                    if (err && err.length > 0) {
                        errors = errors.concat(err)
                    }
                })
                if (errors.length === 0) return undefined
                return errors
            }
            if (this.config.LabelOptions) {
                this.config.LabelOptions.forEach((op, index) => {
                    let com = this.$refs['field' + index]
                    if (com) {
                        if (Array.isArray(com)) {
                            com = com[0]
                        }
                        let err = com.GetError()
                        if (err && err.length > 0) {
                            errors = errors.concat(err)
                        }

                    }
                })
                if (errors.length === 0) return undefined
                return errors
            }


        },
        GetLookUpValue(item, lookupfield, index, unit) {
            let CategoryField = lookupfield.CategoryField
            let data = item.Value[CategoryField]
            if (
                data &&
                this.lookupdatas[index] &&
                this.lookupdatas[index].Items
            ) {
                let item = this.lookupdatas[index].Items.find((t) => {
                    return t[CategoryField] && t[CategoryField].Id === data.Id
                })
                if (item && item[lookupfield.ValueField]) {
                    return item[lookupfield.ValueField] + unit
                }
            } else {
                //  console.log(this.lookupdatas[index])
            }
            return '-'
        }
    }
}
</script>
<style scoped>
.title {
    padding: 10px 5px;
    border-left: 1px solid #777;
    border-bottom: 1px solid #777;
}

.border {
    border-left: 1px solid #777;
    border-bottom: 1px solid #777;
}

.frame {
    border-left: 0px solid #777;
    border-bottom: 0px solid #777;
}

.datalabel {
    line-height: 34px;
    white-space: nowrap;
    padding: 0 0 0 10px;
}
</style>