<template>
  <el-alert
    :title="title"
    type="info"
    :description="value"
    show-icon
    :closable="false"
  >
    <slot></slot>
  </el-alert>
</template>
<script>
export default {
  props: {
    value: String,
    title: String
  }
};
</script>
