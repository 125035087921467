var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticStyle:{"text-align":"left"},attrs:{"title":_vm.config.Title,"visible":true,"width":_vm.dialogWidth,"before-close":_vm.handleClose,"close-on-click-modal":false}},[(_vm.ParentField && _vm.config.Parent.Parent && _vm.$matrix.hasPanel(_vm.ParentField.Link,'ChildForm')  && _vm.config.Parent.Parent.Id)?_c('mx-panel',{attrs:{"type":_vm.ParentField.Link,"panel":"ChildForm","config":_vm.ParentConfig,"value":_vm.config.Parent.Parent}}):_vm._e(),(_vm.children.length > 0)?_c('mx-element',{staticClass:"panel4",attrs:{"type":"FormTitle","title":_vm.Entity.Title}}):_vm._e(),_c('mx-entity',{ref:"entity",attrs:{"mode":_vm.config.Method,"type":_vm.config.Type,"parent":_vm.config.Parent,"role":_vm.config.Role,"role-field":_vm.config.RoleField,"value":_vm.config.Value}},[_c('div',{staticClass:"formbox"},[_c('mx-form',{key:_vm.formkey,ref:"main",attrs:{"type":_vm.mainFormType,"config":_vm.config,"value":_vm.TempValue ? _vm.TempValue : _vm.config.Value,"columns":_vm.config.Columns,"theme":"v"},on:{"change":_vm.OnEntityChange}})],1),_vm._l((_vm.children),function(child){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visiabled[child.Name] !== false),expression:"visiabled[child.Name] !== false"}],key:child.Name,staticStyle:{"margin":"0px 10px"}},[_c('mx-element',{staticClass:"panel4",attrs:{"type":"FormTitle","title":child.Title}}),(_vm.config.Method === 'add')?_c('div',{staticClass:"box",staticStyle:{"pading":"10px"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    child.DeleteEnabled &&
                    _vm.deleted &&
                    _vm.deleted[child.Name] === false
                ),expression:"\n                    child.DeleteEnabled &&\n                    deleted &&\n                    deleted[child.Name] === false\n                "}],staticClass:"mask"},[_c('div',{staticClass:"vcenter"},[_vm._v(" "+_vm._s(child.Title)+"已经停用,数据不会被存储 ")])]),(child.IsList)?[(child.DataLoad)?_c('mx-load-list',{attrs:{"params":child.DataLoad.Params,"type":child.DataLoad.Type,"role":child.DataLoad.Role,"role-field":child.DataLoad.RoleField,"data-handler":child.DataHandle,"page-size":0,"auto-load":true},on:{"loading":function($event){return _vm.handleLoading($event, child)}},scopedSlots:_vm._u([{key:"default",fn:function(res){return [_c('mx-list',{key:_vm.formkey,ref:child.Name,refInFor:true,attrs:{"type":child.ListType,"config":child,"value":_vm.TempValue ? _vm.GetChildData(child) : child.CreateList(child, res.data),"columns":child.Form.Columns,"theme":child.Theme,"options":res.data}})]}}],null,true)}):_c('mx-list',{key:_vm.formkey,ref:child.Name,refInFor:true,attrs:{"type":child.ListType,"config":child,"value":_vm.TempValue ? _vm.GetChildData(child) : child.CreateList(child, child.Options),"columns":child.Form.Columns,"theme":child.Theme,"options":child.Options}})]:_c('mx-form',{key:_vm.formkey,ref:child.Name,refInFor:true,staticStyle:{"padding":"40px 20px 10px 20px"},attrs:{"type":"Edit","config":child,"value":_vm.TempValue ? _vm.GetChildData(child, true) : child.Form.Value,"columns":child.Form.Columns,"theme":child.Theme}})],2):_c('div',{staticClass:"box",staticStyle:{"pading":"10px"}},[(child.IsList)?[_c('mx-load-list',{attrs:{"type":child.Type,"page-size":0,"role":child.Role,"auto-load":true,"role-field":child.RoleField,"params":_vm.getChildLoadParams(child, _vm.config.Value)},scopedSlots:_vm._u([{key:"default",fn:function(list){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                child.DeleteEnabled &&
                                _vm.deleted &&
                                _vm.deleted[child.Name] === false
                            ),expression:"\n                                child.DeleteEnabled &&\n                                deleted &&\n                                deleted[child.Name] === false\n                            "}],staticClass:"mask"},[_c('div',{staticClass:"vcenter"},[_vm._v(" "+_vm._s(child.Title)+"已经停用,数据不会被存储 ")])]),(child.DataLoad)?_c('mx-load-list',{attrs:{"params":child.DataLoad.Params,"type":child.DataLoad.Type,"role":child.DataLoad.Role,"role-field":child.DataLoad.RoleField,"data-handler":child.DataHandle,"page-size":0,"auto-load":true},on:{"loading":function($event){return _vm.handleLoading($event, child)}},scopedSlots:_vm._u([{key:"default",fn:function(res){return [_c('mx-list',{ref:child.Name,refInFor:true,attrs:{"type":child.ListType,"config":child,"value":list.data ||
                                        child.CreateList(
                                            child,
                                            res.data
                                        ),"columns":child.Form.Columns,"theme":child.Theme,"options":res.data}})]}}],null,true)}):_c('mx-list',{ref:child.Name,refInFor:true,attrs:{"type":child.ListType,"config":child,"value":child.OnlyAdd ? [] : list.data || [],"columns":child.Form.Columns,"theme":child.Theme,"options":child.Options}})]}}],null,true)})]:[(!child.OnlyAdd)?_c('mx-load-list',{attrs:{"type":child.Type,"page-size":1,"role":child.Role,"auto-load":true,"role-field":child.RoleField,"params":_vm.getChildLoadParams(child, _vm.config.Value),"data-handler":_vm.OnLoaded(child)},scopedSlots:_vm._u([{key:"default",fn:function(list){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                                child.DeleteEnabled &&
                                _vm.deleted &&
                                _vm.deleted[child.Name] === false
                            ),expression:"\n                                child.DeleteEnabled &&\n                                deleted &&\n                                deleted[child.Name] === false\n                            "}],key:list.timespan,staticClass:"mask"},[_c('div',{staticClass:"vcenter"},[_vm._v(" "+_vm._s(child.Title)+"已经停用,数据不会被存储 ")])]),(list.data[0] && list.data[0].Id)?[_c('mx-form',{directives:[{name:"show",rawName:"v-show",value:(
                                    child.Form.Columns.filter((f) => {
                                        return !f.NoShow
                                    }).length > 0
                                ),expression:"\n                                    child.Form.Columns.filter((f) => {\n                                        return !f.NoShow\n                                    }).length > 0\n                                "}],key:_vm.formkey,ref:child.Name,refInFor:true,staticStyle:{"padding":"40px 20px 10px 20px"},attrs:{"type":"Edit","config":child,"value":list.data[0],"columns":child.Form.Columns,"theme":child.Theme}}),(
                                    child.Form.Columns.filter((f) => {
                                        return !f.NoShow
                                    }).length === 0
                                )?_c('div',{staticStyle:{"padding":"20px"}},[_vm._v(" "+_vm._s(child.Title)+" 不容许变更数据 ")]):_vm._e()]:_c('mx-form',{key:_vm.formkey,ref:child.Name,refInFor:true,staticStyle:{"padding":"40px 20px 10px 20px"},attrs:{"type":"Edit","config":child,"value":child.Form.AddForm.Value,"columns":child.Form.AddForm.Columns,"theme":child.Theme}})]}}],null,true)}):_c('mx-form',{key:_vm.formkey,ref:child.Name,refInFor:true,staticStyle:{"padding":"40px 20px 10px 20px"},attrs:{"type":"Edit","config":child,"value":child.Form.Value,"columns":child.Form.Columns,"theme":child.Theme}})]],2)],1)})],2),(_vm.TempDataRecord)?_c('div',{staticClass:"info"},[_vm._v(" 你有一条"+_vm._s(_vm.TempDataRecord.time)+"保存的记录，是否继续编辑 "),_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":_vm.LoadData}},[_vm._v("继续编辑")])]):_vm._e(),_c('div',{staticClass:"flex-row",attrs:{"slot":"footer"},slot:"footer"},[(_vm.config.OnDelete)?_c('div',{staticStyle:{"padding-left":"30px"}},[_c('el-button',{on:{"click":_vm.handleDelete}},[_vm._v("删 除")])],1):_vm._e(),_c('div',{staticStyle:{"flex":"1"}}),_c('div',[(_vm.TempData)?_c('el-button',{attrs:{"type":"info"},on:{"click":_vm.handleSaveTemp}},[_vm._v("保 存")]):_vm._e(),_c('el-button',{on:{"click":_vm.handleClose}},[_vm._v("取 消")]),(_vm.TempData)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("提 交")]):_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleOk}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }