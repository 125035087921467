<template>
  <div :style="{ padding: title ? '10px' : 'hegiht:100vh;overflow-y:scroll' }" class="">
    <div class="flex-row start middle " style="padding:10px" v-if="title">
      <div class="title-label"></div>
      <div style="color:green;font-weight:700;font-size:18px">{{ title }}33</div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    icon: String
  }
};
</script>
<style scoped>
.title-label {
  width: 5px;
  height: 19px;
  background: #ab0b22;
  display: inline-block;
  margin-top: 0px;
  vertical-align: bottom;
  margin-right: 5px;
}
.border-bottom {
  position: relative;
}
.border-bottom::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 5px;
  right: 0;
  border-bottom: 0.05rem solid #eee;
  color: #eee;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
</style>
