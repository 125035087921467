<template>
  <el-radio-group
    v-model="Data"
    :size="size || config.Size"
    @change="OnChange"
    :disabled="disabled || config.Disabled"
    style="text-align: left"
  >
    <component
      :is="config.RadioType || 'el-radio-button'"
      :border="config.Border"
      :size="size || config.Size"
      v-for="item in Options"
      :label="item.Id"
      :key="item.Id"
      :value="item.Id"
      >{{ item.Title }}</component
    >
  </el-radio-group>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(Boolean)],
  computed: {
    Text() {
      return this.Options.find((t) => {
        return t.Id === true;
      }).Title;
    },
    Options() {
      return (
        this.config.Options || [
          { Id: true, Title: "是" },
          { Id: false, Title: "否" },
        ]
      );
    },
  },
};
</script>