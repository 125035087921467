<template>
  <mx-load-one
    :key="pagekey"
    :auto-load="true"
    :type="parent.Type"
    :id="parent.Id"
    :role="parent.Role"
    :role-field="parent.RoleField"
    @loaded="handleParentLoad"
  >
    <template
      slot-scope="scope"
      v-if="scope.data && Parent"
    >
      <mx-entity
        mode="child"
        :type="parent.Type"
        :role="parent.Role"
        :role-field="parent.RoleField"
        ref="entity"
        @change="handleMetaChange"
      >
        <template v-if="Meta">
          <mx-panel
            style="margin: 0 10px 0 5px"
            :type="parent.Type"
            :value="scope.data"
            :role="parent.Role"
            :role-field="parent.RoleField"
            :panel="Panel || 'Parent'"
          ></mx-panel>
          <div
            class="flex-row start top"
            style="margin: 1px 10px 0 5px"
          >
            <div
              class="box"
              style="width:270px"
            >
              <mx-element
                class="panel10 float"
                style="margin:5px 5px 0 0"
                type="Links"
                :value="CurrentChild"
                :options="Options"
                @click="OnClick"
                theme='Menu'
              ></mx-element>
            </div>
            <mx-page
              style="flex:1"
              type="List"
              :config="config"
              :parent="getParent(scope.data)"
              ref="listcomp"
              @config-change="handleConfigChange"
              @meta-change="handleListMetaChange"
            >
            </mx-page>
          </div>
        </template>
      </mx-entity>
    </template>
  </mx-load-one>
</template>
<script>
import panels from '@/projects/panels/index'
export default {
    props: {
        config: Object,
        parent: Object,
        master: Object
    },
    data() {
        return {
            pagekey: new Date().getTime(),
            Meta: null,
            Data: null,
            Parent: null,
            Options: null,
            ListPanel: ''
        }
    },
    methods: {
        getParent() {
            if (this.Parent)
                return Object.assign({}, this.parent, { Parent: this.Parent })
        },
        handleConfigChange(val) {
            this.$emit('config-change', val)
        },
        OnClick(val) {
            if (val.Url) {
                this.$matrix.Goto(val.Url)
            } else if (val.Path) {
                let paths = this.$route.path.split('/')
                let path = `/${paths[1]}/${val.Path}`
                let params = []
                let q = Object.assign({}, this.$route.query, val.Params)
                Object.keys(q).forEach((key) => {
                    if (
                        key.indexOf('_role') > -1 &&
                        q[key] === this.$matrix.GetCurrentRoleName()
                    ) {
                    } else {
                        params.push(`${key}=${q[key]}`)
                    }
                })
                this.$matrix.Goto(
                    path + '?' + params.join('&'),
                    undefined,
                    true
                )
            }
        },
        handleMetaChange(val) {
            if (val) {
                this.Meta = val
                this.Options = val.Children(
                    this.parent,
                    this.$matrix.GetUser(val.Role)
                )
            }
        },
        handleListMetaChange(val) {
            this.$emit('meta-change', val)
        },
        handleParentLoad(val) {
            this.Parent = val
        },
        handleSortChange(val) {
            this.$refs.listcomp.execute('handleSortChange', val)
        },
        handleFunction(val) {
            this.$refs.listcomp.execute('handleFunction', val)
        },

        handleShowPanel(val) {
            if (val) {
                if (this.ListPanel !== val) {
                    this.ListPanel = val
                } else {
                    this.ListPanel = ''
                }
                this.$refs.listcomp.execute('handleShowPanel', this.ListPanel)
            } else {
                this.$refs.listcomp.execute('handleShowPanel', val)
            }
        }
    },
    computed: {
        Panel() {
            let name =
                this.parent.Type.replace(/\./g, '_') +
                '_Parent_' +
                this.config.Role +
                '_' +
                this.config.RoleField
            if (this.config.RoleField && panels[name]) {
                return name
            }
            name =
                this.parent.Type.replace(/\./g, '_') +
                '_Parent_' +
                this.config.Role
            if (panels[name]) {
                return name
            }
        },
        CurrentChild() {
            return this.Options.findIndex((op) => {
                return op.Params['child_type'] === this.config.Type
            })
        }
    }
}
</script>
<style scoped>
.box {
    position: relative;
}
.float {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
}
</style>
