<template>
  <div>
    <el-input
      style="width: 100%"
      v-model="Data"
      clearable
      @blur="OnChange"
      @clear="OnChange"
      @keyup.13.native="OnChange"
      :size="size || config.Size"
      :placeholder="!IsLine ? '请输入' + (config.Title || '') : ''"
      :maxlength="config.Length"
      :disabled="disabled || config.Disabled"
      :suffix-icon="config.Icon"
      :class="InputClass"
      autocomplete="off"
    >
      <span slot="prepend" v-if="config.FontStyle">{{ config.FontStyle }}</span>
      <span slot="append" v-if="config.BackStyle">{{ config.BackStyle }}</span>
    </el-input>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String)],
  computed: {
    IsLine() {
      return this.config.Theme === "Line";
    },
    InputClass() {
      if (this.IsLine) return "Line";
    }
  }
};
</script>
<style>
.Line .el-input__inner {
  border: 0 !important;
  padding: 5px 0;
  color: rgb(25, 31, 37);
  font-size: 14px;
  text-indent: 0px;
  line-height: 18px;
  height: 46px;
  border: none;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
}
</style>