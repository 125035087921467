<template>
    <div>

        <div class="border-container" style="overflow: hidden;">
            <div class="name-title flex-row left">驾驶员年龄分析</div>
            <div id="Pie4" style="width: 100%; height: 400px"></div>
            <span class="top-left border-span"></span>
            <span class="top-right border-span"></span>
            <span class="bottom-left border-span"></span>
            <span class="bottom-right border-span"></span>
        </div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: {
        Index: Number,
        data: Array,
        config: Object,
    },
    computed: {
        Idx() {
            return Math.floor(Math.random() * 10);
        },
    },
    methods: {
        myEcharts(data) {
            // 基于准备好的dom，初始化echarts实例

            var myChart = echarts.init(document.getElementById("Pie4"));

            var option = {
                xAxis: {
                    type: 'category',
                    data: data.map(item => { return item.name })
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: data.map(item => { return item.value }),
                        type: 'bar',
                        barWidth: "20",
                        itemStyle: {
                            normal: {
                                //这里是重点
                                color: function (params) {
                                    //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                    var colorList = ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#d48265'];
                                    return colorList[params.dataIndex]
                                }
                            }
                        }
                    }
                ]
            };


            myChart.setOption(option);
        },
        LoadData() {
            this.$matrix.AjaxGet3("Driver", 'GetList', {
                Page: 1,
                PageSize: 100,
            }, this.config.Role, this.config.RoleField).then(datas => {

                let data = [
                    { name: '25-29岁', min: 25, max: 29, value: 0 },
                    { name: '30-34岁', min: 30, max: 34, value: 0 },
                    { name: '35-39岁', min: 35, max: 39, value: 0 },
                    { name: '40-44岁', min: 40, max: 44, value: 0 },
                    { name: '45-49岁', min: 45, max: 49, value: 0 },
                    { name: '50-54岁', min: 50, max: 54, value: 0 },
                    { name: '55-59岁', min: 55, max: 59, value: 0 },
                    { name: '60岁以上', min: 60, max: 100, value: 0 },

                ];
                if (datas.Items.length) {
                    let getScore = (val) => {
                        let res = datas.Items.find(item => {
                            return item.Age >= val.min && item.Age <= val.max

                        }) 
                        if (res) return res.Age
                        return 0
                    }
                    data.forEach(item => {
                        item.value = getScore(item)
                    }) 
                }
                this.myEcharts(data);

            }).catch(e => {
                this.$matrix.ShowError(e)
            })

        }
    },


    mounted() {
        this.LoadData()
    },
};
</script>
<style scoped>
.border-container {
    position: relative;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, .15);
    box-shadow: inset 0 0 50px rgba(255, 255, 255, .1), 0 0 5px rgba(0, 0, 0, .3)
}

.border-container span.border-span {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    opacity: .5
}

.border-container span.top-left {
    top: -2px;
    left: -2px;
    border-top: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.top-right {
    top: -2px;
    right: -2px;
    border-top: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.border-container span.bottom-left {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.bottom-right {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.name-title {
    font-size: 16px;
    font-weight: bolder;
    color: #00ffff;
}
</style>