<template>
  <div>
    <div class="panel10 middle flex-row" style="margin-bottom: 1px" v-if="!hide">
      <el-page-header @back="goBack" :content="title" style="flex: 1">
      </el-page-header>
      <slot name="button"></slot>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    hide: Boolean
  },
  methods: {
    goBack() {
      this.$matrix.Back();
    }
  }
};
</script>