<template>
  <mx-load-one
    :key="pagekey"
    :auto-load="true"
    :type="config.Type"
    :id="config.Id"
    :role="config.Role"
    :role-field="config.RoleField"
    mode="detail"
  >
    <template slot-scope="data">
      <mx-entity
        v-if="data.data && data.data.Id"
        mode="detail"
        :type="config.Type"
        :parent="parent"
        :role="config.Role"
        :role-field="config.RoleField"
        ref="entity"
        :value="data.data"
        @change="handleMetaChange($event, data.data)"
      >
        <template slot-scope="meta" v-if="Meta">
          <mx-panel
            :value="data.data"
            :panel="Panel"
            :type="config.Type"
            :config="config"
            v-if="Panel"
          />
          <template v-else>
            <el-descriptions
              :border="true"
              :column="3"
              :title="Meta.Title"
              class="panel20 s4"
              style="margin:5px"
              :labelStyle="{ width: '200px' }"
            >
              <el-descriptions-item
                :label="col.Title"
                v-for="col in Detail.Columns.filter(c => {
                  return ['Image', 'Images', 'File'].indexOf(c.Type) === -1;
                })"
                :key="col.Name"
              >
                <mx-field
                  :value="data.data"
                  :config="col"
                  :type="col.Type"
                  :name="col.Name"
                ></mx-field>
              </el-descriptions-item>
            </el-descriptions>
            <div
              class="panel20 s4"
              style="text-align:left;margin:5px"
              v-for="col in Detail.Columns.filter(c => {
                return (
                  ['Image', 'Images', 'File', 'Files'].indexOf(c.Type) > -1
                );
              })"
              :key="col.Name"
            >
              <div style="padding:10px;background-color:#f7f7f7">
                {{ col.Title }}
              </div>
              <mx-field
                style="margin-top:10px"
                :value="data.data"
                :config="col"
                :type="col.Type"
                :name="col.Name"
              ></mx-field></div
          ></template>
        </template>
      </mx-entity>
    </template>
  </mx-load-one>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    config: Object,
    parent: Object,
    isChild: Boolean
  },
  data() {
    return {
      formkey: new Date().getTime(),
      pagekey: new Date().getTime(),
      Meta: null,
      Data: null,
      Detail: null,
      Children: null
    };
  },
  computed: {
    Panel() {
      let name =
        this.config.Type.replace(/\./g, "_") +
        "_Detail_" +
        this.config.Role +
        "_" +
        this.config.RoleField;
      if (this.config.RoleField && panels[name]) {
        return name;
      }
      name =
        this.config.Type.replace(/\./g, "_") + "_Detail_" + this.config.Role;
      if (panels[name]) {
        return name;
      }
    }
  },
  methods: {
    handleMetaChange(val, value) {
      let Config = this.$refs.entity.init(
        this.parent,
        this.$matrix.GetUser(val.Role),
        value
      );
      this.Detail = Config.Detail;
      this.Children = Config.Children;
      this.Data = value;
      this.Meta = val;
      this.$emit("meta-change", this.Meta);
      if (this.Children) {
        let visiabled = {};
        this.Children.forEach(c => {
          if (c.ParentState && !this.$matrix.Include(c.ParentState, this.Data))
            visiabled[c.Name] = false;
          else visiabled[c.Name] = true;
        });
        this.visiabled = visiabled;
      }
    }
  },
  created() {}
};
</script>
<style scoped>
.box {
  position: relative;
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: #fff;
  z-index: 999;
}
.vcenter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate(0, -50%);
  color: #000;
  font-weight: 700;
}
</style>
