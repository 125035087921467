<template>
    <div class="flex-column list top start" style="width:180px">
      <img src="/static/system/logo.png" class="image" />
    </div>
  </template>
  <script>
  export default {
    props: {
      value: Object,
      config: Object,
    },
   
  };
  </script>
  <style scoped>
  .image {
    width: 100%;
  }
  </style>
  