<template>
  <component :is="Is" :value="value" :config="config" :title="title" :options="options" :icon="icon"
    @change="handleChange" @click="handleClick">
    <slot></slot>
  </component>
</template>
<script>
import ColumnNone from "./None";
import ColumnDefault from "./Default";
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    icon: String,
    theme: { type: String, default: "" },
    options: Array
  },
  components: { ColumnNone, ColumnDefault },
  computed: {
    Is() {
      switch (this.theme.toLowerCase()) {
        case "none":
          return "ColumnNone";
        default:
          return "ColumnDefault";
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
    handleClick(val) {
      this.$emit("click", val);
    }
  }
};
</script>
