<template>
  <mx-element
    type="ColumnFrame"
    theme="tablabel"
    :value="Index"
    @change="handleChange"
    :options="columns"
  >
    <template v-for="(col, index) in columns">
      <div :key="index" v-if="index === Index">
        <slot :config="col" :index="index"></slot></div
    ></template>
  </mx-element>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array
  },
  data() {
    return { Index: 0 };
  },
  methods: {
    handleChange(index) {
      this.Index = index;
    }
  }
};
</script>
