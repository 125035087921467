<template>
  <el-switch
    v-model="Data"
    :size="size || config.Size"
    @change="OnChange"
    :active-text="Text"
    :disabled="disabled || config.Disabled"
  >
  </el-switch>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(Boolean)],
  computed: {
    Text() {
      if (this.config.NoText) return "";
      return this.config.Options.find((t) => {
        return t.Id === this.Data;
      }).Title;
    },
    Options() {
      return (
        this.config.Options || [
          { Id: true, Title: "是" },
          { Id: false, Title: "否" },
        ]
      );
    },
  },
};
</script>
