<template>
  <component ref="form" :is="Is" v-model="_value" :config="config" :title="title" :icon="icon" @change="handleChange" @input="handleInputChange" @click="handleClick">
    <slot></slot>
  </component>
</template>
<script>
import AddForm from './add'
import ModifyForm from './modify'
export default {
  props: {
    value: Object,
    config: Object,
    title: String,
    icon: String,
    theme: { type: String, default: '' }
  },
  components: { AddForm, ModifyForm },
  data() {
    return { _value: null }
  },
  created() {
    this._value = this.value
  },
  computed: {
    Is() {
      switch (this.theme.toLowerCase()) {
        case 'modify':
          return 'ModifyForm'
        default:
          return 'AddForm'
      }
    }
  },
  methods: {
    GetValue(value) {
      return this.$refs.form.GetValue(value)
    },
    handleInputChange(val) {
      this.$emit('input', this._value)
    },
    handleChange(val) {
      this.$emit('change', val)
    },
    handleClick(val) {
      this.$emit('click', val)
    }
  }
}
</script>
