<template>
  <div></div>
</template>
<script>
export default {
  created() {
    this.$router.replace("/index");
  }
};
</script>
