<template>
  <div class="rel">
    <div class="input" v-if="!Selected" @click="onShowTransfer">
      请选择{{ config.Title }}
    </div>
    <div class="input" v-else @click="onShowTransfer">{{ Selected }}</div>
    <el-dialog :title="'选择' + config.Title" :visible.sync="show" append-to-body center width="40%">
      <template v-if="show">
        <div class="center" style="padding: 20px 0">
          <el-transfer v-model="SelectList" :data="TreeList" :titles="['待选' + config.Title, '已选' + config.Title]"
            :props="{ key: 'Id', label: 'Title' }" style="text-align: left; display: inline-block" filterable :filter-method="filterMethod"
            @change="handleChange">
          </el-transfer>
        </div>
        <div @click="onConfirm" class="flex-row center" style="padding-top: 10px; border-top: 1px solid #dcdfe6">
          <el-button type="primary" size="mini">确定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
import panels from "@/projects/panels/index";
let emptyStrHanlder = function () {
  return undefined;
};
let dataHandler = function (data) {
  return data;
};
export default {
  mixins: [base.edit(Array, emptyStrHanlder, emptyStrHanlder, dataHandler)],
  props: {
    isEnd: Boolean,
    isLoading: Boolean,
    options: Array,
  },
  data () {
    return {
      SelectList: [],
      testList: [],
      Selected: null,
      show: false,
      filterText: "",
    };
  },
  computed: {
    testList2 () {
      return [];
    },
    TreeList () {
      let filter = (t) => {
        let res = true;
        if (this.config.DisableField) {
          let value = t[this.config.DisableField];
          if (value !== undefined) {
            let method = this.config.DisableMethod || "=";
            let data = this.config.DisableValue || 0;
            switch (method) {
              case "=":
                res = !(value === data);
                break;
              case ">":
                res = !(value > data);
                break;
              case ">=":
                res = !(value >= data);
                break;
              case "<":
                res = !(value < data);
                break;
              case "<=":
                res = !(value <= data);
                break;
            }
          }
        }
        return res;
      };
      if (this.Options) {
        if (this.config.TreeField) {
          let treefield = this.$matrix.Models[this.config.Options.Entity].Fields.find(
            (t) => {
              return t.Name === this.config.TreeField;
            }
          );
          return this.Options.filter((t) => {
            return filter(t);
          }).reduce((res, t) => {
            let root = res.find((r) => {
              return r.Id === t[treefield.Name];
            });
            if (!root) {
              root = {
                Title: "",
                Id: t[treefield.Name],
                children: [],
                IsNode: true,
              };
              let op = treefield.Options.find((op) => {
                return op.Id === t[treefield.Name];
              });
              root.Title = op.Title;
              res.push(root);
            }
            root.children.push({
              Id: t.Id,
              Title: t[this.LabelField],
              Data: t,
            });
            return res;
          }, []);
        } else {
          return this.Options.filter((t) => {
            return filter(t);
          }).map((t) => {
            return {
              Id: t.Id,
              Title: t[this.LabelField],
              Data: t,
            };
          });
        }
      }
    },

    Panel () {
      let name = (this.config.Link + ".Field").replace(/\./g, "_");
      if (panels[name]) return name;
      return "";
    },
    DataId () {
      let v =
        this.Data && this.Data.map
          ? this.Data.map((t) => {
            if (t) {
              if (t.Id) return t.Id;
              return t;
            }
          }).filter((t) => {
            return !!t;
          })
          : [];
      if (v.length === 0) return;
      return v;
    },
    LabelField () {
      if (this.config && this.config.LabelField) return this.config.LabelField;
      if (this.config && this.config.Options && this.config.Options.Label)
        return this.config.Options.Label;
      return "Title";
    },
    ParentField () {
      if (this.config && this.config.Options && this.config.Options.ParentField)
        return this.config.Options.ParentField;
      return "Parent";
    },
    Label () {
      if (this.value) {
        return this.value[this.LabelField];
      }
      return "";
    },

    Options () {
      let result = [];
      let selections = this.options || this.selections || [];
      if (
        this.value &&
        this.value.Id &&
        (!selections ||
          !selections.find((t) => {
            return t.Id === this.value.Id;
          }))
      ) {
        result = result.concat([this.value]);
      }
      if (selections && selections.length > 0) {
        result = result.concat(selections);
        let getParent = (p) => {
          return p && p.Id === undefined ? p : p.Id;
        };
        let findchild = (pid) => {
          let child = result
            .filter((r) => {
              return (
                (r[this.ParentField] && getParent(r[this.ParentField]) === pid) ||
                (pid === 0 && !r[this.ParentField])
              );
            })
            .map((c) => {
              let children = findchild(c.Id);
              if (children && children.length > 0) c.children = children;
              return c;
            });
          return child;
        };
        let categroys = findchild(0);
        if (categroys && categroys.length === 0 && result.length) return result;
        return categroys;
      } else {
        return result;
      }
    },
  },
  methods: {
    onConfirm () {
      this.show = false;
      if (this.SelectList.length > 0) {
        this.Selected = this.TreeList.filter((o) => {
          return this.SelectList.indexOf(o.Id) > -1;
        }).map((item) => {
          return item.Title;
        });
        this.Selected =
          this.Selected.length > 1
            ? this.Selected[0] + `等${this.Selected.length}人`
            : this.Selected[0];
        this.Data = (this.Data || []).concat(this.SelectList);
        this.OnChange();
      } else {
        this.Selected = null;
      }
    },
    filterMethod (query, item) {
      return item.Title.indexOf(query) > -1;
    },
    onShowTransfer () {
      this.show = true;
      if (!this.Options || this.Options.length === 0) this.OnLoad();
    },
    handleChange (value, direction, movedKeys) {
      this.SelectList = value;
    },
    OnLoad () {
      let selections = this.selections || this.options || [];
      if (!selections || selections.length === 0) {
        let fields = ["Id"];
        fields.push(this.LabelField);
        if (this.config.Options.Level > 1 && this.ParentField)
          fields.push(this.ParentField);
        if (this.Panel) {
          fields = [];
        }
        let LookValue = null;
        let getParentValue = () => {
          let data = {};
          let selffoo = this.$matrix.FindParentFunction(this, "GetSelfValue");
          if (selffoo) {
            data = selffoo();
          }
          if (!data.Parent || !data.Parent.Id) {
            let parentfoo = this.$matrix.FindParentFunction(this, "GetMainFormValue");
            if (parentfoo) {
              data.Parent = parentfoo();
            }
          }
          return data;
        };
        if (this.config.TreeField) fields.push(this.config.TreeField);
        if (this.config && this.config.LookUp && this.config.LookUp.length > 0) {
          LookValue = this.$matrix.Put(getParentValue(), this.config.LookUp);
          let errors = this.$matrix.Check(LookValue, this.config.LookUp);
          if (errors) {
            console.error(errors);
            return;
          }
        }
        let context = this.Context;
        if (
          context &&
          context.RoleField === this.config.RoleField &&
          context.RoleFieldData
        ) {
          LookValue = LookValue || {};
          LookValue[context.RoleField] = context.RoleFieldData;
        }
        let LoadParams = this.config.LoadParams;
        if (LoadParams) {
          LoadParams = JSON.parse(JSON.stringify(LoadParams));
          if (LoadParams.Mix) {
            LoadParams.Mix = LoadParams.Mix.map((m) => {
              if (m.LookUp) {
                m.Params = this.$matrix.Put(getParentValue(), m.LookUp);
                m.LookUp = undefined;
              }
              return m;
            });
          }
        }
        this.$emit("load", {
          type: "LoadSelections",
          params: Object.assign(
            {
              Page: 1,
              PageSize: 0,
              Fields: fields,
              ["Get" + this.ParentField]: false,
            },
            this.config.Options.Params,
            LoadParams,
            LookValue
          ),
          role: this.config.Role,
          rolefield: this.config.RoleField,
        });
      }
    },
  },
  created () {
    if (this.value && this.value.length > 0) {
      this.OnLoad();
    }
  },
};
</script>
<style scoped>
.rel {
  position: relative;
}

.input {
  border: 1px solid #dcdfe6;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.transfer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-top: 0;
  padding: 10px;
  height: 300px;
}

.wf {
  flex: 1;
}

.transfer-left {
  height: calc(100% - 40px);
  overflow-y: scroll;
}

.pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 4px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(053, 057, 071, 0.3);
  width: 6px;
  height: 6px;
  -webkit-border-radius: 4px;
}
</style>
