<template>
    <mx-entity
        mode="list"
        :type="config.Type"
        :parent="parent"
        :role="config.Role"
        :role-field="config.RoleField"
        ref="entity"
        @change="handleMetaChange"
    >
        <div class="flex-row top" v-if="Config">
            <div style="width: 300px" v-if="Tree">
                <mx-element
                    type="Tree"
                    :config="Tree"
                    class="panel3"
                    style="margin: 5px 5px 0 5px"
                    :value="Config.List.Value"
                    :role="config.Role"
                    :role-field="config.RoleField"
                    @change="OnTreeChange"
                    ref="Tree"
                ></mx-element>
            </div>
            <div style="flex: 1; width: 100%">
                <mx-element
                    type="Group"
                    v-if="Config.Group"
                    :config="Config.Group"
                />
                <div
                    class="flex-row top panel10 s4"
                    style="margin: 5px 5px 0 5px"
                    v-show="IsShowCondition"
                >
                    <mx-form
                        style="flex: 1"
                        class=""
                        type="Condition"
                        v-if="Config.Condition"
                        :config="Config.Condition"
                        :value="Config.Condition.Value"
                        :columns="ConditionColumns"
                        :theme="Config.Condition.Theme"
                        ref="condition"
                    >
                        <template slot="button">
                            <mx-element
                                style="margin-left: 10px"
                                type="Button"
                                theme="Search"
                                v-if="Config.Condition.SearchButton"
                                @click="handleSearch"
                                title="搜索"
                                :loading="Loading"
                            />
                        </template>
                    </mx-form>
                </div>
                <mx-load-list
                    v-if="Config.List"
                    :params="Config.List.Params"
                    :type="Config.List.Entity"
                    :role="Config.List.Role"
                    :role-field="Config.List.RoleField"
                    :data-handler="Config.List.DataHandler"
                    :page-size="Config.List.PageSize"
                    ref="listloader"
                    @loading="handleLoading"
                    :auto-load="false"
                >
                    <template slot-scope="res">
                        <div style="overflow-x: auto">
                            <div
                                style="
                                    padding: 10px;
                                    background-color: #fff;
                                    margin: 5px;
                                "
                                :key="res.timespan"
                                class="s4"
                            >
                                <mx-list
                                    :key="listkey"
                                    v-if="res.data"
                                    :type="Panel ? 'ListPanel' : 'Table'"
                                    :config="Config.List"
                                    :theme="Config.List.Theme"
                                    :columns="Config.List.Columns"
                                    :value="res.data"
                                    :panel="Panel"
                                    :select-enabled="SelectEnabled"
                                    @selection-change="OnSelectedChange"
                                    :actions="Actions"
                                >
                                    <mx-element
                                        type="TableTdLinks"
                                        :config="
                                            CreateTitleLinks(
                                                scope.data,
                                                scope.config,
                                                scope.index
                                            )
                                        "
                                        slot-scope="scope"
                                        slot="column-title"
                                        @click="handleFunction"
                                    ></mx-element>
                                    <mx-element
                                        type="ListButtons"
                                        :options="CreateActions(scope.data)"
                                        slot="button"
                                        slot-scope="scope"
                                        @click="handleFunction"
                                    ></mx-element>
                                </mx-list>
                                <div class="flex-row" style="padding: 10px 0">
                                    <mx-element
                                        type="Buttons"
                                        :options="CreateSelectionActions()"
                                        @click="handleFunctionBySelection"
                                    >
                                    </mx-element>
                                    <mx-element
                                        class="flex:1"
                                        style="
                                            margin-top: 10px;
                                            font-size: 16px;
                                        "
                                        type="Pager"
                                        :value="res.page"
                                        :config="{
                                            MaxSize: res.total,
                                            PageSize: res.pageSize
                                        }"
                                        @change="handlePager"
                                    ></mx-element>
                                </div>
                            </div>
                        </div>
                    </template>
                </mx-load-list>
            </div>
        </div>
        <slot></slot>
        <mx-element
            type="Dialog"
            :config="DialogConfig"
            v-if="DialogConfig"
            :key="dialogKey"
            :value="1"
            :title="DialogConfig.Title"
            :nums="PostNums"
            :loading="Posting"
        >
            <mx-panel
                :name="DialogConfig.ParentPanelName"
                v-if="DialogConfig.ParentPanelName && DialogConfig.ParentType"
                :type="DialogConfig.ParentType"
                :panel="DialogConfig.ParentPanelName"
                :value="DialogConfig.Parent.Parent"
                :role="DialogConfig.Role"
                :role-field="DialogConfig.RoleField"
            ></mx-panel>
            <mx-panel
                :name="DialogConfig.PanelName"
                v-if="DialogConfig.PanelName"
                :type="DialogConfig.Type"
                :panel="DialogConfig.PanelName"
                :parent="DialogConfig.Parent"
                :role="DialogConfig.Role"
                :role-field="DialogConfig.RoleField"
            ></mx-panel>
            <mx-form
                type="DialogForm"
                :config="DialogConfig"
                :value="DialogConfig.Value"
                :columns="DialogConfig.Columns"
                :theme="DialogConfig.Theme"
                ref="dialogform"
            ></mx-form>
        </mx-element>
    </mx-entity>
</template>
<script>
import panels from '@/projects/panels/index'

export default {
    props: {
        config: Object,
        parent: Object,
        panel: String
    },
    data() {
        return {
            Condition: {},
            SortIndex: 0,
            Loading: false,
            DialogConfig: null,
            dialogKey: new Date().getTime(),
            listkey: new Date().getTime(),
            ListSearchParam: null,
            Config: null,
            Panel: '',
            Meta: null,
            SelectEnabled: false,
            Selections: [],
            Posting: false,
            PostNums: 0,
            ConditionColumns: [],
            Tree: null,
            TreeValue: null
        }
    },
    computed: {
        IsShowCondition() {
            return (
                this.Config.Condition &&
                this.Config.Condition.Columns.filter((c) => {
                    return !c.NoShow
                }).length > 0
            )
        },
        Actions() {
            return this.Config.AllActions.call(this)
        }
    },
    watch: {
        Config(val) {
            if (val && val.List) {
                this.$nextTick(() => {
                    this.handleSearch()
                })
            }
        }
    },
    mounted() {
        if (this.Config && this.Config.List) this.handleSearch()
    },
    methods: {
        OnTreeChange(val) {
            if (this.TreeValue !== val) {
                this.TreeValue = val
                this.handleSearch(1)
            }
        },
        OnSelectedChange(val) {
            this.Selections = val || []
        },
        handleMetaChange(val) {
            this.Meta = val
            if (this.Meta && this.Meta.List) {
                let Config = this.$refs.entity.init(
                    this.parent,
                    this.$matrix.GetUser(val.Role)
                )
                this.$emit('meta-change', val)
                this.Config = Config.List
                this.ConditionColumns = this.Config.Condition.Columns.filter(
                    (f) => {
                        return f.Theme !== 'Tree'
                    }
                )
                this.Tree = this.Config.Condition.Columns.find((c) => {
                    return c.Theme === 'Tree'
                })

                if (this.Tree && this.Tree.LookUp) {
                    this.Tree.StaticParams = this.$matrix.Put(
                        {
                            Parent: this.parent.ParentField
                                ? this.parent.Parent
                                : this.parent
                        },
                        this.Tree.LookUp
                    )
                }
                if (this.config.Params) {
                    this.Config.List.Params = Object.assign(
                        {},
                        this.Config.List.Params,
                        this.config.Params
                    )
                }
                if (this.Config) {
                    let setting =
                        this.$matrix.GetLocalStorage('page_setting', true) || {}
                    let key =
                        this.$route.path +
                        '_' +
                        this.Config.Type +
                        '_' +
                        this.Config.List.RoleField
                    setting[key] = setting[key] || {}
                    this.Panel = setting[key].Panel
                    this.$emit('panel-change', this.Panel)
                    this.$emit('config-change', Config)
                    let actions = this.CreateSelectionActions()
                    this.SelectEnabled = actions && actions.length > 0
                }
            }
        },
        CreateActions(data) {
            return this.Config.DataAction.call(this, data)
        },
        CreateSelectionActions() {
            return this.Config.DataSelectionAction.call(this, this.Selections)
        },

        CreateTitleLinks(data, column, index) {
            let config = this.Config.TitleLinks.call(this, data)
            config.Column = column
            config.Index = index
            config.Data = data
            return config
        },
        handleShowPanel(panel) {
            if (panel !== this.Panel) {
                this.Panel = panel
                this.$emit('panel-change', panel)
                let setting =
                    this.$matrix.GetLocalStorage('page_setting', true) || {}
                let key =
                    this.$route.path +
                    '_' +
                    this.Config.Type +
                    '_' +
                    this.Config.List.RoleField
                setting[key] = setting[key] || {}
                setting[key].Panel = panel
                this.$matrix.SetLocalStorage('page_setting', setting, true)
                this.listkey = new Date().getTime()
                if (
                    this.Panel &&
                    panels[this.Panel] &&
                    panels[this.Panel].loadparams
                ) {
                    this.handleSearch(1)
                }
            }
        },

        handleSortChange(index) {
            this.SortIndex = index
            this.handleSearch(1)
        },
        handlePager(val) {
            this.handleSearch(val)
        },
        GetParams() {
            if (!this.Panel && this.ListSearchParam === null) {
                this.ListSearchParam = this.Config.List.Columns.reduce(
                    (t, item) => {
                        if (
                            ['Link', 'Object', 'Array'].indexOf(item.Type) > -1
                        ) {
                            t['Get' + item.Name] = true
                        }
                        return t
                    },
                    {}
                )
            }
            let value = this.ListSearchParam || {}
            if (
                this.Config.Condition.SortFields &&
                this.Config.Condition.SortFields[this.SortIndex]
            ) {
                value.Orders =
                    this.Config.Condition.SortFields[this.SortIndex].Value
            }
            if (this.$refs.condition) {
                value = Object.assign(value, this.$refs.condition.GetValue())
            }
            if (this.TreeValue && this.Tree) {
                value[this.Tree.Name] = this.TreeValue
            }

            let panel = this.Panel
            if (panels[panel] && panels[panel].loadparams) {
                value = Object.assign(
                    value,
                    panels[panel].loadparams.call(this, this.Config.List)
                )
            }
            return value
        },
        handleSearch(page) {
            page = page || 1
            this.Selections = []

            if (this.$refs.listloader) {
                this.$refs.listloader.Load(page, this.GetParams())
            }

            //查询
        },
        handleLoading(state) {
            this.Loading = state
        },
        handleFunction(val) {
            if (val) {
                if (val.Path) {
                    switch (val.Path.toLowerCase()) {
                        case 'detail':
                            this.$matrix.ShowDetail(this.Context, this.config, {
                                Id: val.Params.detail_id
                            },this.$route)
                            return
                    }
                }
                if (val.Name) {
                    switch (val.Name.toLowerCase()) {
                        case 'add':
                            if (val.Dialog) {
                                let dialogConfig = val.Dialog(
                                    this.parent,
                                    this.$matrix.GetUser(this.Config.Role)
                                )
                                this.$matrix.AddData(
                                    this.Context,
                                    {},
                                    {
                                        Type: dialogConfig.Type,
                                        Role: dialogConfig.Role,
                                        RoleField: dialogConfig.RoleField,
                                        Roles: this.config.Roles,
                                        RoleFieldData: this.config.RoleFieldData
                                    },
                                    (data) => {
                                        this.$matrix.ShowToast(
                                            dialogConfig.Message || '保存成功!'
                                        )
                                        this.handleSearch(1)
                                    },
                                    this.parent ? this.parent.Parent : undefined
                                )
                                return
                            }
                            break
                        case 'modify':
                            break
                        case 'export':
                            this.$matrix.ExportData(
                                this.Context,
                                Object.assign(
                                    {},
                                    this.GetParams(),
                                    this.$refs.listloader.GetParams()
                                ),
                                this.config,
                                (file) => {
                                    // this.$refs.listloader.GetPage()
                                }
                            )
                            break
                        case 'import':
                            this.$matrix.ImportData(
                                this.Context,
                                this.config,
                                (datas) => {
                                    this.$matrix.ShowToast('导入数据完成')
                                    this.handleSearch(1)
                                }
                            )
                            break
                    }
                }
                if (val.Url) {
                    this.$matrix.Goto(val.Url)
                } else if (val.Path) {
                    let paths = this.$route.path.split('/')
                    let path = `/${paths[1]}/${val.Path}`
                    let params = []
                    let q = Object.assign({}, this.$route.query, val.Params)
                    Object.keys(q).forEach((key) => {
                        if (
                            key.indexOf('_role') > -1 &&
                            q[key] === this.$matrix.GetCurrentRoleName()
                        ) {
                        } else {
                            params.push(`${key}=${q[key]}`)
                        }
                    })
                    this.$matrix.Goto(path + '?' + params.join('&'))
                } else if (val.Method) {
                    switch (val.Method) {
                        case 'Export':
                            break
                        case 'Delete':
                            let context = this.Context
                            let params = val.Config.Params
                            if (
                                context &&
                                context.RoleFieldData &&
                                context.RoleField === val.Config.RoleField
                            ) {
                                params = Object.assign({}, val.config.Params, {
                                    [val.Config.RoleField]:
                                        context.RoleFieldData
                                })
                            }
                            this.$matrix
                                .Confirm(
                                    val.Confirm ||
                                        '删除数据警告，一旦删除数据将无法回复'
                                )
                                .then((res) => {
                                    this.$matrix
                                        .Post3(
                                            val.Config.Type,
                                            val.Config.Method,
                                            params,
                                            val.Config.Role,
                                            val.Config.RoleField
                                        )
                                        .then((res) => {
                                            this.handleSearch(
                                                this.$refs.listloader.GetPage()
                                            )
                                            this.$matrix.ShowToast(
                                                val.Message || '保存成功!'
                                            )
                                        })
                                        .catch((e) => {
                                            this.$matrix.ShowError(e)
                                        })
                                })
                            break
                    }
                } else if (val.Dialog) {
                    let dialogConfig = val.Dialog(
                        this.parent,
                        this.$matrix.GetUser(this.Config.Role)
                    )

                    let context = this.Context
                    let params = {}
                    if (
                        context &&
                        context.RoleFieldData &&
                        context.RoleField === val.Config.RoleField
                    ) {
                        params = Object.assign({}, val.config.Params, {
                            [val.Config.RoleField]: context.RoleFieldData
                        })
                    }
                    /*******检查是否有自定义面板*** */
                    let panelName = ''
                    if (dialogConfig.Method.toLowerCase() === 'add') {
                        panelName =
                            dialogConfig.Type +
                            '.Add' +
                            '.' +
                            (dialogConfig.RoleField || dialogConfig.Role)
                        panelName = panelName.replace(/\./g, '_')
                        if (!panels[panelName]) panelName = ''
                    }

                    let parentPanelName = ''
                    let parentType = ''
                    if (dialogConfig.Method.toLowerCase() === 'add') {
                        let parentTypes = dialogConfig.Type.split('.')
                        parentTypes.pop()
                        parentType = parentTypes.join('.')
                        parentPanelName =
                            dialogConfig.Type +
                            '.' +
                            (dialogConfig.RoleField || dialogConfig.Role)
                        parentPanelName = parentPanelName.replace(/\./g, '_')

                        if (!panels[parentPanelName]) parentPanelName = ''
                    }
                    /********** */
                    if (
                        dialogConfig.Columns.filter((f) => {
                            return !f.NoShow
                        }).length > 0 ||
                        panelName
                    ) {
                        dialogConfig.PanelName = panelName
                        dialogConfig.ParentPanelName = parentPanelName
                        dialogConfig.ParentType = parentType

                        dialogConfig.OnOk = function () {
                            let dosome = () => {
                                let errors = this.$refs.dialogform.GetError()
                                if (!errors) {
                                    let foo = () => {
                                        let value =
                                            this.$refs.dialogform.GetValue()
                                        if (dialogConfig.Value.Id) {
                                            value =
                                                this.$refs.dialogform.GetChange()
                                            if (
                                                !value ||
                                                Object.keys(value).length === 0
                                            ) {
                                                return this.$matrix.ShowError(
                                                    '数据没有发生改变，不能保存'
                                                )
                                            }
                                            value.Id = dialogConfig.Value.Id
                                        }
                                        this.$matrix
                                            .Post3(
                                                dialogConfig.Type,
                                                dialogConfig.Method,
                                                Object.assign(params, value),
                                                dialogConfig.Role,
                                                dialogConfig.RoleField
                                            )
                                            .then((res) => {
                                                this.DialogConfig = null
                                                this.$matrix.ShowToast(
                                                    dialogConfig.Message ||
                                                        '保存成功!'
                                                )
                                                this.handleSearch(
                                                    this.$refs.listloader.GetPage()
                                                )
                                            })
                                            .catch((e) => {
                                                this.$matrix.ShowError(e)
                                            })
                                    }
                                    if (dialogConfig.Confirm) {
                                        this.$matrix
                                            .Confirm(dialogConfig.Confirm)
                                            .then((res) => {
                                                foo()
                                            })
                                    } else {
                                        foo()
                                    }
                                }
                            }
                            let check = false
                            if (dialogConfig.ParentPanelName) {
                                let panel = panels[dialogConfig.ParentPanelName]
                                if (panel && panel.check) {
                                    check = true
                                    panel
                                        .check(
                                            dialogConfig.Parent.Parent,
                                            dialogConfig
                                        )
                                        .then((res) => {
                                            dosome()
                                        })
                                        .catch((e) => {
                                            if (e) this.$matrix.ShowError(e)
                                        })
                                }
                            }
                            if (!check) dosome()
                        }.bind(this)
                        dialogConfig.OnClose = () => {
                            this.DialogConfig = null
                        }
                        dialogConfig.OnCancel = () => {
                            this.DialogConfig = null
                        }
                        this.dialogKey = new Date().getTime()
                        dialogConfig.Width = dialogConfig.Columns.reduce(
                            (width, item) => {
                                if (item.Type === 'Html') width = '700px'
                                if (item.DialogWidth) width = item.DialogWidth
                                return width
                            },
                            undefined
                        )
                        this.DialogConfig = dialogConfig
                    } else if (dialogConfig.Method.toLowerCase() === 'add') {
                        let message =
                            dialogConfig.Confirm ||
                            '您正在' + dialogConfig.Title + ',请确认'
                        this.$matrix
                            .Confirm(message)
                            .then((res) => {
                                this.$matrix
                                    .Post3(
                                        dialogConfig.Type,
                                        dialogConfig.Method,
                                        Object.assign(
                                            params,
                                            dialogConfig.Value
                                        ),
                                        dialogConfig.Role,
                                        dialogConfig.RoleField
                                    )
                                    .then((res) => {
                                        this.DialogConfig = null
                                        this.handleSearch(1)
                                        this.$matrix.ShowToast(
                                            dialogConfig.Message || '保存成功!'
                                        )
                                    })
                                    .catch((e) => {
                                        this.$matrix.ShowError(e)
                                    })
                            })
                            .catch((e) => {
                                this.$matrix.ShowError(e)
                            })
                    }
                }
            }
        },

        handleFunctionBySelection(val) {
            if (val.Dialog) {
                let dialogConfigs = this.Selections.map((data) => {
                    return val.Dialog(data)
                })

                let dialogConfig = {}
                dialogConfig = dialogConfigs[0]
                dialogConfig.Items = this.Selections
                /*******检查是否有自定义面板*** */
                let panelName = ''
                if (dialogConfig.Method.toLowerCase() === 'add') {
                    panelName =
                        dialogConfig.Type +
                        '.Add' +
                        '.' +
                        (dialogConfig.RoleField || dialogConfig.Role)
                    panelName = panelName.replace(/\./g, '_')
                    if (!panels[panelName]) panelName = ''
                }

                let parentPanelName = ''
                let parentType = ''
                if (dialogConfig.Method.toLowerCase() === 'add') {
                    let parentTypes = dialogConfig.Type.split('.')
                    parentTypes.pop()
                    parentType = parentTypes.join('.')
                    parentPanelName =
                        dialogConfig.Type +
                        '.' +
                        (dialogConfig.RoleField || dialogConfig.Role)
                    parentPanelName = parentPanelName.replace(/\./g, '_')

                    if (!panels[parentPanelName]) parentPanelName = ''
                }

                /********** */

                dialogConfig.PanelName = panelName
                dialogConfig.ParentPanelName = parentPanelName
                dialogConfig.ParentType = parentType
                dialogConfig.Progress = true
                dialogConfig.OnOk = function () {
                    let dosome = () => {
                        let errors = this.$refs.dialogform.GetError()
                        if (!errors) {
                            let foo = () => {
                                let value = this.$refs.dialogform.GetValue()
                                let index = 0
                                let Save = (val) => {
                                    return new Promise((r, j) => {
                                        let data = Object.assign({}, value, val)

                                        return this.$matrix
                                            .AjaxGet3(
                                                dialogConfig.Type,
                                                dialogConfig.Method,
                                                data,
                                                dialogConfig.Role,
                                                dialogConfig.RoleField
                                            )
                                            .then((res) => {
                                                index++
                                                this.PostNums = Math.round(
                                                    (index + 1) /
                                                        this.Selections.length
                                                )
                                                r(res)
                                            })
                                            .catch((e) => {
                                                index++
                                                this.PostNums = Math.round(
                                                    (index + 1) /
                                                        this.Selections.length
                                                )
                                                j(e)
                                            })
                                    })
                                }
                                this.Posting = true
                                let SaveList = dialogConfigs.map((d, index) => {
                                    return Save(d.Value)
                                })
                                Promise.all(SaveList)
                                    .then((res) => {
                                        this.Posting = false
                                        this.DialogConfig = null
                                        this.$matrix.ShowToast(
                                            dialogConfig.Message || '保存成功!'
                                        )
                                        this.handleSearch(1)
                                    })
                                    .catch((e) => {
                                        this.$matrix.ShowToast(
                                            '保存完成，部分数据有错误，请检查后重新操作'
                                        )
                                        this.DialogConfig = null
                                        this.handleSearch(1)
                                        this.Posting = false
                                    })
                            }
                            if (dialogConfig.Confirm) {
                                this.$matrix
                                    .Confirm(dialogConfig.Confirm)
                                    .then((res) => {
                                        foo()
                                    })
                            } else {
                                foo()
                            }
                        }
                    }
                    let check = false
                    if (dialogConfig.ParentPanelName) {
                        let panel = panels[dialogConfig.ParentPanelName]
                        if (panel && panel.check) {
                            check = true
                            let checks = this.Selections.map((data) => {
                                return panel.check(data, dialogConfig)
                            })
                            Promise.all(checks)
                                .then((data) => {
                                    dosome()
                                })
                                .catch((e) => {
                                    this.$matrix.ShowError(
                                        '部分数据有误，无法批量处理，请检查'
                                    )
                                })
                        }
                    }
                    if (!check) dosome()
                }.bind(this)
                dialogConfig.OnClose = () => {
                    this.DialogConfig = null
                }
                dialogConfig.OnCancel = () => {
                    this.DialogConfig = null
                }
                this.dialogKey = new Date().getTime()
                dialogConfig.Width = dialogConfig.Columns.reduce(
                    (width, item) => {
                        if (item.Type === 'Html') width = '700px'
                        if (item.DialogWidth) width = item.DialogWidth
                        return width
                    },
                    undefined
                )
                this.DialogConfig = dialogConfig
            }
        }
    }
}
</script>
