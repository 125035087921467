<template>
    <div style="text-align:left;">
        <el-timeline>
            <el-timeline-item v-for="(activity, index) in options" :key="index" :icon="activity.icon"
                :type="activity.type || 'primary'" :color="activity.color || 'blue'" :size="activity.size"
                :timestamp="!activity.CreateTime || activity.CreateTime.indexOf('1970-01-01') === 0 ? '-' : activity.CreateTime">
                <slot data="activity">
                    <div>
                        <div>
                            <a href="javascript:void(0)" @click="OnClick(activity)" v-if="activity.Id"> {{
                                activity.Title
                            }}</a> <span v-else>{{ activity.Title }}</span><span class="fg f14">{{ activity.Result }}</span>
                        </div>
                        <div v-if="activity.Description" class="f14 fg">{{ activity.Description }}</div>
                        <div v-if="activity.User" class="f14 fg">{{ activity.User }}</div>
                    </div>
                </slot>
            </el-timeline-item>
        </el-timeline>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        value: Number,
        role: String,
        roleField: String,
        options: Array
    },
    methods: {
        OnClick(a) {
            this.$matrix.ShowDetail(this.Context, Object.assign({}, this.config, { Type: a.Type }), { Id: a.Id }, this.$route)
        }
    }
}
</script>