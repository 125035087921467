<template>
  <el-image
    :src="Url"
    v-if="this.value"
    :preview-src-list="[Url]"
    :style="Style"
    fit="cover"
  >
  </el-image>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view(String)],
  computed: {
    Url() {
      if (this.value)
        if (this.value.indexOf("http") === -1)
          return this.$matrix.Config.ImageSite + this.value;
      return this.value;
    },
    IsTable() {
      return this.container && this.container.Mode === "search";
    },
    Style() {
      let style = { width: "40px", height: "40px" };
      if (this.config.Width) {
        style.width = this.config.Width - 20 + "px";
        style.height = this.config.Width - 20 + "px";
      }
      if (this.config.Height) style.height = this.config.Height + "px";
      if (this.IsTable) {
        style.height = "48px";
      }
      return style;
    }
  }
};
</script>
