import Item from './item.vue'
import Frame from './frame.vue'
export default {
    Name: 'FormNewForm',
    ItemIs: 'FormNewFormItem',
    Is: 'FormNewFormFrame',
    Components: {
        FormNewFormFrame: Frame,
        FormNewFormItem: Item
    },
    CreateDataConfig: function (config) {
        return Object.assign({}, config.config)
    }
}
