<template>
  <div class="root">
    <div class="mainpanel">
      <div class="flex-column st">
        <div class="flex-row panel3 middle list border-bottom" style=" border-bottom:1px solid #e8e8e8">
          <div class="f16 bold left" style="width:200px;padding-left:20px;position:absolute;left:0">{{ config.Title }}</div>
          <div class="wf flex-row center"> <mx-page type="Home" :config="config" :name="config.Title + '菜单'" theme="Panel"
              @click="OnMenuClick" :query="{ menu: menu }">
            </mx-page></div>
        </div>
        <div class="rightpabel" :style="rightPanelStyle" :class="isOpen ? '' : 'close'">
          <mx-page :style="PageStyle" :key="CurrentTitle" v-if="CurrentTitle" class="" type="Home" :config="config"
            :name="config.Title + CurrentTitle" :title="CurrentTitle" theme="Panel" @click="OnMenu2Click">
          </mx-page>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
    config: Object
  },
  data() {
    return { CurrentTitle: '', menu: '', height: 60, View: {}, isOpen: true }
  },

  methods: {
    OnMenu2Click(e) {
      if (e && e.Menu) {
        this.menu = e.Menu
        this.View = {}
      }
    },
    OnMenuClick(e) {
      if (e.view && e.view.Title) {
        this.CurrentTitle = e.view.Title
        this.menu = ''
        this.View = e.view
      }
    },
    onMenuHandler() {
      this.isOpen = !this.isOpen
    }
  },
  computed: {
    Theme() {
      if (this.config && this.config.Theme) return this.config.Theme
      return 'top'
    },
    currentMenu() {
      if (this.menu)
        return { menu: this.menu }
      else return {}
    },
    leftPanelStyle() {
      return { height: `calc(100vh - ${this.height}px)` }
    },
    rightPanelStyle() {
      let res = { height: `calc(100vh - ${this.height}px)` }
      if (this.View && this.View.Style || this.config.Style) {
        res = Object.assign(res, this.View.Style, this.config.Style)
      }
      return res
    },
    Icon() {
      if (this.isOpen) {
        return '/static/icon/close.png'
      } else {
        return '/static/icon/open.png'
      }

    },
    PageStyle() {
      return {
        boxShadow: "0 1px 2px rgba(56,65,74,.15)",
        borderRidus: "4px",
        margin: "10px",
        background: "#fff"

      }
    }
  },
  mounted() {
    if (this.$refs.nav) {
      this.height = this.$refs.nav.$el.clientHeight + 2
    } else {
      this.height = 0
    }
  },
  created() {
    let user = this.$matrix.GetUser()
    if (!user) this.$matrix.GotoLogin()
  }
}
</script>
<style scoped>
.root {
  display: block;
  background-color: #fff;
  overflow-x: hidden;
  position: relative;

}

.paneltitle {
  padding: 10px 20px;
  font-size: 24px;
  text-align: left;
}

.leftpabel {
  width: 200px;
  height: 100%;
  overflow-y: auto;
}

.rightpabel {
  flex: 1;
  overflow-y: auto;
}

.mainpanel {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f7f7f7;
  position: relative;
  border: 1px solid #f7f7f7;
}

.panel3 {
  position: relative;
}

.bggray {
  background-color: #f7f7f7;
  position: relative;
}

.panel3:after,
.bggray::after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  border-right: 1px solid #ededed;
  width: 1px;
}

.icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.leftpabel.close {
  display: none;
}

.icon-box {
  position: fixed;
  left: 330px;
  top: 65px;
  z-index: 9999;
  background: #fff;
  border-radius: 20px;
  padding: 10px;
}

.icon-box.close {
  left: 180px;
}

.rightpabel.close {
  margin-left: 45px;
}
</style>







