<template>
  <mx-column-data
    :config="config"
    :dataconfig="dataconfig"
    tip="数据滚动分页列表"
    ref="loader"
  >
    <template slot-scope="scope">
      <div
        v-if="scope.data"
        class="flex-row stretch wrap start"
      >
        <template v-for="(item, index) in scope.data">
          <slot
            :index="index"
            :data="item"
          > </slot>
        </template>
      </div>
      <mx-element
        type="Empty"
        v-if="scope.data.length === 0"
      ></mx-element>
      <mx-element
        type="LoadMore"
        @click="handleLoadingChange(true)"
        @change="handleLoadingChange"
        v-if="scope.loading === false && scope.state && !scope.state.IsEnd"
      ></mx-element>
    </template>
    <mx-element
      type="Loading"
      slot="loading"
    ></mx-element>
  </mx-column-data>
</template>
<script>
export default {
    props: {
        config: Object,
        index: Number,
        dataconfig: Object,
        panel: String
    },
    methods: {
        handleLoadingChange(val) {
            if (val) {
                this.$refs.loader.LoadMore()
            }
        },
        Reload() {
            this.$refs.loader.Load(1)
        }
    }
}
</script>
