<template>
  <div>
    <mx-page
      type="Home"
      :config="master"
      :parent="parent"
      :key="timespan"
      :is-child="isChild"
      :name="Name"
      v-show="!isChild"
    >
    </mx-page>
    <router-view class="router-view" v-if="isChild" slot="child"></router-view>
  </div>
</template>
<script>
import master from "matrix.node.ui/page/master";
export default {
  mixins: [master("home")],
  props: {},
  data() {
    return {};
  },
  computed: {
    Name() {
      return this.$route.path.split("/")[1];
    }
  }
};
</script>
