<template>
    <div>
        <div
            v-for="(col, index) in Columns"
            :key="index"
            v-show="col.NoShow !== true"
        >
            <mx-field
                style="width: 100%"
                v-show="!col.FieldState || GetShowState(col)"
                :config="col"
                :value="value"
                ref="field"
                :edit="true"
                :type="col.Type"
                :name="col.Name"
                @change="handleChange"
                :checkable="true"
            >
            </mx-field>
            <div
                v-if="showerr !== false"
                style="
                    color: red;
                    font-size: 14px;
                    margin-top: 5px;
                    text-align: left;
                    height: 16px;
                "
                class="line"
            >
                <template v-if="GetFieldError(col)">
                    <i class="el-icon-err"></i>
                    {{ GetFieldError(col).message }}</template
                >
            </div>
        </div>
    </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
export default {
    mixins: [form.component],
    computed: {
        Columns() {
            return this.columns.map((c) => {
                c.Class = 'flex-row top start'
                c.Style = { width: '50%', margin: '0 0 10px 0' }
                c.TitleReqStyle = {
                    color: c.Req ? 'red' : '#fff',
                    padding: '12px 0 0 0'
                }
                c.TitleStyle = {
                    textAlign: 'left',
                    padding:
                        c.Title.length > 4
                            ? '4px 10px 4px 0'
                            : '10px 10px 10px 0'
                }
                c.ShowReq = true
                if (
                    ['Html', 'Image', 'Images', 'File', 'Files'].indexOf(
                        c.Type
                    ) > -1 ||
                    c.Length >= 200
                ) {
                    c.Style.width = '100%'
                }
                return c
            })
        }
    },
    methods: {
        GetTitleStyle() {
            return {
                width: 4 * 16 + 'px',
                display: 'inline-block',
                textAlignLast: 'justify',
                lineHeight: 1
            }
        },

        handleChange2(val) {
            if (val.name && this.Errors) {
                this.Errors = this.Errors.filter((e) => {
                    return e.name !== val.name
                })
            }
        },
        GetFieldError(col) {
            if (this.Errors && this.Errors.length > 0) {
                return this.Errors.find((e) => {
                    return e.name === col.Name
                })
            }
        },
        GetSelfValue() {
            return Object.assign({}, this.value, this.GetValue({}))
        }
    }
}
</script>
<style scoped>
.title {
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
.panel10lr {
    padding: 2px 0px;
}
.box {
    flex: 1;
    text-align: left;
}
.box .mask {
    opacity: 0.5;
    background-color: #eee;
    padding: 10px;
}
</style>
