<template>
  <div tip="frame-tab" class="flex-column" >
    <div class="flex-row middle border-bottom noselect panel3" v-if="show">
      <div class="flex-row middle start">
        <div @click="OnClick(index)" v-for="(col, index) in columns" class="hand cursor"
          :class="Index === index ? 'active' : ''" style="padding: 10px">
          <div class="line1 title">{{ col.Title }}</div>
        </div>
      </div>
      <div v-show="false" class="cursor fg" style="padding: 10px 10px 10px 0; min-width: 48px">
        更多<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <div style="flex: 1" v-for="(col, index) in columns" v-if="index === Index">
      <slot :config="col" :index="index"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array,
  },
  data() {
    return { Index: 0 };
  },
  computed: {
    show() {
      if (this.columns && this.columns.length) {
        return this.columns.find(col => {
          return col.ColumnConfig.ShowTitle
        })
      } 
      return true

    }
  },
  methods: {
    OnClick(index) {
      this.Index = index;
    },
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}

.title {
  border-bottom: 1px solid #fff;
  font-size: 16px;
}

.active {
  color: #ff6a6a;
  position: relative;
  font-weight: bold;
}

.active::after {
  content: " ";
  position: absolute;
  left: 20px;
  bottom: 0;
  right: 20px;
  height: 3px;
  border-bottom: 3px solid #ff6a6a;
  color: #ebebeb;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.border-bottom {
  position: relative;
}

.border-bottom::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 5px;
  right: 0;
  border-bottom: 0.5px solid #f7f7f7;
  color: #ebebeb;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
</style>