import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DataSortLine",
    Is: "DataSortLineFrame",
    ItemIs: "DataSortLineItem",
    Components: {
        DataSortLineFrame: Frame,
        DataSortLineItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({}, config.Config);
        if (dataconfig) {
            if (!dataconfig.Fields) { dataconfig.Fields = ["IdCount"]; }
            if (config.DataField) {
                if (!dataconfig.Params) {
                    dataconfig.Params = {};                    
                }
                dataconfig.Params["Get" + config.DataField] = true;
            }
            if (!dataconfig.PageSize) { dataconfig.PageSize = 10; }
            if (
                !dataconfig.GroupFields ||
                dataconfig.GroupFields.length === 0
            ) {
                if (typeof dataconfig.Fields === "string")
                    dataconfig.Fields = [dataconfig.Fields];
                let type = this.$matrix.Models[config.Type];
                if (
                    dataconfig.Fields.find((name) => { return !!type.Fields.find((f) => { return f.Name === name; }); })
                ) {
                    let titleField = type.Fields.find((t) => { return t.Label.indexOf("Title") > -1; });
                    if (titleField) dataconfig.Fields.push(titleField.Name);
                }
            }
            return dataconfig;
        }
    }
}