<template>
  <div class="flex-row middle nav list border-end panel3">
    <div>
      <mx-panel type="Site" :config="SystemConfig" panel="CompanyInfo"></mx-panel>
    </div>
    <div class="flex-row list middle">
      <mx-panel type="Site" :config="SystemConfig" panel="RightMenu"></mx-panel>
      <div v-if="User">
        <el-dropdown trigger="click" @command="handleCommand" placement="bottom">
          <div class="flex-row list">
            <img src="/static/system/face.jpg" alt="" class="faceimage" />
            <div class="flex-column left" v-show="false">
              <div class="active">{{ User.NickName }}</div>
              <div class="gray">管理员</div>
            </div>
          </div>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>{{ User.NickName }}</el-dropdown-item>
            <el-dropdown-item divided command="ModifyPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="Logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div v-else>
        <a href="javascript:void(0);" @click="OnLogin">去登录</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { label: "Title" };
      },
    },
  },
  computed: {
    Config(){
      return this.$matrix.Config
    },
    SystemConfig(){
      return Object.assign(this.Config,{ Type: "Site" });

    },
    OtherUsers() {
      let users = [
        /*  { title: "管理员", name: "Manager" },
        { title: "用户", name: "Users" }*/
      ];
      if (this.Role) {
        return users.filter((u) => {
          return u.name !== this.Role;
        });
      }
    },
    User() {
      return this.$matrix.GetUser();
    },
    Title() {
      return this.$matrix.Config.Title || "酒销云-旗舰版";
    },

    RightMenus() {
      return [
        {
          Title: "消息",
          Icon: "el-icon-message",
        },
        {
          Title: "待办",
          Icon: "el-icon-message-solid",
        },
        {
          Title: "客服",
          Icon: "el-icon-phone",
        },
        {
          Title: "反馈",
          Icon: "el-icon-edit-outline",
        },
      ];
    },
    ScrollConfig() {
      return {
        step: 0.3,
      };
    },
  },
  methods: {
    OnLogin() {
      this.$matrix.GotoLogin();
    },
    handleCommand(command) {
      if (!command) return;
      switch (command) {
        case "Logout":
          this.$matrix.Logout();
          this.$matrix.GotoLogin();
          break;
        case "ModifyPassword":
          this.$matrix.ModifyPassword();
          break;
      }
    },
  },
};
</script>
<style scoped>
.nav {
  padding: 10px;
  transition: all;
  height: 40px;
  color: #7c4622;
  position: relative;
}

.nav::after {
  content: "  ";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
}

.ring {
  border-radius: 50%;
  background-color: #8fd1e1;
  color: #fff;
  width: 20px;
  height: 20px;
  padding: 2px;
  font-size: 16px;
  margin-right: 3px;
}

.username {
  color: #7c4622;
  cursor: pointer;
}

.faceimage {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #eee;
}

.gray {
  color: gray;
}

.active {
  color: #2d8cf0;
}

.pointer {
  cursor: pointer;
}

.warp {
  height: 20px;
  overflow: hidden;
}
</style>
