<template>
  <mx-entity mode="add" :type="config.Type" :parent="parent" :role="config.Role" :role-field="config.RoleField"
    ref="entity" @change="handleMetaChange">
    <template v-if="Meta">
      <div style="margin: 1px auto" class="panel10" :key="pagekey">
        <div style="width: 900px; margin: 0 auto">
          <div style="padding: 20px">
            <div class="s4">
              <mx-element type="FormTitle" :title="Meta.Title"></mx-element>
              <mx-form style="padding: 20px 20px" :key="formkey" type="Edit" :config="Form" :value="Form.Value"
                :columns="Form.Columns" :theme="Form.Theme" ref="main" @change="handlFormChange"></mx-form>
            </div>
            <div v-for="child of Children" :key="child.Name" class="s4" style="margin-top: 20px"
              v-show="visiabled[child.Name]">
              <mx-element type="FormTitle" :title="child.Title" class="panel4">
                <mx-element v-if="child.DeleteEnabled" type="Switch"
                  :value="deleted && deleted[child.Name] === false ? 0 : 1"
                  @change="handleDelete($event, child)"></mx-element>
              </mx-element>
              <div class="box">
                <div v-show="
                  child.DeleteEnabled &&
                  deleted &&
                  deleted[child.Name] === false
                " class="mask">
                  <div class="vcenter">
                    {{ child.Title }}已经停用,数据不会被存储
                  </div>
                </div>
                <template v-if="child.IsList">
                  <mx-load-list v-if="child.DataLoad" :params="child.DataLoad.Params" :type="child.DataLoad.Type"
                    :role="child.DataLoad.Role" :role-field="child.DataLoad.RoleField" :data-handler="child.DataHandle"
                    :page-size="0" @loading="handleLoading($event, child)" :auto-load="true">
                    <template slot-scope="res">
                      <mx-list :key="formkey" :type="child.ListType" :config="child"
                        :value="child.CreateList(child, res.data)" :columns="child.Form.Columns" :theme="child.Theme"
                        :ref="child.Name" :options="res.data">
                      </mx-list>
                    </template>
                  </mx-load-list>
                  <mx-list :key="formkey" v-else :type="child.ListType" :config="child"
                    :value="child.CreateList(child, child.Options)" :columns="child.Form.Columns" :theme="child.Theme"
                    :ref="child.Name" :options="child.Options">
                  </mx-list>
                </template>
                <mx-form style="padding: 40px 20px 10px 20px" :key="formkey" v-else type="Edit" :config="child"
                  :value="child.Form.Value" :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name">
                </mx-form>
              </div>
              <mx-panel :panel="getPanel(child)" v-if="getPanel(child)" :name="getPanel(child)" :type="child.Type"
                :role="child.Role" :role-field="child.RoleField"></mx-panel>
            </div>
          </div>
          <div style="margin: 0px 20px" class="panel10 s4">
            <div style="text-align: center" class="flex-row center middle list-half">
              <template v-if="!posting">
                <div v-if="Form.InsertNumber > 1" class="flex-row center middle list-half">
                  <mx-element type="Button" theme="Submit" title="批量提交" @click="handleSubmitList"></mx-element>
                  <div>生成条数</div>
                  <el-input-number v-model="num" :min="1" :max="Form.InsertNumber" label="生成条数"></el-input-number>
                </div>
                <template v-else>
                  <mx-element type="Button" theme="Submit" title="提交" @click="handleSubmit"></mx-element>
                  <mx-element type="Button" theme="Cancel" title="取消" @click="handleCancel"></mx-element></template>
              </template>
              <div v-else class="flex-row center middle list-half">
                <el-progress v-if="!posted" style="width: 500px" :text-inside="true" :stroke-width="24"
                  :percentage="Math.round((postednumbers / num) * 100)" status="success"></el-progress>
                <div v-if="posted" class="flex-row list-half start middle">
                  <el-button size="mini" @click="handleBack">返回</el-button>
                  <div>
                    完成创建，成功{{
                        result.filter((f) => {
                          return f;
                        }).length
                    }}条，失败{{
    result.filter((f) => {
      return !f;
    }).length
}}条
                  </div>
                </div>
              </div>
            </div>
            <mx-element style="margin-top: 10px" type="Message" theme="Error" v-if="errors && errors.length > 0">
              有{{ errors.length }}处错误，无法提交数据
            </mx-element>
          </div>
        </div>
      </div>
    </template>
  </mx-entity>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    config: Object,
    parent: Object,
    isChild: Boolean
  },
  data() {
    return {
      formkey: new Date().getTime(),
      pagekey: new Date().getTime(),
      errors: [],
      loading: {},
      deleted: null,
      Data: null,
      visiabled: {},
      Meta: null,
      Form: null,
      Children: null,
      num: 1,
      posting: false,
      posted: false,
      postednumbers: 0,
      result: []
    };
  },
  methods: {
    getPanel(child) {
      let role = child.Role;
      let rolefield = child.RoleField;
      let name = (child.Type + ".Edit." + rolefield || role).replace(
        /\./g,
        "_"
      );
      if (panels[name]) return name;
      return "";
    },
    handleMetaChange(val) {
      if (val) {
        let Config = this.$refs.entity.init(
          this.parent,
          this.$matrix.GetUser(val.Role)
        );

        this.Form = Config.Form;
        this.Children = Config.Children;
        this.Data = this.Form.Value;
        this.Meta = val;
        this.$emit("meta-change", this.Meta);
        if (this.Children) {
          let visiabled = {};
          this.Children.forEach(c => {
            if (
              c.ParentState &&
              !this.$matrix.Include(c.ParentState, this.Data)
            )
              visiabled[c.Name] = false;
            else visiabled[c.Name] = true;
          });
          this.visiabled = visiabled;
        }
      }
    },
    handleDelete(val, item) {
      this.deleted = Object.assign({}, this.deleted, {
        [item.Name]:
          this.deleted && this.deleted[item.Name] !== undefined
            ? !this.deleted[item.Name]
            : false
      });
    },
    handleLoading(val, item) {
      this.loading[item.Name] = val;
    },
    handleSubmitList() {
      let errors = this.$refs.entity.GetErrors(
        this.$refs,
        this.deleted,
        this.loading
      );
      if (errors && errors.length > 0) return;
      this.postednumbers = 0;
      let list = [];
      this.result = [];
      this.posting = true;
      this.posted = false;
      for (let i = 0; i < this.num; i++) {
        list.push(
          new Promise((r, j) => {
            this.$refs.entity
              .Submit(
                this.$refs,
                this.deleted,
                this.loading,
                this.Form,
                this.Children,
                true
              )
              .then(data => {
                this.postednumbers += 1;
                r(true);
              })
              .catch(e => {
                this.postednumbers += 1;
                r(false);
              });
          })
        );
      }
      Promise.all(list)
        .then(res => {
          this.result = res;
          setTimeout(() => {
            this.posted = true;
          }, 500);
        })
        .catch(e => {
          setTimeout(() => {
            this.posted = true;
          }, 500);
        });
    },
    handleBack() {
      this.$matrix.Back(true);
    },
    handleSubmit() {
      this.$refs.entity
        .Submit(
          this.$refs,
          this.deleted,
          this.loading,
          this.Form,
          this.Children
        )
        .then(data => {
          this.$matrix
            .ShowConfirm("保存数据成功", "请选择继续新增，或者返回列表", {
              confirmButtonText: "返回列表",
              cancelButtonText: "继续新增"
            })
            .then(res => {
              if (res) this.$matrix.Back(true);
              else {
                this.$matrix.RepareReload();
                this.pagekey = new Date().getTime();
              }
            });
        })
        .catch(e => {
          this.$matrix.ShowError(e);
          this.errors = e.items;
        });
    },
    handleCancel() {
      this.formkey = new Date().getTime();
      this.errors = null;
    },
    handlFormChange(val) {
      if (this.Children) {
        let visiabled = {};
        this.Children.forEach(c => {
          if (c.ParentState && !this.$matrix.Include(c.ParentState, val))
            visiabled[c.Name] = false;
          else visiabled[c.Name] = true;
        });
        this.visiabled = visiabled;
      }
    },
    GetMainFormValue() {
      let data = this.$refs.main.GetValue({})
      if (this.Data && this.Data.Id) {
        data.Id = this.Data.Id
        data.Type = this.Data.Type
      }
      return data
    }
  },
  created() { }
};
</script>
<style scoped>
.box {
  position: relative;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: #fff;
  z-index: 999;
}

.vcenter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate(0, -50%);
  color: #000;
  font-weight: 700;
}
</style>
