<template>
    <el-image style="width: 200px; height: 200px" :src="$matrix.Config.ImageSite + value"
        :preview-src-list="[$matrix.Config.ImageSite + value]">
    </el-image>
</template>
<script>
export default {
    props: {
        value: String,
        config: Object,
        title: String,
        options: Array,
        icon: String,
        theme: String
    }
}
</script>