<template>
  <div tip="frame-container" class="noselect">
    <template v-for="(item, index) of columns" :style="OutStyle">
      <slot :config="item" :index="index"></slot>
    </template>
    <slot name="content" :config="config"></slot>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array,
  },
  computed: {
    OutStyle() {
      return {
        width: Math.round((100 / this.columns ? this.columns.length : 1) * 100) / 100 + "%",
        height: "120px",
        overflow: "hidden",
      };
    },
    InnerStyle() {
      return {
        margin: "5px",
        height: "calc(100% - 10px)",
      };
    },
  },

};
</script>