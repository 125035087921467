import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "FormSetting",
    ItemIs: "FormSettingItem",
    Is: "FormSettingFrame",
    Components: {
        FormSettingFrame: Frame,
        FormSettingItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({}, config.Config);

        dataconfig.PageSize = 1;

        if (config.DataField) {
            if (!dataconfig.Params) {
                dataconfig.Params = {};
            }
            dataconfig.Params["Get" + config.DataField] = true;

        }
        return dataconfig;
    }
}