<template>
  <div style="height: 500px; padding-bottom: 82px">
    <vue-editor
      useCustomImageHandler
      @imageAdded="handleImageAdded"
      v-model="Data"
      style="height: 100%"
      @input="EditChange"
    />
  </div>
</template>
<script>
import { VueEditor, Quill } from "vue2-editor";
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String)],
  components: { VueEditor },

  methods: {
    EditChange(val) {
      this.OnChange(val);
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      this.$matrix.Upload(file).then((datas) => {});
    },
  },
};
</script>