import SettingComponent from './Setting/index.vue';
import SimpleComponent from './Simple/index.vue';
import LoginComponent from './Login/index.vue';
import EditComponent from './Edit/index.vue';
import DialogFormComponent from './DialogForm/index.vue';
import ConditionComponent from './Condition/index.vue'
export default { 
    Name: "forms",
    Title: "表单",
    Value: [
        { Name: "Setting",Component: SettingComponent },
        { Name: "Simple",Component: SimpleComponent },
        { Name: "Login",Component: LoginComponent },
        { Name: "Edit",Component: EditComponent },
        { Name: "DialogForm",Component: DialogFormComponent },
        { Name: "Condition",Component: ConditionComponent }
    ]
}