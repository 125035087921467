<template>
  <div class="bg">
    <div class="admin-box flex-row" :style="LoginStyle">
      <div class="admin-left" :style="LeftBgStyle">
        <div class="text-box flex-column list">
          <div></div>
          <div class="flex-row left bold" :style="MainStyle">
            {{ MainTitle || "MatrixNode快速开发模型系统" }}
          </div>
          <div class="flex-row left bold" :style="SubStyle">
            {{ SubTitle || "用最简单、便捷的方式实现你的想法" }}
          </div>
        </div>
      </div>
      <div class="admin-right">
        <div class="login-box">
          <div @click="OnChange" class="code" v-if="hasCode">
            <img src="/static/system/code.png" class="'code" v-if="isCode" />
            <img src="/static/system/computer.png" class="'code" v-else />
          </div>
          <template v-if="isCode">
            <div class="flex-row start middle list">
              <div>
                <img src="/static/system/login-logo.png" alt="" class="logo" />
              </div>
              <div>
                <b>{{ Config.SubTitle }}</b
                >欢迎登录
              </div>
            </div>
            <div class="login-form">
              <mx-form
                type="Login"
                :columns="LoginMeta.children[0].Columns"
                ref="login"
                :value="LoginValue"
              >
              </mx-form>
            </div>
            <div class="flex-row left">
              <el-checkbox v-model="AutoSave" @change="OnAutoSaveChange"
                >记住密码</el-checkbox
              >
            </div>
            <div @click="OnLogin" class="login-button" :style="LoginButton">登录</div>
          </template>
          <template v-else>
            <div class="login-code">
              <div class="flex-row start middle list">
                <div>
                  <img src="/static/system/login.png" alt="" class="logo" />
                </div>
                <div>欢迎登录</div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    config: Object,
  },
  data() {
    return { AutoSave: true, LoginValue: { LoginName: "", Password: "" }, isCode: true };
  },

  computed: {
    Scenes() {
      return this.$matrix.Metas;
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    },
    LoginMeta() {
      return this.CurrentMeta.SiteMap.find((c) => {
        return c.name === "account";
      }).children.find((c) => {
        return c.name === "Login";
      });
    },
    LoginPanelConfig() {
      if (this.LoginMeta) {
        let _panels = this.LoginMeta.children[0].Role.split(".");
        _panels.push("Login");
        let panel = _panels.join("_");

        return {
          Type: this.LoginMeta.children[0].Type,
          Role: this.LoginMeta.children[0].Role,
          RoleField: this.LoginMeta.children[0].RoleField,
          Panel: panels[panel] ? panel : "",
        };
      }
    },
    LoginStyle() {
      if (this.Login) {
        return {
          width: this.Login.width,
          height: this.Login.height,
          margin: this.Login.margin,
          overflow: "hidden",
          borderRadius: this.Login.borderRadius || "60px",
        };
      }
    },
    Theme() {
      if (this.$matrix.Config.Theme) {
        return this.$matrix.Config.Theme;
      }
    },
    Color() {
      if (this.Theme) {
        return this.Theme.DefaultColor;
      }
    },
    LoginButton() {
      if (this.Color) {
        return {
          backgroundColor: this.Color,
          marginTop: "40px",
          padding: "20px",
          color: "#fff",
          borderRadius: "100px",
        };
      }
    },
    Config() {
      return this.$matrix.Config;
    },
    Login() {
      return this.Config.Login;
    },
    Left() {
      return this.Login.Left;
    },
    Right() {
      return this.Login.Right;
    },
    MainTitle() {
      if (!this.Left || !this.Left.MainTitle) return "MatrixNode";
      return this.Left.MainTitle.Text;
    },
    SubTitle() {
      if (!this.Left || !this.Left.LoginSubTitle) return "MatrixNode";
      return this.Left.LoginSubTitle.Text;
    },
    MainStyle() {
      if (this.Left && this.Left.MainTitle.Style) return this.Left.MainTitle.Style;
      return {
        fontSize: "40px",
        color: "#fff",
      };
    },
    SubStyle() {
      if (this.Left.LoginSubTitle && this.Left.LoginSubTitle.Style)
        return this.Left.LoginSubTitle.Style;
      return {
        fontSize: "30px",
        color: "#fff",
      };
    },
    LeftBgStyle() {
      if (this.Left.leftbg) {
        return {
          background: `url('${this.Left.leftbg}')`,
          backgroundSize: "cover",
          backgroundPosition: "bottom right",
        };
      }
    },
    hasCode() {
      return this.Right.hasCode;
    },
  },
  methods: {
    OnChange() {
      this.isCode = !this.isCode;
    },
    OnAutoSaveChange(val) {
      this.$matrix.SetLocalStorage(
        "AutoSaveCheck" + this.$matrix.GetCurrentRoleName(),
        { AutoSave: val },
        true
      );
    },
    handleScene(val) {
      let scene = this.Scenes[val];
      if (scene) {
        this.$matrix.SetMeta(val);
        this.$nextTick(() => {
          this.$matrix.Goto("/home?t=" + new Date().getTime(), undefined, true);
        });
      }
    },
    OnLogin(val) {
      let errors = this.$refs.login.GetError();
      if (!errors) {
        let value = this.$refs.login.GetValue();
        //LoginMeta.children[0]
        this.$matrix
          .Post3(
            this.LoginMeta.children[0].Type,
            "add",
            value,
            this.LoginMeta.children[0].Role,
            this.LoginMeta.children[0].RoleField,
            true
          )
          .then((data) => {
            let value = this.$refs.login.GetValue();

            if (this.AutoSave) {
              this.$matrix.SetLocalStorage(
                "AutoSave" + this.$matrix.GetCurrentRoleName(),
                value,
                true
              );
            }
            this.$matrix.SetRole(this.LoginMeta.children[0].Role, data);
            this.$matrix.CloseLogin();
          })
          .catch((e) => {
            this.$matrix.ShowError(e);
          });
      }
    },
    OnOut() {
      this.$matrix.CloseLogin();
    },
    onUserNameChange() {},
  },
  created() {
    let val = this.$matrix.GetLocalStorage(
      "AutoSaveCheck" + this.$matrix.GetCurrentRoleName(),
      true
    );
    if (val) {
      this.AutoSave = val.AutoSave;
    } else {
      this.$matrix.SetLocalStorage(
        "AutoSaveCheck" + this.$matrix.GetCurrentRoleName(),
        { AutoSave: this.AutoSave },
        true
      );
    }
    if (this.AutoSave) {
      let data = this.$matrix.GetLocalStorage(
        "AutoSave" + this.$matrix.GetCurrentRoleName(),
        true
      );
      if (data) {
        let val = {};
        val.LoginName = data.LoginName;
        val.Password = data.Password;
        this.LoginValue = val;
      }
    }
  },
};
</script>
<style scoped>
.admin-box {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  background: #f8f2fa;
}

.admin-left,
.admin-right {
  height: 100%;
}

.admin-left {
  width: 60%;
  background: linear-gradient(#0575e6, #021b79);
  align-items: center;
  display: flex;
}

.admin-right {
  width: 40%;
  background: #fff;
  align-items: center;
  display: flex;
}

.text-box {
  width: 70%;
  margin: 0 auto;
}

.login-box {
  width: 70%;
  margin: 0 auto;
  -webkit-box-shadow: 0px 8px 24px 0px rgba(42, 94, 173, 0.06);
  box-shadow: 0px 8px 24px 0px rgba(42, 94, 173, 0.06);
  padding: 40px;
  border-radius: 8px;
  position: relative;
  height: 514px;
}

.login-box .logo {
  width: 200px;
}

.login-box .code {
  position: absolute;
  right: 0;
  top: 0;
}

.bg {
  /* background:url('/static/system/logo.png') */
}

.login-form {
  margin-top: 60px;
}

.login-code {
  height: 514px;
}
</style>
