<template>
  <div id="app">
    <router-view v-if="init"/>
  </div>
</template>

<script>
import "./projects/project.css";
export default {
  name: "App",
  data() {
    return { init: false }
  },
  created() {
    this.$matrix.LoadApp({
      message: this.$message,
      confirm: this.$confirm,
      dialog: this.$confirm
    });
    if (this.$matrix.Config.Meta) {
      this.$matrix.GetMeta('model').then(res => {
        this.$matrix.Models = res.model
        this.init = true
      })
    } else {
      this.init = true
    }
    //this.$matrix.SetToast(this.$message);
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #f7f7f7;
  overflow-y: hidden;
}
* {
  margin: 0;
  padding: 0;
}
</style>
