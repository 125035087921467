<template>
  <div style="width: 100%">
    <mx-panel v-if="!IsDelete && value" :type="config.Type" :value="value" :config="Config" panel="SystemList2"
      :selected="selected" :index="index" @event="OnEvent">
      <template>
        <div class="flex-row" style="width: 100%">
          <div class="wf left" style="color: #878a99">名称</div>
          <div class="wf left" style="color: #878a99">登录账号</div>
          <div class="wf left" style="color: #878a99">所属公司</div>



          <div class="wf left" style="color: #878a99">上次登录时间</div>
          <div class="wf left" style="color: #878a99">操作</div>
        </div>
      </template>

      <template slot="content">
        <div class="flex-row start middle border-bottom" style="width: 100%; padding: 20px 0">
          <div class="wf flex-row start list active">
            {{ value.NickName }}
          </div>
          <div class="wf flex-row start list active">
            {{ value.LoginName }}
          </div>
          <div class="wf flex-row start list  "  >
            {{ value.Company?value.Company.Name:"未配置" }}
          </div>
          <div class="wf flex-row start list">
            {{ value.LastLoginTime }}
          </div>
          <div class="wf flex-row start list">
            <div class="pointer active" @click="onGoDetail" v-if="IsDetail">详情</div>
            <div v-if="!GetDisabled('modify')">
              <a class="active" href="javascript:void(0)" @click="OnModify">编辑</a>
            </div>
            <div v-if="!GetDisabled('del')">
              <a class="active" href="javascript:void(0)" @click="OnDelete">删除</a>
            </div>
          </div>
        </div>
      </template>
      <div slot="button"></div>
    </mx-panel>
  </div>
</template>

<script>
import base from "matrix.node.ui/libs/panels/base.js";
export default {
  props: {
    config: Object,
    value: Object,
    index: Number,
    role: String,
    roleField: String,
    name: String,
    selected: Object,
  },
  mixins: [base],
  data() {
    return { IsModify: false, IsDelete: false };
  },
  computed: {
    SiteUrl() {
      return this.$matrix.Config.ImageSite;
    },
    Config() {
      return Object.assign(
        { EventName: this.name ? this.name : "TypeEvent", IsDark: false },
        this.config
      );
    },
    IsDetail() {
      if (this.config.DetailView) return true
      return false
    }
  },
  methods: {
    OnEvent(e) {
      this.$emit("event", e);
    },
    onGoDetail() {
      let View = this.config.DetailView;
      if (!View) return this.$matrix.ShowError("未配置详情视图");
      this.$emit("goview", {
        View: View,
        ViewType: "详情",
        Data: this.value,
        Role: this.config.Role,
        RoleField: this.config.RoleField,
      });
    },
  },
};
</script>

<style scoped>
.wf {
  flex: 1;
}

.wf2 {
  flex: 2;
}

.border {
  border: 1px solid #eee;
}

.label_1,
.label_2,
.label_3 {
  border-radius: 4px;
  padding: 4px 6px;
  text-align: center;
}

.label_1 {
  background: #fbc451;

  color: #fff;
}

.label_2 {
  background: #a498cb;

  color: #fff;
}

.label_3 {
  background: #a7bba9;

  color: #fff;
}

.label_4 {
  background: #f49a71;

  color: #fff;
}

.label_5 {
  background: #6d72bb;

  color: #fff;
}

.active {
  color: #2d8cf0;
}

.text-line {
  text-decoration: line-through;
  color: gray;
}

span.button {
  color: #2d8cf0;
  cursor: pointer;
}

.image-box {
  width: 48px;
  height: 48px;
  border-radius: 4px;
  opacity: 1;
  background-color: rgba(243, 246, 249, 1);
  padding: 4px;
}

.border-bottom {
  border-bottom: 1px solid #e9ebec;
}
</style>
