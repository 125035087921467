<template>
	<el-table :data="value" fit @selection-change="handleSelectionChange">
		<el-table-column type="selection" width="55" v-if="selectEnabled">
		</el-table-column>
		<el-table-column v-else label="序号" width="54" type="index" style="text-align: center">
		</el-table-column>
		<el-table-column v-for="(col, index) in columns" :key="index" :label="col.Title" :width="col.Width" :min-width="col.IsTitle ? 250 : 0">
			<template slot-scope="scope">
				<slot name="column" :config="col" :data="scope.row" :index="scope.$index">
				</slot>
				<slot name="column-title" :config="col" :data="scope.row" :index="scope.$index" v-if="col.IsTitle">
				</slot>
				<mx-field v-else :type="col.Type" :edit="false" :name="col.Name" :value="scope.row" :config="col"></mx-field>
			</template>
		</el-table-column>
		<el-table-column label="操作" align="center" width="140" v-if="actions && actions.length > 0">
			<slot name="button" slot-scope="scope" :data="scope.row" :index="scope.$index"></slot>
		</el-table-column>
	</el-table>
</template>
<script>
export default {
	props: {
		config: Object,
		value: Array,
		columns: Array,
		selectEnabled: Boolean,
		actions: Array
	},
	methods: {
		handleSelectionChange (val) {
			this.$emit("selection-change", val);
		}
	}
};
</script>