<template>
    <mx-panel v-if='!IsDelete && value' :type='config.Type' panel='SystemList' :value='value' :config='Config'
        :index='index' @event="OnEvent">
        <div slot='button' slot-scope="scope" v-if="scope.handle" class="flex-row start wrap list-half">
            <div v-if="!scope.handle.GetDisabled('modify')">
                <a class="button" href="javascript:void(0)" @click="scope.handle.OnModify">编辑</a> </div>
            <div v-if="!scope.handle.GetDisabled('del')">
                <a class="button" href="javascript:void(0)" @click="scope.handle.OnDelete">删除</a> </div>
        </div>
        <div v-if='IsModify' class="modifyed">
            <div>数据已经发生变化，请重新刷新页面</div>
        </div>
    </mx-panel>
</template>
<script>
export default {
    props: {
        config: Object,
        value: Object,
        index: Number,
        role: String,
        roleField: String,
        name: String
    },
    data() {
        return { IsModify: false, IsDelete: false }
    },
    computed: {
        Config() {
            return Object.assign({ EventName: this.name ? this.name : 'TypeEvent', IsDark:false }, this.config)
        }
    },
    methods: {
        OnEvent(e) {
            this.$emit('event', e)
        }
    }
}
</script>
<style scoped>
.disvisible {
    visibility: hidden;
}

.box {
    position: relative;
}

.modifyed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3000;
    opacity: 0.5;
    background-color: #37437f;
    color: #fff;
    line-height: 34px;
}

.button {
    font-size: 16px;
    color: #37437f;
    font-weight: 450;
}
</style>



