import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DataViewPanel",
    ItemIs: "DataViewPanelItem",
    Is: "DataViewPanelFrame",
    Components: {
        DataViewPanelFrame: Frame,
        DataViewPanelItem: Item
    },
    CreateDataConfig: function (config) {
        return Object.assign({}, config.config);
    }
}