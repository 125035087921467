<template>

    <div class="main">
        <main class="container">
            <section class="left">
                <div class="left-bg"></div>
            </section>
            <section class="right">
                <div class="form">
                    <div class="form-box">
                        <div class="box-inner">

                            <div class="flex-row title middle start list">
                                <div style="font-size: 30px">修改密码</div>

                                <div>
                                    <a href="javascript:void(0)" @click="OnHide">返回</a>
                                </div>
                            </div>
                        </div>
                        <div class="shadow" style="margin-top: 30px">
                            <div style="margin-top: 30px; width: 100%">
                                <mx-form type="Login" :columns="Columns" ref="form"></mx-form>
                            </div>
                            <div style="width: 100%" @click="OnModifyPassword" class="btn">
                                修改密码
                            </div>
                            <div style='color:red'>{{ Error }}</div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>
<script>
import panels from '@/projects/panels/index'
export default {
    props: {},
    computed: {
        Columns() {
            return [
                { Name: "OldPassword", Title: "旧密码", Type: "String", Req: true, EditStyle: "Password" },
                { Name: "Password", Title: "新密码", Type: "String", Req: true, EditStyle: "Password" },
                { Name: "RepPassword", Title: "重复新密码", Type: "String", Req: true, EditStyle: "Password" }]
        }
    },
    data() {
        return { Error: "" }
    },
    methods: {
        OnModifyPassword(val) {
            this.Error = ""
            let errors = this.$refs.form.GetError()
            if (!errors) {
                let value = this.$refs.form.GetValue()
                //LoginMeta.children[0] 
                if (value.RpPassword !== value.NewPassword) {
                    this.Error = '两次输入的密码不相同，请重新输入新密码'
                    return
                }
                let role = this.$matrix.GetCurrentRoleName()

                this.$matrix
                    .Post3(
                        role + '.ModifyPassword',
                        'add',
                        value,
                        role,
                        'Parent'
                    )
                    .then((data) => {
                        this.$matrix.CloseModifyPassword()
                        this.$matrix.ShowToast('修改密码成功，请重新登录')
                        this.$matrix.Logout(role, true)
                    })
                    .catch((e) => {
                        this.$matrix.ShowError(e)
                    })
            }
        },
        OnHide() {
            this.$matrix.CloseModifyPassword()
        },
        onUserNameChange() { }
    }
}
</script>
<style scoped>
.main {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
}

.container {
    background: rgb(255, 255, 255);
    height: 100%;
    overflow: hidden;
    min-height: 670px;
}

.left {
    position: relative;
    float: left;
    width: 32%;
    height: 100%;
}

.right {
    width: auto;
    height: 100%;
    overflow: auto;
}

.left-bg {
    width: 100%;
    height: 100%;
    background-color: rgb(245, 246, 250);
    background-size: cover;
    background-position: center center;
    background-image: url('~@/assets/officer/login-left.png');
}

.form {
    height: 100%;
    min-height: 598px;
}

.form-box {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    box-sizing: border-box;
    width: 56%;
    height: 100%;
    max-width: 450px;
    min-width: 288px;
    min-height: 575px;
    margin: 0px auto;
    padding: 0px;
}

.box-inner {
    width: 100%;
    height: auto;
    margin-bottom: 0px;
}

.logo {
    padding-bottom: 37px;
    text-align: left;
}

.logo img {
    height: 38px;
}

.title {
    text-align: left;
    font-size: 24px;
    font-weight: 400;
    color: rgb(38, 49, 66);
}

.shadow {
    -webkit-box-shadow: 0 0 10px 0 hsl(0deg 0% 86% / 57%);
    box-shadow: 0 0 10px 0 hsl(0deg 0% 86% / 57%);
    border-radius: 12px;
    padding: 20px;
    width: 100%;
}

.btn {
    background: #3d7fff;
    border-radius: 30px;
    border: none;
    margin-top: 14px;
    height: 60px;
    font-size: 20px;
    line-height: 60px;
    outline: none;
    color: #fff;
    width: 100%;
    cursor: pointer;
    text-align: center;
}

.link {
    font-family: PingFangSC-Regular;
    font-size: 20px;
    letter-spacing: 0;
    color: #3d7fff;
    text-align: center;
    margin: 30px 0;
    cursor: pointer;
}
</style>
