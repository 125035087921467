<template>
  <el-pagination
    v-if="config"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="Data"
    :page-size="config.PageSize"
    layout="total, prev, pager, next"
    :total="config.MaxSize"
    background
  >
  </el-pagination>
</template>
<script>
export default {
  props: {
    value: Number,
    config: Object
  },
  data() {
    return { Data: this.value || 1 };
  },
  methods: {
    handleSizeChange(val) {
      this.$emit("config-change", { PageSize: val });
    },
    handleCurrentChange(val) {
      this.$emit("change", val);
    }
  }
};
</script>