<template>
  <el-dropdown trigger="click" @command="handleCommand">
    <el-button style="height: 40px">
      <i
        :class="getIconClass(icon)"
        style="font-size: 16px; font-weight: 700"
      />
      {{ title }}<i class="el-icon-arrow-down el-icon--right" />
    </el-button>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item
        :command="index"
        v-for="(op, index) in options"
        :key="index"
      >
        <i :class="getIconClass(op.Icon)" v-if="op.Icon"></i
        ><span :class="Current === index ? 'active' : ''">{{ op.Title }}</span>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>
<script>
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    options: Array,
    icon: String
  },
  data() {
    return { Current: this.value };
  },
  methods: {
    getIconClass(icon) {
      return "el-icon-" + icon;
    },
    handleCommand(command) { 
      command = parseInt(command);
      if (this.Current !== command) {
        this.Current = command; 
        this.$emit("change", command);
      }
    }
  }
};
</script>
<style scoped>
.mx-dropdown {
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 8px;
}
.active {
  color: red;
  font-weight: 450;
}
</style>
