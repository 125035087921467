<template>
  <div class="flex-row st start wrap">
    <component
      :is="Is"
      v-for="(data, index) in value"
      :key="data.Id + '_' + data._row"
      :value="data"
      :index="index"
      :config="config.ItemConfig"
      :columns="columns"
      :select-enabled="selectEnabled"
      @selected="onChange(value, index)"
      @click="onClick"
      :class="Current === data ? 'active' : ''"
    >
      <el-checkbox
        v-if="selectEnabled"
        :value="Checked[index]"
        slot="selection"
        @change="onChange(data, index)"
      ></el-checkbox>
      <slot :data="data" :index="index"></slot>
      <slot name="button" slot="button" :data="data" :index="index"></slot>
      <slot
        name="column-title"
        slot-scope="scope"
        slot="column-title"
        :data="data"
        :index="index"
        :config="scope.config"
      >
      </slot>
    </component>
  </div>
</template>
<script>
import panels from "@/projects/panels/index";
import ListPanelDefaultPanel from "./panel";
panels["ListPanelDefaultPanel"] = ListPanelDefaultPanel;
export default {
  components: panels,
  props: {
    config: Object,
    value: Array,
    columns: Array,
    panel: String,
    selectEnabled: Boolean
  },
  data() {
    return { Selections: [], Checked: {}, Current: null };
  },
  computed: {
    Is() {
      if (!panels[this.panel]) return "ListPanelDefaultPanel";
      return this.panel;
    }
  },
  methods: {
    onChange(val, index) {
      this.Checked = Object.assign({}, this.Checked, {
        [index]: !this.Checked[index]
      });
      this.$emit(
        "selection-change",
        this.value.filter((val, index) => {
          return this.Checked[index];
        })
      );
    },
    onClick(val) {
      this.Current = val;
      this.$emit("click", val);
    }
  },
  watch: {
    value(val, index) {
      let Checked = {};
      if (val) {
        val.forEach((v, index) => {
          Checked[index] = false;
        });
      }
      this.Checked = Checked;
    }
  },
  created() {
    if (this.value) {
      let Checked = {};
      this.value.forEach((v, index) => {
        Checked[index] = false;
      });
      this.Checked = Checked;
    }
  }
};
</script>
<style >
.active .activeitem {
  background-color: red;
  color: #fff;
}
</style>