<template>
  <component :is="Is" :value="value" :config="config" :title="title">
    <slot> </slot>
  </component>
</template>
<script>
import Error from "./error";
import Info from "./info";
export default {
  props: {
    value: String,
    theme: String,
    config: Object,
    title: String
  },
  components: {
    Error,
    Info
  },
  computed: {
    Is() {
      if (this.theme === "Error") return "Error";
      return "Info";
    }
  }
};
</script>
