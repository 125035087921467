<template>
	<div class='flex-row start list'>
		<div class='label-box flex-column center' :class='Data===item.Value?"active":""' v-for='(item,index) in Options' :key='index'
			@click="onClick(item.Value)" :style='Style'>
			<div class='' style='font-size:16px;font-weight:600'> {{item.Title}}</div>
			<div v-if='item.Descs' style="font-size:14px;font-weight:400;color:#999999">{{item.Descs}}</div>
			<div v-if='Data===item.Value' class='label-sel'></div>
			<div v-if='Data===item.Value' class='iconfont'></div>
		</div>

	</div>
</template>
<script>
import base from "matrix.node.ui/field.js";
const emptyStrHanlder = function (params) {
	return;
};
export default {
	mixins: [base.edit(Number, emptyStrHanlder)],
	computed: {
		Text () {
			let selectItem = this.Options.find(t => {
				return t.Id === this.Data;
			});
			return selectItem ? selectItem.Title : "";
		},
		Options () {
			if (this.config.Descs) {
				this.config.Options.forEach(opt => {
					opt.Descs = this.config.Descs[opt.Value]
				})
			}
			return this.config.Options || [];
		},
		Style () {
			if (this.config.DivWidth) return `width:${this.config.DivWidth}px`
			return ''
		}
	},
	methods: {
		onClick (val) {
			this.Data = val
			this.OnChange()

		}
	}
};
</script>
<style scoped>
.label-box {
	border: 1px solid #eee;
	border-radius: 4px;
	padding: 10px 20px;
	cursor: pointer;
	position: relative;
}
.label-box.active {
	border: 1px solid #1890ff;
	color: #1890ff;
}
.label-sel {
	position: absolute;
	bottom: 0px;
	right: 0px;
	width: 0px;
	height: 0px;
	border-bottom: 26px solid rgb(24, 144, 255);
	border-left: 26px solid transparent;
}
.iconfont {
	position: absolute;
	bottom: 3px;
	right: 1px;
	color: rgb(255, 255, 255);
	font-size: 12px;
}
.iconfont::after {
	content: '\2714';
}
</style>