<template>
  <mx-element type="ColumnFrame" theme="None">
    <mx-element
      type="Title"
      :title="config.Position"
      theme="column"
      v-if="
        config.Position &&
        columns.length > 0 &&
        !config.HideTitle &&
        !columns[0].HideTitle
      "
    ></mx-element>
    <div class="flex-row st start wrap">
      <div v-for="(item, index) of columns" :key="index" :style="OutStyle">
        <div :style="InnerStyle">
          <slot :config="item" :index="index"></slot>
        </div>
      </div>
      <slot name="content"></slot>
    </div>
  </mx-element>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  components: panels,
  props: {
    config: Object,
    columns: Array,
    panel: String,
  },
  computed: {
    PanelHeight() {
      return this.config.Columns.find((col) => {
        return !!col.height;
      });
    },
    OutStyle() {
      let defaultValue = {};

      if (this.config.Columns[0].Panel) {
        let name = this.config.Columns[0].Type + "." + this.config.Columns[0].Panel;
        name = name.replace(/\./g, "_");

        let po = panels[name];
        if (po && po.methods && po.methods.parentStyle) {
          if (typeof po.methods.parentStyle === "function") {
            defaultValue = po.methods.parentStyle();
          } else {
            defaultValue = po.methods.parentStyle;
          }
        }
      }
      let size = Math.round((100 / this.columns.length) * 100) / 100;
      let count = Math.round(size * this.columns.length * 100) / 100 - 100;
      if (count > 0) {
        size = Math.round(((100 - count) / this.columns.length) * 100) / 100;
      }
      return Object.assign(
        {
          width: size + "%",
          height: this.PanelHeight&&this.PanelHeight.height||"120px",
          overflow: "hidden",
        },
        defaultValue
      );
    },
    InnerStyle() {
      return {
        // margin: "5px",
        // height: "calc(100% - 10px)",
      };
    },
  },
};
</script>
