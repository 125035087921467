<template>
    <div style="width: 100%">
        <mx-panel :config="config" panel="SystemList2" :value="value" :actions="Actions" :index="index" :parent="parent"
            @event="OnEvent">
            <template>
                <div class="wf2  left">评测名称</div>
                <div class=" wf  left">司机</div>
                <div class=" wf  left">评测总分</div>
                <div class=" wf  left">反应力得分</div>
                <div class=" wf  left">开始时间</div>
                <div class=" wf  left">结束时间</div>
                <div class=" wf  left">驾驶时长</div>
                <div class=" wf  left">操作</div>

            </template>
            <template slot="content">
                <div class="flex-row middle p10" style="width: 100%;padding:20px 0;">
                    <div class="wf2 left pointer active" @click="OnGoDetail">{{ value.Name }}</div>
                    <div class="wf left">{{ value.Driver.Name }}</div>
                    <div class="wf left">{{ value.TotalScore }}</div>
                    <div class="wf left">{{ value.ReponseScore }}</div>
                    <div class="wf left">{{ value.StartTime }}</div>
                    <div class="wf left">{{ value.EndTime }}</div>
                    <div class="wf left">{{ value.TimeLen }}</div>
                    <div class="wf left flex-row start">
                        <Word Title="导出报告" :value="value" :config="config" />
                        <div @click="OnDelete" class="f16 ml10 pointer">
                            删除
                        </div>
                    </div>

                </div>
            </template>
            <div slot="button"></div>

        </mx-panel>
    </div>
</template>
<script>
import base from 'matrix.node.ui/libs/panels/base.js'
import Word from './Word'
export default {
    props: {
        value: Object,
        config: Object,
        index: Number,
        parent: Object,
    },
    mixins: [base],
    components: {
        Word
    },
    data() {
        return { IsModify: false, IsDelete: false };
    },
    computed: {

        Config() {
            return Object.assign(
                { EventName: name ? name : "TypeEvent", IsDark: false },
                this.config
            );
        },
        Actions() {
            return [];
        },
    },
    methods: {
        OnEvent(e) {
            this.$emit("event", e);
        },

        OnModifyData() {
            this.$matrix.ModifyData(
                this.Context,
                this.value,
                this.config,
                (res) => {
                    this.$emit("event", {
                        target: "item",
                        name: "Reload",
                    });
                },

            );
        },


    },
};
</script>
<style scoped>
.disvisible {
    visibility: hidden;
}

.box {
    position: relative;
}

.modifyed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3000;
    opacity: 0.5;
    background-color: #37437f;
    color: #fff;
    line-height: 34px;
}

.button {
    font-size: 14px;
    color: #37437f;
    font-weight: 450;
}

.wf {
    flex: 1;
}

.wf2 {
    flex: 2
}

.pl0 {
    padding-left: 0;
}

.p10 {
    padding: 10px;
}

.active {
    cursor: pointer;
    color: #38adff
}

.ml10 {
    margin-left: 10px
}
</style>
