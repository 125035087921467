<template>
  <mx-element type="ChildPageTitle" v-if="detail" :title="Title">
    <mx-page
      type="Detail"
      :config="detail"
      :parent="Parent"
      :key="timespan"
      :is-child="isChild"
      :master="master"
      @meta-change="OnMetaChange"
      v-if="!detail.View"
    >
    </mx-page>
    <mx-page
      v-else
      type="Home"
      :config="detail"
      :parent="parent"
      :key="timespan"
      :is-child="isChild"
      :name="detail.View"
      theme="Detail"
      @meta-change="OnMetaChange"
    >
    </mx-page>
  </mx-element>
</template>
<script>
import detail from "matrix.node.ui/page/detail";

export default {
  mixins: [detail("detail")],
  props: {},
  data() {
    return { Meta: {}, ParentValue: null, Title: "" };
  },
  computed: {
    Parent() {
      if (this.parent && this.parent.Id) {
        if (this.ParentValue) {
          return Object.assign({}, this.parent, { Parent: this.ParentValue });
        }
      } else {
        return this.parent;
      }
    }
  },
  methods: {
    handleParentLoad(val) {
      if (val && val.Id) this.ParentValue = val;
    },
    OnMetaChange(val) {
      if (val.meta) {
        this.Title = `${val.meta.Page.Page}`;
        this.Meta = val.meta;
      } else {
        this.Meta = val;
        this.Title = val.Title;
      }
    }
  }
};
</script>
