<template>
    <div class="flex-row start middle">
        <el-switch :value="Data" :active-value="options[1].Value" :inactive-value="options[0].Value"
            @click="handleChange">
        </el-switch>
        <div class="line1" style="margin-left: 6px; color: #666" v-if="!config.HideTitle">
            {{ options[Data].Title }}
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: Number,
        config: Object,
        options: {
            type: Array,
            default: () => {
                return [
                    { Value: 0, Title: "删除" },
                    { Value: 1, Title: "启用" }
                ];
            }
        }
    },
    watch: {
        value(val) {
            this.Data = val
        }
    },
    methods: {
        handleChange(val) {
            this.$emit("change", !this.Data);
        }
    },
    data() {
        return { Data: this.value || 0 };
    }
};
</script>