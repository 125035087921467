<template>
  <div>
    <el-input
      style="width:100%"
      v-model="Data"
      clearable
      @blur="OnChange"
      @clear="OnChange"
      @keyup.13.native="OnChange"
      :size="size||config.Size"
      :placeholder="'请输入' + (config.Title||'')"
      :maxlength="config.Length"
      :disabled="disabled ||config.Disabled"
      type="textarea"
      show-word-limit
      :rows='5'
    >
    </el-input>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String)]
};
</script>