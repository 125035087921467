<template>
  <component
    :is="Is"
    :config="config"
    :parent="parent"
    :panel="panel"
    ref="listcom"
    @panel-change="handlePanelChange"
    @config-change="handleConfigChange"
    @meta-change="handleMetaChange"
  ></component>
</template>
<script>
import DefaultList from "./default";
import CategoryList from "./category.vue";
import LevelList from "./level.vue";
export default {
  props: {
    config: Object,
    parent: Object,
    panel: String,
    theme: String
  },
  components: { DefaultList, CategoryList, LevelList },
  computed: {
    Is() {
      switch ((this.theme || "").toLowerCase()) {
        case "category":
          if (this.config.Config.Level) return "LevelList";
          return "CategoryList";
        default:
          return "DefaultList";
      }
    }
  },
  methods: {
    execute(event, params) {
      this.$refs.listcom[event](params);
    },
    handlePanelChange(val) {
      this.$emit("panel-change", val);
    },
    handleConfigChange(val) {
      this.$emit("config-change", val);
    },
    handleMetaChange(val) {
      this.$emit("meta-change", val);
    }
  }
};
</script>
