<template>
  <mx-view mode="view" :parent="parent" :role="config.Role" :role-field="config.RoleField" :name="name" ref="view"
    @change="handleMetaChange($event)">
    <template slot-scope="scope">
      <mx-layout v-if="scope.meta.Layout" :layers="scope.meta.Layout.Layer"
        :columns="getColumns(scope.meta.Layout.Columns, scope.meta.Columns)" :config="scope.meta.Layout.Config">
        <slot slot-scope="cell">
          <mx-column style="min-height: 100%" :config="cell.column" :items="cell.column.Columns" :type="cell.column.Comp"
            @click="OnClick" :theme="cell.column.Theme" :query="query" @event="OnEvent">
          </mx-column>
        </slot>
      </mx-layout>
      <div v-else>
        <mx-column v-for="(col, index) in getColumns2(scope.meta.Columns)" :key="index" :config="col" :items="col.Columns"
          :type="col.Comp" @click="OnClick" :theme="col.Theme" :query="query" @event="OnEvent">
        </mx-column>
      </div>
      <slot></slot>
    </template>
  </mx-view>
</template>
<script>
import base from 'matrix.node.ui/master/column'
export default {
  mixins: [base],
  methods: {
    OnEvent() { }
  }
}
</script>
