<template>
  <component
    :is="Is"
    :value="Data"
    :config="config"
    @change="handleChange"
    @load="handleLoad"
    :options="options"
    :is-loading="isLoading"
    :is-end="isEnd"
    ref="field"
  >
    <slot></slot>
  </component>
</template>
<script>
import base from "matrix.node.ui/master/field";
import comps from "./index.js";
export default {
  mixins: [base.component],
  components: comps.components.reduce((t, item) => {
    t[item.Name] = item.Component;
    return t;
  }, {}),
  data() {
    return { Data: undefined, IsChange: false };
  },
  computed: {
    Is() {
      return comps.Is.call(this, this.edit, this.config);
    },
    Origin() {
      return this.value ? this.value[this.name] : undefined;
    }
  },
  methods: {
    handleChange(val) {
      if (this.Data !== val) {
        this.Data = val;
        this.IsChange = this.Data !== this.Origin;
        this.$emit("change", { name: this.config.Name, value: val });
      }
    },
    GetValue(value) {
      value[this.name] = this.Data
      if (this.$refs.field && this.$refs.field.GetValue) {
        value = this.$refs.field.GetValue(value)
      }
      return value

    },



    GetChange(value) {
      if (this.IsChange) {
        value[this.name] = this.Data
        if (this.$refs.field && this.$refs.field.GetChange) {
          value = this.$refs.field.GetChange(value)
        }
      }
      return value
    }
  },
  created() {
    if (this.value) this.Data = this.value[this.name];
  }
};
</script>
