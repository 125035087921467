<template>
  <div>
    <slot :config="columns[Index]" :index="Index" v-if="!show"></slot>
    <mx-element type="ColumnFrame" theme="tab" :value="Index" @change="handleChange" :options="columns" v-else>
      <template v-for="(col, index) in columns">
        <div :key="index" v-if="index === Index">
          <slot :config="col" :index="index"></slot>
        </div>
      </template>
    </mx-element>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array
  },
  data() {
    return { Index: 0 };
  },
  computed: {
    show() {
      if (this.columns && this.columns.length) {
        return this.columns.find(col => {
          return col.ColumnConfig.ShowTitle
        })
      }
      return true

    }
  },
  methods: {
    handleChange(index) {
      this.Index = index;
    }
  }
};
</script>
