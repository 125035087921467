<template>
  <div class="box" :style="widthStyle">
    <div style="margin: 5px 10px">
      <div class="flex-row start">
        <el-upload
          v-show="showUpload && !uploading"
          :action="Url"
          :headers="Headers"
          list-type="text"
          :show-file-list="false"
          :on-success="onSuccess"
          :before-upload="onBeforeUpload"
          :disabled="disabled || config.Disabled"
          :on-progress="onProgress"
        >
          <div style="text-align: left">
            <template v-if="Data">
              <a class="f14 ht fb" href="javascript:void(0)">重新上传</a>
            </template>
            <template v-else>
              <div class="f14 ht fb">点击上传</div>
            </template>
            <div class="line2" style="color: #777; font-size: 12px">
              {{ Data || Info }}
            </div>
          </div>
        </el-upload>
      </div>
      <div v-show="uploading" :style="ProcessStyle" class="flex-column center middle">
        <el-progress
          :stroke-width="16"
          :text-inside="true"
          :percentage="percentage"
          :color="config.Colors"
        ></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import upload from './upload.js'
import base from 'matrix.node.ui/field.js'
export default {
  mixins: [base.edit(String), upload],
  props: {
    value: String
  },
  created() {}
}
</script>
<style scoped>
.box {
  width: 100%;
  text-align: left;
  border: 1px solid #dcdfe6;
  border-radius: 5px;
}
</style>
