<template>
  <el-empty :description="title || value"></el-empty>
</template>
<script>
export default {
  props: {
    title: String,
    value: String
  }
};
</script>