import ListPanelComponent from './ListPanel/index.vue';
import ListEditComponent from './ListEdit/index.vue';
import TableComponent from './Table/index.vue'
export default { 
    Name: "lists",
    Title: "列表",
    Value: [
        { Name: "ListPanel",Component: ListPanelComponent },
        { Name: "ListEdit",Component: ListEditComponent },
        { Name: "Table",Component: TableComponent }
    ]
}