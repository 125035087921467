<template>
  <aside class="left-sidebar" :style="LeftStyle">
    <div>
      <div class="brand-logo flex-center">
        <mx-panel type="Site" :config="{ Type: 'Site' }" panel="SystemLogo"></mx-panel>
      </div>
      <nav class="scroll-sidebar">
        <div class="scroll-content">
          <div class="nav-li" v-for="(menu, index) in Menus" :key="index" @click="handleClick(menu)">
            <div class="text flex-row middle start" :class="IsPath(menu) ? 'active' : ''">
              <img :src="GetMenuImage(menu)" class="menu_image" />
              <span> {{ menu.Title || menu.Page }}</span>
            </div>
          </div>
        </div>
      </nav>
      <div class="left-bottom" v-if="User">
        <div>
          <el-dropdown trigger="click" @command="handleCommand" placement="right">
            <div class="flex-row list">
              <img src="/static/system/face.jpg" alt="" class="faceimage" />
              <div class="flex-column left" v-show="false">
                <div class="active">{{ User.NickName }}</div>
                <div class="gray">管理员</div>
              </div>
            </div>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>{{ User.NickName }}</el-dropdown-item>
              <el-dropdown-item divided command="ModifyPassword">修改密码</el-dropdown-item>
              <el-dropdown-item command="Logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </aside>
</template>
<script>
export default {
  props: {
    columns: Array,
    config: Object,
  },
  data() {
    return {
      Current: "",
      key: new Date().getTime(),
    };
  },

  computed: {
    LeftStyle() {
      if (this.$matrix.Config.Theme && this.$matrix.Config.Theme.Menu) {
        return {
          backgroundColor: this.$matrix.Config.Theme.Menu.BackgroundColor,
          color:this.$matrix.Config.Theme.Menu.Color
        }
      }
    },
    OtherUsers() {
      let users = [
        /*  { title: "管理员", name: "Manager" },
        { title: "用户", name: "Users" }*/
      ];
      if (this.Role) {
        return users.filter((u) => {
          return u.name !== this.Role;
        });
      }
    },

    Config() {
      return this.$matrix.Config;
    },
    Theme() {
      if (this.Config) return this.Config.Theme;
    },
    Menu() {
      if (this.Theme) return this.Theme.Menu;
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    },
    Menus() {
      if (this.CurrentMeta) {
        let home = this.CurrentMeta.SiteMap.find((t) => {
          return t.name === "home";
        });
        if (home) return home.children;
      }
    },
    Scenes() {
      return this.$matrix.Metas;
    },
  },
  methods: {
    GetMenuImage(menu) {
      if (menu.Title || menu.Page) {
        let name = menu.Title || menu.Page;
        return `/static/menu/${name}.png`;
      }
    },
    IsPath(val) {
      let path = `/${val.Name}`;
      return this.$route.path === path;
    },
    handleClick(val) {
      //console.log(val);
      let path = `/${val.Name}?t=${new Date().getTime()}`;
      this.$matrix.Goto(path);
    },
    handleScene(val) {
      let scene = this.Scenes[val];
      if (scene) {
        this.$matrix.SetMeta(val);
        this.$nextTick(() => {
          this.$matrix.Goto("/home?t=" + new Date().getTime(), undefined, true);
        });
      }
    },
    setWatched() {
      this.IsWatching = true;
      let fooMessage = () => {
        this.$matrix
          .SocketWatch("Message")
          .then((data) => {
            this.IsWatching = false;
          })
          .catch((e) => {
            setTimeout(() => {
              if (!this.IsEnd) fooMessage();
            }, 50000);
          });
      };
      fooMessage();
      let fooTask = () => {
        this.$matrix
          .SocketWatch("Task")
          .then((data) => {
            this.IsWatching = false;
          })
          .catch((e) => {
            setTimeout(() => {
              if (!this.IsEnd) fooTask();
            }, 50000);
          });
      };
      fooTask();
    },
    handleCommand(command) {
      if (!command) return;
      switch (command) {
        case "Logout":
          this.$matrix.Logout();
          this.$matrix.GotoLogin();
          break;
        case "ModifyPassword":
          this.$matrix.ModifyPassword();
          break;
      }
    },
    OnLogin() {
      this.$matrix.GotoLogin();
    },
  },
};
</script>
<style scoped>
.left-sidebar {
  width: 270px;
  transition: 0.2s ease-in;
  height: 100%;
  z-index: 11;
  /* border-right: 1px solid #e5eaef; */
  position: relative;
}

.brand-logo {
  min-height: 70px;
  padding: 0 24px;
}

.flex-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.scroll-sidebar {
  margin-top: 24px;
  padding: 0 24px;
}

.nav-li .text.active {
  /* background-color: #5d87ff; */
  color: #fff;
  position: relative;
}

.nav-li .text {
  display: flex;
  font-size: 16px;
  white-space: nowrap;
  align-items: center;
  line-height: 25px;
  position: relative;
  margin: 0 0 10px 0;
  padding: 10px;
  border-radius: 7px;
  gap: 15px;
  text-decoration: none;
  font-weight: 400;
  cursor: pointer;
}

.nav-li:hover {
  /* background-color: rgba(93, 135, 255, 0.1); */
  /* color: #5d87ff; */
  color: #fff;
  /* border-radius: 7px; */
}

.nav-li .text.active .menu_image {
  position: relative;
  transform: translateX(-80px);
  filter: drop-shadow(#fff 80px 0);
  /* border-left: 4px solid transparent;
  border-right: 4px solid transparent; */
  overflow: hidden;
}

.faceimage {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  border: 1px solid #eee;
}

.left-bottom {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}
</style>
