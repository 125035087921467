<template>
    <div class="box">
        <mx-element type="Title" :title="config.Title" theme="PageTitle"></mx-element>
        <div class="float flex-row middle list-half" v-if="isEdit">
            <el-switch v-model="CanEdit" active-color="#13ce66" inactive-color="gray">
            </el-switch>
            <div>编辑</div>
        </div>
        <div style="padding: 2px">
            <el-tree :data="Items" :props="defaultProps" :default-expand-all="config.ExpandAll !== false">
                <template slot-scope="scope">
                    <template v-if="scope.node.data.action">
                        <div style="color: blue; padding: 5px 0" @click="OnAction(scope.node.data)" v-if="CanEdit">
                            <i class="el-icon-plus" style="font-size: 12px" />{{
                                    scope.node.label
                            }}
                        </div>
                    </template>
                    <div v-else class="flex-row between" style="flex: 1">
                        <div class="line1" style="flex: 1; text-align: left; padding: 5px 0"
                            @click="OnSelect(scope.node)">
                            {{ scope.node.label }}
                        </div>
                        <div>
                            <i class="el-icon-edit" v-if="CanEdit" style="padding-right: 10px"
                                @click="OnModify(scope.node)"></i>
                        </div>
                    </div>
                </template>
            </el-tree>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        value: Object,
        role: String,
        roleField: String
    },
    data() {
        return { Items: [], CanEdit: true }
    },
    created() {
        if (this.config) {
            this.CanEdit = this.config.Edit
            this.Init(this.config)
        }
    },
    watch: {
        CanEdit(val) {
            this.Init(this.config)
        }
    },
    methods: {
        Init(config) {
            if (config.Options.Entity) {
                let model = this.$matrix.Models[config.Options.Entity]
                let levelField = model.Fields.find((t) => {
                    return t.Name === 'Level'
                })

                let getLevelTitle = (level) => {
                    if (levelField) {
                        let op = levelField.Options.find((t) => {
                            return t.Id === level
                        })
                        if (op) return op.Title
                    }
                    return ''
                }
                let getchild = function (datas, parent, getLabel, level, isEdit) {
                    let childs = datas.filter((d) => {
                        return d.Parent.Id === parent.Id
                    })
                    let items = []
                    if (childs.length > 0) {
                        items = childs.map((ch) => {
                            let c = {}
                            c.data = ch
                            c.label = getLabel(ch)
                            c.parent = parent
                            c.level = level
                            c.children = getchild(
                                datas,
                                ch,
                                getLabel,
                                level + 1,
                                isEdit
                            )

                            if (c.children.length === 0) c.children = undefined
                            return c
                        })
                    }
                    if (isEdit) {
                        let title = getLevelTitle(level)
                        if (title) {
                            items.push({
                                label: '新增' + title,
                                action: 'add',
                                parent: parent
                            })
                        }
                    }
                    return items
                }
                this.$matrix
                    .LoadField(
                        config,
                        this.role,
                        this.roleField,
                        1,
                        0,
                        {},
                        this.Context,
                        this.config.StaticParams
                    )
                    .then((datas) => {
                        this.Items = datas
                            .filter((t) => {
                                return t.Parent.Id === 0
                            })
                            .map((item) => {
                                let getLabel = (t) => {
                                    return t[config.Options.Label || 'Name']
                                }
                                return {
                                    label: getLabel(item),
                                    data: item,
                                    level: 1,
                                    children: getchild(
                                        datas,
                                        item,
                                        getLabel,
                                        2,
                                        this.isEdit
                                    )
                                }
                            })
                        if (this.isEdit) {
                            this.Items.push({
                                label: '新增' + getLevelTitle(1),
                                level: 1,
                                action: 'add'
                            })
                        }
                    })
            } else {
                this.$matrix
                    .LoadField(
                        config,
                        this.role,
                        this.roleField,
                        1,
                        0,
                        {},
                        this.Context,
                        this.config.StaticParams
                    )
                    .then((datas) => {

                        this.Items = datas.map((ch) => {
                            let c = {}
                            c.data = ch
                            c.label = ch.Title
                            c.level = 1

                            return c
                        })
                        this.Items.unshift({ data: null, label: '全部', level: 1 })
                    })
            }
        },
        OnAction(e) {
            switch (e.action) {
                case 'add':
                    let value = Object.assign(
                        e.parent ? { Parent: e.parent } : {},
                        this.config.StaticParams
                    )
                    this.$matrix.ShowModelDialog(
                        this.config.Options.Entity,
                        (data) => {
                            if (data) {
                                this.$matrix
                                    .Post3(
                                        this.config.Options.Entity,
                                        'add',
                                        data,
                                        this.role,
                                        this.roleField
                                    )
                                    .then((val) => {
                                        this.Init(this.config)
                                        this.$matrix.CloseModelDialog()
                                    })
                                    .catch((e) => {
                                        this.$matrix.ShowError(e)
                                    })
                            }
                        },
                        value,
                        e.parent,
                        {
                            Role: this.role,
                            RoleField: this.roleField,
                            Mode: 'add',
                            DisabledColumns: ['Parent'].concat(
                                Object.keys(this.config.StaticParams || {})
                            )
                        }
                    )
                    break
                case 'modify':
                    break
            }
        },
        OnSelect(node) {
            let e = node.data
            if (this.config.Options && this.config.Options.Level) {
                if (e.data.Level === this.config.Options.Level)
                    this.$emit('change', e.data)
            } else {
                this.$emit('change', e.data ? e.data.Value : undefined)
            }
        },
        OnModify(node) {
            let e = node.data
            let value = Object.assign(
                {},
                e.data,
                e.parent ? { Parent: e.parent } : {},
                this.config.StaticParams
            )
            this.$matrix.ShowModelDialog(
                this.config.Options.Entity,
                (data) => {
                    if (data) {
                        data.Id = e.data.Id
                        this.$matrix
                            .Post3(
                                this.config.Options.Entity,
                                'modify',
                                data,
                                this.role,
                                this.roleField
                            )
                            .then((val) => {
                                let getLabel = (t) => {
                                    return t[
                                        this.config.Options.Label || 'Name'
                                    ]
                                }
                                this.$set(e, 'label', getLabel(val))
                                this.$set(e, 'data', val)
                                this.$matrix.CloseModelDialog()
                            })
                            .catch((e) => {
                                this.$matrix.ShowError(e)
                            })
                    }
                },
                value,
                e.parent,
                {
                    Role: this.role,
                    RoleField: this.roleField,
                    Mode: 'modify',
                    DisabledColumns: ['Parent'].concat(
                        Object.keys(this.config.StaticParams || {})
                    ),
                    OnDelete: (data) => {
                        this.$matrix
                            .Post3(
                                this.config.Options.Entity,
                                'delete',
                                { Id: e.data.Id },
                                this.role,
                                this.roleField
                            )
                            .then((datas) => {
                                this.$matrix.CloseModelDialog()
                                this.Init(this.config)
                            })
                            .catch((e) => {
                                this.$matrix.ShowError(e)
                            })
                    }
                }
            )
        }
    },
    computed: {
        defaultProps() {
            return {}
        },
        isEdit() {
            return (
                this.config.EditRole &&
                this.config.EditRole.indexOf(this.role) > -1
            )
        }
    }
}
</script>
<style scoped>
.box {
    position: relative;
}

.float {
    position: absolute;
    right: 10px;
    top: 10px;
}
</style>
<style>
.el-tree-node__content {
    height: auto !important;
}
</style>