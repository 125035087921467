<template>
  <div tip="frame-tab" class="flex-column panel10">
    <div class="flex-row middle border-bottom noselect">
      <div class="flex-row middle start">
        <div
          @click="handleClick(index)"
          v-for="(col, index) in options"
          :key="index"
          class="hand cursor"
          :class="value === index ? 'active' : ''"
          style="padding: 10px"
        >
          <div class="line1 title">{{ col.Title }}</div>
        </div>
      </div>
      <div
        v-if="options && options[value] && options[value].ListView"
        class="cursor fg"
        style="padding: 10px 10px 10px 0; min-width: 48px"
        @click="handleClickMore"
      >
        更多<i class="el-icon-arrow-right"></i>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { label: "Title" };
      }
    },
    icon: String
  },
  methods: {
    getIcon() {
      if (this.config.More) {
        if (this.config.More.Icon) {
          switch (this.config.More.Icon) {
            default:
              return "el-icon-arrow-right";
          }
        }
      }
    },
    handleClick(index) {
      this.$emit("change", index);
    },
    handleClickMore() {
      this.$matrix.GotoListView(
        this.$route,
        this.options[this.value].ListView,
        this.options[this.value].Role || "",
        this.options[this.value].RoleField || "",
        this.options[this.value].ListParams
      );
    }
  }
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
.title {
  border-bottom: 1px solid #fff;
  font-size: 16px;
  padding: 5px;
}
.active {
  color: #ff6a6a;
  position: relative;
  font-weight: bold;
}
.active::after {
  content: " ";
  position: absolute;
  left: 20px;
  bottom: 0;
  right: 20px;
  height: 3px;
  border-bottom: 3px solid #ff6a6a;
  color: #ebebeb;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}

.border-bottom {
  position: relative;
}
.border-bottom::after {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 5px;
  right: 0;
  border-bottom: 0.05rem solid #f7f7f7;
  color: #ebebeb;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
}
</style>
