<template>
  <div>11
    <div class="box">
      <el-button size="mini" type="text" icon="el-icon-edit" @click="handleAdd">
        新增
      </el-button>
    </div>
    <div class="table-box">
      <el-table :data="ShowData" fit row-key="_key">
        <el-table-column label="序号" width="72">
          <template slot-scope="scope">{{ scope.$index + 1 }}</template>
        </el-table-column>
        <el-table-column
          v-for="(col, index) in ShowColumns"
          :key="col.Name + '_' + index"
          :label="col.Title"
          :width="index > 0 ? col.Width : ''"
        >
          <template slot-scope="scope">
            <mx-field
              :key="scope.$index"
              :type="col.Type"
              :edit="true"
              :name="col.Name"
              :value="scope.row"
              :one-line='true'
              :config="col"
              @change="FieldChange($event, scope.row, scope.$index)"
            ></mx-field>
          </template>
        </el-table-column>
        <el-table-column align="right" label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row, scope.$index)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import list from "matrix.node.ui/master/list";
export default {
  mixins: [list.component],
  props: {
    config: Object,
    value: Array,
    columns: Array,
    options: Array,
    theme: String
  },
  methods: {
    FieldChange(val, item, index) {
      let data = this.ShowData[index];
      data[val.name] = val.value;
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #f7f7f7;
  padding: 5px 10px 5px 5px;
  text-align: right;
  overflow-x: auto;
}
.table-box {
  width: 100%;
  overflow-x: auto;
  padding: 0;
  margin: 0;
}
</style>
