<template>
  <div class="box flex-column">
    <div>
      <div class="box-item">
        <mx-panel type="Site" :config="{ Type: 'Site' }" panel="SystemLogo"></mx-panel>
      </div>
      <div class="box-item flex-row middle start list" v-for="(menu, index) in Menus" :key="index" :class="IsPath(menu) ? 'active' : ''"
        @click="handleClick(menu)">
        <div>
          <el-icon :class="Config.icons[index]" class="icon"></el-icon>
        </div>
        <div class="line1" style="font-size: 16px" :title="menu.Title || menu.Page">
          {{ menu.Title || menu.Page }}
        </div>
      </div>
    </div>
    <div>
      <div class="box-item">
        <el-dropdown v-if="Scenes" trigger="click" @command="handleScene" class="item" placement="left"
          v-show="Scenes.length > 1">
          <div>
            <el-icon class="el-icon-s-operation icon"></el-icon>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :key="s.name" :command="s.Id" v-for="s in Scenes" v-show="s.Id !== CurrentMeta.Id">{{
              s.Instance.title || s.Title
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="box-item">
        <el-dropdown trigger="click" @command="handleCommand" placement="left">
          <div>
            <el-icon class="el-icon-setting icon"></el-icon>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="ModifyPassword">修改密码</el-dropdown-item>
            <el-dropdown-item command="UserCenter" v-show="false">用户中心</el-dropdown-item>
            <el-dropdown-item v-for="(r, index) in OtherUsers" :command="'SetRole_' + r.name" :divided="index === 0"
              :key="index">切换{{ r.title }}</el-dropdown-item>
            <el-dropdown-item divided command="Logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    columns: Array,
    config: Object
  },
  data() {
    return {
      Current: "",
      key: new Date().getTime()
    };
  },

  computed: {
    OtherUsers() {
      let users = [
        /*  { title: "管理员", name: "Manager" },
        { title: "用户", name: "Users" }*/
      ];
      if (this.Role) {
        return users.filter(u => {
          return u.name !== this.Role;
        });
      }
    },
    Config() {
      return {
        backgroundColor: "#004b94",
        textColor: "#fff",
        activeTextColor: "#0070c6",
        // mode: "vertical",
        icons: [
          "el-icon-s-home",
          "el-icon-s-data",
          "el-icon-pie-chart",
          "el-icon-suitcase",
          "el-icon-printer",
          "el-icon-s-order",
          "el-icon-menu",
          "el-icon-collection",
          "el-icon-picture-outline",
          "el-icon-star-off",
          "el-icon-document",
          "el-icon-setting"
        ]
      };
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    },
    Menus() {
      if (this.CurrentMeta) {
        let home = this.CurrentMeta.SiteMap.find(t => {
          return t.name === "home";
        });
        if (home) return home.children;
      }
    },
    Scenes() {
      return this.$matrix.Metas;
    }
  },
  methods: {
    IsPath(val) {
      let path = `/${val.Name}`;
      return this.$route.path === path;
    },
    handleClick(val) {
      //console.log(val);
      let path = `/${val.Name}?t=${new Date().getTime()}`;
      this.$matrix.Goto(path);
    },
    handleScene(val) {
      let scene = this.Scenes[val];
      if (scene) {
        this.$matrix.SetMeta(val);
        this.$nextTick(() => {
          this.$matrix.Goto("/home?t=" + new Date().getTime(), undefined, true);
        });
      }
    },
    setWatched() {
      this.IsWatching = true;
      let fooMessage = () => {
        this.$matrix
          .SocketWatch("Message")
          .then(data => {
            this.IsWatching = false;
          })
          .catch(e => {
            setTimeout(() => {
              if (!this.IsEnd) fooMessage();
            }, 50000);
          });
      };
      fooMessage();
      let fooTask = () => {
        this.$matrix
          .SocketWatch("Task")
          .then(data => {
            this.IsWatching = false;
          })
          .catch(e => {
            setTimeout(() => {
              if (!this.IsEnd) fooTask();
            }, 50000);
          });
      };
      fooTask();
    },
    handleCommand(command) {
      if (!command) return;
      switch (command) {
        case "Logout":
          this.$matrix.Logout();
          this.$matrix.GotoLogin();
          break;
        case "ModifyPassword":
          this.$matrix.ModifyPassword();
          break;
      }
    },
    OnLogin() {
      this.$matrix.GotoLogin();
    }
  }
};
</script>
<style scoped>
.box {
  /* background: #37437f; */
  background:linear-gradient(0,#5e88ff,#2173dc,#1e93ff);
  text-align: center;
  color: #fff;
  height: 100vh;
  overflow: hidden;
}

.box-item {
  padding: 15px 10px 15px 20px;
  cursor: pointer;
}

.icon {
  font-size: 24px;
  color: #fff;
}

.image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.active {
  color: #1e93ff;
  position: relative;
  filter: drop-shadow(-6px 0 12px rgba(0, 0, 0, .04));
}

.active .icon {
  /*color: #37437f;*/
  color:#1e93ff
}

.active::after {
  content: "";
  position: absolute;
  display: block;
  height: 60px;
  width: 105%;
  background-image: url('~@/assets/menubg.png');
  background-repeat: no-repeat;
  background-size: auto 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 10px;
  z-index: -1;
}
</style>
