<template>
  <div class="flex-row middle">
    <div style="padding: 10px" v-if="config.FontStyle">
      <span slot="prepend" v-if="config.FontStyle">{{ config.FontStyle }}</span>
    </div>
    <el-input-number
      style="flex: 1"
      v-model="Data"
      clearable
      @change="OnChange"
      @clear="OnChange"
      @keyup.13.native="OnChange"
      :size="size || config.Size"
      :placeholder="'请输入' + (config.Title || '')"
      :maxlength="config.Length"
      :disabled="disabled || config.Disabled"
      :controls-position="config.ControlsPosition || 'right'"
      :min="config.Min"
      :max="config.Max"
      :precision="0"
    >
    </el-input-number>
    <div style="padding: 10px" v-if="config.BackStyle">
      <span v-if="config.BackStyle" size="large">{{ config.BackStyle }}</span>
    </div>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(Number)]
};
</script>
