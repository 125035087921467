<template>
    <div>
        <el-cascader style="width: 100%" :value="DataId" :size="size || config.Size" :props="Propertys"
            :options="Options" @change="OnInputObject" :disabled="disabled || config.Disabled"
            :placeholder="'请输入' + (config.Title || '')" clearable filterable @focus="OnLoad" :collapse-tags="true">
            <template slot-scope="{ node, data }">
                <mx-panel :panel="Panel" v-if="Panel" :value="data"></mx-panel>
            </template>
        </el-cascader>
    </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
import panels from '@/projects/panels/index'
export default {
    mixins: [base.edit(Object)],
    props: {
        isEnd: Boolean,
        isLoading: Boolean,
        options: Array
    },
    computed: {
        Panel() {
            let name = (this.config.Link + '.Field').replace(/\./g, '_')
            if (panels[name]) return name
            return ''
        },
        DataId() {
            return this.Data ? this.Data.Id : 0
        },
        LabelField() {
            if (this.config && this.config.LabelField)
                return this.config.LabelField
            if (this.config && this.config.Options && this.config.Options.Label)
                return this.config.Options.Label
            return 'Title'
        },
        ParentField() {
            if (
                this.config &&
                this.config.Options &&
                this.config.Options.ParentField
            )
                return this.config.Options.ParentField
            return 'Parent'
        },
        Label() {
            if (this.value) {
                return this.value[this.LabelField]
            }
            return ''
        },
        Propertys() {
            return {
                label: this.LabelField,
                value: 'Id',
                checkStrictly: this.CheckStrictly,
                emitPath: false
            }
        },
        CheckStrictly() {
            return (
                this.config.CheckStrictly ||
                (this.config.Name === 'Parent' &&
                    this.config.Link === this.config.Options.Entity)
            )
        },
        Options() {
            let result = []
            let selections = this.options || this.selections || []
            if (
                this.value &&
                this.value.Id &&
                (!selections ||
                    !selections.find((t) => {
                        return t.Id === this.value.Id
                    }))
            ) {
                result = result.concat([this.value])
            }
            if (selections && selections.length > 0) {
                result = result.concat(selections)
                let getParent = (p) => {
                    return p && p.Id === undefined ? p : p.Id
                }
                let findchild = (pid) => {
                    let child = result
                        .filter((r) => {
                            return (
                                (r[this.ParentField] &&
                                    getParent(r[this.ParentField]) === pid) ||
                                (pid === 0 && !r[this.ParentField])
                            )
                        })
                        .map((c) => {
                            let children = findchild(c.Id)
                            if (children && children.length > 0)
                                c.children = children
                            return c
                        })
                    return child
                }
                let categroys = findchild(0)
                if (categroys && categroys.length === 0 && result.length)
                    return result
                return categroys
            } else {
                return result
            }
        }
    },
    methods: {
        OnLoad() {
            let selections = this.selections || this.options || []
            if (!selections || selections.length === 0) {
                let fields = ['Id']
                fields.push(this.LabelField)
                if (this.config.Options.Level > 1 && this.ParentField)
                    fields.push(this.ParentField)
                if (this.Panel) {
                    fields = []
                }
                let LookValue = null
                if (
                    this.config &&
                    this.config.LookUp &&
                    this.config.LookUp.length > 0
                ) {
                    let getParentValue = () => {
                        let data = {}
                        let selffoo = this.$matrix.FindParentFunction(
                            this,
                            'GetSelfValue'
                        )
                        if (selffoo) {
                            data = selffoo()
                        }
                        if (!data.Parent || !data.Parent.Id) {
                            let parentfoo = this.$matrix.FindParentFunction(
                                this,
                                'GetMainFormValue'
                            )
                            if (parentfoo) {
                                data.Parent = parentfoo()
                            }
                        }
                        return data
                    }
                    LookValue = this.$matrix.Put(
                        getParentValue(),
                        this.config.LookUp
                    )
                    let errors = this.$matrix.Check(
                        LookValue,
                        this.config.LookUp
                    )
                    if (errors) return
                }

                this.$emit('load', {
                    type: 'LoadSelections',
                    params: Object.assign(
                        {
                            Page: 1,
                            PageSize: 0,
                            Fields: fields,
                            ['Get' + this.ParentField]: false
                        },
                        this.config.Options.Params,
                        LookValue
                    )
                })
            }
        },
        OnInputObject(val) {
            let selections = this.selections || this.options || []
            if (typeof val === 'number') {
                let id = val
                if (!this.value || val !== this.value.Id) {
                    this.Data = selections.find((t) => {
                        return t.Id === id
                    })
                    this.OnChange()
                }
            } else if (val && val.length > 0) {
                let id = val[val.length - 1]
                if (!this.value || val !== this.value.Id) {
                    this.Data = selections.find((t) => {
                        return t.Id === id
                    })
                    this.OnChange()
                }
            } else {
                this.Data = this.config.EmptyValue
                this.OnChange()
            }
        }
    },
    watch: {
        options(val) {
            if (val.length > 0) {
                if (!this.DataId && this.config.SelectFirst) {
                    this.Data = val[0]
                    this.OnChange()
                }
            }
        }
    },
    mounted() {
        if (this.config.SelectFirst) {
            if (!this.DataId) {
                this.$nextTick(() => {
                    this.OnLoad()
                })
            }
        }
    }

}
</script>