import Company_Detail from "./Company/Detail.vue"
import Company_Home2 from "./Company/Home2.vue"
import Company_List2 from "./Company/List2.vue"
import Driver_ChartBar from "./Driver/ChartBar.vue"
import Driver_Detail from "./Driver/Detail.vue"
import Driver_Home2 from "./Driver/Home2.vue"
import Driver_List2 from "./Driver/List2.vue"
import Driver_Response_List2 from "./Driver/Response/List2.vue"
import Manager_ChartLine from "./Manager/ChartLine.vue"
import Manager_Detail from "./Manager/Detail.vue"
import Manager_Home from "./Manager/Home.vue"
import Manager_Home2 from "./Manager/Home2.vue"
import Manager_List2 from "./Manager/List2.vue"
import Manager_ListPanel from "./Manager/ListPanel.vue"
import Manager_Pie from "./Manager/Pie.vue"
import Paper_Detail from "./Paper/Detail.vue"
import Paper_Home2 from "./Paper/Home2.vue"
import Paper_ListPanel from "./Paper/ListPanel.vue"
import Paper_TopicList_ListPanel from "./Paper/TopicList/ListPanel.vue"
import Site_Banners_List from "./Site/Banners/List.vue"
import Site_Banners_ListPanel from "./Site/Banners/ListPanel.vue"
import Site_CarType_ListPanel from "./Site/CarType/ListPanel.vue"
import Site_CheckTable_Checking_ListPanel from "./Site/CheckTable/Checking/ListPanel.vue"
import Site_CheckTable_CommentModule1_ListPanel from "./Site/CheckTable/CommentModule1/ListPanel.vue"
import Site_CheckTable_CommentModule2_ListPanel from "./Site/CheckTable/CommentModule2/ListPanel.vue"
import Site_CheckTable_CommentModule3_ListPanel from "./Site/CheckTable/CommentModule3/ListPanel.vue"
import Site_CheckTable_ListPanel from "./Site/CheckTable/ListPanel.vue"
import Site_CheckTable_Out_ListPanel from "./Site/CheckTable/Out/ListPanel.vue"
import Site_CheckTable1_ListPanel from "./Site/CheckTable1/ListPanel.vue"
import Site_CheckTable2_ListPanel from "./Site/CheckTable2/ListPanel.vue"
import Site_CommentModule1_ListPanel from "./Site/CommentModule1/ListPanel.vue"
import Site_CommentModule2_ListPanel from "./Site/CommentModule2/ListPanel.vue"
import Site_CommentModule3_ListPanel from "./Site/CommentModule3/ListPanel.vue"
import Site_Health_ListPanel from "./Site/Health/ListPanel.vue"
import Site_Home2 from "./Site/Home2.vue"
import Site_LearnContent_ListPanel from "./Site/LearnContent/ListPanel.vue"
import Site_LearnDic_ListPanel from "./Site/LearnDic/ListPanel.vue"
import Site_Radar_ListPanel from "./Site/Radar/ListPanel.vue"
import Site_SystemLogo from "./Site/SystemLogo.vue"
import Test_ChartBar from "./Test/ChartBar.vue"
import Test_Detail from "./Test/Detail.vue"
import Test_Discuss_ListPanel from "./Test/Discuss/ListPanel.vue"
import Test_Home2 from "./Test/Home2.vue"
import Test_Items_ListPanel from "./Test/Items/ListPanel.vue"
import Test_List2 from "./Test/List2.vue"
import Test_ListPanel from "./Test/ListPanel.vue"
import Test_PassPie from "./Test/PassPie.vue"
import Test_Pie from "./Test/Pie.vue"
import Test_Response_ListPanel from "./Test/Response/ListPanel.vue"
import Test_Sign_ListPanel from "./Test/Sign/ListPanel.vue"
import Test_Word from "./Test/Word.vue"
import Users_Detail from "./Users/Detail.vue"
import Users_Home2 from "./Users/Home2.vue"
import Users_List2 from "./Users/List2.vue"
import Users_ListPanel from "./Users/ListPanel.vue"
export default {Company_Detail,Company_Home2,Company_List2,Driver_ChartBar,Driver_Detail,Driver_Home2,Driver_List2,Driver_Response_List2,Manager_ChartLine,Manager_Detail,Manager_Home,Manager_Home2,Manager_List2,Manager_ListPanel,Manager_Pie,Paper_Detail,Paper_Home2,Paper_ListPanel,Paper_TopicList_ListPanel,Site_Banners_List,Site_Banners_ListPanel,Site_CarType_ListPanel,Site_CheckTable_Checking_ListPanel,Site_CheckTable_CommentModule1_ListPanel,Site_CheckTable_CommentModule2_ListPanel,Site_CheckTable_CommentModule3_ListPanel,Site_CheckTable_ListPanel,Site_CheckTable_Out_ListPanel,Site_CheckTable1_ListPanel,Site_CheckTable2_ListPanel,Site_CommentModule1_ListPanel,Site_CommentModule2_ListPanel,Site_CommentModule3_ListPanel,Site_Health_ListPanel,Site_Home2,Site_LearnContent_ListPanel,Site_LearnDic_ListPanel,Site_Radar_ListPanel,Site_SystemLogo,Test_ChartBar,Test_Detail,Test_Discuss_ListPanel,Test_Home2,Test_Items_ListPanel,Test_List2,Test_ListPanel,Test_PassPie,Test_Pie,Test_Response_ListPanel,Test_Sign_ListPanel,Test_Word,Users_Detail,Users_Home2,Users_List2,Users_ListPanel}