<template>
  <div class="flex-row middle box">
    <div class="flex-row start middle">
      <div style="width: 40px">
        <i
          class="el-icon-s-unfold"
          style="font-size: 24px"
        ></i>
      </div>
      <div style="color: #333; font-weight: 700; font-size: 18px">
        {{ title }}
      </div>
    </div>
    <slot></slot>
    <div style="margin-right:20px">
      <slot name='button'></slot>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        value: Number,
        config: Object,
        title: String,
        icon: String
    }
}
</script>
<style scoped>
.title-label {
    width: 5px;
    height: 19px;
    background: #ab0b22;
    display: inline-block;
    margin-top: 0px;
    vertical-align: bottom;
    margin-right: 5px;
}
.box {
    padding: 10px 10px;
    position: relative;
}
</style>
