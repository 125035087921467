<template>
    <div :is="panel || 'DIV'" style="height: 100%; overflow-y: hidden">
        <component :is="panel || 'DIV'">
            <template v-for="(data, i) in Data">
                <slot :data="data" :index="i">
                    <div :key="i" class="flex-row start middle"
                        style="margin: 5px; background-color: #f7f7f7; padding: 5px">
                        <div style="width: 18px">{{ i + 1 }}</div>
                        <div class="line1 wf">{{ data.Title }}</div>
                        <div style="text-align: right">{{ data.Value }}</div>
                    </div>
                </slot>
            </template>
        </component>
        <mx-element v-if="!Data" value="" type="Empty" theme="skeleton" class="panel3"></mx-element>
        <mx-data-list name="DataLoad" :type="config.Type" load-type="GetReport" :fields="dataconfig.Fields"
            :params="dataconfig.Params" :group-fields="dataconfig.GroupFields" :order-fields="dataconfig.OrderFields"
            :date-field="dataconfig.DateField" :date-type="dataconfig.DateType" :role="config.Role"
            :role-field="config.RoleField" @event="OnEvent" :page-size="dataconfig.PageSize"
            :get-count="dataconfig.GetCount === true"></mx-data-list>
    </div>
</template>
<script>
import panels from '@/projects/panels/index'
export default {
    components: panels,
    props: {
        config: Object,
        index: Number,
        dataconfig: Object,
        panel: String
    },
    data() {
        return { Data: null, List: null }
    },
    methods: {
        OnEvent(data, e) {
            if (data.event === 'ValueChange') {
                let name = Array.isArray(this.dataconfig.Fields) ? this.dataconfig.Fields[0] : this.dataconfig.Fields

                let GroupField = Array.isArray(this.dataconfig.GroupFields) ? this.dataconfig.GroupFields[0] : this.dataconfig.GroupFields

                let getTitle = null
                if (GroupField) {
                    GroupField = this.$matrix.Models[this.config.Type].Fields.find((t) => {
                        return t.Name === GroupField
                    })

                    if (GroupField) {
                        getTitle = function (value) {
                            return value[GroupField.Name]
                        }
                        if (GroupField.Options && Array.isArray(GroupField.Options)) {
                            getTitle = function (value) {
                                let op = GroupField.Options.find((op) => {
                                    return op.Value === value[GroupField.Name]
                                })
                                if (op) return op.Title
                                return '未知选项'
                            }
                        } else if (GroupField.Link) {
                            let fields = this.$matrix.GetTitleFields(GroupField.Link)
                            if (fields && fields.length > 0) {
                                let field = GroupField.Name
                                let fname = fields[0].Name
                               
                                getTitle = function (value) {
                                    return value[field][fname]
                                } 
                            }
                        }
                    }
                } else {
                    let type = this.$matrix.Models[this.config.Type]

                    let titleField = type.Fields.find((t) => {

                        return t.Label && t.Label.indexOf('Title') > -1
                    })
                    if (titleField) {
                        getTitle = function (value) {
                            return value[titleField.Name]
                        }
                    }
                }

                if (getTitle) {
                    console.log(name,'name')
                    let total = data.data.reduce((t, d) => {
                        return t + d[name]
                    }, 0)
                    this.Data = data.data.map((d) => {
                        return {
                            Title: getTitle(d),
                            Value: d[name],
                            Rate: Math.round((d[name] / total) * 10000) / 100,
                            Data: d
                        }
                    })
                    this.$emit('change', this.Data)
                }
            }
        }
    }
}
</script>
