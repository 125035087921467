<template>
  <div :style='config.InnerStyle'>
    <div class="line2" :title="Label">
      {{ Label || '&nbsp;' }}
      <slot></slot>
    </div>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(Object)],
  computed: {
    LabelField() {
      if (this.config && this.config.LabelField) return this.config.LabelField;
      if (this.config && this.config.Options && this.config.Options.Label)
        return this.config.Options.Label;
      return "Title";
    },
    Label() {
      if (this.value) {
        return this.value[this.LabelField] || (this.value ? this.value.Id : "")
      }
      return this.value ? this.value.Id : "";
    }
  }, methods: {
    GoDetail() {
      this.$matrix.ShowDetail(
        this.Context,
        { Type: this.config.Options.Entity, Role: this.config.Role, RoleField: this.config.RoleField },
        this.value,
        this.$route
      )
    }
  }
};
</script>