let create = function(category, data) {
  return {
    tooltip: {
      trigger: "axis",
      backgroundColor: "#4d8bf7",
      color: "#ffffff",
      textStyle: {
        color: "#ffffff"
      },
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        data: category,
        axisTick: {
          alignWithLabel: true
        }
      }
    ],
    yAxis: [
      {
        type: "value"
      }
    ],
    series: [
      {
        name: "总数",
        type: "bar",
        itemStyle: {
          color: "#4da1ff",
          barBorderRadius: [18, 18, 0, 0]
        },
        barWidth: 10,
        barCategoryGap: "20%",
        data: data
      }
    ]
  };
};

export default { create };
