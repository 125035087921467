<template>
  <div>
    <div class="flex-row noselect" style="margin:10px">
      <el-breadcrumb separator-class="el-icon-arrow-right " class="noselect">
        <el-breadcrumb-item :to="{ path: '/' }">
          首页
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="CurrentLevel1Page">
          {{ CurrentLevel1Page.Url.title }}
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="CurrentLevel2Page">
          {{ CurrentLevel2Page.Url.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flex-row list-half">
        <div class="item" @click="OnBack">返回</div>
        <div v-if="false" class="item" @click="OnReload">
          <i class="el-icon-refresh"></i>刷新
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return { CurrentLevel1Page: null, CurrentLevel2Page: null };
  },
  methods: {
    OnBack() {
      this.$matrix.Back();
    },
    OnReload() {
      this.$matrix.Reload();
    }
  },
  mounted() {
    this.CurrentLevel1Page = this.$store.state.matrix.CurrentLevel1Page;
    this.CurrentLevel2Page = this.$store.state.matrix.CurrentLevel2Page;
  }
};
</script>
<style scoped>
.item {
  cursor: pointer;
}
</style>
