import * as echarts from "echarts";
let create = function (category, data, config) {
    return {
        backgroundColor: "#0a1e3d",
        grid: {
            top: "25%",
            left: "-5%",
            bottom: "5%",
            right: "5%",
            containLabel: true,
        },
        tooltip: {
            show: true,
        },
        animation: false,
        xAxis: [
            {
                type: "category",
                data: category,
                axisTick: {
                    alignWithLabel: true,
                },
                nameTextStyle: {
                    color: "#82b0ec",
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: "#82b0ec",
                    },
                },
                axisLabel: {
                    textStyle: {
                        color: "#fff",
                    },
                    margin: 30,
                },
            },
        ],
        yAxis: [
            {
                type: "value",
                axisLabel: {
                    formatter: "{value}",
                    textStyle: {
                        color: "#8a98a7",
                    },
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: "#456087",
                        type: "dashed",
                    },
                },
            },
        ],
        series: [
            {
                name: "",
                type: "pictorialBar",
                symbolSize: [40, 10],
                symbolOffset: [0, -6],
                symbolPosition: "end",
                z: 12,
                // "barWidth": "0",
                label: {
                    normal: {
                        show: true,
                        position: "top",
                        // "formatter": "{c}%"
                        fontSize: 15,
                        fontWeight: "bold",
                        color: "#1EA0FF",
                    },
                },
                color: "#1EA0FF",
                data: data,
            },
            {
                name: "",
                type: "pictorialBar",
                symbolSize: [40, 10],
                symbolOffset: [0, 7],
                // "barWidth": "20",
                z: 12,
                color: "#2DB1EF",
                data: data,
            },

            {
                type: "bar",
                //silent: true,
                barWidth: "40",
                barGap: "10%", // Make series be overlap
                barCateGoryGap: "10%",
                itemStyle: {
                    normal: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
                            {
                                offset: 0,
                                color: "#347dcc",
                            },
                            {
                                offset: 1,
                                color: "#1d4478",
                            },
                        ]),
                        opacity: 0.7,
                    },
                },
                data: data,
            },
        ],
    };
};

export default { create };
