export default {
    props: {
        config: Object
    },
    data() {
        return { uploading: false,showUpload: true,percentage: 0,list: [] }
    },
    methods: {
        deleteImage(index) {
            this.list.splice(index,1);
            this.Data = this.list.join(',')
            this.OnChange();
        },
        onSuccess(res) {
            this.uploading = false;
            if (res.Decrypt) {
                res = this.$matrix.Decrypt(res.Decrypt)
            }
            if (res.Error) return this.$message.error("上传文件错误:" + res.Error);
            if (res.url) {
                if (this.IsMuilt) {
                    let data = this.list || [];
                    let maxSize = this.config.MaxLength || 20;
                    if (
                        this.config.MaxLength > 0 &&
                        data.length >= maxSize
                    ) {
                        data.shift();
                    }
                    data.push(res.url);
                    this.list = data;
                    this.Data = data.join(',')
                    this.OnChange();
                } else {
                    this.Data = res.url;
                    this.OnChange();
                }
            }
        },
        onBeforeUpload(file) {
            if (this.uploading) return false;
            if (this.config.MaxSize > 0) {
                if (file.size / 1024 > this.config.MaxSize) {
                    this.$matrix.ShowError("文件大小不能超过" + this.config.MaxSize + "K!");
                    return false;
                }
            }
            if (file.name) {
                let extnames = file.name.split(".");
                if (extnames.length > 1) {
                    let ext = (extnames[extnames.length - 1] || "").toLowerCase();
                    if (this.config.Exts) {
                        if (this.config.Exts.indexOf('.' + ext) > -1) {
                            //this.showFileList = true;
                            this.uploading = true;
                            this.percentage = 0;
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
            }
            this.$matrix.ShowError("无法识别上传文件格式!");
            return false;
        },
        getFullPath(path) {
            return this.$matrix.Config.ImageSite + path;
        },
        downLoad(path) {
            if (path) {
                this.$matrix.DownloadFile(this.$matrix.Config.Site + path,this.config.Title)
            }
        },
        getData(val) {
            if (this.IsMuilt) {
                if (val) this.list = val.split(','); else this.list = [];
            }
            return val;
        }
    },
    computed: {
        Headers() {
            let Methods = (this.config.Method || (this.config.Type + ".Upload")).split(".");
            let rolefield = this.config.RoleField || this.config.Role || this.$matrix.GetCurrentRoleName();
            if (this.config.RoleFields && this.config.RoleFields[rolefield] !== undefined) {
                rolefield = this.config.RoleFields[rolefield];
            } 
            let head = Methods.shift();
            Methods.unshift(rolefield);
            Methods.unshift(head);
            return { Authorization: this.$matrix.CreateHeader(Methods.join("."),this.config.Role || this.$matrix.GetCurrentRoleName(),rolefield) }
        },
        Url() { return this.$matrix.Config.ImageSite + "/upload" },
        Size() {
            if (this.config.H && this.config.Nums !== 1) {
                let size = 40;
                switch (this.config.Size) {
                    case "small": size = 36; break;
                    case "mini": size = 32; break;
                }
                return { width: size,height: size }
            } else {
                let width = this.config.Width || 120;
                let height = this.config.Height || width;
                let range = 1;
                if (width > 300) {
                    range = width / 300;
                    width = 300;
                    height = Marth.round(height / range);
                }
                return { width: width,height: height }
            }
        },
        ImageStyle() {
            return { width: (parseInt(this.Size.width) + 1) + 'px',height: (parseInt(this.Size.height) + 1) + 'px',border: 'dashed 0.5px #fff' }
        },
        UploadImageStyle() {
            return { width: this.Size.width + 'px',height: this.Size.height + 'px',border: 'dashed 0.5px #eee' }
        },
        ProcessStyle() {
            return { width: (this.Size.width + 1) + 'px',height: (this.Size.height + 1) + 'px',border: 'dashed 0.5px #eee' }
        },
        ImageCss() {
            if (this.config.H) {
                return ["flex-row","start","bottom","list-half"]
            } else {
                return ["list-half"]
            }
        },
        Info() {
            if (this.config) {
                let text = [];
                if (this.config.Exts) {
                    text.push(
                        `请上传${this.config.Exts.map((t) => {
                            return t.replace(/\./g,"").toUpperCase();
                        }).join("、")}类型的文件`
                    );
                }
                if (this.config.Maxlength > 0) {
                    text.push(`数量不得超过${this.config.Maxlength}条`);
                }
                if (this.config.MaxSize) {
                    text.push(
                        `单个文件大小不得超过${Math.round(this.config.MaxSize / 1024)}K`
                    );
                }
                return text.join("，");
            }
        },
    },
    created() {
        if (this.IsMuilt && this.value) this.list = this.value.split(',')
    }
}