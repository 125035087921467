import StringField from './String/index.vue';
import ImageField from './Image/index.vue';
import MobileField from './Mobile/index.vue';
import ObjectField from './Object/index.vue';
import EnumField from './Enum/index.vue';
import DateTimeField from './DateTime/index.vue';
import GUIDField from './GUID/index.vue';
import BoolField from './Bool/index.vue';
import IntField from './Int/index.vue';
import DateField from './Date/index.vue';
import BooleanField from './Boolean/index.vue';
import DecimalField from './Decimal/index.vue'
export default { 
    Name: "Element v4",
    Value: [
        { Type: "String", Name: "String", Theme:"Element v4", Component: StringField },
        { Type: "Image", Name: "Image", Theme:"Element v4", Component: ImageField },
        { Type: "Mobile", Name: "Mobile", Theme:"Element v4", Component: MobileField },
        { Type: "Object", Name: "Object", Theme:"Element v4", Component: ObjectField },
        { Type: "Enum", Name: "Enum", Theme:"Element v4", Component: EnumField },
        { Type: "DateTime", Name: "DateTime", Theme:"Element v4", Component: DateTimeField },
        { Type: "GUID", Name: "GUID", Theme:"Element v4", Component: GUIDField },
        { Type: "Bool", Name: "Bool", Theme:"Element v4", Component: BoolField },
        { Type: "Int", Name: "Int", Theme:"Element v4", Component: IntField },
        { Type: "Date", Name: "Date", Theme:"Element v4", Component: DateField },
        { Type: "Boolean", Name: "Boolean", Theme:"Element v4", Component: BooleanField },
        { Type: "Decimal", Name: "Decimal", Theme:"Element v4", Component: DecimalField }
    ]
}