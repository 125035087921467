<template>
	<component :is="Is" :value="value" :config="config" :title="title" :options="options" :icon="icon"
		@change="handleChange" @click="handleClick">
		<slot></slot>
		<slot slot="button" name="button"></slot>
	</component>
</template>
<script>
import defaultComponet from './default.vue'
import PageTitle from './PageTitle.vue'
import ColumnTitle from './ColumnTitle.vue'
import LittleTitle from './LittleTitle.vue'
import MainTitle from './MainTitle.vue'
import ListTitle from './ListTitle.vue'

export default {
	props: {
		value: Number,
		config: Object,
		title: String,
		icon: String,
		theme: { type: String, default: '' },
		options: Array
	},
	components: { defaultComponet, PageTitle, ColumnTitle, LittleTitle, MainTitle, ListTitle },
	computed: {
		Is() {
			switch (this.theme.toLowerCase()) {
				case 'pagetitle':
					return 'PageTitle'
				case 'column':
				case 'columntitle':
					return 'ColumnTitle'
				case 'littletitle':
					return 'LittleTitle'
				case 'maintitle':
					return 'MainTitle'
				case 'listtitle':
					return 'ListTitle'
				default:
					return 'defaultComponet'
			}
		}
	},
	methods: {
		handleChange(val) {
			this.$emit('change', val)
		},
		handleClick(val) {
			this.$emit('click', val)
		}
	}
}
</script>
