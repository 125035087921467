<template>
    <el-descriptions :column="nums" :content-style="contentStyle" :border="true" direction="vertical" size="small"
        v-if="value &&options">
        <el-descriptions-item :label="col.Title" v-for="col in options" :key="col.Name">
            <mx-field v-if="value[col.Name] !== undefined" :value="value" :config="col" :type="col.Type"
                :name="col.Name"> <span v-if="!value[col.Name]">&nbsp;未填写</span>
            </mx-field>
            <span v-else style="color: #777">未填</span>

        </el-descriptions-item>
    </el-descriptions>
</template>
<script>
export default {
    props: {
        type: String,
        options: Array,
        value: Object,
        nums: { type: Number, default: 2 },
        config: Object
    },
    computed: {
        contentStyle() {
            let Nums = this.nums
            return { width: Math.round(1 / Nums * 10000) / 100 + '%', verticalAlign: 'top' }
        }
    }
}
</script>