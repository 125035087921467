<template>
    <el-dialog style="text-align: left" :title="config.Title" :visible="true" width="720px" :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="frame">
            <el-steps :active="currentStepIndex" simple>
                <el-step :title="item.Title" :icon="item.Icon" v-for="(item, index) in steps" :key="index"></el-step>
            </el-steps>
            <el-carousel height="454px" style="padding: 0; margin: 0" :autoplay="false" arrow="never" :loop="false"
                ref="car" :interval="0" trigger="click">
                <el-carousel-item style="postion: relative">
                    <div class="centerdiv">
                        <el-upload v-if="!reading" class="upload-demo" drag accept=".xlsx" action="http://localhost"
                            :before-upload="beforeUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">
                                将文件拖到此处，或<em>点击上传</em>
                            </div>
                            <div class="el-upload__tip" slot="tip">
                                <div>* 只能上传xlsx文件</div>
                                <div>
                                    * 点击<a href="javascript:void(0)" @click="downloadTempletehandler">这里</a>下载模板
                                </div>
                            </div>
                        </el-upload>
                        <div v-else>正在处理数据，请稍后</div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="flex-row start wrap top" v-if="imports">
                        <div class="label" :class="importstate === 0 ? 'active' : ''" @click="OnSelect(0)">
                            全部({{ imports.length }})
                        </div>
                        <div class="label" :class="importstate === 1 ? 'active' : ''" @click="OnSelect(1)">
                            有效数据({{
                                    imports.filter((t) => {
                                        return !t.errors
                                    }).length
                            }})
                        </div>
                        <div class="label" :class="importstate === 2 ? 'active' : ''" @click="OnSelect(2)">
                            错误数据({{
                                    imports.filter((t) => {
                                        return !!t.errors
                                    }).length
                            }})
                        </div>
                    </div>
                    <div class="itembox">
                        <div v-for="(item, index) in ImportDatas" :key="index + '_' + importstate" class="panel"
                            style="width: 100%; padding: 5px">
                            <div>
                                <span style="margin-right: 5px; width: 20px">{{
                                        index + 1
                                }}</span>{{ getTitle(item.data) }}
                            </div>
                            <div class="err">
                                {{ item.errors ? item.errors[0].message || item.errors[0] : '' }}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
                <el-carousel-item>
                    <div class="flex-row start wrap top" v-if="result">
                        <div style="padding: 5px">
                            导入：<span class="number">{{ result.length }}</span>项,成功：<span class="number">{{
                                    result.filter((t) => {
                                        return !t.error
                                    }).length
                            }}</span>项,失败：<span class="number">{{
        result.filter((t) => {
            return !!t.error
        }).length
}}</span>项
                        </div>
                    </div>
                    <div class="itembox flex-row start wrap top" v-if="result">
                        <div v-for="(item, index) in result.filter((f) => {
                            return f.error
                        })" :key="index" class="panel" style="width: 100%; padding: 3px">
                            <div>
                                <span style="margin-right: 5px; width: 20px">{{
                                        index + 1
                                }}</span>{{ getTitle(item.data) }}
                            </div>
                            <div class="err">
                                {{ item.error ? item.error : '' }}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <div slot="footer" class="flex-row">
            <div style="flex: 1"></div>
            <div style="width: 600px" v-if="!reading">
                <template>
                    <el-button :type="button.Type" v-for="(button, index) of currentStep.Buttons" :key="index"
                        @click="handleNext(button)">{{ button.Title }}</el-button>
                </template>
            </div>
            <div v-if="reading" style="width: 600px">
                <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage"></el-progress>
            </div>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        config: Object
    },
    data() {
        return {
            visiabled: {},
            deleted: null,
            formkey: new Date().getTime(),
            currentStepIndex: 0,
            file: null,
            reading: false,
            percentage: 0,
            processtitle: '',
            imports: null,
            maxper: 0,
            importstate: 0,
            result: null
        }
    },

    methods: {
        OnSelect(item) {
            if (this.importstate !== item) this.importstate = item
        },
        getTitle(item) {
            return this.$matrix.GetTitleByType(this.config.Type, item)
        },
        beforeUpload(file) {
            this.file = file
            this.reading = true
            this.percentage = 0
            this.maxper = 0
            this.$matrix
                .ReadDataFromExcel(
                    this.Context,
                    file,
                    this.config.Type,
                    this.config,
                    undefined,
                    (total, per, title) => {
                        if (per > this.maxper) {
                            this.maxper = per
                            per = per + 1
                            this.percentage = parseInt((per / total) * 100)
                        }
                        this.processtitle = title
                        this.importstate = 0
                    }
                )
                .then((data) => {
                    if (data && data.length > 0) {
                        this.imports = data
                        setTimeout(() => {
                            this.currentStepIndex += 1
                            this.$refs.car.next()
                            this.reading = false
                        }, 200)
                    }
                })
                .catch((e) => {
                    this.reading = false
                    this.$matrix.ShowError(e)
                })

            return false
        },
        handleLoading(e, child) { },
        OnLoaded(e, child) { },
        handleNext(button) {
            if (button.OnClick) {
                button.OnClick.call(this)
            } else if (this.currentStepIndex.length > 0)
                this.currentStepIndex -= 1
        },
        handleClose(e) {
            if (this.config && this.config.OnClose) {
                this.config.OnClose()
            } else this.$matrix.CloseImportDialog(this.config.Type)
        },
        downloadTempletehandler() {
            this.$matrix.GetImportTemplete(
                this.Context,
                this.config.Type,
                this.config
            )
        }
    },
    computed: {
        ImportDatas() {
            if (this.imports) {
                if (this.importstate === 0) return this.imports
                else if (this.importstate === 1)
                    return this.imports.filter((d) => {
                        return !d.errors
                    })
                else if (this.importstate === 2)
                    return this.imports.filter((d) => {
                        return !!d.errors
                    })
            }
        },
        currentStep() {
            return this.steps[this.currentStepIndex]
        },
        steps() {
            return [
                {
                    Title: '上传文件',
                    Icon: 'el-icon-upload',
                    Buttons: [
                        {
                            Title: '取消导入',
                            OnClick: () => {
                                this.handleClose()
                            }
                        }
                    ]
                },
                {
                    Title: '确认数据',
                    Icon: 'el-icon-edit',
                    Buttons: [
                        {
                            Title: '上一步',
                            OnClick: () => {
                                this.currentStepIndex -= 1
                                this.$refs.car.prev()
                            }
                        },
                        {
                            Title: '开始导入',
                            Type: 'primary',
                            OnClick: () => {
                                if (this.imports) {
                                    let datas = this.imports.filter((f) => {
                                        return !f.errors
                                    })

                                    if (datas.length > 0) {
                                        this.reading = true
                                        this.percentage = 0
                                        this.processtitle = ''
                                        let updateAll = async (datas) => {
                                            let result = []
                                            let index = 0
                                            for (let data of datas) {
                                                index++
                                                try {
                                                    let res =
                                                        await this.$matrix.Post4(
                                                            this.Context,
                                                            this.config.Type,
                                                            'Add',
                                                            data.data,
                                                            this.config.Role,
                                                            this.config
                                                                .RoleField
                                                        )
                                                    result.push({
                                                        data: res
                                                    })
                                                } catch (e) {
                                                    result.push({
                                                        data: data.data,
                                                        error:
                                                            e.message ||
                                                            e.Error ||
                                                            e
                                                    })
                                                }
                                                let progress = parseInt(
                                                    (index / datas.length) * 100
                                                )
                                                if (progress > this.percentage)
                                                    this.percentage = progress
                                            }
                                            return result
                                        }
                                        updateAll(datas)
                                            .then((datas) => {
                                                this.result = datas
                                                this.currentStepIndex += 1
                                                this.$refs.car.next()
                                                this.reading = false
                                            })
                                            .catch((e) => {
                                                this.$matrix.ShowError(e)
                                                this.reading = false
                                            })
                                    } else {
                                        this.$matrix.ShowError(
                                            '数据不正常，无法导入数据'
                                        )
                                    }
                                }
                            }
                        }
                    ]
                },
                {
                    Title: '导入数据',
                    Icon: 'el-icon-s-grid',
                    Buttons: [
                        {
                            Title: '完成',
                            Type: 'primary',
                            OnClick: () => {
                                if (this.config.OnSuccess)
                                    this.config.OnSuccess()
                            }
                        }
                    ]
                }
            ]
        },
        mainFormType() {
            return this.children.length > 0 ? 'Edit' : 'DialogForm'
        },
        dialogWidth() {
            return this.children.length > 0 ? '1024px' : '470px'
        },
        children() {
            if (this.config.Children) {
                return this.config.Children.filter((c) => {
                    return (
                        c.Form.Columns.filter((c) => {
                            return !c.NoShow && c.IsInsert
                        }).length > 0
                    )
                })
            }
            return []
        },
        Context() {
            return this.config.Context || {}
        }
    }
}
</script>
<style scoped>
.frame {
    height: 500px;
    overflow: auto;
}

.framebox {
    background-color: #f7f7f7;
    display: block;
    min-height: calc(100% - 46px);
    padding: 0;
    margin: 0;
}

.label {
    padding: 10px;
    margin: 0 5px 5px 0;
}

.label.active {
    background-color: red;
}

.err {
    color: red;
    font-size: 14px;
}

.itembox {
    height: calc(100% - 44px);
    overflow-y: auto;
}

.number {
    font-size: 14px;
    font-weight: 700;
    color: red;
}
</style>