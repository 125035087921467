<template>
    <div class="pointer" @click="OnCreateWord">{{ Title }}</div>
</template>
<script>
import docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import ImageModule from "docxtemplater-image-module-free";
export default {
    props: {
        Title: String,
        value: Object,
        config: Object,
    },
    computed: {
        File() {
            return {
                Title: "报告模板",
                Url: "/static/File/评测报告.docx",
            };
        },
        SignImageList() {
            return this.value.SignList[0]
        }

    },
    methods: {
        FormatHtml(str) {
            var re1 = new RegExp("<.+?>|&.+?;", "g")
            var msg = str ? str.replace(re1, "") : ""
            return msg
            //return str ? str.replace(/<.*?>/g, "").replace(/[ ]|[&nbsp;]/g, "") : "";
        },
        async OnCreateWord() {

            let that = this;
            let imagePathList = []


            // 读取并获得模板文件的二进制内容
            JSZipUtils.getBinaryContent(that.File.Url, async function (error, content) {
                if (error) {
                    throw error;
                }
                if (that.SignImageList) {
                    let imageUrl = await that.getBase64Sync(that.SignImageList.DriverImagePath)
                    let imageUrl2 = await that.getBase64Sync(that.SignImageList.TeacherImagePath)
                    imagePathList.push({ ImagePath: imageUrl, ImagePath2: imageUrl2 })
                }
                let FaceImage = ''
                if (that.value.Driver.FaceImage) {
                    FaceImage = await that.getBase64Sync(that.value.Driver.FaceImage)
                }
                // 设置模板变量的值
                let Discuss = that.value.DiscussList[0]
                let IsPassTitle = that.value.TotalScore < 70 ? "不通过" : "通过"
                let docxData = {
                    NickName: that.value.Driver.Name,
                    IdCard: that.value.Driver.IdCard,
                    TotalScore: that.value.TotalScore,
                    ReponseScore: that.value.ReponseScore,
                    TimeLen: that.value.TimeLen,
                    ManagerName: that.value.Manager.NickName || "超级管理员",
                    Address: "",
                    IsPassTitle: IsPassTitle,
                    CartTypeTitle: that.value.Driver.CartType.Name,
                    FirstDate: that.value.Driver.FirstDate || "2024-01-01",
                    Excellent: Discuss.Excellent,
                    Shortcoming: Discuss.Shortcoming,
                    Advise: Discuss.Advise,


                   SignImageList: imagePathList ? imagePathList : [],
                     FaceImage:FaceImage
                };
                const opts = {};
                opts.centered = true;
                opts.fileType = "docx";
                opts.getImage = (dataURL) => {
                    return that.base64DataURLToArrayBuffer(dataURL);
                };
                opts.getSize = () => {
                    return [100,200];
                };

                const imageModule = new ImageModule(opts);
                // 创建一个PizZip实例，内容为模板的内容
                let zip = new PizZip(content);
                // 创建并加载docxtemplater实例对象
                let doc = new docxtemplater().loadZip(zip);
                console.log(imageModule, 33);
                doc.attachModule(imageModule);
                doc.setData({
                    ...docxData,
                });

                try {
                    // 用模板变量的值替换所有模板变量
                    doc.render();
                } catch (error) {
                    // 抛出异常
                    let e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    };

                    console.log(JSON.stringify({ error: e }));
                    throw error;
                } // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
                let out = doc.getZip().generate({
                    type: "blob",
                    mimeType:
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                // 将目标文件对象保存为目标类型的文件，并命名
                saveAs(out, that.value.Driver.Name + ".docx");
            });
        },
        getBase64Sync(ImageUrl) { 
            return new Promise((r, j) => {
                let image = new Image;
                image.crossOrigin = 'anoymous'
                image.src = ImageUrl 
                image.onload = () => { 
                    let canvas = document.createElement('canvas')
                    canvas.width = image.width;
                    canvas.height = image.height;
                    let context = canvas.getContext('2d');
                    context.drawImage(image, 0, 0, image.width, image.height)
                    let ext = image.src
                        .substring(image.src.lastIndexOf(".") + 1)
                        .toLowerCase();
                    let quality = 0.8
                    let dataurl = canvas.toDataURL('image/' + ext, quality)
              
                    r(dataurl)
                }
            })
        },
        // 导出图片，格式转换
        base64DataURLToArrayBuffer(dataURL) {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        },
    },
};
</script>
  