<template>
    <div class="box">
    
        <component :is="Theme" :value="value" :config="config" :columns="columns" :options="options" ref="listform"
            @change="handleChange">
        </component>
        <el-alert class="bottom" effect="dark" show-icon v-if="Errors && Errors.length > 0" :title="Errors[0].message"
            type="error" :closable="false">
        </el-alert>
        <mx-element type="Dialog" :config="DialogConfig" v-if="DialogConfig" :value="1" :title="DialogConfig.Title">
            <mx-form type="DialogForm" :config="DialogConfig" :value="DialogConfig.Value"
                :columns="DialogConfig.Columns" :theme="DialogConfig.Theme" ref="dialogform"></mx-form>
        </mx-element>
    </div>
</template>
<script>
import FileEdit from './File'
import ImageEdit from './Image'
import TableEdit from './Table'
import PanelEdit from './Panel'
import LabelEdit from './Label'
import LabelFormEdit from './LabelEdit'
import TableEditEdit from './TableEdit'
import TableFormEdit from './TableForm'
import PanelSelectEdit from './PanelSelect'
import list from 'matrix.node.ui/master/list'
export default {
    mixins: [list.container],
    components: {
        FileEdit,
        ImageEdit,
        TableEdit,
        PanelEdit,
        LabelEdit,
        TableEditEdit,
        TableFormEdit,
        LabelFormEdit,
        PanelSelectEdit
    },
    data() {
        return { DialogConfig: null }
    },
    computed: {
        Theme() {
             if (
                [
                    'File',
                    'Image',
                    'Table',
                    'Panel',
                    'Label',
                    'TableEdit'
                ].indexOf(this.theme) > -1
            ) {
              
                if (this.theme === 'Label') {
                    let field = this.$matrix.Models[
                        this.config.Type
                    ].Fields.find((t) => {
                        return t.IsInsert && t.Theme === 'TableForm'
                    })
                    if (field) return 'TableFormEdit'
                    field = this.$matrix.Models[this.config.Type].Fields.find(
                        (t) => {
                            return (
                                t.IsInsert &&
                                (t.Theme === 'LabelEdit' ||
                                    t.Theme === 'LabelForm')
                            )
                        }
                    )
                    if (field) return 'LabelFormEdit'
                    return this.theme + 'Edit'
                } else {
                    let field = this.$matrix.Models[
                        this.config.Type
                    ].Fields.find((t) => {
                        return t.IsInsert && t.Theme === 'PanelSelect'
                    })
                    if (field) return 'PanelSelectEdit'
                
                    return this.theme + 'Edit'
                }
            }

            return 'PanelEdit'
        }
    },
    methods: {
        handleChange(val) {
            this.Errors = null
        },
        ModifyByDialog(value, field) {
            return new Promise((resolve, reject) => {
                let val = JSON.parse(JSON.stringify(value))
                let dialogConfig = JSON.parse(JSON.stringify(this.config.Form))
                dialogConfig.Value = val
                dialogConfig.Columns = dialogConfig.Columns.filter((f) => {
                    return f.Name !== field
                })
                if (
                    dialogConfig.Columns.filter((f) => {
                        return !f.NoShow
                    }).length === 0
                ) {
                    resolve(val)
                    return
                }
                dialogConfig.OnOk = function () {
                    let errors = this.$refs.dialogform.GetError()
                    if (!errors) {
                        resolve(this.$refs.dialogform.GetValue())
                        this.DialogConfig = null
                    }
                }.bind(this)
                dialogConfig.OnClose = () => {
                    this.DialogConfig = null
                    resolve(null)
                }
                dialogConfig.OnCancel = () => {
                    this.DialogConfig = null
                    resolve(null)
                }
                dialogConfig.Width =
                    dialogConfig.Columns.filter((f) => {
                        return ['Html'].indexOf(f.Type) > -1
                    }).length > 0
                        ? '900px'
                        : undefined
                this.DialogConfig = dialogConfig
            })
        },
        OnParentChange(e) {
            let com = this.$refs.listform
            if (Array.isArray(com)) {
                com.forEach((c) => {
                    if (c.OnParentChange) c.OnParentChange(e)
                })
            } else if (com && com.OnParentChange) {
                com.OnParentChange(e)
            }
        }
    }
}
</script>
<style scoped>
.err {
    text-align: left;
}

.box {
    position: relative;
    padding-bottom: 30px;
}

.bottom {
    position: absolute;
    bottom: 0;
    left: 0px;
}
</style>
