<template>
  <el-select v-model="Data" :size="size || config.Size" @change="OnChange" :disabled="disabled || config.Disabled"
    :placeholder="'请输入' + (config.Title || '')" clearable>
    <el-option v-for="item in Options" :label="item.Title" :key="item.Id" :value="item.Id">
    </el-option>
  </el-select>
</template>
<script>
import base from "matrix.node.ui/field.js";
const emptyStrHanlder = function (params) {
  return;
};
export default {
  mixins: [base.edit(Number, emptyStrHanlder)],
  computed: {
    Text() {
      let selectItem = this.Options.find(t => {
        return t.Id === this.Data;
      });
      return selectItem ? selectItem.Title : "";
    },
    Options() {
      return this.config.Options || [];
    }
  }
};
</script>