<template>
  <div :class="IsH ? 'flex-row  panel3 start' : 'flex-column list'">
    <div v-for="(col, index) in columns" @click="handleClick(col, index)" :style="itemStyle" :key="index + ActiveIndex"
      :class="Isfit(col, index, ActiveIndex) ? (IsH ? 'active menu_row' : 'active menu') : (IsH ? 'menu_row' : 'menu')">
      <span class="line1" v-if="IsH"> {{ col.Title }}</span>
      <span class="line1" v-else><i class="el-icon-menu"></i> {{ col.Title }}</span>
      <slot :config="col" :index="index" v-if="col.Config.Fields"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array,
  },
  data() {
    return { ActiveIndex: -1 };
  },
  computed: {
    ColumnConfig() {
      let config = {};
      this.columns.forEach((col) => {
        if (col.ColumnConfig) {
          config = Object.assign(config, col.ColumnConfig);
        }
      });
      return config;
    },
    Cls() {
      if (this.ColumnConfig.Direction === "v") {
        return ["flex-row start middle wrap"];
      } else {
      }
    },
    itemStyle() {
      return { "--activeColor": this.ColumnConfig.Color || "#123d6b" };
    },
    StorageName() {
      let t = this.$route.query.t || ''
      if (!t) return ''
      return `${t}_${this.config.Name}`
    },
    IsH() {
      let v = this.config.views.find(item => {
        return item.IsH
      })
      if (v) return v.IsH
    },
  },
  methods: {
    Isfit(config, index, current) {
      return (
        (config.ListParams &&
          this.$matrix.Include(config.ListParams, this.$route.query)) ||
        this.ActiveIndex === index
      );
    },
    handleClick(item, index) {
      if (item.ListView) {
        this.$matrix.GotoListView(
          this.$route,
          item.ListView,
          item.Role,
          item.RoleField,
          item.ListParams
        );
      } else if (this.ActiveIndex !== index) {
        this.ActiveIndex = index;
        this.$emit("itemclick", item);
      }


      if (this.StorageName) {
        this.$matrix.SetLocalStorage(this.StorageName, { index: index })
      }

    },
  },
  destroyed() {
    if (this.StorageName) {
      this.$matrix.SetLocalStorage(this.StorageName, undefined)
    }
  },
  created() {

    if (this.columns) {
      let isClicked = false;
      if (!isClicked && this.StorageName) {
        let currentMenu = this.$matrix.GetLocalStorage(this.StorageName)
        if (currentMenu) {
          let col = this.columns[currentMenu.index]
          if (col) {
            this.handleClick(col, currentMenu.index);
            isClicked = true;
          }
        }
      }
      if (!isClicked) {
        this.columns.forEach((col, index) => {
          if (col.Active) {
            this.handleClick(col, index);
            isClicked = true;
            if (this.StorageName) {
              this.$matrix.SetLocalStorage(this.StorageName, { index: index })
            }
          }
        });
      }
      if (!isClicked) {
        this.handleClick(this.columns[0], 0);
        if (this.StorageName) {
          this.$matrix.SetLocalStorage(this.StorageName, { index: 0 })
        }
      }
    }
  },
};
</script>
<style scoped>
.menu-box {
  background-color: #f7f7f7;
}

.menu {
  border-radius: 50px;
}

.menu.active {
  color: #fff;
  background-color: var(--activeColor);
}


.menu {
  padding: 10px;
  background-color: #fff;
  margin-top: 1px;
  cursor: pointer;
}

.menu_row.active::after {
  -webkit-transform: scale(1);
  transform: scale(1);

}
.menu_row.active{
  color:#123d6b;
  font-weight:bold;
}

.menu_row {
  display: block;
  padding: 15px;
  position: relative;
  background: 0 0;
  border: 0;
  color: #000;
  -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  cursor: pointer;
}



.menu_row::after {
  content: "";
  background: #405189;
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
  right: 0;
  -webkit-transition: all 250ms ease 0s;
  transition: all 250ms ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
}
</style>
