<template>
  <div
    tip="数据统计"
    class="r4 frame-half flex-row middle"
    :style="InnerStyle"
  >
    <div style="text-align: center">
      <div
        :class="Icons"
        :style="IconStyle"
      ></div>
    </div>
    <div style="flex: 1; text-align: right; padding-right: 20px">
      <div
        class="number"
        style="font-size: 32px"
      >{{ data }}</div>
      <div class="line1">{{ config.Title }}</div>
    </div>
    <div
      v-if="list"
      style="width: 40%"
      class="border-left"
    >
      <div
        v-for="(l, index) in list"
        :key="index"
        v-show="index <= 3"
        style="padding-left: 10px"
        class="line1 f14"
      >
        <span
          class="f14"
          style="padding: 0 5px"
        >{{ l.title }}</span><span
          class="f14 number"
          style="padding: 0 5px"
        >{{ l.count }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        data: Number,
        list: Array,
        config: {
            type: Object,
            default: () => {
                return {}
            }
        },
        index: Number
    },
    computed: {
        InnerStyle() {
            const backgroundcolors = [
                '#191970',
                '#008000',
                '#800080',
                '#228B22',
                '#8B4513',
                '#2F4F4F'
            ]
            const colors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff']
            const bordercolor = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff']

            let index = this.index % backgroundcolors.length
            return {
                width: '100%',
                height: '100%',
                backgroundColor: backgroundcolors[index],
                color: colors[index],
                border: '1px solid ' + bordercolor[index]
            }
        },
        Icons() {
            const backgroundcolors = [
                '#191970',
                '#008000',
                '#800080',
                '#228B22',
                '#8B4513',
                '#2F4F4F'
            ]
            const icons = [
                'el-icon-eleme',
                'el-icon-upload',
                'el-icon-set-up',
                'el-icon-medal',
                'el-icon-refresh',
                'el-icon-news'
            ]
            let index = this.index % backgroundcolors.length 
            return icons[index]
        },
        IconFrameStyle() {
            return {
                width: '50%',
                textAlign: 'right'
            }
        },
        IconStyle() {
            const backgroundcolors = [
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff',
                '#fff'
            ]
            const colors = [
                '#191970',
                '#008000',
                '#800080',
                '#228B22',
                '#8B4513',
                '#2F4F4F'
            ]
            let index = this.index % backgroundcolors.length
            return {
                backgroundColor: backgroundcolors[index],
                color: colors[index],
                fontSize: '32px',
                borderRadius: '50px',
                width: '50px',
                height: '50px',
                padding: '0',
                textAlign: 'center',
                lineHeight: 1.5,
                marginLeft: '10px'
            }
        }
    }
}
</script>

<style scoped>
.number {
    font-family: Number_Font !important;
}
</style>
