import { render, staticRenderFns } from "./LabelEdit.vue?vue&type=template&id=451ca9a1&scoped=true&"
import script from "./LabelEdit.vue?vue&type=script&lang=js&"
export * from "./LabelEdit.vue?vue&type=script&lang=js&"
import style0 from "./LabelEdit.vue?vue&type=style&index=0&id=451ca9a1&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451ca9a1",
  null
  
)

export default component.exports