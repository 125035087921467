import { render, staticRenderFns } from "./frame.vue?vue&type=template&id=68e26b2f&scoped=true&"
import script from "./frame.vue?vue&type=script&lang=js&"
export * from "./frame.vue?vue&type=script&lang=js&"
import style0 from "./frame.vue?vue&type=style&index=0&id=68e26b2f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68e26b2f",
  null
  
)

export default component.exports