<template>
    <component type="ChildPageTitle" :title="Title" :is="ComponentName">
        <mx-page
            type="Home"
            :config="detail"
            :parent="parent"
            :key="timespan"
            :is-child="isChild"
            :name="Name"
            v-if="Name"
            theme="Detail"
            @meta-change="OnMetaChange"
        >
        </mx-page>
    </component>
</template>
<script>
import detail from 'matrix.node.ui/page/detail'
export default {
    mixins: [detail('detail')],
    props: {},
    data() {
        return { Title: '', ComponentName: 'mxElement' }
    },
    methods: {
        OnMetaChange(val) {
            this.Title = `${val.meta.Page.Page}`
            if (val.meta.Page.Config.NoTopBanner) {
                this.ComponentName = 'DIV'
            }
        }
    },
    computed: {
        Name() {
            return this.$route.query.name
        }
    }
}
</script>