<template>
    <el-dialog style="text-align: left" :title="config.Title" :visible="true" width="550px" :before-close="handleClose"
        :close-on-click-modal="false">
        <div class="framebox">
            <template v-if="Selection && Selection.length > 0">
                <div class="panel3 flex-row middle" @click="OnClick(data)" v-for="data of Selection" :key="data.Id">
                    <input type="checkbox" :checked="Selectioned[data.Id]" /> <mx-panel style="flex:1"
                        :config="FieldConfig" panel="SystemSelection" :value="data"></mx-panel>

                </div>
            </template>
            <mx-element v-else type="Empty"
                :title="'没有可选项' + (this.config.Config.Selected && this.config.Config.Selected.length > 0 ? `，已经选中${this.config.Config.Selected.length}项` : '')"></mx-element>
        </div>
        <div slot="footer" class="flex-row">
            <div style="padding-left: 30px">
                <span v-if='Error'>{{ Error }}</span> <span v-else>当前选中{{ SelectionItem.length }}项目</span>
            </div>
            <div style="flex: 1"></div>
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="handleOk">确认选中</el-button>
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        config: Object
    },
    data() {
        return {
            Error: null, Selection: [], Selectioned: {}
        }
    },

    methods: {
        OnClick(item) {
            if (this.config.Config.IsMult !== false) {
                this.Selectioned = Object.assign({}, this.Selectioned, { [item.Id]: !this.Selectioned[item.Id] })

            }
            else {
                this.Selectioned = { [item.Id]: !this.Selectioned[item.Id] }
            }

        },
        handleOk(e) {
            if (this.SelectionItem.length === 0) return this.ShowError('请选择后再点确认选中')
            if (this.config.Success) {
                this.config.Success(this.Selection.filter(item => {
                    return this.Selectioned[item.Id]
                }))
            }
            this.$matrix.CloseSelectionDialog()
        },
        handleClose(e) {
            if (this.config.Success) {
                this.config.Success([])
            }
            this.$matrix.CloseSelectionDialog()
        }
    },
    computed: {
        SelectionItem() {
            return Object.keys(this.Selectioned).filter(key => {
                return this.Selectioned[key]
            })
        },
        FieldConfig() {
            return { Type: this.config.Field.Link, Role: this.config.Role, RoleField: this.config.RoleField }
        },
        Context() {
            return this.config.Context || {}
        }
    },
    created() {
        this.$matrix.LoadFieldData(this.config.Field, this.config.Config.Role, this.config.Config.RoleField, this.config.Config.Params, this.config.Value)
            .then(datas => {
                if (this.config.Config.Selected) {
                    datas = datas.filter(f => {
                        return !this.config.Config.Selected.find(s => { return s.Id === f.Id })
                    })
                }
                this.Selection = datas
            }).
            catch(e => {
                this.Error = e.Message || e
            })
    }
}
</script>
<style scoped>
.framebox {
    height: 500px;
    overflow: auto;
    background-color: #f7f7f7
}

.framebox div {
    padding: 5px;
    cursor: pointer;
    margin: 1px 1px 0 1px;
}

.label {
    padding: 10px;
    margin: 0 5px 5px 0;
}

.label.active {
    background-color: red;
}

.err {
    color: red;
    font-size: 14px;
}

.itembox {
    height: calc(100% - 44px);
    overflow-y: auto;
}

.number {
    font-size: 14px;
    font-weight: 700;
    color: red;
}
</style>