import Detail from "./detail";
import Edit from "./edit";
import EditRadio from "./editradio";
import EditDropDown from "./editdropdown";
import EditCheckBox from "./editcheckbox";
export default {
    components: [
        { Name: "Detail", Component: Detail },
        { Name: "Edit", Component: Edit },
        { Name: "EditRadio", Component: EditRadio },
        { Name: "EditDropDown", Component: EditDropDown },
        { Name: "EditCheckBox", Component: EditCheckBox }
    ],
    Is() {
        if (this.edit) {
            let EditStyle = this.config
                ? this.config.EditStyle || this.config.theme || ""
                : "";
            switch (EditStyle) {
                case "Radio":
                    return "EditRadio";
                case "DropDown":
                    return "EditDropDown";
                case "CheckBox":
                    return "EditCheckBox";
                case "Switch":
                    return "Edit";
            }
            return "EditRadio";
        }
        return "Detail";
    }
}