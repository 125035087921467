<template>
  <mx-load-one :key="pagekey + config.Id" :auto-load="true" :type="config.Type" :id="config.Id" :role="config.Role"
    :role-field="config.RoleField" mode="modify">
    <template slot-scope="data">
      <mx-entity v-if="data.data && data.data.Id" mode="modify" :type="config.Type" :parent="parent" :role="config.Role"
        :role-field="config.RoleField" ref="entity" :value="data.data" @change="handleMetaChange($event, data.data)">
        <template slot-scope="meta" v-if="Meta">
          <div style="margin: 1px auto" class="panel10">
            <div style="width: 900px; margin: 0 auto">
              <div style="padding: 20px" class="panel10">
                <div class="s4" v-if="Form">
                  <mx-element type="FormTitle" :title="Form.Title">
                  </mx-element>
                  <mx-form style="padding: 20px" :key="formkey" type="Edit" :config="Form" :columns="Form.Columns"
                    :theme="Form.Theme" :value="data.data" ref="main" @change="handlFormChange"></mx-form>
                </div>
                <div v-for="child of Children" :key="meta.childrenkey[child.Name]" class="s4"
                  style="margin-top: 20px; padding-bottom: 10px" v-show="visiabled[child.Name]">
                  <mx-element type="FormTitle" :title="child.Title" class="panel4">
                    <div>
                      <mx-element v-if="child.DeleteEnabled && deleted" type="Switch" :value="
                        deleted && deleted[child.Name] === false ? 0 : 1
                      " @change="handleDelete($event, child)"></mx-element>
                      <template v-if="!deleted || deleted[child.Name] === false">
                        <mx-element v-if="CanSave(child)" type="Button" theme="Submit" title="保存" size="mini"
                          @click="handleSubmitChild(child)"></mx-element>
                        <mx-element v-if="CanReset(child)" type="Button" theme="reflash" title="重置" size="mini"
                          @click="handleCancelChild(child)" v-show="child"></mx-element></template>
                    </div>
                  </mx-element>
                  <div class="box">
                    <template v-if="child.IsList">
                      <mx-load-list :type="child.Type" :page-size="0" :role="child.Role" :auto-load="true"
                        :role-field="child.RoleField" :params="getChildLoadParams(child, data.data)">
                        <template slot-scope="list">
                          <div v-show="
                            child.DeleteEnabled &&
                            deleted &&
                            deleted[child.Name] === false
                          " class="mask">
                            <div class="vcenter">
                              {{ child.Title }}已经停用,数据不会被存储
                            </div>
                          </div>
                          <mx-load-list v-if="child.DataLoad" :params="child.DataLoad.Params"
                            :type="child.DataLoad.Type" :role="child.DataLoad.Role"
                            :role-field="child.DataLoad.RoleField" :data-handler="child.DataHandle" :page-size="0"
                            @loading="handleLoading($event, child)" :auto-load="true">
                            <template slot-scope="res">
                              <mx-list :type="child.ListType" :config="child" :value="
                                list.data || child.CreateList(child, res.data)
                              " :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name"
                                :options="res.data">
                              </mx-list>
                            </template>
                          </mx-load-list>
                          <mx-list v-else :type="child.ListType" :config="child" :value="list.data || []"
                            :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name"
                            :options="child.Options">
                          </mx-list>
                        </template>
                      </mx-load-list>
                    </template>
                    <mx-load-list v-else :type="child.Type" :page-size="1" :role="child.Role" :auto-load="true"
                      :role-field="child.RoleField" :params="getChildLoadParams(child, data.data)"
                      :data-handler="OnLoaded(child)">
                      <template slot-scope="list">
                        <div v-show="
                          child.DeleteEnabled &&
                          deleted &&
                          deleted[child.Name] === false
                        " class="mask" :key="list.timespan">
                          <div class="vcenter">
                            {{ child.Title }}已经停用,数据不会被存储
                          </div>
                        </div>
                        <template v-if="list.data[0] && list.data[0].Id">
                          <mx-form v-show="
                            child.Form.Columns.filter((f) => {
                              return !f.NoShow;
                            }).length > 0
                          " style="padding: 40px 20px 10px 20px" :key="formkey" type="Edit" :config="child"
                            :value="list.data[0]" :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name">
                          </mx-form>
                          <div style="padding: 20px" v-if="
                            child.Form.Columns.filter((f) => {
                              return !f.NoShow;
                            }).length === 0
                          ">
                            {{ child.Title }} 不容许变更数据
                          </div>
                        </template>
                        <mx-form v-else style="padding: 40px 20px 10px 20px" :key="formkey" type="Edit" :config="child"
                          :value="child.Form.AddForm.Value" :columns="child.Form.AddForm.Columns" :theme="child.Theme"
                          :ref="child.Name">
                        </mx-form>
                      </template>
                    </mx-load-list>
                  </div>
                  <mx-panel :panel="getPanel(child)" v-if="getPanel(child)" :name="getPanel(child)" :type="child.Type"
                    :role="child.Role" :role-field="child.RoleField"></mx-panel>
                  <div class="button-group" v-show="false"></div>
                </div>
              </div>
              <div style="margin: 0px 20px" class="panel10 s4">
                <div style="text-align: center">
                  <mx-element type="Button" theme="Submit" title="全部保存" @click="handleSubmit"></mx-element>
                  <mx-element type="Button" theme="reflash" title="重置" @click="handleCancel"></mx-element>
                </div>
                <mx-element type="Message" style="margin-top: 10px" theme="Error" v-if="errors && errors.length > 0">
                  有{{ errors.length }}处错误，无法提交数据
                </mx-element>
              </div>
            </div>
          </div>
        </template>
      </mx-entity>
    </template>
  </mx-load-one>
</template>
<script>
import panels from "@/projects/panels/index";
export default {
  props: {
    config: Object,
    parent: Object,
    isChild: Boolean
  },
  data() {
    return {
      formkey: new Date().getTime(),
      pagekey: new Date().getTime(),
      errors: [],
      loading: {},
      deleted: null,
      visiabled: {},
      Meta: null,
      Data: null,
      Form: null,
      Children: null
    };
  },
  methods: {
    GetMainFormValue() {
      let data = this.$refs.main.GetValue({})
      if (this.Data && this.Data.Id) {
        data.Id = this.Data.Id
        data.Type = this.Data.Type
      }
      return data
    },
    getPanel(child) {
      let role = child.Role;
      let rolefield = child.RoleField;
      let name = (child.Type + ".Edit." + rolefield || role).replace(
        /\./g,
        "_"
      );
      if (panels[name]) return name;
      return "";
    },
    getChildLoadParams(child, data) {
      let params = child.Form.Columns.reduce((t, item) => {
        if (["Link", "Object", "Array"].indexOf(item.Type) > -1) {
          t["Get" + item.Name] = true;
        }
        return t;
      }, {});
      if (child.Form.AddForm && child.Form.AddForm.Columns) {
        params = child.Form.AddForm.Columns.reduce((t, item) => {
          if (["Link", "Object", "Array"].indexOf(item.Type) > -1) {
            t["Get" + item.Name] = true;
          }
          return t;
        }, params);
      }
      return Object.assign({ Parent: { Id: data.Id } }, params);
    },
    handlFormChange(val) {
      if (this.Children) {
        let visiabled = {};
        this.Children.forEach(c => {
          if (c.ParentState && !this.$matrix.Include(c.ParentState, val))
            visiabled[c.Name] = false;
          else visiabled[c.Name] = true;
        });
        this.visiabled = visiabled;
      }
    },
    CanSave(child) {
      return !this.$matrix.Models[child.Type].Config.NoSaveBySelf;
    },
    CanReset(child) {
      return false;
    },
    handleMetaChange(val, value) {
      let Config = this.$refs.entity.init(
        value,
        this.$matrix.GetUser(val.Role)
      );
      this.Form = Config.Form;
      this.Children = Config.Children;
      this.Data = value;
      this.Meta = val;
      this.$emit("meta-change", this.Meta);
      if (this.Children) {
        let visiabled = {};
        this.Children.forEach(c => {
          if (c.ParentState && !this.$matrix.Include(c.ParentState, this.Data))
            visiabled[c.Name] = false;
          else visiabled[c.Name] = true;
        });
        this.visiabled = visiabled;
      }
    },
    OnLoaded(child) {
      return function (value) {
        if (value.length === 0) {
          this.$nextTick(() => {
            this.deleted = Object.assign({}, this.deleted, {
              [child.Name]: false
            });
          });
        }
        return value;
      }.bind(this);
    },
    handleDelete(val, item) {
      this.deleted = Object.assign({}, this.deleted, {
        [item.Name]:
          this.deleted && this.deleted[item.Name] !== undefined
            ? !this.deleted[item.Name]
            : false
      });
    },
    handleLoading(val, item) {
      //console.log(item, val);
      this.loading[item.Name] = val;
    },
    handleSubmit() {
      this.$refs.entity
        .Submit(this.$refs, this.deleted, this.loading)
        .then(data => {
          this.$matrix
            .ShowConfirm("保存数据成功", "请选择继续新增，或者返回列表", {
              confirmButtonText: "返回列表",
              cancelButtonText: "继续编辑"
            })
            .then(res => {
              if (res) this.$matrix.Back(true);
              else {
                this.$matrix.RepareReload();
                this.pagekey = new Date().getTime();
              }
            });
        })
        .catch(e => {
          this.$matrix.ShowError(e);
          this.errors = e.items;
        });
    },
    handleSubmitChild(child) {
      this.$refs.entity
        .SubmitChild(this.$refs, child, this.deleted, this.loading)
        .then(data => {
          this.$refs.entity.ResetChild(child);
        })
        .catch(e => {
          this.$matrix.ShowError(e);
        });
    },
    handleCancelChild(child) {
      this.$refs.entity.ResetChild(child);
    },
    handleCancel() {
      this.pagekey = new Date().getTime();
      this.errors = null;
    }
  },
  created() { }
};
</script>
<style scoped>
.box {
  position: relative;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background-color: #fff;
  z-index: 999;
}

.vcenter {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  transform: translate(0, -50%);
  color: #000;
  font-weight: 700;
}

.button-group {
  text-align: center;
  margin: 10px;
  padding: 10px;
  background-color: #f7f7f7;
}
</style>
