<template>
  <component
    :is="Is"
    :title="title"
    :icon="icon"
    :name="name"
    :options="options"
    :value="value"
    @click="handleClick"
  />
</template>
<script>
import Default from './Default.vue'
import Menu from './Menu.vue'
export default {
    components: { Default, Menu },
    props: {
        title: String,
        icon: String,
        name: String,
        theme: String,
        options: Array,
        value: [String, Number]
    },
    computed: {
        Is() {
            switch ((this.theme || '').toLowerCase()) {
                case 'menu':
                    return 'Menu'
                default:
                    return 'Default'
            }
        }
    },
    methods: {
        handleClick(e) {
            this.$emit('click', e)
        }
    }
}
</script>
<style scoped>
.active {
    color: red;
}
</style>