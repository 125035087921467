<template>
  <div>
    <el-input-number
      style="width:100%"
      v-model="Data"
      clearable
      @change="OnChange"
      @clear="OnChange"
      @keyup.13.native="OnChange"
      :size="size||config.Size"
      :placeholder="'请输入' + (config.Title||'')"
      :maxlength="config.Length"
      :disabled="disabled ||config.Disabled"
      :controls-position="config.ControlsPosition||'right'"
      :min='config.Min'
      :max='config.Max'
      :precision='2'
    >
    </el-input-number>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(Number)],
};
</script>