<template>
  <div
    class="flex-row start"
    v-if="options && options.length > 0"
  >
    <Button
      v-for="(button, index) in options"
      :key="index"
      :name="button.Name"
      :theme="button.Theme"
      :icon="button.Icon"
      :title="button.Title"
      @click="handleClick(button, index)"
      :is-link="true"
      :class="value === index ? 'active' : ''"
    ></Button>
  </div>
</template>
<script>
import Button from '../Button'
export default {
    components: { Button },
    props: {
        title: String,
        icon: String,
        name: String,
        theme: String,
        options: Array,
        value: [String, Number]
    },
    methods: {
        handleClick(button, index) {
            if (index !== this.value) this.$emit('click', button)
        }
    }
}
</script>
<style scoped>
.active {
    color: red;
}
</style>