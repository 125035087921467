<template>
  <div :style='config.InnerStyle'>
    <div class="line2">
      {{ Label }} </div>
  </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
export default {
    mixins: [base.edit(Array)],
    computed: {
        LabelField() {
            if (this.config && this.config.Label) return this.config.Label
            if (this.config && this.config.Options && this.config.Options.Label)
                return this.config.Options.Label
            return 'Title'
        },
        Label() {
            if (this.value) {
                return (
                    this.value
                        .map((t) => {
                            return t[this.LabelField]
                        })
                        .filter((t) => {
                            return !!t
                        })
                        .join(',') || `${this.value.length}项`
                )
            }
            return ''
        }
    }
}
</script>