import Detail from "./detail";
import Edit from "./edit_selection";
import Category from "./edit_category";
import Transfer from "./edit_transfer";

export default {
  components: [
    { Name: "Detail", Component: Detail },
    { Name: "Edit", Component: Edit },
    { Name: "Category", Component: Category },
    { Name: "Transfer", Component: Transfer }

  ],
  Is () {
    if (this.edit) {
      if (this.config.Theme === "Transfer") return "Transfer"
      return this.config.Options &&
        this.config.Options.Type === "Category" &&
        this.config.Options.Level !== 1
        ? "Category"
        : "Edit";
    }
    return "Detail";
  }
}