<template>
  <div v-if="config.IsV" class="flex-column list-half">
    <div
      v-for="group in Groups"
      :key="group.Title"
      class="panel3"
      style="text-align: left; padding: 20px 20px 20px 40px; margin: 5px; width: calc(100% - 70px)"
    >
      <div class="flex-row middle start">
        <div style="font-weight: 700">
          {{ group.Title }}
        </div>
      </div>
      <div class="flex-row wrap start st">
        <hField2
          v-for="(col, index) in group.Columns"
          :config="config"
          :field="col"
          :value="value"
          :errors="Errors"
          @change="handleChange"
          ref="field"
          :key="col.Name"
          :showerr="showerr"
        >
        </hField2>
      </div>
    </div>
    <template v-for="(col, index) in Columns">
      <vField
        :config="config"
        :field="col"
        :value="value"
        :errors="Errors"
        @change="handleChange"
        ref="field"
        :key="'cell' + index"
        :showerr="showerr"
      ></vField>
    </template>
  </div>
  <div v-else class="flex-row wrap start st">
    <hField
      v-for="(col, index) in Columns"
      :config="config"
      :field="col"
      :value="value"
      :errors="Errors"
      @change="handleChange"
      ref="field"
      :key="'cell' + index"
      :showerr="showerr"
    >
    </hField>
    <template v-for="group in Groups">
      <div class="flex-row middle start" :key="group.Title">
        <div style="text-align: right; width: 380px" class="flex-row">
          <div class="flex:1"></div>
          <div style="padding: 10px 40px; background-color: #f0f6ff">
            {{ group.Title }}
          </div>
        </div>
        <div style="flex: 1"></div>
      </div>
      <hField
        v-for="(col, index) in group.Columns"
        :config="config"
        :field="col"
        :value="value"
        :errors="Errors"
        @change="handleChange"
        ref="field"
        :key="col.Name"
        :showerr="showerr"
      >
      </hField>
    </template>
  </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
import hField from './hField.vue'
import hField2 from './hField2.vue'
import vField from './vField.vue'
export default {
  mixins: [form.component],
  components: { hField, vField, hField2 },
  computed: {
    Columns() {
      let columns = this.columns.filter((c) => {
        return !c.Group
      })
      if (this.config.ColumnOrders) {
        let orders = this.config.ColumnOrders.split(',')
        let ordercolumns = []
        for (let t of orders) {
          let index = columns.findIndex((col) => {
            return col.Name === t || col.Title === t
          })
          if (index > -1) {
            ordercolumns.push(columns[index])
            columns.splice(index, 1)
          }
        }
        return ordercolumns.concat(columns)
      } else return columns
    },
    Groups() {
      return this.columns
        .reduce((groups, col) => {
          if (col.Group) {
            if (groups.indexOf(col.Group) === -1) {
              groups.push(col.Group)
            }
          }
          return groups
        }, [])
        .map((g) => {
          return {
            Title: g,
            Columns: this.columns.filter((c) => {
              return c.Group === g
            })
          }
        })
    }
  },
  methods: {
    handleChange2(val) {
      if (val.name && this.Errors) {
        this.Errors = this.Errors.filter((e) => {
          return e.name !== val.name
        })
      }
    },

    GetSelfValue() {
      return Object.assign({}, this.value, this.GetValue({}))
    }
  }
}
</script>
<style scoped>
.errorTitle {
  color: red;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
  height: 16px;
}

.title {
  word-break: keep-all;
  /* 不换行 */
  white-space: nowrap;
  /* 不换行 */
}

.panel10lr {
  padding: 2px 0px;
}

.box {
  flex: 1;
  text-align: left;
}

.box .mask {
  opacity: 0.5;
  background-color: #eee;
  padding: 10px;
}
</style>
