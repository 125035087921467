<template>
  <div class="flex-row start top wrap noselect" style="padding: 10px">
    <template v-if="Panel">
      <mx-panel
        :config="config"
        :value="GetActiveState(item)"
        :panel="Panel"
        v-for="(item,index) in options"
        :key="item.Id || index"
        :option="item"
      >
        <template slot="button">
          <a
            href="javascript:void(0)"
            @click="handleSelect(item)"
            v-if="!GetActiveState(item)"
            >填报</a
          >
          <template v-else>
            <a href="javascript:void(0)" @click="handleSelect(item)"
              >清除</a
            >
            <a
              href="javascript:void(0)"
              @click="handleSelect(item, true)"
              >修改</a
            >
          </template>
        </template>
      </mx-panel>
    </template>
    <el-tag
      v-else
      effect="dark"
      v-for="(item,index) in options"
      :key="item.Id || index"
      :type="GetActiveState(item) ? '' : 'danger'"
      @click="handleSelect(item)"
      :closable="!!GetActiveState(item)"
      style="margin: 5px"
    >
      <span style="font-size: 16px">{{ GetTitle(item) }}</span>
    </el-tag>
  </div>
</template>
<script>
import list from 'matrix.node.ui/master/list'
import panels from '@/projects/panels/index'
export default {
    mixins: [list.component, list.options],
    computed: {
        Panel() {
            let name = (this.config.Type + '.ListEdit').split('.').join('_')
            if (panels[name]) return name
            return ''
        }
    }
}
</script>
<style scoped>
.box {
  padding: 5px 12px;
  cursor: pointer;
  border: 1px solid red;
  margin: 0 0 10px 10px;
  color: #999;
}
</style>
