import DetailComponent from './Detail/index.vue';
import ChildComponent from './Child/index.vue';
import ModifyComponent from './Modify/index.vue';
import ListComponent from './List/index.vue';
import HomeComponent from './Home/index.vue';
import AddComponent from './Add/index.vue'
export default { 
    Name: "pages",
    Title: "页面组件",
    Value: [
        { Name: "Detail",Component: DetailComponent },
        { Name: "Child",Component: ChildComponent },
        { Name: "Modify",Component: ModifyComponent },
        { Name: "List",Component: ListComponent },
        { Name: "Home",Component: HomeComponent },
        { Name: "Add",Component: AddComponent }
    ]
}