<template>
  <div :key="timespan" :style="formStyle" v-if="current">
    <mx-element
      v-if="MasterClass"
      class="panel3 fixed"
      type="Title"
      theme="PageTitle"
      :title="MasterClass.Title + '的信息'"
    />
    <div class="flex-row start st" style="height: calc(100% - 64px)">
      <div class="panel3" style="width: 200px">
        <div
          v-for="(item2, i) in menus"
          :key="i"
          class="menu"
          :class="index === i ? 'menu_active' : ''"
          @click="OnClick(item2)"
        >
          {{ item2.Title }}
        </div>
      </div>
      <entity
        :style="contentStyle"
        v-if="current"
        :timespan="timespan"
        :type="current.Type"
        :config="current"
        :is-child="isChild"
        :parent="parent"
      />
      <div v-else :style="contentStyle" class="panel20">
        你的授权不足，无法使用该功能
      </div>
    </div>
    <keep-alive include="child">
      <router-view class="router-view"></router-view>
    </keep-alive>
  </div>
</template>
<script>
import master from "matrix.node.ui/page/master";
import entity from "./entity";
export default {
  mixins: [master("parent")],
  props: {},
  components: { entity },
  data() {
    return { index: 0, MasterClass: {} };
  },
  updated() {
    if (this.isChild !== this.ChildState) {
      this.ChildState = this.isChild;
    }
  },
  computed: {
    isSingleMenu() {
      return false;
    },
    user() {
      return this.$matrix.GetUser(this.master.Role);
    },
    contentStyle() {
      return {
        width: this.menus && !this.isSingleMenu ? "calc(100% - 200px)" : "100%"
      };
    },
    formStyle() {
      return { height: "100%" }; //this.$parent.$el.offsetHeight + "px"
    },
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    },
    menus() {
      let type = this.master.Type;
      let rolefield = "Parent";
      if (type) {
        let types = type.split(".");
        let UserRole = this.$matrix.GetUserRole(
          undefined,
          this.user,
          rolefield
        );
        types.pop();
        let Type = types.join(".");
        if (!Type) Type = type;
        this.MasterClass = this.$matrix.Models[Type];
        let len = Type.split(".").length;
        let result = [];
        Object.keys(this.$matrix.Models).forEach(key => {
          if (
            key.indexOf(Type + ".") === 0 &&
            key.split(".").length === len + 1
          ) {
            let entity = this.$matrix.Models[key];
            if (["Data"].indexOf(entity.Config.Node) > -1) {
              if (UserRole.Check(key, "query")) {
                result.push({
                  Type: key,
                  RoleField: rolefield,
                  key: key,
                  Title: entity.Title
                });
              } else {
              }
            }
          }
        });
        this.index = result.findIndex(t => {
          return t.key === type;
        });
        return result;
      }
      return [];
    },

    current() {
      return this.menus[this.index];
    }
  },

  methods: {
    OnClick(menu) {
      //console.log(menu);
      this.$matrix.Goto(`/parent?type=${menu.Type}&t=${new Date().getTime()}`);
    }
  }
};
</script>
<style scoped>
.item {
  cursor: pointer;
  min-width: 64px;
  font-size: 16px;
  text-align-last: justify;
}
.menu {
  cursor: pointer;

  padding: 10px;
  display: block;
  margin: 10px 0 0 0;
  text-align: left;
}
.menu:hover,
.menu_active {
  background-color: #f7f7f7;
  color: red;
}
.active {
  color: red;
  position: relative;
}
.active::before {
  content: " ";
  position: absolute;
  left: 10px;
  right: 10px;
  bottom: 0;
  height: 4px;
  z-index: 999;
  background-color: red;
}
.menu_title {
  text-align: center;
  padding: 5px;
  font-weight: 550;
  font-size: 14px;
  color: #777;
  position: relative;
  display: flex;
}
.menu_title::after {
  content: " ";
  margin-top: 8px;
  margin-left: 10px;
  flex: 1;
  height: 1px;
  background-color: #eee;
  z-index: 1;
}
.menu_title::before {
  content: " ";
  flex: 1;
  margin-top: 8px;
  margin-right: 10px;
  right: 0;
  height: 1px;
  background-color: #eee;
  z-index: 1;
}
</style>
