<template>
  <div class="flex-row middle  " style="background-color:#fff;margin-bottom:20px;">
    <div class="flex-row start middle left" style="width:100%;">
      <div class="title-label">{{ title }}</div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    icon: String
  }
};
</script>
<style scoped>
.title-label {
  position: relative;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
  width: 100%;
}

.title-label::before {
  content: ' ';
  position: absolute;
  left: 0;
  top: 10px;
  bottom: 10px;
  border-left: 4px solid #3377ff;
  border-radius: 4px;
}

.title-label::after {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px solid #f1f2f7;
  height: 1px;
}


.box {
  padding: 10px 10px;
  position: relative;

}
</style>
   