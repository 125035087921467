<template>
  <div style="width: 100%;text-align:left">
    <div class="flex-row start middle wrap">
      <el-upload
        v-show="showUpload && !uploading"
        :action="Url"
        :headers='Headers'
        list-type="text"
        :show-file-list="false"
        :on-success="onSuccess"
        :before-upload="onBeforeUpload"
        :disabled="disabled||config.Disabled"
      >
        <div
          :style="UploadImageStyle"
          class="flex-column center middle"
        >
          <span style="font-size:32px;color:#666">+</span>
        </div>
      </el-upload>
      <div
        v-show="uploading"
        :style="ProcessStyle"
        class="flex-column center middle"
      >
        <el-progress
          :stroke-width="16"
          :text-inside="true"
          :percentage="percentage"
          :color="config.Colors"
        ></el-progress>
      </div>
      <div
        v-for='(file,index) in list'
        :style="ImageStyle" @click="deleteImage(index)"
      >
        <el-image
          :src="getFullPath(file)"
          :style="ImageStyle"
          fit='cover'
        />
      </div>
    </div>

  </div>
</template>
<script>
import upload from "./upload.js";
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String), upload],
  data() {
    return { Files: [] };
  },
  computed: {
    IsMuilt() {
      return true;
    }
  },
  props: {
    value: String
  }
};
</script>
 