import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DetailPanel",
    ItemIs: "DetailPanelItem",
    Is: "DetailPanelFrame",
    Components: {
        DetailPanelFrame: Frame,
        DetailPanelItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({}, config.Config);
        if (
            this.pageview &&
            config.RoleField === this.pageview.RoleField &&
            config.Role === this.pageview.Role &&
            config.Type === this.pageview.Type
        ) {
            dataconfig.LoadType = "";
            dataconfig.Value = this.parent;
        } else {
            if (config.RoleField === "Self") {
                dataconfig.LoadType = "GetSelf";
            } else if (this.parentField && this.parent) {
                dataconfig.LoadType = "GetFirst";
            } else {
                //这种情况，是由其他值传入
                dataconfig.LoadType = "";
                dataconfig.Value = {};
            }
        }
        return dataconfig;
    }
}