import Vue from "vue";
import App from "./App";
import Router from "vue-router";
import ui from "matrix.node.ui";

Vue.use(Router);
Vue.use(ui);

import element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(element);

Vue.config.productionTip = false;
import router from "./router";
Vue.initApp(Vue, router).then(context => {
  new Vue({
    el: "#app",
    router,
    store: context.store,
    render: h=>h(App), 
  }).$mount("#app")
})
