<template>
  <div>
    <div v-if="config.detail && config.detail.length > 0">
      <el-link
        type="primary"
        :underline="false"
        @click="handleClick(config.detail[0])"
      >
        <span class="line2" :title="Title"> {{ Title }}</span>
      </el-link>
    </div>
    <div v-else class="line2" :title="Title">
      {{ Title }}
    </div>
    <div class="flex-row start middle wrap">
      <el-link
        class="item line1"
        type="info"
        :underline="false"
        v-for="(item, index) in config.detail"
        :key="index + '_detail'"
        @click="handleClick(item)"
        v-show="index !== 0"
      >
        {{ item.Title }}
      </el-link>
      <template v-for="(item, index) in config.children">
        <el-link
          v-if="index <= 2"
          class="item line1"
          type="info"
          :underline="false"
          :key="index + '_children'"
          @click="handleClick(item)"
          :title="item.Title"
        >
          {{ item.Title }}
        </el-link>
      </template>
      <el-dropdown
        v-if="config.children.length > 2"
        trigger="click"
        @command="handleCommand"
      >
        <span style="padding-top: 4px" class="icon"
          ><i class="el-icon-more-outline"></i
        ></span>
        <el-dropdown-menu slot="dropdown">
          <template v-for="(item, index) in config.children">
            <el-dropdown-item
              :key="index + '_children'"
              v-if="index > 2"
              :command="item"
            >
              {{ item.Title }}</el-dropdown-item
            ></template
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { LabelField: "Title" };
      }
    }
  },
  computed: {
    Title() {
      let title = this.config.Data[this.config.Column.Name];
      if (typeof title === "object") {
        if (
          this.config.Column &&
          this.config.Column.Options &&
          this.config.Column.Options.Label
        ) {
          return title[this.config.Column.Options.Label];
        } else if (this.config.Column.LabelField) {
          return title[this.config.Column.LabelField];
        } else {
          return title["Title"] || title["Name"] || title["NickName"];
        }
      }
      return title;
    }
  },
  methods: {
    handleClick(item) {
      this.$emit("click", item);
    },
    handleCommand(item) {
      this.$emit("click", item);
    }
  }
};
</script>
<style scoped>
.item {
  margin: 5px 5px 0 0;
}
.icon {
  cursor: pointer;
}
</style>
