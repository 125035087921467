<template>
  <div class="panel10 flex-row middle">
    <div class="flex-row start middle noselect borderend">
      <div style="width: 42px">
        <i class="el-icon-s-unfold" style="font-size: 24px"></i>
      </div>
      <div class="scrollbox-item">{{ title }}</div>
      <slot></slot>
    </div>
    <div style="margin-right:20px">
      <slot name='button'></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    icon: String
  }
}
</script>
<style scoped>
.scrollbox-item {
  word-break: keep-all;
  font-size: 18px;
  font-weight: 550;
  padding: 12px 5px;
  cursor: pointer;
  color: red;
}
</style>