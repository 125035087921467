<template>
  <div :class="IsDark ? 'Dark panel3' : 'panel3'">
    <div :id="'charts' + Id" style="min-height: 296px"></div>
    <mx-column-data
      :config="config"
      :dataconfig="dataconfig"
      @change="handleChange"
    ></mx-column-data>
  </div>
</template>
<script>
import pie from "./echartcolumn";
import threed from "./echart3d";
import echat from "./echart.js";
export default {
  components: {},
  props: {
    config: Object,
    index: Number,
    dataconfig: Object
  },
  mixins: [echat],
  computed: {
    IsDark() {
      return this.$matrix.Config.IsDark || false;
    },
    ChartType() { 
      return this.config.ChartType || "default";
    },
  },
  data() {
    return { List: null, Data: null };
  },

  methods: {
    create(category, data) { 
      switch (this.ChartType) {
        case "3d":
          return threed.create.call(this, category, data, this.config);
        case "default":
          return pie.create.call(this, category, data, this.config);
      }
    },
    handleChange(data) {
      let groupfield = data.GroupField[0] || "";
      let foo = this.$matrix.GetTitleFun(groupfield, this.config.Type);
      this.show(
        data.Data.map(d => {
          return foo(d);
        }),
        data.Data.map(d => {
          return d[data.Fields];
        })
      );
    }
  }
};
</script>
﻿﻿﻿<style scoped>
.Dark.panel3 {
  background: #0b2847;
}
</style>
