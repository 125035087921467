<template>
    <div name="ms-app" v-if="CurrentMeta" :key="CurrentMeta.Id + ApplicationKey" v-loading="Posting">
        <template v-if="!ApplicationState">
            <div class="flex-row top">
                <Menu :style="MenuStyle" name="menu" />
                <ScrollBox :style="ContentStyle" name="content" class="mx-content">
                    <router-view />
                </ScrollBox>
                <Dialog v-if="ModelDialog" :config="ModelDialog" :key="ModelDialog.Id"></Dialog>
                <Dialog v-if="ModelDialog2" :config="ModelDialog2" :key="ModelDialog2.Id" :number="2"></Dialog>
                <Import v-if="ImportDialog" :config="ImportDialog" :key="ImportDialog.Type"></Import>
                <Selection v-if="SelectionDialog" :config="SelectionDialog" :key="SelectionDialog.Id"></Selection>
                <template v-for="(dc, index) in DetailConfig">
                    <Drawer :show="true" :config="dc" :key="dc && dc.Key"></Drawer>
                </template>
            </div>
        </template>
        <template v-if="ApplicationState === 'Login'">
            <Login style="width: 100vw; height: 100vh; overflow: hidden" />
        </template>
        <template v-if="ApplicationState === 'ModifyPassword'">
            <ModifyPassword style="width: 100vw; height: 100vh; overflow: hidden" />
        </template>
    </div>
</template>
<script>
import Menu from './Menu'
import ScrollBox from './ScrollBox'
import Login from './Login'
import Dialog from './Dialog'
import Import from './Import'
import Drawer from './Drawer'
import Selection from "./Selection"
import ModifyPassword from './ModifyPassword'
export default {
    components: {
        Menu,
        ScrollBox,
        Login,
        Dialog,
        Drawer,
        Import,
        Selection,
        ModifyPassword
    },
    props: {},
    watch: {
        CurrentMeta(val) {
            this.$router.replace('/home?timespan=' + new Date().getTime())
        },
        ChangeUrl(val) {
            if (val) {
                val.router = this.$router
                this.$store.commit('UrlChanged', val)
            }
        }
    },
    computed: {
        SelectionDialog() {

            return this.$store.state.matrix.SelectionDialog
        },
        ImportDialog() {
            return this.$store.state.matrix.ImportDialog
        },
        ModelDialog() {
            return this.$store.state.matrix.ModelDialog
        },
        ModelDialog2() {
            return this.$store.state.matrix.ModelDialog2
        },
        DetailConfig() {
            return this.$store.state.matrix.DetailConfig

        },
        DetailShow() {
            return this.DetailConfig
        },
        Posting() {
            return this.$store.state.matrix.IsPosting
        },
        ChangeUrl(val) {
            return this.$store.state.matrix.ChangeUrl
        },
        ApplicationState() {
            return this.$store.state.matrix.ApplicationState
        },
        ApplicationKey() {
            return this.$store.state.matrix.ApplicationKey
        },
        CurrentMeta() {
            return this.$store.state.matrix.CurrentMeta
        },
        CurrentPage() {
            return {
                ShowMenu: true,
                MenuWidth: 120,
                MenuSpanWidth: 1,
                ShowFoot: true,
                FootHeight: 0,
                ShowBread: false,
                BreadHeight: 0,
                ShowNav: true,
                NavHeight: 0
            }
        },
        Config() {
            let config = {
                navHeight: this.CurrentPage.ShowNav
                    ? this.CurrentPage.NavHeight
                    : 0,
                menuWidth: this.CurrentPage.ShowMenu
                    ? this.CurrentPage.MenuWidth
                    : 0,
                menuSpanWidth: this.CurrentPage.ShowMenu
                    ? this.CurrentPage.MenuSpanWidth
                    : 0,
                footHeight: this.CurrentPage.ShowFoot
                    ? this.CurrentPage.FootHeight
                    : 0,
                appwidth: '100vw'
            }
            return config
        },

        ViewStyle() {
            return {
                minHeight:
                    'calc(100vh - ' +
                    (this.Config.navHeight + this.Config.footHeight) +
                    'px)',
                width:
                    'calc(' +
                    this.Config.appwidth +
                    ' - ' +
                    (this.Config.menuWidth + this.Config.menuSpanWidth) +
                    'px)',
                overflowX: 'auto',
                marginLeft: this.Config.menuSpanWidth + 'px'
            }
        },
        ContentStyle() {
            return {
                flex: 1,
                height: 'calc(100vh - ' + this.Config.navHeight + 'px)',
                overflow: 'auto'
            }
        },
        NavStyle() {
            return { height: this.Config.navHeight + 'px', overflow: 'hidden' }
        },
        MenuStyle() {
            let style = {
                width: this.Config.menuWidth + 'px',
                height: 'calc(100vh - ' + this.Config.navHeight + 'px)',
                overflowY: 'auto',
                overflowX: 'hidden'
            }
            return style
        },
        FootStyle() {
            return { height: this.Config.footHeight + 'px', overflow: 'hidden' }
        }
    }
}
</script>

<style scoped>
.mx-content {
    padding: 0;
}

.mx-content::-webkit-scrollbar {
    width: 5px;
    height: 1px;
}

.mx-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
}

.mx-content::-webkit-scrollbar-track {
    border-radius: 10px;
}
</style>
