import Detail from "./detail";
import Edit from "./edit";
export default {
    components: [
        { Name: "Detail", Component: Detail },
        { Name: "Edit", Component: Edit }
    ],
    Is() {
        if (this.edit) {
            return "Edit";
        }
        return "Detail";
    }
}