<template>
  <div v-if="options && options.length > 0">
    <div
      v-for="(button, index) in options"
      :key="index"
      @click="handleClick(button, index)"
      :class="value === index ? 'active' : 'item'"
      class="panel"
    >
      <div
        class="line1"
        style="padding:10px"
      > {{button.Title}}</div>
    </div>
  </div>
</template>
<script>
import Button from '../Button'
export default {
    components: { Button },
    props: {
        title: String,
        icon: String,
        name: String,
        theme: String,
        options: Array,
        value: [String, Number]
    },
    methods: {
        handleClick(button, index) {
            if (index !== this.value) this.$emit('click', button)
        }
    }
}
</script>
<style scoped>
.active {
    color: #fff;
    background-color: #37437f;
}
.item {
    cursor: pointer;
}
</style>