<template>
  <div class='rel'>
    <div class='input' v-if='!Selected' @click="onShowTransfer">请选择{{config.Title}}</div>
    <div class='input' v-else @click="onShowTransfer">{{Selected}}</div>
    <template v-if='show'>
      <div class='transfer'>
        <div class='flex-row top transfer-left'>
          <div class='wf'>
            <div class='flex-row f14'>请选择</div>
            <div>
              <div>
                <el-tree :data="TreeList" show-checkbox node-key="id" :default-expanded-keys="[0, 1]" :props="defaultProps" @check="currentChecked"
                  style="font-size: 18px" :filter-node-method="filterNode" ref="tree">
                </el-tree>

              </div>
            </div>
          </div>
          <div class='' style="width:100px;padding-top:100px;">
            <div class='flex-column list-half'>
              <div class='f14  pointer'>
                <el-tag size="medium" @click="OnJoin">加入 <i class="el-icon-arrow-right" style="font-size:14px;"></i></el-tag>
              </div>
              <div class='f14 pointer'>
                <el-tag size="medium" @click="OnRemove"> <i class="el-icon-arrow-left" style="font-size:14px;"></i> 移除</el-tag>
              </div>
            </div>
          </div>
          <div class='wf'>
            <div class='flex-row start middle'>
              <div class='wf f14'>已加入待选择</div>
              <div class='f14'>{{ValueList.length}}个</div>
            </div>
            <div>
              <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange" style="text-align: left">全选
              </el-checkbox>
              <el-checkbox-group v-model="SelectRight" class="flex-column left list-half" style="font-size: 18px">
                <el-checkbox v-for="(item, index) in ValueList" :label="item" :value="index" :key="item.Id" style="font-size: 18px">
                  {{ item.Title || item.NickName }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <div @click="onClose" class='flex-row center' style="padding-top:10px;border-top:1px solid #dcdfe6">
          <el-button type="primary" size="mini">确定</el-button>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
import panels from '@/projects/panels/index'
let emptyStrHanlder = function () {
  return undefined
}
let dataHandler = function (data) {
  return data
}
export default {
  data () {
    return { DataOptions: null }
  },
  mixins: [base.edit(Array, emptyStrHanlder, emptyStrHanlder, dataHandler)],
  props: {
    isEnd: Boolean,
    isLoading: Boolean,
    options: Array
  },
  data () {
    return {
      Selected: null,
      show: false,
      filterText: "",

      defaultProps: {
        children: "children",
        label: "Title",
      },
      checkAll: false,
      isIndeterminate: false,
      fullscreenLoading: false,
      SelectRight: [],
      ConfigIds: [],
    }
  },
  computed: {
    TreeList () {
      let filter = (t) => {
        let res = !this.DataId || t && this.DataId.findIndex(id => { return t.Id == id }) === -1
        if (!res) return res
        if (this.config.DisableField) {
          let value = t[this.config.DisableField]
          if (value !== undefined) {
            let method = this.config.DisableMethod || '='
            let data = this.config.DisableValue || 0
            switch (method) {
              case "=":
                res = !(value === data)
                break;
              case ">":
                res = !(value > data)
                break;
              case ">=":
                res = !(value >= data)
                break;
              case "<":
                res = !(value < data)
                break;
              case "<=":
                res = !(value <= data)
                break;
            }
          }
        }
        return res;
      }
      if (this.Options) {
        if (this.config.TreeField) {
          let treefield = this.$matrix.Models[this.config.Options.Entity].Fields.find(t => { return t.Name === this.config.TreeField })
          return this.Options.filter(t => {
            return filter(t)
          }).reduce((res, t) => {
            let root = res.find(r => { return r.Id === t[treefield.Name] })
            if (!root) {
              root = { Title: "", Id: t[treefield.Name], children: [], IsNode: true }
              let op = treefield.Options.find(op => { return op.Id === t[treefield.Name] })
              root.Title = op.Title
              res.push(root)
            }
            root.children.push({ Id: t.Id, Title: t[this.LabelField], Data: t })
            return res
          }, [])

        } else {
          return this.Options.filter(t => {
            return filter(t)
          }).map(t => {
            return { Id: t.Id, Title: t[this.LabelField], Data: t }
          })
        }
      }
    },
    ValueList () {
      if (this.Options) {
        return this.Options.filter(t => {
          return this.DataId && t && this.DataId.findIndex(id => { return t.Id == id }) > -1
        }).map(t => {
          return { Id: t.Id, Title: t[this.LabelField], Data: t }
        })
      }
    },
    Panel () {
      let name = (this.config.Link + '.Field').replace(/\./g, '_')
      if (panels[name]) return name
      return ''
    },
    DataId () {

      let v =
        this.Data && this.Data.map
          ? this.Data.map((t) => {
            if (t) {
              if (t.Id) return t.Id
              return t
            }
          }).filter((t) => {
            return !!t
          })
          : []
      if (v.length === 0) return
      return v
    },
    LabelField () {
      if (this.config && this.config.LabelField)
        return this.config.LabelField
      if (this.config && this.config.Options && this.config.Options.Label)
        return this.config.Options.Label
      return 'Title'
    },
    ParentField () {
      if (
        this.config &&
        this.config.Options &&
        this.config.Options.ParentField
      )
        return this.config.Options.ParentField
      return 'Parent'
    },
    Label () {
      if (this.value) {
        return this.value[this.LabelField]
      }
      return ''
    },
    Propertys () {
      return {
        label: this.LabelField,
        value: 'Id',
        checkStrictly: this.CheckStrictly,
        emitPath: false,
        multiple: true
      }
    },
    CheckStrictly () {
      return (
        this.config.CheckStrictly ||
        (this.config.Name === 'Parent' &&
          this.config.Link === this.config.Options.Entity)
      )
    },
    Options () {
      let result = []
      let selections = this.options || this.selections || []
      if (
        this.value &&
        this.value.Id &&
        (!selections ||
          !selections.find((t) => {
            return t.Id === this.value.Id
          }))
      ) {
        result = result.concat([this.value])
      }
      if (selections && selections.length > 0) {
        result = result.concat(selections)
        let getParent = (p) => {
          return p && p.Id === undefined ? p : p.Id
        }
        let findchild = (pid) => {
          let child = result
            .filter((r) => {
              return (
                (r[this.ParentField] &&
                  getParent(r[this.ParentField]) === pid) ||
                (pid === 0 && !r[this.ParentField])
              )
            })
            .map((c) => {
              let children = findchild(c.Id)
              if (children && children.length > 0)
                c.children = children
              return c
            })
          return child
        }
        let categroys = findchild(0)
        if (categroys && categroys.length === 0 && result.length)
          return result
        return categroys
      } else {
        return result
      }
    }
  },
  methods: {
    OnLoad () {
      let selections = this.selections || this.options || []
      if (!selections || selections.length === 0) {
        let fields = ['Id']
        fields.push(this.LabelField)
        if (this.config.Options.Level > 1 && this.ParentField)
          fields.push(this.ParentField)
        if (this.Panel) {
          fields = []
        }
        let LookValue = null
        let getParentValue = () => {
          let data = {}
          let selffoo = this.$matrix.FindParentFunction(
            this,
            'GetSelfValue'
          )
          if (selffoo) {
            data = selffoo()
          }
          if (!data.Parent || !data.Parent.Id) {
            let parentfoo = this.$matrix.FindParentFunction(
              this,
              'GetMainFormValue'
            )
            if (parentfoo) {
              data.Parent = parentfoo()
            }
          }
          return data
        }
        if (this.config.TreeField) fields.push(this.config.TreeField)
        if (
          this.config &&
          this.config.LookUp &&
          this.config.LookUp.length > 0
        ) {

          LookValue = this.$matrix.Put(
            getParentValue(),
            this.config.LookUp
          )
          let errors = this.$matrix.Check(
            LookValue,
            this.config.LookUp
          )
          if (errors) {
            console.error(errors)
            return
          }
        }
        let context = this.Context
        if (
          context &&
          context.RoleField === this.config.RoleField &&
          context.RoleFieldData
        ) {
          LookValue = LookValue || {}
          LookValue[context.RoleField] = context.RoleFieldData
        }
        let LoadParams = this.config.LoadParams
        if (LoadParams) {
          LoadParams = JSON.parse(JSON.stringify(LoadParams))
          if (LoadParams.Mix) {
            LoadParams.Mix = LoadParams.Mix.map(m => {
              if (m.LookUp) {
                m.Params = this.$matrix.Put(
                  getParentValue(),
                  m.LookUp
                )
                m.LookUp = undefined
              }
              return m
            })
          }
        }
        this.$emit('load', {
          type: 'LoadSelections',
          params: Object.assign(
            {
              Page: 1,
              PageSize: 0,
              Fields: fields,
              ['Get' + this.ParentField]: false
            },
            this.config.Options.Params,
            LoadParams,
            LookValue
          ),
          role: this.config.Role,
          rolefield: this.config.RoleField
        })
      }
    },
    OnInputObject (val) {
      let selections = this.selections || this.options || []
      if (typeof val === 'number') {
        let id = val
        let op = selections.find((t) => {
          return t.Id === id
        })
        this.Data = [op]
        this.OnChange()
      } else if (val) {
        if (val.length > 0) {
          this.Data = selections.filter((t) => {
            return val.indexOf(t.Id) > -1
          })
        } else {
          this.Data = []
        }
        this.OnChange()
      } else {
        this.Data = []
        this.OnChange()
      }
    },
    onShowTransfer () {
      this.show = true
      if (!this.Options || this.Options.length === 0) this.OnLoad()
    },
    onClose () {
      this.show = false
      if (this.SelectRight.length > 0) {
        this.Selected = this.SelectRight.map(item => { return item.Title })[0] + "等"
      } else {
        this.Selected = null
      }
    },
    handleCheckAllChange (val) {
      if (val) {
        this.SelectRight = this.ValueList;
      } else {
        this.SelectRight = []
      }
      this.isIndeterminate = false;
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.Title.indexOf(value) !== -1;
    },
    OnJoin () {
      this.Data = (this.Data || []).concat(this.ConfigIds)
      this.OnChange()
    },
    OnRemove () {
      if (this.SelectRight && this.SelectRight.length) {
        this.Data = this.Data.filter(d => {
          return !this.SelectRight.find(t => { return t.Id === d })
        })
        this.OnChange()
      }
    },
    currentChecked (nodeObj, SelectedObj) {
      this.ConfigIds = SelectedObj.checkedNodes.filter(t => {
        return !t.IsNode
      }).map((item) => {

        return item.Id;
      });
    },
  },
  created () {
    if (this.value && this.value.length > 0) {
      this.OnLoad()

    }
  }
}
</script>
<style scoped>
.rel {
  position: relative;
}
.input {
  border: 1px solid #dcdfe6;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}
.transfer {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-top: 0;
  padding: 10px;
  height: 300px;
}
.wf {
  flex: 1;
}
.transfer-left {
  height: calc(100% - 40px);
  overflow-y: scroll;
}
.pointer {
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 4px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(053, 057, 071, 0.3);
  width: 6px;
  height: 6px;
  -webkit-border-radius: 4px;
}
</style>