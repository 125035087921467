<template>
	<div :style='RadioStyle' :class='RadioClass'>
		<el-radio-group v-model="Data" :size="size || config.Size" @change="OnChange" :disabled="disabled || config.Disabled" style='text-align:left'>
			<component :is="config.RadioType||'el-radio-button' " :border='config.Border' :size="size || config.Size" v-for="item in Options"
				:label="item.Id" :key="item.Id" :value="item.Id">{{ item.Title }}</component>
		</el-radio-group>
	</div>
</template>
<script>
import base from "matrix.node.ui/field.js";
const emptyStrHanlder = function (params) {
	return;
};
export default {
	mixins: [base.edit(Number, emptyStrHanlder)],
	computed: {
		Text () {
			let selectItem = this.Options.find(t => {
				return t.Id === this.Data;
			});
			return selectItem ? selectItem.Title : "";
		},
		Options () {
			return this.config.Options || [];
		},
		RadioStyle () {
			if (this.config.RadioType) {
				return {
					height: "32px",
					lineHeight: '32px'
				}
			}
		},
		RadioClass () {
			return this.config.RadioClass || ""
		}
	}
};
</script>
<style scoped>
/deep/.f16 .el-radio-group .el-radio .el-radio__label {
	font-size: 16px !important;
}
</style>