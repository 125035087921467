<template>
  <component
    :is="Is"
    v-if="Is"
    ref="form"
    :value="value"
    :config="config"
    :columns="columns"
    @change="handleChange"
    :empty="true"
    :clear-empty-string="true"
  >
    <slot name="button" slot="button"></slot>
  </component>
</template>
<script>
import form from "matrix.node.ui/master/form";
import DefaultLogin from "./default";
import RoundLogin from "./round";
export default {
  mixins: [form.container],
  components: { DefaultLogin, RoundLogin },
  props: {
    theme: String,
  },
  computed: {
    Is() {
      switch ((this.theme || "").toLowerCase()) {
        case "round":
          return "RoundLogin";
        default:
          return "DefaultLogin";
      }
    },
  },
  methods: {},
};
</script>
