<template>
	<div class="box">
		<div v-if="options && options" class="flex-row start wrap st image-box">
			<div v-for="(item, index) in options" :key="item.Id || index" class="file-box">
				<div class="file">
					<el-upload :multiple="false" :accept="Exts" :action="Url" :headers="Headers" list-type="text" :show-file-list="false"
						:on-success="onSuccess(item)" :before-upload="onBeforeUpload(item, index)">
						<template v-if="!GetActiveState(item)">
							<div class="file-empty"></div>
							<div class="flex-row start" style="margin-top: 20px">
								<div style="flex: 1" class="line1">
									上传{{ GetTitle(item) }}
								</div>
							</div>
						</template>
						<template v-else>
							<div class="file-upload rel">
								<el-image v-if="Data[index]" style="width: 100%; height: 100%" fit="none" :src="$matrix.Config.ImageSite + Data[index][UpLoad.Name]">
								</el-image>
								<div v-if="GetActiveState(item) && !uploading" class="tool-bar flex-row">
									<span v-if="IsEdit" type="text" class="btn" size="large" @click="handleEdit(item)"><i class="el-icon-edit"></i></span>
									<span type="text" class="btn" size="large" @click="handleDelete(item)"><i class="el-icon-delete"></i></span>
									<span type="text" class="btn" size="large" @click="handleDownload(item)"><i class="el-icon-download"></i></span>
									<span type="text" class="btn" size="large" @click="handlePreview(item)"><i class="el-icon-view"></i></span>
								</div>
							</div>
							<div class="flex-row start" style="margin-top: 20px">
								<div style="flex: 1" class="line1">
									{{ GetTitle(item) }}
								</div>
							</div>
						</template>
					</el-upload>
					<div style="height: 16px; margin-top: 10px" class="noselect" v-show="uploading && index === currentIndex">
						<el-progress :stroke-width="16" :text-inside="true" :percentage="percentage" v-show="uploading && index === currentIndex"></el-progress>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="flex-row start wrap st image-box">
			<div class="file-box">
				<div class="file">
					<el-upload :multiple="false" :accept="Exts" :action="Url" :headers="Headers" list-type="text" :show-file-list="false"
						:on-success="onSuccessData" :before-upload="onBeforeUploadData">
						<template>
							<div>
								<i class='el-icon-plus' style="font-size:24px;"></i>
							</div>
						</template>
					</el-upload>
					<div style="height: 16px; margin-top: 10px" class="noselect">
						<el-progress :stroke-width="16" :text-inside="true" :percentage="percentage" v-show="uploading">
						</el-progress>
					</div>
				</div>
			</div>
			<div v-for="(item, index) in Data" :key="item.Id || index" class="file-border" v-show="!item._delete">
				<div class="image-upload">
					<el-image :src="$matrix.Config.ImageSite + item[UpLoad.Name]" :preview-src-list="[$matrix.Config.ImageSite + item[UpLoad.Name]]">
					</el-image>
					<template v-if="!uploading">
						<i class='el-icon-error'  @click="handleDeleteData(item, index)"></i>
					</template>
				</div>
				<div class="flex-row start" style="margin-top: 20px;display:none">
					<div style="flex: 1" class="line1">
						{{ GetTitleByColumn(item) }}
					</div>
				</div>
				<div style="height: 16px; margin-top: 10px;display:none" class="noselect">
					<div v-if="!uploading">
						<span v-if="IsEdit" type="text" class="btn" size="mini" @click="handleEditData(item, index)"><i class="el-icon-edit"></i>编辑</span>
						<span type="text" class="btn" size="mini" @click="handleDeleteData(item, index)"><i class="el-icon-delete"></i>删除</span>
					</div>
				</div>
			</div>
		</div>
		<div class="info-box" v-if="UpLoad">
			<p v-if="UpLoad.Exts">* 图片格式支持 {{ UpLoad.Exts.join(", ") }}</p>
			<p v-if='parseInt(UpLoad.ImageHeight)'>
				* 图片高宽最好为 {{ parseInt(UpLoad.ImageHeight) || 720 }} :
				{{ parseInt(UpLoad.ImageWidth) || 480 }}
			</p>
			<p>* 图片大小不能超过 {{ MaxSize }}{{ BackStyle }}</p>
			<p v-if="Entity && Entity.Config.MinSize">
				* 至少需要上传 {{ Entity.Config.MinSize }} 张图片
			</p>
			<p v-if="Entity && Entity.Config.MaxSize">
				* 图片数不能超过 {{ Entity.Config.MaxSize }} 张
			</p>
			<p>
				* 点击图片可以预览大图
			</p>
		</div>
	</div>
</template>
<script>
import list from "matrix.node.ui/master/list";
export default {
	mixins: [list.component, list.options],
	props: {
		config: Object,
		value: Array,
		columns: Array,
		theme: String,
	},
	data () {
		return {
			uploading: false,
			showUpload: true,
			percentage: 0,
			currentIndex: -1,
			time: new Date().getTime(),
		};
	},
	computed: {
		MaxSize () {
			if (this.UpLoad) {
				let mx = this.UpLoad.MaxSize
				let unit = (this.UpLoad.SizeUnit || 'Kb')
				return mx + unit
			}
		},
		Entity () {
			return this.$matrix.Models[this.config.Type];
		},

		BackStyle () {
			return this.UpLoad.BackStyle;
		},
		IsEdit () {
			return (
				this.config.Form.Columns.filter((c) => {
					return !c.NoShow && c.Name !== this.UpLoad.Name;
				}).length > 0
			);
		},
		Exts () {
			return (this.UpLoad.Exts || ["bmp", "png", "gif", "jpg", "jepg"])
				.map((e) => {
					if (e[0] === ".") return e;
					return "." + e;
				})
				.join(",");
		},
		UpLoad () {
			return this.config.Upload;
		},
		Url () {
			return this.$matrix.Config.ImageSite + "/invoke";
		},
		Headers () {
			if (this.time) {
				let Methods = (
					this.UpLoad.Method || this.UpLoad.Type + ".Upload"
				).split(".");
				let rolefield =
					this.UpLoad.RoleField ||
					this.UpLoad.Role ||
					this.$matrix.GetCurrentRoleName();

				if (
					this.UpLoad.RoleFields &&
					this.UpLoad.RoleFields[rolefield] !== undefined
				) {
					rolefield = this.UpLoad.RoleFields[rolefield];
				}
				let head = Methods.shift();
				Methods.unshift(rolefield);
				Methods.unshift(head);
				return {
					Authorization: this.$matrix.CreateHeader(
						Methods.join("."),
						this.UpLoad.Role || this.$matrix.GetCurrentRoleName(),
						rolefield
					),
				};
			}
		},
	},
	methods: {
		handleDelete (item) {
			if (item) {
				let data = this.GetActiveState(item);
				if (data) {
					data._delete = true;
				}
				this.Data = [].concat(this.Data);
			}
		},

		handleDeleteData (item, index) {
			let data = this.Data[index];
			if (data) {
				data._delete = true;
			}
			this.Data = [].concat(this.Data);
		},
		handleEdit (item) {
			if (item) {
				let data = this.GetActiveState(item);
				if (data) {
					this.$parent.ModifyByDialog(data).then(res => {
						if (res) {
							data = Object.assign(data, res);
							this.Data = [].concat(this.Data);
						}
					});
				}
			}
		},
		handleEditData (item, index) {
			this.$parent.ModifyByDialog(item).then(res => {
				if (res) {
					this.Data[index] = res;
					this.Data[index] = Object.assign(item, res);
					this.Data = [].concat(this.Data);
				}
			});
		},
		handleDownload (item) {
			let data = this.GetActiveState(item);
			if (data) {
				this.$matrix.DownloadFile(
					this.$matrix.Config.Site + data[this.UpLoad.Name],
					this.GetTitle(item)
				);
			}
		},
		handleDownloadData (data) {
			if (data) {
				this.$matrix.DownloadFile(
					this.$matrix.Config.Site + data[this.UpLoad.Name],
					this.GetTitleByColumn(data)
				);
			}
		},
		onSuccessData (res) {
			this.time = new Date().getTime();
			this.uploading = false;
			if (res.Decrypt) {
				res = this.$matrix.Decrypt(res.Decrypt);
			}
			if (res.Error) return this.$message.error("上传文件错误:" + res.Error);
			if (res.url) {
				let data = this.config.Form.CreateNew();
				data[this.UpLoad.Name] = res.url;
				if (this.IsEdit) {
					this.$parent.ModifyByDialog(data).then((res) => {
						this.Data.push(res);
						this.Data = [].concat(this.Data);
					});
				} else {
					this.Data.push(data);
					this.Data = [].concat(this.Data);
				}
			}
		},
		onSuccess (item) {
			return function (res) {
				this.time = new Date().getTime();
				this.uploading = false;
				if (res.Decrypt) {
					res = this.$matrix.Decrypt(res.Decrypt);
				}
				if (res.Error) return this.$message.error("上传文件错误:" + res.Error);
				if (res.url) {
					if (item) {
						let data = this.GetActiveState(item);
						if (data) {
							data[this.UpLoad.Name] = res.url;
							this.Data = [].concat(this.Data);
						} else {
							data = this.config.Form.CreateNew();
							data[this.UpLoad.Name] = res.url;
							data = this.SetEnumField(item, data, this.config);
							this.Data.push(data);
							this.Data = [].concat(this.Data);
						}
					} else {
						data = this.config.Form.CreateNew();
						data[this.UpLoad.Name] = res.url;
						if (this.IsEdit) {
							this.$parent.ModifyByDialog(data).then((res) => {
								this.Data.push(res);
								this.Data = [].concat(this.Data);
							});
						} else {
							this.Data.push(data);
							this.Data = [].concat(this.Data);
						}
					}
				}
			}.bind(this);
		},
		onBeforeUploadData (file) {
			if (this.uploading) return false

			if (this.UpLoad.MaxSize > 0) {
				let mx = this.UpLoad.MaxSize
				let unit = this.UpLoad.SizeUnit || 'k'
				if (unit.indexOf('k') === 0) {
					mx = mx * 1024
				} else if (unit.indexOf('m') === 0) {
					mx = mx * 1024 * 1024
				}
				if (file.size / 1024 > mx) {
					this.$matrix.ShowError(
						'文件大小不能超过' + mx + unit.toUpperCase() + 'b!'
					)
					return false
				}
			}
			if (file.name) {
				let extnames = file.name.split(".");
				if (extnames.length > 1) {
					let ext = (extnames[extnames.length - 1] || "").toLowerCase();

					if (this.Exts.indexOf("." + ext) > -1) {
						this.uploading = true;
						this.percentage = 0;
						return true;
					}
				}
			}
			this.$matrix.ShowError("无法识别上传文件格式!");
			return false;
		},
		onBeforeUpload (item, index) {
			return function (file) {
				this.currentIndex = index;
				if (this.uploading) return false;

				if (this.UpLoad.MaxSize > 0) {
					if (file.size / 1024 / 1024 > this.UpLoad.MaxSize) {
						this.$matrix.ShowError(
							"文件大小不能超过" + this.UpLoad.MaxSize + "MB!"
						);
						return false;
					}
				}
				if (file.name) {
					let extnames = file.name.split(".");
					if (extnames.length > 1) {
						let ext = (extnames[extnames.length - 1] || "").toLowerCase();

						if (this.Exts.indexOf("." + ext) > -1) {
							this.uploading = true;
							this.percentage = 0;
							return true;
						}
					}
				}
				this.$matrix.ShowError("无法识别上传文件格式!");
				return false;
			}.bind(this);
		},
	},
};
</script>
<style scoped>
.box {
	background-color: #fff;
	padding: 2px 2px 0 0;
}

.image-box {
	background-color: #fff;
	position: relative;
	padding-left:20px;
}

.image-box::before {
	content: ' ';
	position: absolute;
	right: 0;
	left: 0;
	height: 1px;
	top: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	color: rgba(0, 0, 0, 0.1);
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	-webkit-transform: scaleY(0.5);
	transform: scaleY(0.5);
}

.image-box::after {
	content: ' ';
	/* position: absolute; */
	right: 0;
	left: 0;
	height: 1px;
	bottom: 0;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: rgba(0, 0, 0, 0.1);
	-webkit-transform-origin: 0 100%;
	transform-origin: 0 100%;
	-webkit-transform: scaleY(0.5);
	transform: scaleY(0.5);
}

.file-box {
	/* background-color: #fff; */
	/* margin: 0 0 2px 2px; */
	/* width: calc(25% - 2px); */
	/* width: 25%; */
	position: relative;
	margin: 0 8px 8px 0;
	width: 80px;
	height: 80px;
	line-height: 2;
	border-radius: 2px;
	overflow: hidden;
	background-color: #f5f6f9;
}
.file-border {
	margin: 0 8px 8px 0;
	width: 78px;
	height: 78px;
	border: 1px solid #ddd;
	background-color: #fff;
	position: relative;
	overflow: visible;
}



.image-upload {
	width: 100%;
	height: 100%;
	background-size: contain;
	overflow: hidden;
}

.file {
	padding: 10px;
	line-height: 60px;
}

.btn {
	padding: 3px;
	cursor: pointer;
	color: #000;
}

.info-box {
	background-color: #fcfcfc;
	padding: 10px 20px;
	text-align: left;
	color: #949494;
	margin: 20px;
}

.info-box p {
	font-size: 12px;
	line-height: 1.5;
}

.file-upload {
	position: relative;
	width: 200px;
	height: 200px;
}

.file-upload:hover .tool-bar {
	display: flex;
	opacity: 1;
}

.tool-bar {
	padding: 5px 0;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	text-align: center;
	left: 0;
	opacity: 0;
	display: none;
	width: 100%;
	font-size: 12px;
	background-color: rgba(51, 51, 51, 0.7);
	transition: opacity 0.3s ease;
	margin: 0;
	color: #fff;
	z-index: 1;
}

.el-icon-error {
	position: absolute;
	right: -10px;
	top: -15px;
	font-size: 24px;
  cursor: pointer;
  display:none
}
.image-upload:hover .el-icon-error{display:block}
.el-upload{display: block;}
.image-box{
  padding-top:10px;
}
</style>