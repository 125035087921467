<template>
  <div class="root">

    <div class="flex-row title center">
      <div class="wf">评测结果看板</div>
      <div> <el-select v-model="Company" placeholder="请选择" @change="onChange">
          <el-option v-for="item in Companys" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select></div>
    </div>

    <div class="flex-row">
      <div class="wf m01" style="overflow: hidden;">
        <mx-page type="Home" :config="PageConfig" :name="config.Title + '驾驶员成绩分析'" theme="Panel" :query="Query"
          :key="config.Title + key"></mx-page>
      </div>
      <div class=" wf m01" style="overflow: hidden;">
        <mx-page type="Home" :config="PageConfig" :name="config.Title + '通过率分析'" theme="Panel" :query="Query"
          :key="config.Title + key"></mx-page>
      </div>
    </div>

    <div class="flex-row">
      <div class="wf m01">
        <mx-page type="Home" :config="PageConfig" :name="config.Title + '平均分横向对比分析'" theme="Panel" :query="Query"
          :key="config.Title + key"></mx-page>
      </div>
      <div class="wf m01">
        <mx-page type="Home" :config="PageConfig" :name="config.Title + '驾驶员年龄分析'" theme="Panel" :query="Query"
          :key="config.Title + key"></mx-page>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    value: Object,
    config: Object
  },
  data() {
    return { height: 60, Query: { Company: { Id: 1, Type: 400 } }, Companys: [], Company: 1, key: new Date().getTime() }
  },
  computed: {

    PageConfig() {
      return {
        Role: this.config.Role,
        RoleField: this.config.RoleField
      }
    },

  },
  methods: {
    onChange(e) {
      this.Query = {
        Company: { Id: this.Company, Type: 400 }
      }
      this.key = new Date().getTime()
    }
  },
  mounted() {
    if (this.$refs.nav) {
      this.height = this.$refs.nav.$el.clientHeight + 10
    } else {
      this.height = 0
    }
  },
  created() {
    let user = this.$matrix.GetUser()
    if (!user) this.$matrix.GotoLogin()
    this.$matrix.AjaxGet3('Company', 'GetList', { Page: 1, PageSize: 0 }, this.config.Role, this.config.RoleField).then(data => {
      if (data.Items.length) {
        this.Companys = data.Items.map(item => {
          return {
            value: item.Id,
            label: item.Name
          }
        })

        this.key = new Date().getTime()
      }
    }).catch(e => {
      this.$matrix.ShowError(e)
    })
  }
}
</script>
<style scoped>
.root {
  display: block;

  overflow-x: hidden;
  position: relative;
  background-color: #040f3c;
  height: 100vh;
  /* border-top-left-radius: 20px;
  padding: 20px; */
}

.title {
  height: 80px;
  line-height: 80px;
  position: relative;
  font-size: 36px;
  color: #00ffff;
  text-align: center;
}

.border-container {
  position: relative;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, .15);
  box-shadow: inset 0 0 50px rgba(255, 255, 255, .1), 0 0 5px rgba(0, 0, 0, .3)
}

.border-container span.border-span {
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  opacity: .5
}

.border-container span.top-left {
  top: -2px;
  left: -2px;
  border-top: 2px solid #54dcf2;
  border-left: 2px solid #54dcf2;
}

.border-container span.top-right {
  top: -2px;
  right: -2px;
  border-top: 2px solid #54dcf2;
  border-right: 2px solid #54dcf2;
}

.border-container span.bottom-left {
  bottom: -2px;
  left: -2px;
  border-bottom: 2px solid #54dcf2;
  border-left: 2px solid #54dcf2;
}

.border-container span.bottom-right {
  bottom: -2px;
  right: -2px;
  border-bottom: 2px solid #54dcf2;
  border-right: 2px solid #54dcf2;
}

.name-title {
  font-size: 16px;
  font-weight: bolder;
  color: #00ffff;
}
</style>
