<template>
  <mx-element type="ChildPageTitle" :title="Meta.Title">
    <mx-load-one
      v-if="parent.Id"
      :auto-load="true"
      :type="parent.Type"
      :id="parent.Id"
      :role="parent.Role"
      :role-field="parent.RoleField"
      @loaded="handleParentLoad"
    ></mx-load-one>
    <mx-page
      v-if="Parent"
      type="Modify"
      :config="detail"
      :parent="Parent"
      :key="timespan"
      :is-child="isChild"
      :master="master"
      @meta-change="OnMetaChange"
    >
    </mx-page>
  </mx-element>
</template>
<script>
import detail from "matrix.node.ui/page/detail";
export default {
  mixins: [detail("modify")],
  props: {},
  data() {
    return { Meta: {}, ParentValue: null };
  },
  computed: {
    Parent() {
      if (this.parent && this.parent.Id) {
        if (this.ParentValue) {
          return Object.assign({}, this.parent, { Parent: this.ParentValue });
        }
      } else {
        return this.parent;
      }
    }
  },
  methods: {
    handleParentLoad(val) {
      if (val && val.Id) this.ParentValue = val;
    },
    OnMetaChange(val) {
      this.Meta = val;
    }
  }
};
</script>
