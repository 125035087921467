<template>
	<div>
		<div class="box" v-if="!AddMoreModel">
			<el-button size="mini" type="text" icon="el-icon-edit" @click="handleAdd3">新增 </el-button>
			<el-button v-if="isConAddmore" size="mini" type="text" icon="el-icon-edit" @click="handleAddMore">批量新增
			</el-button>
		</div>
		<div class="table-box">
			<div v-if="AddMoreModel">
				<div v-for="field in GroupFields" :key="field.Name">
					<div class="flex-row start list titlebar">
						<div style="flex: 1">请选择{{ field.Title }}</div>
						<div>
							<a href="javascript:void(0)" @click="AddFieldData(field)">新增{{ field.Title }}</a>
						</div>
					</div>
					<mx-data-list :type="field.Options.Entity" :role="config.Role" :role-field="config.RoleField"
						:page-size="0" :params="field.Options.Params" :ref="'GroupField' + field.Name">
						<div slot-scope="scope" :key="scope.timespan + field.Name" class="flex-row start top wrap"
							style="padding: 6px">

							<component :is="GetFieldType(field)" @click="OnSelectGroupFieldData(field, data)"
								:panel="$matrix.getPanelName(field.Options.Entity, 'Selection')" :value="data"
								:config="GetFieldConfig(field)" :selected="SelectedGroupData[field.Name][data.Id]"
								v-for="(data,index) in scope.data" class="selecteditem"
								:class="SelectedGroupData[field.Name][data.Id] ? 'selected' : ''" :key="index">
								{{ $matrix.GetTitleByType(field.Options.Entity, data) }}
							</component>
						</div>
					</mx-data-list>
				</div>
				<el-button type="primary" @click="OnCreate">批量创建</el-button>
			</div>
			<template v-else>
				<div v-if="IsCustFormPanel">
					<mx-panel :value="data" v-for="(data, index) in ShowData" :key='index' :config="config"
						:panel="$matrix.getPanelName(config.Type, 'ListEdit')" :index="data._row"
						@change="OnChange($event, data, index)">
						<div slot="button">
							<el-button size="mini" type="text" icon="el-icon-delete"
								@click="handleDelete(data, data._index)">删除</el-button>
						</div>
					</mx-panel>
					<div v-if="ShowData.length === 0" style="padding: 30px">
						<div @click="OnAddNewLine">新增一条{{ Entity.Title }}</div>
					</div>
				</div>
				<el-table :data="ShowData" fit v-else>
					<el-table-column label="序号" width="58">
						<template slot-scope="scope">{{ data._row }}</template>
					</el-table-column>
					<el-table-column v-for="(col, index) in ShowColumns" :key="col.Name + '_' + index" :label="col.Title"
						:width="col.Width" v-show="ShowColumns.length < 6 || col.Req">
						<template slot-scope="scope">
							<mx-field :type="col.Type" :edit="false" :name="col.Name" :value="scope.row" :one-line="true"
								:config="col"></mx-field>
						</template>
					</el-table-column>
					<el-table-column align="right" label="操作">
						<template slot-scope="scope">
							<el-button size="mini" type="text" icon="el-icon-edit"
								@click="handleModify(scope.row, scope.$index)">编辑 </el-button>
							<el-button size="mini" type="text" icon="el-icon-delete"
								@click="handleDelete(scope.row, scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</template>
		</div>
	</div>
</template>
<script>
import list from 'matrix.node.ui/master/list'
export default {
	mixins: [list.component],
	props: {
		config: Object,
		value: Array,
		columns: Array,
		options: Array,
		theme: String
	},
	computed: {
		isConAddmore() {
			return this.GroupFields && this.GroupFields.length > 0
		},
		IsCustFormPanel() {
			return this.$matrix.hasPanel(this.config.Type, 'ListEdit')
		}
	},
	methods: {
		OnAddNewLine() {
			if (this.isConAddmore) {
				this.handleAddMore()
			} else {
				let newdata = this.config.Form.CreateNew()
				if (this.config.Node === 'ChildData') {
					let mainData = this.$matrix.FindParentFunction(this, 'GetMainFormValue')
					if (mainData) newdata.Parent = mainData()
				}
				this.Data = this.Data.concat(newdata)
			}
		},
		OnChange(change, data, index) {
			this.Data[index] = Object.assign(this.Data[index], change)
			this.Data = [].concat(this.Data)
		},
		GetFieldType(field) {
			if (this.$matrix.hasPanel(field.Options.Entity, 'Selection')) return 'mx-panel'
			return 'DIV'
		},
		GetFieldConfig(field) {
			return {
				Type: field.Options.Entity,
				Role: this.config.Role,
				RoleField: this.config.RoleField
			}
		},
		OnSelectGroupFieldData(field, data) {
			this.SelectedGroupData[field.Name] = Object.assign({}, this.SelectedGroupData[field.Name], {
				[data.Id]: this.SelectedGroupData[field.Name][data.Id] ? null : data
			})
			this.SelectedGroupData = Object.assign({}, this.SelectedGroupData)
		},
		handleAdd3() {
			if (this.IsCustFormPanel && !this.isConAddmore) {
				this.OnAddNewLine()
			} else {
				this.handleAdd()
			}
		},
		AddFieldData(field) {
			this.$matrix.AddData(
				this.Context,
				{},
				{
					Type: field.Options.Entity,
					Role: this.config.Role,
					RoleField: this.config.RoleField
				},
				(res) => {
					let com = this.$refs['GroupField' + field.Name]
					if (Array.isArray(com)) com = com[0]
					if (com) com.Load(1)
				}
			)
		},
		handleAddMore(e) {
			this.AddMoreModel = true
			this.GroupFields.forEach((field) => {
				this.SelectedGroupData[field.Name] = {}
			})
		},
		OnCreate() {
			let res = {}
			for (let field of this.GroupFields) {
				let selected = this.SelectedGroupData[field.Name]
				if (!selected) return this.$matrix.ShowError(`请至少选择一项${field.Title}`)
				res[field.Name] = Object.keys(selected)
					.filter((key) => {
						return !!selected[key]
					})
					.map((key) => {
						return selected[key]
					})

				if (res[field.Name].length === 0) return this.$matrix.ShowError(`请至少选择一项${field.Title}`)
			}
			let datas = []

			let foo = (index, parent) => {
				parent = parent || {}
				res[this.GroupFields[index].Name].map((d) => {

					let data = JSON.parse(JSON.stringify(parent))
					data[this.GroupFields[index].Name] = d
					if (!this.GroupFields[index + 1]) {
						datas.push(data)
					} else {
						foo(index + 1, data)
					}
				})
			}
			foo(0, {})

			let newdatas = datas
				.filter((data) => {
					let isHave = false
					if (
						this.Data &&
						this.Data.filter((d) => {
							return this.$matrix.Include(data, d) && !d._delete
						}).length > 0
					) {
						isHave = true
					}
					return !isHave
				})
				.map((data) => {
					let newdata = Object.assign(this.config.Form.CreateNew(), data)
					if (this.config.Node === 'ChildData') {
						let mainData = this.$matrix.FindParentFunction(this, 'GetMainFormValue')
						if (mainData) newdata.Parent = mainData()
					}
					return newdata
				})
			this.Data = this.Data.concat(newdatas)
			this.AddMoreModel = false
		}
	},
	data() {
		return { AddMoreModel: false, SelectedGroupData: {} }
	},
	created() {
		if (this.ShowData.length === 0 && this.isConAddmore) {
			this.AddMoreModel = true
			this.GroupFields.forEach((field) => {
				this.SelectedGroupData[field.Name] = {}
			})
		}
	}
}
</script>
<style scoped>
.selecteditem {
	padding: 8px;
	cursor: pointer;
	background-color: #f7f7f7;
	margin-top: 4px;
	margin-right: 4px;
	border-radius: 10px;
}

.selecteditem:hover {
	background-color: #2d8cf0;
	color: #fff;
}

.selecteditem.selected {
	background-color: #2d8cf0;
	color: #fff;
}

.box {
	background-color: #f7f7f7;
	padding: 5px 10px 5px 5px;
	text-align: right;
	overflow-x: auto;
}

.table-box {
	width: 100%;
	overflow-x: auto;
	padding: 0;
	margin: 0;
}

.titlebar {
	padding: 6px;
	background-color: #f7f7f7;
	text-align: left;
	margin-top: 4px;
}

.titlebar a {
	color: #2d8cf0;
}</style>
