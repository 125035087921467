let create = function(category, data, legend) {
  let ydata = [];
  if (legend) {
    ydata = legend.map((l, index) => {
      return {
        name: l,
        type: "line",
        stack: "总量",
        smooth: true,
        data: data[index],
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4DA1FF"
            }
          }
        },
        areaStyle: {
          normal: { color: "#DFEDFE" }
        }
      };
    });
  } else {
    ydata = [
      {
        name: this.config.Title,
        type: "line",
        stack: "总量",
        smooth: true,
        data: data,
        itemStyle: {
          normal: {
            lineStyle: {
              color: "#4DA1FF"
            }
          }
        },
        areaStyle: {
          normal: { color: "#DFEDFE" }
        }
      }
    ];
  }
  return {
    title: {
      // text: this.config.Title || "",
      // subtext: this.config.SubTitle || "",
      left: "center"
    },
    legend: legend
      ? {
          data: legend
        }
      : null,
    tooltip: {
      trigger: "axis",
      backgroundColor: "#4d8bf7",
      color: "#ffffff",
      textStyle: {
        color: "#ffffff"
      },
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
    },
    grid: {
      top: "13%",
      left: "2%",
      right: "4%",
      bottom: "16%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        axisLine: {
          onZero: false,
          lineStyle: {
            // color: '#4b87a9'
          }
        },
        data: category
      }
    ],
    yAxis: [
      {
        type: "value",
        axisLine: {
          onZero: false,
          lineStyle: {
            color: "#000000"
          }
        },
        splitLine: {
          //网格线
          show: true,
          lineStyle: {
            //x网格线
            color: "#f8f8f8"
          }
        }
      }
    ],
    series: ydata
  };
};

export default { create };
