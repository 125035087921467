 <template>
  <div class="flex-row middle">
    <div class="flex-row start middle noselect">
      <div style="width: 40px">
        <i class="el-icon-s-unfold" style="font-size: 24px"></i>
      </div>
      <div class="scrollbox">
        <div class="flex-row start middle">
          <div
            v-for="(item, index) of options"
            :key="index"
            class="scrollbox-item"
            :class="index === value ? 'active' : ''"
            @click="handleClick(index)"
          >
            {{ item[config.label] }}
          </div>
        </div>
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { label: "Title" };
      }
    }
  },
  methods: {
    handleClick(index) {
      if (index !== this.current) {
        this.$emit("change", index);
      }
    }
  }
};
</script>
<style scoped>
.scrollbox {
  width: calc(100% - 40px);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}
.scrollbox-item {
  word-break: keep-all;
  font-size: 18px;
  font-weight: 550;
  padding: 20px 5px;
  cursor: pointer;
}
.active {
  color: red;
}
.scrollbox::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
.scrollbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.scrollbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
 