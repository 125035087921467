<template>
  <mx-application :type="CurrentMeta.Instance.Theme" :key="key">
    <router-view class="router-view"></router-view>
  </mx-application>
</template>
<script>
export default {
  components: {},
  computed: {
    CurrentMeta() {
      return this.$store.state.matrix.CurrentMeta;
    }
  },
  data() {
    return { key: new Date().getTime(), name: "" };
  },
  watch: {
    "$route.path": function(val) {
      let p = val.split("/")[1];
      if (p !== this.name) {
        // this.key = new Date().getTime();
        this.name = p;
      }
    } 
  },
  created() {
    this.name = this.$route.path.split("/")[1];
  }
};
</script>