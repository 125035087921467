<template>
    <el-steps :active="value" finish-status="success">
        <el-step :title="op.Title" :key="op.Id" v-for="op in options"></el-step>
    </el-steps>
</template>
<script>
export default {
    props: {
        config: Object,
        value: Number,
        role: String,
        roleField: String,
        options: Array
    }
}
</script>