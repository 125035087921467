<template>
  <el-input
    v-model="Data"
    clearable
    @blur="OnChange"
    @clear="OnChange"
    @keyup.13.native="OnChange"
    :size="size || config.Size"
    :placeholder="'请输入' + (config.Title || '')"
    :maxlength="config.Length"
    :disabled="disabled || config.Disabled"
  >
    <span slot="prepend" v-if="config.FontStyle">{{ config.FontStyle }}</span>
    <span slot="append" v-if="config.BackStyle">{{ config.BackStyle }}</span>
  </el-input>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String)],
};
</script>