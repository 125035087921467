<template>
  <div class="flex-column">
    <slot name="font"></slot>
    <div
      v-for="(col, index) in Columns"
      :key="index"
      class="flex-column top start"
      style="margin: 0 0 10px 0"
      v-show="GetShowState(col)"
    >
      <div style="flex: 1">
        <div
          class="form-item"
          :class="CurrentData && CurrentData[col.Name] ? 'active' : ''"
        >
          <div class="rel" style="flex: 1; text-align: left">
            <label for="username" class="input-label">{{ col.Title }} </label>
            <mx-field
              :config="col"
              :value="value"
              ref="field"
              :edit="true"
              :type="col.Type"
              :name="col.Name"
              @change="handleChange"
              :checkable="true"
            >
            </mx-field>
          </div>
        </div>
        <div class="flex-row">
          <div
            style="color: #777; font-size: 14px; margin-top: 5px"
            v-if="col.Description && !GetFieldError(col)"
          >
            <i class="el-icon-info"></i> {{ col.Description }}
          </div>
          <div
            style="
              color: red;
              font-size: 14px;
              margin-top: 5px;
              text-align: left;
              height: 16px;
            "
            class="line"
          >
            <template v-if="GetFieldError(col)">
              <i class="el-icon-err"></i>
              {{ GetFieldError(col).message }}</template
            >
          </div>
        </div>
      </div>
    </div>
    <slot name="button"></slot>
  </div>
</template>
<script>
import form from "matrix.node.ui/master/form";
export default {
  mixins: [form.component],
  data() {
    return { Errors: null, CurrentData: null, Columns: null };
  },
  methods: {
    GetTitleStyle() {
      return {
        width: 4 * 16 + "px",
        display: "inline-block",
        textAlignLast: "justify",
        lineHeight: 1,
      };
    },
    handleError(errors) {
      this.Errors = errors;
    },
    handleChange2(val) {
      if (this.CurrentData) {
        this.CurrentData[val.name] = val.value;
        if (val.name && this.Errors) {
          this.Errors = this.Errors.filter((e) => {
            return e.name !== val.name;
          });
        }
      }
    },
    GetFieldError(col) {
      if (this.Errors && this.Errors.length > 0) {
        return this.Errors.find((e) => {
          return e.name === col.Name;
        });
      }
    },
  },
  created() {
    let CurrentData = this.value || {};
    this.Columns = this.columns.map((c) => {
      let cobject = JSON.parse(JSON.stringify(c));
      CurrentData[cobject.Name] = CurrentData[cobject.Name] || undefined;
      cobject.Theme = "Line";
      return cobject;
    });
    this.CurrentData = CurrentData;
  },
};
</script>
<style scoped>
.title {
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
}
.panel10lr {
  padding: 4px 10px;
}
.form-item {
  display: flex;
  position: relative;
  border-bottom: 1px solid rgb(230, 230, 230);
  transition: border-bottom 0.3s ease-in-out 0s;
  border-radius: 0px;
  margin-top: 12px;
}
.form-item .input-label {
  width: 100%;
  position: absolute;
  cursor: text;
  font-size: 14px;
  line-height: 14px;
  color: rgb(157, 165, 179);
  background-color: rgb(255, 255, 255);
  bottom: 15px;
  transition: all 0.2s ease 0s;
  /* z-index: 9999; */
}
.input {
  color: rgb(25, 31, 37);
  font-size: 14px;
  text-indent: 0px;
  line-height: 18px;
  height: 46px;
  border: none;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
}
.form-item:focus-within {
  border-bottom: 1px solid rgb(78, 128, 245);
}
.form-item:focus-within .input-label,
.form-item.active .input-label {
  font-size: 12px;
  bottom: 42px;
}

input,
button,
select,
textarea {
  outline: none;
}

textarea {
  resize: none;
}

input {
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:-webkit-autofill {
  border: none;
  border-radius: 0px !important;
  box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  -webkit-text-fill-color: #333 !important;
}

input:-webkit-autofill:hover {
  border: none !important;
}
input:-webkit-autofill:focus {
  border: none !important;
}

input::placeholder {
  color: rgba(25, 31, 37, 0.2);
  font-size: 14px;
}
</style>
