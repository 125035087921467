<template>
  <div v-if="value">
    <span v-if="config.ShowPath">{{ value }}</span>
    <a v-if="!config.NoDownload" :href="$matrix.config.ImageSite + value" target="_blank" download="下载文件">下载</a>
  </div>
  <div v-else>未上传<slot></slot>
  </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
export default {
  mixins: [base.view(String)]
}
</script>