import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DataChatColumn",
    Is: "DataChatColumnFrame",
    ItemIs: "DataChatColumnItem",
    Components: {
        DataChatColumnFrame: Frame,
        DataChatColumnItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({ Type: config.Type },config.Config);
        if (dataconfig) {
            if (dataconfig && !dataconfig.Fields) {
                dataconfig.Fields = "IdCount";
                dataconfig.PageSize = 0;
            }
            if (config.DataField) {
                if (!dataconfig.Params) {
                    dataconfig.Params = {};
                    dataconfig.Params["Get" + config.DataField] = true;
                }
            }
            if (dataconfig && !dataconfig.GroupFields) {
                let groupfield = this.$matrix
                    .Models[dataconfig.Type]
                    .Fields.find((f) => {
                        return (
                            ["Enum", "Object", "Link"].indexOf(f.Type) > -1 &&
                            f.Name !== "Parent"
                        );
                    });
                if (groupfield) dataconfig.GroupFields = [groupfield.Name];
            }
            return dataconfig;
        }
    }
}