<template>
  <component :is="Is" v-if="Is" ref="form" :value="value" :config="config" :columns="columns" @change="handleChange"
    :empty='true' :clear-empty-string="true">
    <slot name="button" slot="button"></slot>
  </component>
</template>
<script>
import form from 'matrix.node.ui/master/form'
import OneForm from './one'
import DefaultForm from './default'
export default {
  mixins: [form.container],
  components: { OneForm, DefaultForm },
  props: {
    theme: String
  },
  computed: {
    Is() {
      switch ((this.theme || '').toLowerCase()) {
        case 'one':
          return 'OneForm'
        default:
          return 'DefaultForm'
      }
    }
  },
  methods: {}
}
</script>
