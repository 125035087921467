<template>
  <component
    :is="Is"
    :value="value"
    :config="config"
    :title="title"
    :options="options"
    :icon="icon"
    @change="handleChange"
    @click="handleClick"
  >
    <slot></slot>
  </component>
</template>
<script>
import defaultComponet from "./default.vue";
import Group from "./group.vue";
export default {
  props: {
    value: Number,
    config: Object,
    title: String,
    icon: String,
    theme: { type: String, default: "" },
    options: Array
  },
  components: { defaultComponet, Group },
  computed: {
    Is() {
      switch (this.theme.toLowerCase()) {
        case "group":
          return "Group";
        default:
          return "defaultComponet";
      }
    }
  },
  methods: {
    handleChange(val) {
      this.$emit("change", val);
    },
    handleClick(val) {
      this.$emit("click", val);
    }
  }
};
</script>
