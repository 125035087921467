import Item from "./item.vue";
import Frame from "./frame.vue";
export default {
    Name: "DataSecondInterMenu",
    ItemIs: "DataSecondInterMenuItem",
    Is: "DataSecondInterMenuFrame",
    Components: {
        DataSecondInterMenuFrame: Frame,
        DataSecondInterMenuItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = Object.assign({},config.Config);
        if (!dataconfig.PageSize) {
            dataconfig.PageSize = 10;
        }
        if (!dataconfig.Fields) {
            dataconfig.Fields = ["IdCount"];
        }
        return dataconfig;
    }
};
