<template>
  <mx-element type="ColumnFrame" theme="None">
    <div v-for="(item, index) of columns" :key="index">
      <slot :config="item" :index="index"></slot>
    </div>
    <slot name="content"></slot>
  </mx-element>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array,
    parent: Object,
    entity: String
  },
  computed: {}
};
</script>
