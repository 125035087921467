<template>
    <div class="flex-row left noselect main-title">{{ title }}</div>
</template>
<script>
export default {
    props: {
        title: String
    }
}
</script>
<style scoped  >
.main-title {
    position: relative;
    padding: 24px 10px;
    font-size:20px;
    font-weight: 600;
    line-height:1.3;
}

/* .main-title::before {
    content: " ";
    border-left: 5px solid #3d7fff;
    position: absolute;
    left: 0;
    top: 40%;
    bottom: 35%;



} */
</style>