 <template>
  <div class="flex-row middle noselect">
    <div
      class="flex-row start middle"
      v-for="(group, gindex) of groups"
      :key="gindex"
    >
      <div
        v-for="(item, index) of group.Items"
        :key="index"
        class="scrollbox-item"
        :class="item.Index === value ? 'active' : ''"
        @click="handleClick(item.Index)"
      >
        <span style="font-size: 18px">
          <mx-element
            type="Icon"
            :theme="item.Icon"
            v-if="item.Icon"
            style="margin-right: 5px"
          />{{ item[config.label] }}</span
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    options: Array,
    value: Number,
    config: {
      type: Object,
      default: () => {
        return { label: "Title" };
      }
    }
  },
  computed: {
    groups() {
      return this.options.reduce((g, item, index) => {
        item.Index = index;
        let groups = g.find(t => {
          return t.Group === item.Group;
        });
        if (groups) {
          groups.Items.push(item);
        } else {
          groups = { Group: item.Group, Items: [item] };
          g.push(groups);
        }
        return g;
      }, []);
    }
  },
  methods: {
    handleClick(index) {
      if (index !== this.current) {
        this.$emit("change", index);
      }
    }
  }
};
</script>
<style scoped>
.scrollbox {
  width: calc(100% - 40px);
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0;
  margin: 0;
}
.scrollbox-item {
  word-break: keep-all;
  font-size: 18px;
  font-weight: 550;
  padding: 10px 5px;
  cursor: pointer;
  margin: 0 10px;
}
.active {
  color: #fff;
  position: relative;
  z-index: 8;
}
.active span {
  color: red;
}
.active::after {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  bottom: 0;
  background-color: red;
  margin: 0 5px;
}
.scrollbox::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}
.scrollbox::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #535353;
}
.scrollbox::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: #ededed;
}
</style>
 