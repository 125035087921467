/*Json*/
import Detail from "./Detail";
import Edit from "./Edit";
export default {
    components: [
        { Name: "Detail", Component: Detail },
        { Name: "Edit", Component: Edit }
    ],
    Is() {
      if (this.edit) {
        return "Edit";
      }
      return "Detail";
    }
}