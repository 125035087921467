<template>
  <div :style="config.InnerStyle">
    <div
      class="line2"
      :class="config.DetailLink ? 'link' : ''"
      @click="OnClick"
    >
      {{ TextValue || value }}<slot></slot>
    </div>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view(String)],
  methods: {
    OnClick(e) {
      if (this.config.DetailLink)
        this.$emit("click", { params: { url: this.config.DetailLink } });
    },
  },
};
</script>
<style scoped>
.link {
  cursor: pointer;
  color: #409eff;
}
</style>