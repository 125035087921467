/*
  version : matrix node v3.0
*/
export default {
    Manager: [{
        Title: '首页',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home',
        PanelModule: '标准商城首页模板1',
        FrameModule: '',
        Role: '管理员自己',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '首页',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Self'
        }],
        Id: 0
    },
    {
        Title: '用户',
        ShowTitle: '用户管理',
        Path: 'Manager',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员自己',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '用户',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Self'
        }],
        Id: 1
    },
    {
        Title: '全部用户',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '用户管理菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: 1,
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    },
    {
        Title: '用户管理',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '用户管理全部用户',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 3
    },
    {
        Title: '用户详情',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '视图面板',
        Theme: '',
        Panel: 'Detail',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '用户详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 4
    },
    {
        Title: '账号',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '账号',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 5
    },
    {
        Title: '账号',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '账号菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 6
    },
    {
        Title: '账号',
        ShowTitle: '',
        Path: 'Manager',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '自定义列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '账号账号',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 7
    }],
    Driver: [{
        Title: '驾驶员年龄分析',
        ShowTitle: '',
        Path: 'Driver',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'ChartBar',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '首页驾驶员年龄分析',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '司机',
        ShowTitle: '',
        Path: 'Driver',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员自己',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '司机',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Parent'
        }],
        Id: 1
    },
    {
        Title: '司机管理',
        ShowTitle: '',
        Path: 'Driver',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '司机菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: 1,
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    },
    {
        Title: '司机管理',
        ShowTitle: '',
        Path: 'Driver',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '司机司机管理',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 3
    },
    {
        Title: '司机详情',
        ShowTitle: '',
        Path: 'Driver',
        Code: '',
        ViewType: '视图面板',
        Theme: '',
        Panel: 'Detail',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '司机详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 4
    }],
    Driver_Response: [{
        Title: '反应力测试',
        ShowTitle: '',
        Path: 'Driver.Response',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '司机菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: 1,
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '反应力测试',
        ShowTitle: '',
        Path: 'Driver.Response',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '司机反应力测试',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    },
    {
        Title: '反应力测试',
        ShowTitle: '',
        Path: 'Driver.Response',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: 'Parent',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '司机详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    }],
    Company: [{
        Title: '公司',
        ShowTitle: '',
        Path: 'Company',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员自己',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '公司',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Parent'
        }],
        Id: 0
    },
    {
        Title: '公司管理',
        ShowTitle: '',
        Path: 'Company',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '公司菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: 1,
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    },
    {
        Title: '公司管理',
        ShowTitle: '',
        Path: 'Company',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '公司公司管理',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    },
    {
        Title: '公司详情',
        ShowTitle: '',
        Path: 'Company',
        Code: '',
        ViewType: '视图面板',
        Theme: '',
        Panel: 'Detail',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '公司详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 3
    }],
    Site: [{
        Title: '设置',
        ShowTitle: '设置',
        Path: 'Site',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    }],
    Site_Banners: [{
        Title: '首页轮播图',
        ShowTitle: '',
        Path: 'Site.Banners',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '首页轮播图',
        ShowTitle: '',
        Path: 'Site.Banners',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置首页轮播图',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_LearnDic: [{
        Title: '学习手册目录',
        ShowTitle: '',
        Path: 'Site.LearnDic',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '学习手册目录',
        ShowTitle: '',
        Path: 'Site.LearnDic',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置学习手册目录',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_LearnContent: [{
        Title: '学习手册',
        ShowTitle: '',
        Path: 'Site.LearnContent',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '学习手册',
        ShowTitle: '',
        Path: 'Site.LearnContent',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置学习手册',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_Radar: [{
        Title: '雷达图列表',
        ShowTitle: '',
        Path: 'Site.Radar',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '雷达图列表',
        ShowTitle: '',
        Path: 'Site.Radar',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置雷达图列表',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_Health: [{
        Title: '健康列表',
        ShowTitle: '',
        Path: 'Site.Health',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '健康列表',
        ShowTitle: '',
        Path: 'Site.Health',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置健康列表',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CarType: [{
        Title: '准驾车型',
        ShowTitle: '',
        Path: 'Site.CarType',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '准驾车型',
        ShowTitle: '',
        Path: 'Site.CarType',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置准驾车型',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable: [{
        Title: '检查表',
        ShowTitle: '',
        Path: 'Site.CheckTable',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '检查表',
        ShowTitle: '',
        Path: 'Site.CheckTable',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置检查表',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable_Out: [{
        Title: '出发检查表',
        ShowTitle: '',
        Path: 'Site.CheckTable.Out',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '出发检查表',
        ShowTitle: '',
        Path: 'Site.CheckTable.Out',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置出发检查表',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable_Checking: [{
        Title: '评估表',
        ShowTitle: '',
        Path: 'Site.CheckTable.Checking',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '评估表',
        ShowTitle: '',
        Path: 'Site.CheckTable.Checking',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置评估表',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable_CommentModule1: [{
        Title: '评级模板优点',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule1',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '评级模板优点',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule1',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置评级模板优点',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable_CommentModule2: [{
        Title: '评级模板缺点',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule2',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '评级模板缺点',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule2',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置评级模板缺点',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Site_CheckTable_CommentModule3: [{
        Title: '评价模板建议',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule3',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '评价模板建议',
        ShowTitle: '',
        Path: 'Site.CheckTable.CommentModule3',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '设置评价模板建议',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    }],
    Paper: [{
        Title: '试卷',
        ShowTitle: '',
        Path: 'Paper',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '试卷',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '试卷管理',
        ShowTitle: '',
        Path: 'Paper',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '试卷菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    },
    {
        Title: '试卷管理',
        ShowTitle: '',
        Path: 'Paper',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            },
            LoadParams: {
                GetCheckTable1ItemsList: true,
                GetCheckTable2ItemsList: true
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '试卷试卷管理',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    },
    {
        Title: '试卷详情',
        ShowTitle: '',
        Path: 'Paper',
        Code: '',
        ViewType: '视图面板',
        Theme: '',
        Panel: 'Detail',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '试卷详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 3
    }],
    Paper_TopicList: [{
        Title: '题目明细',
        ShowTitle: '',
        Path: 'Paper.TopicList',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Add: {
                Title: '新增'
            },
            Import: {
                Title: '导入'
            },
            Export: {
                Title: '导出'
            },
            LoadParams: {
                GetTestTopic: true,
                GetParent: true
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '试卷详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    }],
    Test: [{
        Title: '驾驶员成绩分析',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Pie',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '首页驾驶员成绩分析',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    },
    {
        Title: '通过率分析',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'PassPie',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '首页通过率分析',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 1
    },
    {
        Title: '平均分横向对比分析',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'ChartBar',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '首页平均分横向对比分析',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 2
    },
    {
        Title: '评测',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '面板',
        Theme: '',
        Panel: 'Home2',
        PanelModule: '管理首页3',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Theme: 'top2'
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '评测',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 3
    },
    {
        Title: '评测管理',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '按钮',
        Theme: '单行菜单',
        Panel: '',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Active: true,
            IsH: true
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '评测菜单',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 4
    },
    {
        Title: '评测管理',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'List2',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Export: {
                Title: '导出'
            },
            LoadParams: {
                GetSignList: true,
                GetDiscussList: true,
                GetManager: true,
                DriverSearchParams: {
                    GetCartType: true
                }
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '评测评测管理',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 5
    },
    {
        Title: '评测详情',
        ShowTitle: '',
        Path: 'Test',
        Code: '',
        ViewType: '视图面板',
        Theme: '',
        Panel: 'Detail',
        PanelModule: '',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: '',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '评测详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 6
    }],
    Test_Items: [{
        Title: '评测明细',
        ShowTitle: '',
        Path: 'Test.Items',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: 'Parent',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '评测详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    }],
    Test_Discuss: [{
        Title: '评测评价',
        ShowTitle: '',
        Path: 'Test.Discuss',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: 'Parent',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: '',
        ColumnConfig: '',
        SortFields: '',
        Page: '评测详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    }],
    Test_Sign: [{
        Title: '签名',
        ShowTitle: '',
        Path: 'Test.Sign',
        Code: '',
        ViewType: '数据列表',
        Theme: '数据分页列表',
        Panel: 'ListPanel',
        PanelModule: '通用列表面板',
        FrameModule: '',
        Role: '管理员作业人',
        ViewParams: null,
        Count: '',
        ParentField: 'Parent',
        SearchFields: '',
        GroupFields: '',
        DateField: '',
        Fields: '',
        DataField: '',
        Config: {
            Export: {
                Title: '导出'
            }
        },
        ColumnConfig: '',
        SortFields: '',
        Page: '评测详情',
        PageColumns: '',
        Scene: '',
        Propertys: '',
        DetailView: '',
        ListView: '',
        SortNo: '',
        Roles: [{
            Role: 'Manager',
            RoleField: 'Manager'
        }],
        Id: 0
    }]
}