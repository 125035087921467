<template>
  <div
    v-if='Links && Links.length>0'
    class="flex-row start middle"
  >
    <el-image
      v-for='(url,index) in Links'
      :key="index"
      :src='url'
      :preview-src-list='Links'
      :style="Style"
      fit='cover'
    > </el-image>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.view(String)],

  computed: {
    Links() {
      if (this.value) {
        let urls = this.value.split(",").map(url => {
          if (url.indexOf("http") > -1) return url;
          return this.$matrix.Config.ImageSite + url;
        });
        return urls;
      }
      return [];
    },
    IsTable() {
      return this.container && this.container.Mode === "search";
    },
    Style() {
      let style = { width: "60px", height: "60px" };
      if (this.config.Width) {
        style.width = this.config.Width - 20 + "px";
        style.height = this.config.Width - 20 + "px";
      }
      if (this.config.Height) style.height = this.config.Height + "px";
      if (this.IsTable) {
        style.height = "32px";
      }
      return style;
    }
  }
};
</script>