import * as echarts from "echarts";
export default {
    data() {
        return { Chart: null, Data: null, Category: null };
    },
    destroyed() {
        if (this.Chart && this.Chart.destroy) this.Chart.destroy();
    },
    mounted() {
        if (document.getElementById("charts" + this.Id)) {
            this.Chart = echarts.init(document.getElementById("charts" + this.Id));
        }
    },
    computed: {
        Id() {
            return Math.floor(Math.random() * 1000000);
        },

    },
    methods: {
        show(category, data, legend) {
            if (this.Chart) this.Chart.setOption(this.create(category, data, legend));
        }
    }
}