var render = function render(){var _vm=this,_c=_vm._self._c;return _c('mx-load-one',{key:_vm.pagekey,attrs:{"auto-load":true,"type":_vm.config.Type,"id":_vm.config.Id,"role":_vm.config.Role,"role-field":_vm.config.RoleField,"mode":"detail"},scopedSlots:_vm._u([{key:"default",fn:function(data){return [(data.data && data.data.Id)?_c('mx-entity',{ref:"entity",attrs:{"mode":"detail","type":_vm.config.Type,"parent":_vm.parent,"role":_vm.config.Role,"role-field":_vm.config.RoleField,"value":data.data},on:{"change":function($event){return _vm.handleMetaChange($event, data.data)}},scopedSlots:_vm._u([{key:"default",fn:function(meta){return (_vm.Meta)?[(_vm.Panel)?_c('mx-panel',{attrs:{"value":data.data,"panel":_vm.Panel,"type":_vm.config.Type,"config":_vm.config}}):[_c('el-descriptions',{staticClass:"panel20 s4",staticStyle:{"margin":"5px"},attrs:{"border":true,"column":3,"title":_vm.Meta.Title,"labelStyle":{ width: '200px' }}},_vm._l((_vm.Detail.Columns.filter(c => {
                return ['Image', 'Images', 'File'].indexOf(c.Type) === -1;
              })),function(col){return _c('el-descriptions-item',{key:col.Name,attrs:{"label":col.Title}},[_c('mx-field',{attrs:{"value":data.data,"config":col,"type":col.Type,"name":col.Name}})],1)}),1),_vm._l((_vm.Detail.Columns.filter(c => {
              return (
                ['Image', 'Images', 'File', 'Files'].indexOf(c.Type) > -1
              );
            })),function(col){return _c('div',{key:col.Name,staticClass:"panel20 s4",staticStyle:{"text-align":"left","margin":"5px"}},[_c('div',{staticStyle:{"padding":"10px","background-color":"#f7f7f7"}},[_vm._v(" "+_vm._s(col.Title)+" ")]),_c('mx-field',{staticStyle:{"margin-top":"10px"},attrs:{"value":data.data,"config":col,"type":col.Type,"name":col.Name}})],1)})]]:undefined}}],null,true)}):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }