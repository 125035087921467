<template>
  <component
    :is="Is"
    :config="config"
    @event="OnEvent"
    :parent="parent"
    :name="name"
    :is-child="isChild"
    :parent-type="parentType"
    @meta-change="handleMetaChange"
    @click="OnClick"
    :query="query"
  >
    <slot slot-scope="scope" :meta="scope.meta"></slot>
  </component>
</template>
<script>
import HomePage from './home'
import DetailPage from './detail'
import ListPage from './list'
import PanelPage from './panel'
import ReportPage from './report'
import SelfPage from './self'
export default {
  props: {
    config: Object,
    parent: Object,
    name: String,
    isChild: Boolean,
    theme: { type: String, default: 'home' },
    parentType: String,
    query: Object
  },
  components: {
    HomePage,
    DetailPage,
    ListPage,
    PanelPage,
    ReportPage,
    SelfPage
  },
  methods: {
    handleMetaChange(val) {
      this.$emit('meta-change', val)
    },
    OnClick(e) {
      this.$emit('click', e)
    },
    OnEvent(e) {
      this.$emit('event', e)
    }
  },
  computed: {
    Is() {
      switch (this.theme.toLowerCase()) {
        case 'home':
          return 'HomePage'
        case 'detail':
          return 'DetailPage'
        case 'list':
          return 'ListPage'
        case 'panel':
          return 'PanelPage'
        case 'report':
          return 'ReportPage'
        case 'self':
          return 'SelfPage'
      }
      return 'HomePage'
    }
  }
}
</script>
