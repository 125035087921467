<template>
  <div class="panel3">
    <div :id="'charts' + Id" style="min-height: 296px;">
      <div>没有指定时间列</div>
    </div>
  </div>
</template>
<script>
import line from "./echartline";
import echart from "./echart.js";
export default {
  props: {
    data: Array,
    list: Array,
    category: Array,
    config: Object
  },
  mixins: [echart],
  methods: {
    create(category, data, legend) {
      return line.create.call(this, category, data, legend);
    }
  },
  mounted() {
    let DataField = this.config.Config && this.config.Config.Fields ? this.config.Config.Fields[0] || "IdCount" : "IdCount";
    this.show(
      this.data.map(d => {
        return d.Title;
      }),
      this.data.map(d => {
        return d.Value.length > 0 ? d.Value[0][DataField] : 0;
      }),
      null
    );
  }
};
</script>
