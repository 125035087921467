<template>
    <div>

        <div class="border-container" style="overflow: hidden;">
            <div class="name-title flex-row left">通过率分析</div>
            <div id="Pie2" style="width: 100%; height: 400px"></div>
            <span class="top-left border-span"></span>
            <span class="top-right border-span"></span>
            <span class="bottom-left border-span"></span>
            <span class="bottom-right border-span"></span>
        </div>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    props: {
        Index: Number,
        data: Array,
        config: Object,
        query:Object
    },
    computed: {
        Idx() {
            return Math.floor(Math.random() * 10);
        },
    },
    methods: {
        myEcharts(data) {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById("Pie2"));
            var option = {

                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    orient: 'vertical',
                    left: 'left',
                    textStyle: {
                        fontSize: 14,//字体大小
                        color: '#fff'//字体颜色
                    },
                },
                series: [
                    {
                        name: '通过率分析',
                        type: 'pie',
                        radius: '50%',
                        data: data,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                            }
                        }
                    }
                ]
            };;
            myChart.setOption(option);
        },
        LoadData() {
            this.$matrix.AjaxGet3("Test", 'GetList', {
                GetDriver: true,
                Groups: ['Driver', 'TotalScore'],
                DriverSearchParams: { GetCompany: true },
                Company: this.query.Company
            }, this.config.Role, this.config.RoleField).then(datas => {

                let data = [
                    { name: '通过', min: 70, max: 100, value: 0 },
                    { name: '不通过', min: 0, max: 69, value: 0 },
                ];
                if (datas.Items.length) {
                    let getScore = (val) => {
                        let res = datas.Items.filter(item => {
                            return item.TotalScore >= val.min && item.TotalScore <= val.max

                        })
                        return res.length
                    }
                    data.forEach(item => {
                        item.value = getScore(item)
                    })
                    this.myEcharts(data)
                }

            }).catch(e => {
                this.$matrix.ShowError(e)
            })

        }
    },
    mounted() {
        this.LoadData()
    },
};
</script>
<style scoped>
.border-container {
    position: relative;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid rgba(255, 255, 255, .15);
    box-shadow: inset 0 0 50px rgba(255, 255, 255, .1), 0 0 5px rgba(0, 0, 0, .3)
}

.border-container span.border-span {
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    opacity: .5
}

.border-container span.top-left {
    top: -2px;
    left: -2px;
    border-top: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.top-right {
    top: -2px;
    right: -2px;
    border-top: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.border-container span.bottom-left {
    bottom: -2px;
    left: -2px;
    border-bottom: 2px solid #54dcf2;
    border-left: 2px solid #54dcf2;
}

.border-container span.bottom-right {
    bottom: -2px;
    right: -2px;
    border-bottom: 2px solid #54dcf2;
    border-right: 2px solid #54dcf2;
}

.name-title {
    font-size: 16px;
    font-weight: bolder;
    color: #00ffff;
}
</style>