export default [{
        Scene: '管理模式',
        Role: 'Manager',
        OrgRole: '',
        Layout: 'Template_Admin_1',
        Title: '驾安评',
        SubTitle: '管理中心',
        Page: [{
            Page: '首页',
            Name: 'home',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '用户详情',
            Name: 'users_detail',
            Title: '',
            Type: '详情',
            Entity: 'Users',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'DetailPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'DetailPanel0',
                Theme: '',
                Comp: '视图面板'
            }]
        },
        {
            Page: '司机',
            Name: 'driver',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '司机详情',
            Name: 'driver_detail',
            Title: '',
            Type: '详情',
            Entity: 'Driver',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'DetailPanel0'
                },
                {
                    Name: 'DataScrollList1'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'DetailPanel0',
                Theme: '',
                Comp: '视图面板'
            },
            {
                Name: 'DataScrollList1',
                Theme: '数据分页列表',
                Comp: '数据列表'
            }]
        },
        {
            Page: '公司',
            Name: 'company',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '公司详情',
            Name: 'company_detail',
            Title: '',
            Type: '详情',
            Entity: 'Company',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'DetailPanel0'
                },
                {
                    Name: 'DataScrollList1'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'DetailPanel0',
                Theme: '',
                Comp: '视图面板'
            },
            {
                Name: 'DataScrollList1',
                Theme: '数据分页列表',
                Comp: '数据列表'
            }]
        },
        {
            Page: '评测',
            Name: 'test',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '评测详情',
            Name: 'test_detail',
            Title: '',
            Type: '详情',
            Entity: 'Test',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'DetailPanel0'
                },
                {
                    Name: 'DataScrollList1'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'DetailPanel0',
                Theme: '',
                Comp: '视图面板'
            },
            {
                Name: 'DataScrollList1',
                Theme: '数据分页列表',
                Comp: '数据列表'
            }]
        },
        {
            Page: '试卷',
            Name: 'paper',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '试卷详情',
            Name: 'paper_detail',
            Title: '',
            Type: '详情',
            Entity: 'Paper',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'DetailPanel0'
                },
                {
                    Name: 'DataScrollList1'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'DetailPanel0',
                Theme: '',
                Comp: '视图面板'
            },
            {
                Name: 'DataScrollList1',
                Theme: '数据分页列表',
                Comp: '数据列表'
            }]
        },
        {
            Page: '账号',
            Name: 'manager',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        },
        {
            Page: '设置',
            Name: 'setting',
            Title: '',
            Type: '首页',
            Entity: '',
            RoleFields: '',
            Layout: {
                Type: 'Flex',
                H: false,
                Columns: [{
                    Name: 'ViewPanel0'
                }]
            },
            Config: {
                Border: {
                    Span: 0,
                    BC: '#fff'
                }
            },
            Columns: [{
                Name: 'ViewPanel0',
                Theme: '',
                Comp: '面板'
            }]
        }]
    }]