<template>
    <mx-view
        mode="view"
        :parent="parent"
        :role="config.Role"
        :role-field="config.RoleField"
        :name="name"
        ref="view"
        node="详情"
        :key="name + '_' + Id"
        @change="handleMetaChange"
    >
        <template slot-scope="scope" v-if="meta">
            <mx-data
                :type="scope.meta.Page.Entity"
                :role="config.Role"
                :role-field="getDataRoleField(scope.meta.Page.Entity)"
                :id="Id"
                :auto-load="true"
                :params="getParams(getDataRoleField(scope.meta.Page.Entity))"
                @event="handleLoaded"
            >
                <template slot-scope="data" v-if="data.data">
                    <mx-layout
                        v-if="scope.meta.Layout"
                        :layers="scope.meta.Layout.Layer"
                        :columns="
                            getColumns(
                                scope.meta.Layout.Columns,
                                scope.meta.Columns
                            )
                        "
                        :config="scope.meta.Layout.Config"
                    >
                        <slot slot-scope="cell">
                            <mx-column
                                style="min-height: 100%"
                                :config="cell.column"
                                :items="cell.column.Columns"
                                :type="cell.column.Comp"
                                @click="OnClick"
                                :parent="data.data"
                                :parent-type="scope.meta.Page.Entity"
                                :entity="meta.Page.Entity"
                                :theme="cell.column.Theme"
                                @event="OnEvent"
                            >
                            </mx-column>
                        </slot>
                    </mx-layout>
                    <div v-else>
                        <mx-column
                            v-for="(col, index) in getColumns2(
                                scope.meta.Columns
                            )"
                            :key="index"
                            :config="col"
                            :items="col.Columns"
                            :type="col.Comp"
                            :parent="data.data"
                            :parent-type="meta.Page.Entity"
                            @click="OnClick"
                            :theme="col.Theme"
                            @event="OnEvent"
                        >
                        </mx-column>
                    </div>
                    <slot></slot>
                </template>
            </mx-data>
        </template>
    </mx-view>
</template>
<script>
import base from 'matrix.node.ui/master/column'
import panels from '@/projects/panels/index'
export default {
    mixins: [base],
    data() {
        return { meta: null }
    },
    computed: {
        Id() {
            return (
                this.$route.query.Id || this.$route.query.id || this.config.Id
            )
        }
    },
    methods: {
        getDataRoleField(type) {
            let user = this.$matrix.GetUser(this.config.Role)
            if (!user && this.$matrix.Models[type].Config.IsViewer)
                return 'Viewer'
            return this.config.RoleField
        },
        handleLoaded(val) {
            if (val.event === 'ValueChange') {
                this.$emit('meta-change', { meta: this.meta, parent: val.data })
            }
        },
        handleMetaChange(val) {
            this.meta = val
            this.$emit('meta-change', { meta: this.meta })
        },
        getPanel(config) {
            let type = (config.Type || '').replace(/\./g, '_')
            if (config.Panel) {
                let panel = type + '_' + config.Panel
                if (panels[panel]) return panel
            }
            return ''
        },
        getParams(RoleField) {
            let res = {}
            this.meta.Columns.forEach(v => {
                if (v.Comp === '视图面板') {
                    if (v.Type === this.meta.Page.Entity) {
                        let panelname = this.getPanel(v)
                        if (panelname) {
                            let panel = panels[panelname]
                            if (panel.objectparams) {
                                let fields = this.$matrix.GetObjectField(
                                    v.Type,
                                    panel.objectparams()
                                )
                                fields.forEach(f => {
                                    res['Get' + f.Name] = true
                                })
                            }
                            if (panel.childparams) {
                                let childs = panel.childparams()
                                if (childs && !Array.isArray(childs))
                                    childs = [childs]
                                childs
                                    .filter(c => {
                                        return c && typeof c === 'string'
                                    })
                                    .map(c => {
                                        let cs = c.split('-')
                                        let typenams = cs[0].split(',')
                                        let rolenames = cs[1]
                                            ? cs[1].split(',')
                                            : null
                                        let childconfig = this.$matrix.FindActionChild(
                                            v.Type,
                                            typenams,
                                            v.Role,
                                            rolenames
                                        )
                                        if (childconfig) {
                                            let rolefield = childconfig.Field
                                                ? childconfig.Field.Name
                                                : 'Viewer'
                                            if (RoleField === 'Viewer')
                                                rolefield = 'Viewer'
                                            let names = childconfig.Model.Name.split(
                                                '.'
                                            )
                                            let data = {
                                                Name:
                                                    names[names.length - 1] +
                                                    'List',
                                                RoleField: rolefield,
                                                Params: { Role: rolefield }
                                            }
                                            if (
                                                childconfig.Model.Config
                                                    .GetFirst
                                            ) {
                                                data.Params.PageSize = 1
                                            }
                                            return data
                                        }
                                    })
                                    .forEach(f => {
                                        if (f) {
                                            res['Get' + f.Name] = true
                                            res[f.Name + 'SearchParams'] =
                                                f.Params
                                        }
                                    })
                            }
                            if (panel.loadparams) {
                                res = Object.assign(res, panel.loadparams())
                            }
                        }
                    }
                }
            })

            if (this.meta.Page.Config.LoadParams) {
                res = Object.assign(res, this.meta.Page.Config.LoadParams)
            }
            return res
        }
    }
}
</script>
