<template>
  <div> 
    <el-select v-if="Options" style="width: 100%" :value="DataId" :size="size || config.Size" @change="OnInputObject"
      :disabled="disabled || config.Disabled" :placeholder="'请输入' + (config.Title || '')" clearable @focus="OnLoad" filterable :remote="!!SearchKey"
      :remote-method="OnLoadKey" :multiple="true" :collapse-tags="false">
      <el-option class="item" v-for="item in Options" :label="GetTitle(item)" :key="item.Id" :value="item.Id">
        <mx-panel :panel="Panel" v-if="Panel" :value="item"></mx-panel>
      </el-option>

      <li class="el-select-dropdown__item" @click="OnLoadMore" v-show="!isLoading && !isEnd">
        更多
      </li>
    </el-select>
  </div>
</template>
<script>
import base from 'matrix.node.ui/field.js'
import panels from '@/projects/panels/index'
let emptyStrHanlder = function () {
  return undefined
}
let dataHandler = function (data) {
  return data
}
export default {
  mixins: [base.edit(Array, emptyStrHanlder, emptyStrHanlder, dataHandler)],
  props: {
    isEnd: Boolean,
    isLoading: Boolean,
    options: Array
  },
  data () {
    return { Options: null, DataOptions: null }
  },
  computed: {
    DataId () {
      let v =
        this.Data && this.Data.map
          ? this.Data.map((t) => {
            if (t) {
              if (t.Id) return t.Id
              if (typeof t === 'string') {
                if (t.length > 11) return t
                return parseInt(t)
              }
              return t
            }
          }).filter((t) => {
            return !!t
          })
          : []
      if (v.length === 0) return null
      return v
    },
    Panel () {
      let name = (this.config.Link + '.Field').replace(/\./g, '_')
      if (panels[name]) return name
      return ''
    },
    LabelField () {
      if (this.config && this.config.LabelField)
        return this.config.LabelField
      if (this.config && this.config.Options && this.config.Options.Label)
        return this.config.Options.Label
      return 'Title'
    },
    LabelFields () {
      let entity = this.$matrix.Models[this.config.Options.Entity]
      return entity.Fields.filter((f) => {
        return f.Label && f.Label.indexOf('Title') > -1
      })
    },
    Label () {
      if (this.value) {
        return this.value[this.LabelField]
      }
      return ''
    },
    SearchKey () {
      if (this.config.Options.Entity) {
        let entity = this.$matrix.Models[this.config.Options.Entity]
        if (entity) {
          if (
            entity.Fields.find((f) => {
              return !!f.IsSearchFields
            })
          ) {
            let names = entity.Name.split('.')
            return names[names.length - 1] + 'Keywords'
          }
          let label = entity.Fields.find((f) => {
            return f.Label && f.Label.indexOf('Label') > -1
          })
          if (label) {
            return label.Name + 'Like'
          }
          return
        }
      }
    },
    Orders () {
      let entity = this.$matrix.Models[this.config.Options.Entity]
      if (entity) {
        return entity.Config.Orders || 'IdDESC'
      }
      return 'IdDESC'
    }
  },
  mounted () {
    // this.InitOptions(this.options)
    let ids
    if (this.value && Array.isArray(this.value)) {
      ids = this.value
        .filter((f) => {
          return typeof f !== 'object'
        })
        .map((t) => {
          return t
        })
    }
    if (ids && ids.length > 0) {
      this.$matrix.LoadFieldById(this.config, '', '', ids, this.Context).then(data => {
        this.InitOptions(data)
      })
    } else {
      this.DataOptions = this.value || []
      this.Options = [].concat(this.DataOptions)
    }
  },
  watch: {
    options (val) {
      this.InitOptions(val)
    }
  },
  methods: {
    GetTitle (item) {
      return (
        this.LabelFields.map((field) => {
          return item[field.Name]
        })
          .filter((val) => {
            return !!val
          })
          .join(',') || '未知选项'
      )
    },
    ResetByLookUp () {
      if (this.Data !== this.config.EmptyValue) {
        this.Data = this.config.EmptyValue
        this.OnChange()
      }
      if (this.options) {
        this.$emit('load', { type: 'Clear' })
      }
    },
    InitOptions (options) {
      options = options || []
      if (this.Options === null) {
        this.Options = options
        this.DataOptions = options
      } else {
        if (options.length > 0) {
          options = options.filter((item) => {
            return (
              this.Options.findIndex((t) => {
                return t && t.Id === item.Id
              }) === -1
            )
          })
          this.Options = this.Options.concat(options)
        } else {
          this.Options = []
        }
      }
    },
    OnLoadMore () {
      if (this.isLoading) return
      this._Reset = false
      this.$emit('load', {
        type: 'LoadMoreSelections'
      })
    },
    OnLoadKey (key) {
      if (this.SearchKey) {
        const handler = () => {
          this._Reset = true
          this.$emit('load', {
            type: 'LoadSelections',
            params: {
              Page: 1,
              PageSize: this.config.Options.PageSize || 10,
              [this.SearchKey]: key,
              Orders: this.Orders
            }
          })
        }
        if (this._SearchHandler) clearTimeout(this._SearchHandler)
        this._SearchHandler = setTimeout(handler, 500)
      }
    },
    OnLoad (str) {
      if (!this.options || this.options.length === 0) {
        if (this.isLoading) return
        this._Reset = true

        this.$emit('load', {
          type: 'LoadSelections',
          params: {
            Page: 1,
            PageSize: this.config.Options.PageSize || 10,
            Orders: this.Orders
          }
        })
      }
    },
    OnInputObject (val) {
      let selections = this.selections || this.options || []
      if (typeof val === 'number') {
        let id = val
        let op = selections.find((t) => {
          return t.Id === id
        })
        this.Data = [op]
        this.OnChange()
      } else if (val) {
        if (val.length > 0) {
          this.Data = selections.filter((t) => {
            return val.indexOf(t.Id) > -1
          })
        } else {
          this.Data = []
        }
        this.OnChange()
      } else {
        this.Data = []
        this.OnChange()
      }
    }
  }
}
</script>
<style scoped>
.item {
  height: auto !important;
}
</style>