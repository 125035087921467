let create = function(category, data) {
  return {
    title: {
      subtext: this.config.SubTitle || "",
      left: "center"
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)"
    },
    color: ["#f86464", "#fa8f3d", "#8c70f8", "#19dc7c", "#34a6fe", "#e8ea3e"],

    legend: {
      orient: "vertical",
      left: 4,
      top: 10,
      data: category,
      selected: category.map(c => {
        return { [c]: true };
      })
    },
    series: [
      {
        name: this.config.FieldTitle || "数据",
        type: "pie",
        data: data.map((d, index) => {
          return { name: category[index], value: d };
        }),
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          },
          label: {
            show: false,
            fontSize: "16",
            fontWeight: "bold"
          }
        }
      }
    ]
  };
};
export default { create };
