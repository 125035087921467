<template>
    <div v-show="GetShowState(col)" :class="col.Class" :style="col.Style">
      <div :class="col.TitleClass" class="flex-row middle end" :style="col.TitleStyle">
        <div :style="GetTitleStyle(col)">
          <span :style="col.TitleReqStyle" v-if="col.ShowReq">*</span>{{ col.Title }}：
        </div>
        <div style="width: 20px; padding: 10px" v-if="col.IsV">
          <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
            <i class="el-icon-warning" style="color: red"> </i>
          </el-tooltip>
        </div>
      </div>
      <div :style="col.FieldStyle" class="box">
        <div v-if="col.FieldState && !GetShowState(col)" class="mask">-</div>
        <mx-field
          :config="col"
          :value="value"
          ref="field"
          :edit="config.Edit !== false"
          :type="col.Type"
          :name="col.Name"
          @change="handleChange"
          :checkable="true"
        >
        </mx-field>
        <div style="color: #777; font-size: 14px; margin-top: 5px" v-if="col.Description && !GetFieldError(col)">
          <i class="el-icon-info"></i> {{ col.Description }}
        </div>
        <div v-if="showerr !== false" class="line errorTitle">
          <template v-if="GetFieldError(col)">
            <i class="el-icon-err"></i>
            {{ GetFieldError(col).message }}</template
          >
        </div>
      </div>
      <div style="padding: 10px 0">
        <el-tooltip v-if="GetFieldError(col)" class="item" :content="GetFieldError(col).message" placement="bottom">
          <i class="el-icon-warning" style="color: red"> </i>
        </el-tooltip>
      </div>
    </div>
  </template>
  <script>
  export default {
    props: {
      errors: Array,
      field: Object,
      value: Object,
      config: Object,
      showerr: Boolean
    },
    computed: {
      col() {
        let c = {}
        c.RoleField = this.config.RoleField //2023-3-31
        c.Role = this.config.Role //2023-3-31
        c.Class = 'flex-row middle start'
        c.Style = { width: '50%', margin: '10px 0 10px 0' }
        c.TitleReqStyle = {
          color: this.field.Req ? 'red' : '#fff',
          padding: '4px',
          width: '4px'
        }
        c.TitleStyle = {
          textAlign: 'left',
          width: 20 * 8 + 'px',
          padding: this.field.Title.length > 4 ? '4px 10px 4px 0' : '10px 10px 10px 0'
        }
        c.ShowReq = true
        if (['Boolean', 'Bool'].indexOf(this.field.Type) > -1 || this.field.ForOneCell) {
          c.FieldStyle = { flex: 1 }
          c.TitleStyle.padding = '10px 10px 10px 0'
        }
        return Object.assign({}, this.field, c)
      }
    },
    methods: {
      GetTitleStyle(col) {
        if (col.ForOneLine) {
          return {}
        }
        if (['Boolean', 'Bool'].indexOf(col.Type) > -1 || col.ForOneCell) {
          return {
            whiteSpace: 'nowrap'
          }
        } else
          return {
            width: 20 * 8 + 'px',
            display: 'inline-block',
            textAlignLast: 'left',
            lineHeight: 1
          }
      },
      GetShowState(col) {
        return this.$parent.GetShowState(col)
      },
      handleChange(e) {
        this.$emit('change', e)
      },
      GetFieldError(col) {
        if (this.errors && this.errors.length > 0) {
          return this.errors.find((e) => {
            return e.name === col.Name
          })
        }
      },
      GetValue(value) {
        return this.$refs.field.GetValue(value)
      },
      GetOrigin(value) {
        return this.$refs.field.GetOrigin(value)
      },
      GetChange(value) {
        return this.$refs.field.GetChange(value)
      },
      GetError(value) {
        return this.$refs.field.GetError(value)
      }
    }
  }
  </script>
