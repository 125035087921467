<template>
	<div style="width: 100%;text-align:left;padding:0 10px">
		<div class="flex-row start">
			<el-upload v-show="showUpload && !uploading" :action="Url" :headers='Headers' list-type="text" :show-file-list="false" :on-success="onSuccess"
				:before-upload="onBeforeUpload" :disabled="disabled||config.Disabled">

				<div :class="ImageCss">
					<template v-if="Data">
						<div>
							<el-image :src="getFullPath(Data)" :style="ImageStyle" fit='cover' />
						</div>
						<div class="fg f12">点击重新上传</div>

					</template>
					<template v-else>
						<div :style="UploadImageStyle" class="flex-column center middle">
							<span style="font-size:32px;color:#666">+</span>
						</div>
						<span class="fg f12" v-if='Show'>点击上传文件</span>
						<span class="fg f12">{{Exts}}</span>
					</template>
				</div>
			</el-upload>
		</div>
		<div v-show="uploading" :style="ProcessStyle" class="flex-column center middle">
			<el-progress :stroke-width="16" :text-inside="true" :percentage="percentage" :color="config.Colors"></el-progress>
		</div>
	</div>
</template>
<script>
import upload from "./upload.js";
import base from "matrix.node.ui/field.js";
export default {
	mixins: [base.edit(String), upload],
	props: {
		value: String,
		config: Object
	},
	computed: {
		Show () {
			return this.config.ShowTitle === false ? false : true
		},
		Exts () {
			if (this.config.Exts) {
				return `请上传${this.config.Exts.join(",").replaceAll(".", '')}格式的图片`
			}
		}
	}
};
</script>
