import parentComponent from './parent/index.vue';
import childComponent from './child/index.vue';
import pageComponent from './page/index.vue';
import modifyComponent from './modify/index.vue';
import homeComponent from './home/index.vue';
import detailComponent from './detail/index.vue';
import listComponent from './list/index.vue';
import addComponent from './add/index.vue';
import defaultComponent from './default/index.vue';
import indexPageComponent from './indexPage/index.vue'
export default { 
    Name: "components",
    Title: "页组件",
    Value: [
        { Name: "parent",Component: parentComponent },
        { Name: "child",Component: childComponent },
        { Name: "page",Component: pageComponent },
        { Name: "modify",Component: modifyComponent },
        { Name: "home",Component: homeComponent },
        { Name: "detail",Component: detailComponent },
        { Name: "list",Component: listComponent },
        { Name: "add",Component: addComponent },
        { Name: "default",Component: defaultComponent },
        { Name: "indexPage",Component: indexPageComponent }
    ]
}