<template>
    <mx-element type="ColumnFrame" theme="none">
        <template v-for="(col, index) in columns">
            <div :key="index" :style="Style">
                <slot :config="col" :index="index"></slot>
            </div>
        </template>
    </mx-element>
</template>
<script>
export default {
    props: {
        config: Object,
        columns: Array
    },
    computed: {
        Style() {
            let v = this.config.views.find(item => { return item.Style })
            if (v) return v.Style
        }
    }
}
</script>
  