<template>
  <div>
    <el-date-picker style="width: 100%" :value="Data" clearable :align="config.Align" :type="Type"
      :placeholder="'选择' + (config.Title || '日期')" value-format="yyyy-MM-dd" :size="size || config.Size"
      @input="OnInput" :disabled="disabled || config.Disabled">
    </el-date-picker>
  </div>
</template>
<script>
import base from "matrix.node.ui/field.js";
export default {
  mixins: [base.edit(String)],
  computed: {
    Type() {
      if (this.config.Theme === 'Month') return 'month'
      return 'date'
    }
  },
};
</script>