<template>
  <div class="flex-row wrap start top">
    <slot name="font"></slot>
    <div
      v-for="(col, index) in columns"
      :key="index"
      class="flex-row top start"
      style="margin: 0 0 10px 0"
      v-show="GetShowState(col)"
    >
      <div class="panel10 line1">
        <span class="title">{{ col.Title }}</span>
      </div>
      <mx-field
        :config="col"
        :value="value"
        ref="field"
        :edit="true"
        :type="col.Type"
        :name="col.Name"
        :one-line="true"
        @change="handleChange"
      >
      </mx-field>
    </div>
    <slot name="button"></slot>
  </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
export default {
    mixins: [form.component],
    methods: {
        GetSelfValue() {
            return Object.assign({}, this.value, this.GetValue({}))
        }
    }
}
</script>
<style scoped>
.title {
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
</style>
