import Detail from "./detail";
import Edit from "./edit_selection";
import Category from "./edit_category";
import Table from "./edit_table";

export default {
    components: [
        { Name: "Detail", Component: Detail },
        { Name: "Edit", Component: Edit },
        { Name: "Category", Component: Category },
        { Name: "Table", Component: Table }

    ],
    Is() {
        if (this.edit) {
            return this.config.Options &&
                this.config.Options.Type === "Category" &&
                this.config.Options.Level !== 1
                ? "Category"
                : "Edit";
        }
        return "Detail";
    }
}