<template>
  <mx-page
    type="Detail"
    :config="detail"
    :parent="parent"
    :key="timespan"
    :is-child="isChild"
    :master="master"
  >
  </mx-page>
</template>
<script>
import detail from "matrix.node.ui/page/detail";
export default {
  mixins: [detail("detail")],
  props: {},
  data() {
    return {};
  }
};
</script>
