<template>
    <div class="box">
        <mx-element type="Loading" v-if="OptionLoading" class="float"></mx-element>
        <template v-if="Options">
            <mx-form type="Condition" :columns="EnumFieldConditionColumns" style="margin-top:5px"
                v-if="EnumFieldConditionColumns && EnumFieldConditionColumns.length > 0" ref="condition"
                :key="ConditionKey">
                <template slot="button">
                    <mx-element type="Button" title="查询" theme="Search" style="margin-left:10px"
                        @click="OnConditionChange"></mx-element>
                </template>
            </mx-form>
            <div v-if="Options.length > 0" class="listbox">
                <div v-for="(option, index) in Options" :key="option.Id" style="padding:10px"
                    class="flex-row start top">
                    <div @click="handleSelect2(option)" style="margin-top:10px">
                        <mx-element type="Switch" :value="!GetActiveState(option) ? 0 : 1" :config="{ HideTitle: true }"
                            theme="clickchange"></mx-element>
                    </div>
                    <div class="border-end" style="flex:1">
                        <mx-panel :type="EnumField.Type" style="margin:5px 0"
                            :config="{ Type: EnumField.Options.Entity, Role: config.Role, RoleField: config.RoleField }"
                            :value="option" :panel="EnumFieldPanel" @click="OnPanelClick($event, option)"
                            :option="{ data: GetData(option), selected: !!GetActiveState(option) }"
                            :index="index"></mx-panel>
                        <div style="padding:10px;display:none">
                            <div v-if="GetActiveState(option)" class="flex-row start">
                                <mx-element type="Button" @click="handleSelect(option)" title="取消选择" theme="delete">取消选择
                                </mx-element>
                                <mx-element type="Button" title="编辑" theme="modify"
                                    @click="handleModifyByOption(option)">
                                </mx-element>
                                <mx-panel :type="config.Type" style="margin:5px 0"
                                    :config="{ Type: config.Type, Role: config.Role, RoleField: config.RoleField }"
                                    :value="GetData(option)" :panel="Panel"></mx-panel>
                            </div>
                            <div v-else @click="handleSelect(option)">
                                <mx-element type="Button" @click="handleSelect(option)" title="选择" theme="add" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mx-element type="Empty" v-else title="无可选数据"></mx-element>
        </template>
        <mx-element type="Empty" v-else title="尚有关键数据未填写"></mx-element>
    </div>
</template>
<script>
import list from 'matrix.node.ui/master/list'
import panels from '@/projects/panels/index'
export default {
    mixins: [list.component, list.options],
    data() { return { listKey: new Date().getTime() } },
    computed: {
        EnumFieldConditionColumns() {
            let cols
            if (this.EnumField && this.EnumField.ConditionColumns) {
                cols = []
                this.$matrix.Models[this.EnumField.Options.Entity].Fields.filter(f => {
                    return this.EnumField.ConditionColumns.indexOf(f.Title) > -1
                }).forEach(item => {
                    return this.$matrix.CreateConditionColumn(item, this.config.Role, this.config.RoleField, cols)
                })
            }
            return cols
        },
        EnumField() {
            return this.$matrix.Models[
                this.config.Type
            ].Fields.find((t) => {
                return t.IsInsert && t.Theme === 'PanelSelect'
            })
        },
        EnumFieldPanel() {
            if (this.EnumField && this.EnumField.Options) {
                let name = (this.EnumField.Options.Entity + '.ListEdit').split('.').join('_')
                if (panels[name]) return name
                return 'SystemLine'
            }
        },
        Panel() {
            let name = (this.config.Type + '.ListEdit').split('.').join('_')
            if (panels[name]) return name
            return 'SystemLine'
        }
    },
    methods: {
        handleSelect2(option) {
            this.handleSelect(option)
            this.$nextTick(() => {
                this.listKey = new Date().getTime()
                //console.log(this.Data)
            })
        },
        OnPanelClick(e, option) {
            if (e === 'Edit') {
                this.handleModifyByOption(option)
            }
        }
    }
}
</script>
<style scoped>
.box {
    position: relative
}

.float {
    position: absolute
}

.listbox {
    max-height: 500px;
    overflow: auto;
}
</style>