<template>
 <div>
  <slot></slot>
 </div>
</template>
<script>
export default {
 props: {}
};
</script>
