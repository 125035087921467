<template>
    <div style="width:100%;">
        <mx-panel v-if='!IsDelete && value' :type="config.Type" :value="value" :config="Config" panel="SystemList2" :selected="selected" :index='index'
            @event="OnEvent">
            <template>
                <div class="flex-row" style="width:100%;">
                    <div class="wf left">
                        头像
                    </div>
                    <div class="wf left">
                        昵称
                    </div>
                    <div class="wf left">
                        当前用户等级
                    </div>
                    <div class="wf left">
                        上级用户
                    </div>
                    <div class="wf left">
                        注册时间
                    </div>
                    <div class="  left" style="min-width:300px;">
                        操作
                    </div>
                </div>
            </template>
            <template slot="content">
                <div class="flex-row start middle  " style="width:100%;padding:20px 0">
                    <div class="wf flex-row start list-half top">
                        <div class="border">
                            <el-image :src="value.FaceImage" style="width: 60px; height: 60px" fit="cover" />
                        </div>
                    </div>
                    <div class="wf left active" @click="onGoDetail">{{ value.NickName }}</div>
                    <div class="wf left">
                        <span :class='LevelClass'>{{value.Level.Name}}</span>
                    </div>
                    <div class="wf left">{{ value.Father.NickName }} </div>
                    <div class="wf left">{{ value.CreateTime }}</div>
                    <div class=" left flex-row list start" style="min-width:300px;">
                        <template v-for="(act, index) in Actions">
                            <div class="  active" :key="index" v-if="act.Show" @click="onAction(act)">
                                {{ act.Title }}
                            </div>
                        </template>
                    </div>

                </div>
            </template>
            <div slot="button"></div>
        </mx-panel>
    </div>
</template>
<script>

export default {
    props: {
        config: Object,
        value: Object,
        index: Number,
        role: String,
        roleField: String,
        name: String,
        selected: Object
    },



    data () {
        return { IsModify: false, IsDelete: false, dialogTableVisible: false, ListType: 1 }
    },
    computed: {

        Config () {
            return Object.assign({ EventName: this.name ? this.name : 'TypeEvent', IsDark: false }, this.config)
        },
        Actions () {
            return [
                {
                    Title: "设置会员等级",
                    Show: true,
                    click: () => {
                        this.$matrix.AddData(this.Context, this.value,
                            {
                                Type: "Users.ManagerSetUserLevel",
                                Role: this.config.Role,
                                RoleField: this.config.RoleField
                            },
                            (res) => {
                                this.$emit('event', {
                                    target: "item",
                                    name: "Reload"
                                })
                            },
                            this.value
                        )

                    }
                },
                {
                    Title: "发放优惠券",
                    Show: true,
                    click: () => {
                        this.$matrix.AddData(this.Context, this.value,
                            {
                                Type: "Users.TicketBySystem",
                                Role: this.config.Role,
                                RoleField: this.config.RoleField
                            },
                            (res) => {
                                this.$emit('event', {
                                    target: "item",
                                    name: "Reload"
                                })
                            },
                            this.value
                        )
                    }
                }, {
                    Title: "更多",
                    Show: true,
                    Type: 'more',
                    click: () => {
                        console.log(3)
                    }
                }
            ]
        },
        LevelClass () {
            if (this.value && this.value.Level) {
                switch (this.value.Level.Levels) {
                    case 1:
                        return 'label_1'
                    case 2:
                        return 'label_2'
                    case 3:
                        return 'label_3'
                    case 4:
                        return 'label_4'
                    default:
                        return 'label_1'

                }
            }
        }


    },
    methods: {
        OnEvent (e) {
            this.$emit('event', e)
        },
        onAction (act) {
            act.click()
            console.log(act, 'aact')

        },
        getLabel (type) {
            switch (type) {
                case 1:
                    return 'label_1'
                case 2:
                    return 'label_2'
                case 3:
                    return 'label_3'
            }
        },
        getLabelTitle (type) {
            return this.$matrix.Models[this.config.Type].Fields.find(field => {
                return field.Name === type
            })

        },
        onShow () {
            this.dialogTableVisible = true
        },
        onGoDetail () {
            this.$emit("goview", {
                View: '用户详情',
                ViewType: "详情",
                Data: this.value,
                Role: this.config.Role,
                RoleField: this.config.RoleField
            })
        }

    }
}
</script>
<style scoped>
.wf {
    flex: 1;
}

.wf2 {
    flex: 2;
}

.border {
    border: 1px solid #eee;
}

.label_1,
.label_2,
.label_3,
.label_4 {
    border-radius: 4px;
    padding: 4px 6px;
}

.label_1 {
    background: #fbc451;
    color: #fff;
}

.label_2 {
    background: #a498cb;
    color: #fff;
}

.label_3 {
    background: #a7bba9;
    color: #fff;
}

.label_4 {
    background: #f49a71;
    color: #fff;
}

.label_5 {
    background: #6d72bb;
    color: #fff;
}

.active {
    color: #2d8cf0;
    cursor: pointer;
}
</style>

