<template>
    <component
      :is="Is"
      :value="value"
      :config="config"
      :title="title"
      :options="options"
      :icon="icon"
      @change="handleChange"
      @click="handleClick"
    >
      <slot></slot>
      <slot slot="button" name="button"></slot>
    </component>
  </template>
  <script>
  import topComponet from "./Top.vue";
  import topComponet2 from "./Top2.vue";
  
  export default {
    props: {
      value: Number,
      config: Object,
      title: String,
      icon: String,
      theme: { type: String, default: "" },
      options: Array,
    },
    components: { topComponet, topComponet2 },
    computed: {
      Is() {
        switch (this.theme.toLowerCase()) {
          case "top":
            return "topComponet";
          case "top2":
            return "topComponet2";
          default:
            return "defaultComponet";
        }
      },
    },
    methods: {
      handleChange(val) {
        this.$emit("change", val);
      },
      handleClick(val) {
        this.$emit("click", val);
      },
    },
  };
  </script>
  