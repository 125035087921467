import Item from './item.vue';
import Frame from './frame.vue';
export default {
    Name: "DataPageList",
    ItemIs: "DataPageListItem",
    Is: "DataPageListFrame",
    Components: {
        DataPageListFrame: Frame,
        DataPageListItem: Item
    },
    CreateDataConfig: function (config) {
        let dataconfig = JSON.parse(JSON.stringify(config.Config || {}));
        if (dataconfig) {
            if (!dataconfig.PageSize) {
                dataconfig.PageSize = 10;
            }
            if (config.DataField) {
                if (!dataconfig.Params) {
                    dataconfig.Params = {};                    
                }
                dataconfig.Params["Get" + config.DataField] = true;
            }
            return dataconfig;
        }
    }
}