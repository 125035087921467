<template>
  <div>
    <slot :config="config" :data="parent"> </slot>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    index: Number,
    dataconfig: Object,
    value: Object,
    panel: String,
    parent: Object,
    entity: String
  },
  created() { }
};
</script>
