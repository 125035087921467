<template>
    <div style="width:1024px" class="form-container" :key="FormKey">
        <div v-if='TempDataRecord' class="info">
            你有一条{{ TempDataRecord.time }}保存的记录，是否继续编辑 <a href="javascript:void(0)" @click="LoadData">继续编辑</a>
        </div>

        <mx-entity mode="add" :type="FormConfig.Type" :parent="FormConfig.Parent" :role="FormConfig.Role"
            :role-field="FormConfig.RoleField" ref="entity" :value="FormConfig.Value">
            <div class="formbox">
                <mx-element type="FormTitle" :title="FormConfig.Title" theme="none"> </mx-element>
                <mx-form :type="mainFormType" :config="FormConfig" :value="TempValue ? TempValue : FormConfig.Value"
                    :columns="FormConfig.Columns" :key="formkey" ref="main" theme="v" @change="OnEntityChange"></mx-form>
            </div>
            <div v-for="child of children" :key="child.Name" v-show="visiabled[child.Name] !== false" class="childform">
                <mx-element type="FormTitle" :title="child.Title" theme="none">
                </mx-element>
                <div class="box" style="pading: 10px">
                    <div v-show="child.DeleteEnabled &&
                        deleted &&
                        deleted[child.Name] === false
                        " class="mask">
                        <div class="vcenter">
                            {{ child.Title }}已经停用,数据不会被存储
                        </div>
                    </div>
                    <template v-if="child.IsList">
                        <mx-load-list v-if="child.DataLoad" :params="child.DataLoad.Params" :type="child.DataLoad.Type"
                            :role="child.DataLoad.Role" :role-field="child.DataLoad.RoleField"
                            :data-handler="child.DataHandle" :page-size="0" @loading="handleLoading($event, child)"
                            :auto-load="true">
                            <template slot-scope="res">
                                <mx-list :key="formkey" :type="child.ListType" :config="child"
                                    :value="TempValue ? GetChildData(child) : child.CreateList(child, res.data)"
                                    :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name"
                                    :options="res.data">
                                </mx-list>
                            </template>
                        </mx-load-list>
                        <mx-list :key="formkey" v-else :type="child.ListType" :config="child"
                            :value="TempValue ? GetChildData(child) : child.CreateList(child, child.Options)"
                            :columns="child.Form.Columns" :theme="child.Theme" :ref="child.Name" :options="child.Options">
                        </mx-list>
                    </template>
                    <mx-form style="padding: 40px 20px 10px 20px" :key="formkey" v-else type="Edit" :config="child"
                        :value="TempValue ? GetChildData(child, true) : child.Form.Value" :columns="child.Form.Columns"
                        :theme="child.Theme" :ref="child.Name">
                    </mx-form>
                </div>
            </div>
        </mx-entity>
        <template v-if="!IsSaved && !IsSaving">
            <div class="fixed-bottom" :style="BottomStyle">
                <el-button @click="handleBack">返 回</el-button>
                <el-button type="info" @click="handleSaveTemp" v-if="TempData">保存为草稿</el-button>
                <el-button type="primary" @click="handleOk">提 交</el-button>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        index: Number,
        dataconfig: Object,
        panel: String,
        parent: Object
    },
    data() {
        return {
            visiabled: {}, deleted: null, formkey: new Date().getTime(),
            TempDataRecord: null, TempValue: null, IsSaved: false,
            IsSaving: false, FormKey: new Date().getTime()
        }
    },
    computed: {
        FormConfig() {
            let form = this.$matrix.GetForm(this.Context, 'add', this.config.Type,
                { Type: this.config.Type, Role: this.config.Role, RoleField: this.config.RoleField }, this.parent)
            return form
        },
        Entity() {
            return this.$matrix.Models[this.FormConfig.Type]
        },
        mainFormType() {
            return 'Edit'
        },

        children() {
            if (this.FormConfig.Children) {

                return this.FormConfig.Children.filter((c) => {
                    return (
                        c.Form.Columns.filter((c) => {
                            return !c.NoShow
                        }).length > 0
                    )
                })
            }
            return []
        },
        ParentField() {
            if (this.FormConfig.Parent) {
                return this.$matrix.Models[this.FormConfig.Type].Fields.find(f => { return f.Name === this.FormConfig.Parent.ParentField })
            }
        },
        TempData() {

            return this.$matrix.Models[this.FormConfig.Type].Config.TempData && this.FormConfig.Method === 'add'

        },
        ParentPanelName() {
            return this.ParentField.Link + '.' + 'ChildForm'
        },
        ParentConfig() {
            return { Type: this.ParentField.Link, FormType: this.FormConfig.Type, Role: this.conFormConfigfig.Role, RoleField: this.FormConfig.RoleField }
        },
        TempDataKey() {

            return "TempData_" + this.FormConfig.Type

        },
        BottomStyle() { 
            if (this.$matrix.Config.Theme && this.$matrix.Config.Theme.Menu && this.$matrix.Config.Theme.Menu.Width) {
                return `left:${this.$matrix.Config.Theme.Menu.Width}px`
            }
        },
    },
    methods: {
        GetChildData(child, isfirst) {
            let datas = this.TempValue._Childrens.find(d => {
                return d[child.Name]
            })
            if (isfirst) {

                if (datas) return datas[child.Name][0]

            } else {
                if (datas) return datas[child.Name]
            }
        },
        OnEntityChange(e) {
            for (let child of this.children) {
                let com = this.$refs[child.Name]
                if (Array.isArray(com)) com = com[0]
                if (com && com.OnParentChange) com.OnParentChange()
            }
            this.visiabled = this.SetVisibile(e)
        },
        handleBack(e) {
            if (this.config.ListView) {
                this.$matrix.GotoListView(
                    this.$route,
                    this.config.ListView,
                    this.config.Role,
                    this.config.RoleField,
                    this.config.ListParams
                )
            } else if (this.config.Menu) {
                this.$emit('itemclick', { Menu: this.config.Menu })
            }
        },
        handleOk(e) {
            if (this.FormConfig) {
                if (this.$refs.main) {
                    try {
                        if (this.IsSaving) return
                        this.IsSaving = true
                        let value = this.$refs.entity.GetFormValue(this.$refs, this.FormConfig.Value)
                        if (this.TempDataKey) {
                            this.$matrix.SetLocalStorage(this.TempDataKey, null, true)
                        }
                        this.$matrix.Post4(this.Context, this.config.Type, 'add', value, this.config.Role, this.config.RoleField).then(data => {
                            this.IsSaving = false
                            this.IsSaved = true
                            this.handleBack()
                        }).catch(e => {
                            this.IsSaving = false
                            this.$matrix.ShowError(e)
                        })

                    } catch (e) {
                        this.IsSaving = false
                        this.$matrix.ShowError(e)
                    }
                } else {
                    //this.FormConfig.OnSuccess({})
                }
            } else this.$matrix.CloseModelDialog(this.FormConfig.Type, this.number)
        },
        handleSaveTemp(e) {
            let value = this.$refs.entity.GetFormValue(this.$refs, this.FormConfig.Value, true)
            if (this.TempDataKey) {
                this.$matrix.SetLocalStorage(this.TempDataKey, { data: value, time: this.$matrix.formatDateTime(new Date()) }, true)
                this.$matrix.ShowToast('您的信息已经临时保存在本地，您可以在下次编辑时将他加载进来')
            }
        },
        getChildLoadParams(child, data) {
            data = data || {}
            let params = child.Form.Columns.reduce((t, item) => {
                if (['Link', 'Object', 'Array'].indexOf(item.Type) > -1) {
                    t['Get' + item.Name] = true
                }
                return t
            }, {})
            if (child.Form.AddForm && child.Form.AddForm.Columns) {
                params = child.Form.AddForm.Columns.reduce((t, item) => {
                    if (['Link', 'Object', 'Array'].indexOf(item.Type) > -1) {
                        t['Get' + item.Name] = true
                    }
                    return t
                }, params)
            }
            return Object.assign(
                { Parent: { Id: data.Id, Type: data.Type } },
                params
            )
        },
        GetMainFormValue() {
            let value = this.$refs.main.GetValue({})
            let entity = this.$matrix.Models[this.FormConfig.Type]
            let defaultValue = this.$matrix.Put({}, entity.Fields)

            return Object.assign({}, defaultValue, this.FormConfig.Value, value)
        },
        SetVisibile(data) {
            let vs = {}
            let entity = this.$matrix.Models[this.FormConfig.Type]
            let defaultValue = this.$matrix.Put({}, entity.Fields)

            this.children.forEach(c => {
                if (c.ParentState) {
                    vs[c.Name] = this.$matrix.Include(c.ParentState, Object.assign(defaultValue, data))
                }
                else {
                    vs[c.Name] = true
                }
            })
            return vs
        },
        LoadData(data) {
            this.TempValue = this.TempDataRecord.data
            this.formkey = new Date().getTime()

            this.$matrix.SetLocalStorage(this.TempDataKey, null, true)
            this.TempDataRecord = null
        }
    },
    created() {
        this.visiabled = this.SetVisibile(this.config.Value)

        if (this.TempData) {
            let data = this.$matrix.GetLocalStorage(this.TempDataKey, true)

            this.TempDataRecord = data
        }
    }
}
</script>
<style scoped>
.childform,
.formbox {
    background: #fff;
    margin: 0 0 10px 0;
    border-radius: 10px;
    padding: 0 10px;
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    background: #fff;
    left: 120px;
    right: 0;
    border-top: 1px solid #eee;
    border-left:1px solid #eee;
    padding: 20px;
    z-index: 999
}

.form-container {
    padding-bottom: 100px;
}
</style>