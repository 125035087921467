<template>
  <div
    class="flex-row middle start"
    v-if="TitleFields && TitleFields.length > 0"
  >
    <template v-for="(col, index) in TitleFields">
      <mx-field
        :key="index"
        v-if="col"
        :config="col"
        :value="value"
        ref="field"
        :edit="true"
        :type="col.Type"
        :name="col.Name"
        :one-line="true"
        @change="handleChange"
        style="flex:1"
      ></mx-field>
    </template>
    <slot name="button"></slot>
  </div>
</template>
<script>
import form from 'matrix.node.ui/master/form'
export default {
    mixins: [form.component],
    methods: {
        GetSelfValue() {
            return Object.assign({}, this.value, this.GetValue({}))
        }
    },
    computed: {
        TitleFields() {
            return this.columns.filter((t) => {
                return t.Label && t.Label.indexOf('Title') > -1
            })
        }
    }
}
</script>
<style scoped>
.title {
    word-break: keep-all; /* 不换行 */
    white-space: nowrap; /* 不换行 */
}
</style>
