<template>
  <div>Panel</div>
</template>
<script>
import list from "matrix.node.ui/master/list";
export default {
  mixins: [list.component],
  props: {
    config: Object,
    value: Array,
    columns: Array,
    options: Array,
    theme: String
  }
};
</script>