<template>
  <div style="width: 100%; text-align: left">
    <div class="panel10 flex-row middle">
      <div
        class="flex-row start middle"
        style="cursor: pointer; flex: 1"
        @click="OnClick"
      >
        <div class="box activeitem"></div>
        <div class="line2" style="font-weight: 700; flex: 1">
          {{ Title }}
        </div>
      </div>
      <slot :data="value" :index="index"></slot>
      <slot name="button" :data="value" :index="index"></slot>
      <slot
        slot-scope="scope"
        name="column-title"
        :data="value"
        :index="index"
        :config="scope.config"
      >
      </slot>
    </div>
    <div style="border-bottom: 1px solid #f7f7f7; height: 1px"></div>
  </div>
</template>
<script>
export default {
  props: {
    value: Object,
    columns: Array,
    config: Object,
    index: Number
  },
  computed: {
    Title() {
      let titleField = this.columns.find(c => {
        return c.Label && c.Label.indexOf("Title") > -1;
      });
      if (titleField && this.value) return this.value[titleField.Name];
      return "没有设置标题列";
    },
    Image() {}
  },
  methods: {
    OnClick() {
      this.$emit("click", this.value);
    }
  }
};
</script> 
<style scoped>
.box {
  width: 10px;
  height: 10px;
}
</style>