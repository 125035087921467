<template>
  <div>
    <div class="flex-column list-half bg">
      <div
        v-for="(col, index) in columns"
        :class="col.FrameClass || 'panel3'"
        :style="col.FrameStyle || { padding: '10px' }"
      >
        <slot :config="col" :index="index"></slot>
      </div>
    </div> 
    <div class="flex-row start panel" style="padding: 20px" v-if="IsEdit">
      <div style="width: 310px"></div>
      <mx-element type="Button" :loading="Posting" title="保存数据" theme="submit" @click="SaveAll()" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    config: Object,
    columns: Array
  },
  data() {
    return { Posting: false }
  },
  computed: {
    IsEdit() {
      let isedit = false
      this.columns.forEach((col) => {
        if (col.Edit != false) {
          isedit = true
        }
      })
      return isedit
    }
  },
  methods: {
    SaveAll() {
      let doing = {}
      let check = () => {
        let has = false
        Object.keys(doing).forEach((d) => {
          if (doing[d]) {
            has = true
          }
        }) 
       
        this.Posting = has
      }
      let doinghandle = (i) => {
        return (res) => {
          doing[i] = res === true
          check()
        }
      }
      let donehandle = (i) => {
        return (res) => {
          doing[i] = false
          check()
          this.$message('保存成功');
        }
      }
      for (let i = 0; i < this.columns.length; i++) {
        doing[i] = true
        this.$emit('event', {
          target: 'item',
          index: i,
          name: 'OnSave',
          ondoing: doinghandle(i),
          ondone: donehandle(i)
        })
      }
    }
  }
}
</script>
