<template>
    <el-drawer :title="Entity ? Entity.Title : '详情'" :visible="Showing" :size="config ? config.Width || '30%' : '30%'"
        :withHeader="false" :wrapper-closable="true" @close="OnClose" @closed="OnClosed">
        <div :tip="Key" :key="Key">
            <template v-if="Config">
                <mx-element style="margin-left:10px" type="Title" title="详情" theme="column" v-if="!config.HideTitle && (!Tabs || Tabs.length === 1)">
                    <a href="javascript:void(0)" style="margin-left: 20px; color: gray" @click="OnClose()"><i
                            class="el-icon-close"></i>关闭</a>
                </mx-element>
                <mx-panel class="r4 box" :style="{ paddingLeft: !Tabs || Tabs.length === 1 ? '20px' : '10px' }" :type="Config.Type"
                    :panel="config.Panel || 'SystemDetail'" :value="config.Value" :config="Config"></mx-panel>
                <mx-page style="margin: 10px 0" v-if="Entity && Config" type="Home" :parent="config.Value" :config="{
                    Role: Config.Role,
                    RoleField: Config.RoleField,
                    Roles: Config.Roles,
                    ViewColumnStyle: { marginBottom: '10px' }
                }" :name="Entity.Title + '详情'" theme="Panel" :parent-type="Config.Type">
                </mx-page>
            </template>
        </div>
    </el-drawer>
</template>
<script>
export default {
    props: {
        config: Object,
        show: Boolean
    },
    data() {
        return { Showing: this.show }
    },
    watch: {
        show(val) {
            this.Showing = val
        }
    },
    computed: {
        Tabs() {
            if (this.Entity) {
                let actlist = this.$matrix.GetActChildList(this.Context, this.Config, this.config.Value)
                let tab = [{ Title: this.Entity.Title }]
                if (actlist.length > 0) {
                    tab.push({ Title: '操作记录' })
                }
                return tab
            }
        },
        Entity() {
            if (this.Config) {
                return this.$matrix.Models[this.Config.Type]
            }
        },
        Config() {
            return this.config ? this.config.Config : null
        },
        Value() {
            return this.config ? this.config.Value : null
        },

        Key() {
            let key = 'null'
            if (this.Config) {
                key = this.Config.Type
                if (this.Value) key += '_' + this.Value.Id
            }
            return key
        },
        Context() {
            return this.config.Context || {}
        }
    },
    methods: {
        OnClosed() {
            this.$matrix.CloseDetail(this.config)
        },
        OnClose() {
            this.Showing = false;
        }
    }
}
</script>
<style scoped>
.box {
    padding: 0 20px;
}
</style>