<template>
  <div class="flex-column list" @click="handleClick">
    <div :style="ImageStyle" :class="Icon"></div>
    <div>{{ title }}</div>
  </div>
</template>

<script>
const themes = {
  Large: {
    width: "200px",
    height: "200px",
  },
  Medium: {
    width: "100px",
    height: "100px",
  },
  Small: {
    width: "64px",
    height: "64px",
  },
};
export default {
  props: {
    value: String,
    title: String,
    theme: String,
  },
  computed: {
    ImageStyle() {
      return themes[this.theme] || "";
    },
    Icon() {
      let filetype;
      if (this.value) {
        filetype = this.value.split(".")[1];
        switch (filetype) {
          case "pdf":
            return "pdf";
          case "doc":
          case "docx":
            return "doc";
          case "ppt":
            return "ppt";
          case "xls":
            return "ppt";
          case "xlsx":
            return "xlsx";
          case "png":
            return "png";
          case "jpg":
            return "jpg";
          case "zip":
            return "zip";
          case "rar":
            return "rar";
          default:
            return "pdf";
        }
      }
    },
  },
  methods: {
    handleClick() {
      window.open(this.$matrix.Config.ImageSite + this.value);
    },
  },
};
</script>
<style scoped>
.pdf,
.png,
.jpg,
.ppt,
.doc,
.docx {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  cursor: pointer;
}
.pdf {
  background: url("~@/assets/file/pdf.png");
  background-size: 100%;
}
.xls {
  background: url("~@/assets/file/xls.png");
  background-size: 100%;
}
.xlsx {
  background: url("~@/assets/file/xlsx.png");
  background-size: 100%;
}
.png {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
.jpg {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
.ppt {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
.doc,
.docx {
  background: url("~@/assets/file/doc.png");
  background-size: 100%;
}
.mp4 {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
.zip {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
.rar {
  background: url("~@/assets/file/file.png");
  background-size: 100%;
}
</style>
