//import Item from "./item.vue";
import Frame from "./frame.vue";
import Panel from "./panel.vue";
export default {
  Name: "DataChatLine",
  Is: "DataChatLineFrame",
  ItemIs: "mxColumnData",
  PanelIs: "DataChatLinePanel",
  Components: {
    DataChatLineFrame: Frame,
    //DataChatLineItem: Item,
    DataChatLinePanel: Panel
  },
  CreateDataConfig: function(config) {
    let dataconfig = Object.assign({ Type: config.Type },config.Config);
    if (dataconfig && !dataconfig.Fields) {
      dataconfig.Fields = "IdCount";
      dataconfig.PageSize = 0;
    }
    if (config.DataField) {
      if (!dataconfig.Params) {
        dataconfig.Params = {};
        dataconfig.Params["Get" + config.DataField] = true;
      }
    }
    if (dataconfig && !dataconfig.DateField) {
      let datefield = this.$matrix.Models[dataconfig.Type].Fields.find(f => {
        return ["Date", "DateTime", "Datetime"].indexOf(f.Type) > -1;
      });
      if (datefield) dataconfig.DateField = datefield.Name;
      dataconfig.DateField = "CreateTime";
    }
    return dataconfig;
  }
};
