<template>
    <div> 
        <mx-element :title="config.Title" type="Title" v-if="!config.HideTitle" :theme="TitleTheme">
            <div class="flex-row start middle">
                <mx-element v-if="config.Add" type="Button" theme="Add" :title="config.Add.Title || '新增'"
                    @click="OnAddClick" :disabled="GetDisabled('add')"></mx-element>
                <mx-element v-if="config.Import" type="Button" theme="Import" :title="config.Import.Title || '导入'"
                    @click="OnImportClick" :disabled="GetDisabled('add')"></mx-element>
                <mx-element v-if="config.Export" type="Button" theme="Export" :title="config.Export.Title || '导出'"
                    @click="OnExportClick" :disabled="GetDisabled('query')"></mx-element>
                <template v-if="TypeActions">
                    <mx-element v-for="(action, index) of TypeActions" :key="index" type="Button"
                        :theme="action.Theme || ''" :title="action.Title" @click="OnTypeActionClick(action)"
                        :disabled="GetDisabled(action.Name)"></mx-element>
                </template>
            </div>
        </mx-element>
        <div class="flex-row st start">
            <div v-if="TreeField" style="width: 200px">
                <mx-element :config="TreeField" type="Tree" @change="OnTreeChange"></mx-element>
            </div>
            <div v-if="TreeField" style="width: 5px; background-color: #f7f7f7; display: block"></div>
            <div style="flex: 1">
                <mx-form v-if="ConditionConfig.Columns.length > 0 &&
                    !config.DisabledCondition
                    " style="
                        flex: 1;
                        margin: 10px;
                        border-bottom: 1px solid #f7f7f7;
                    " class="" type="Condition" :value="Condition" :columns="ConditionConfig.Columns" ref="condition"
                    @change="OnConditionChange" :key="Key">
                    <template slot="button">
                        <mx-element style="margin-left: 10px" type="Button" @click="handleReset" title="清空" />
                        <template v-if="config.HideTitle">
                            <mx-element v-if="config.Add" type="Button" theme="Add" :title="config.Add.Title || '新增'"
                                @click="OnAddClick" :disabled="GetDisabled('add')"></mx-element>
                            <mx-element v-if="config.Import" type="Button" theme="Import"
                                :title="config.Import.Title || '导入'" @click="OnImportClick"
                                :disabled="GetDisabled('add')"></mx-element>
                            <mx-element v-if="config.Export" type="Button" theme="Export"
                                :title="config.Export.Title || '导出'" @click="OnExportClick"
                                :disabled="GetDisabled('query')"></mx-element>
                            <template v-if="TypeActions">
                                <mx-element v-for="(
                                        action, index
                                    ) of TypeActions" :key="index" type="Button" :theme="action.Theme || ''"
                                    :title="action.Title" @click="OnTypeActionClick(action)"
                                    :disabled="GetDisabled(action.Name)"></mx-element> </template></template>
                    </template>
                </mx-form>
                <div v-else-if="config.HideTitle" class="flex-row list-half" style="margin:10px">
                    <mx-element v-if="config.Add" type="Button" theme="Add" :title="config.Add.Title || '新增'"
                        @click="OnAddClick" :disabled="GetDisabled('add')"></mx-element>
                    <mx-element v-if="config.Import" type="Button" theme="Import" :title="config.Import.Title || '导入'"
                        @click="OnImportClick" :disabled="GetDisabled('add')"></mx-element>
                    <mx-element v-if="config.Export" type="Button" theme="Export" :title="config.Export.Title || '导出'"
                        @click="OnExportClick" :disabled="GetDisabled('query')"></mx-element>
                    <template v-if="TypeActions">
                        <mx-element v-for="(action, index) of TypeActions" :key="index" type="Button"
                            :theme="action.Theme || ''" :title="action.Title" @click="OnTypeActionClick(action)"
                            :disabled="GetDisabled(action.Name)"></mx-element>
                    </template>
                </div>
                <div class="flex-row start wrap top list-half" v-if="config.Tabs && config.Tabs.length > 0">
                    <div :class="!currentTab ? 'activeditem tabitem' : 'tabitem'" @click="OnSelectTab(null)">全部</div>
                    <div v-for="tab in config.Tabs" :key="tab.Title"
                        :class="currentTab === tab ? 'activeditem tabitem' : 'tabitem'" @click="OnSelectTab(tab)">
                        {{ tab.Title
                        }} <span class="bage" v-if="TabDatas[tab.Title]">{{ TabDatas[tab.Title] }}</span> <span><i
                                class="el-icon-close" v-if="currentTab === tab" style="display:none"></i></span>
                    </div>
                </div>
                <mx-column-data style="margin-top: 4px" :config="config" :dataconfig="DataConfig" tip="数据分页列表" ref="loader"
                    :params="Condition" :is-page="true" @change="OnDataChange" :style="ListStyle">
                    <template slot-scope="scope">
                        <div v-if="scope.data" class="flex-row stretch wrap start" :key="scope.timespan"
                            :style="ListHeadStyle">
                            <template v-for="(item, index) in scope.data">
                                <slot :index="index" :data="item"></slot>
                            </template>
                        </div>
                        <mx-element type="Empty" v-if="scope.data.length === 0"></mx-element>
                        <div v-if="MultActions && scope.data.length > 0" style="text-align:left;padding:10px">
                            <mx-element v-for="(action, index) of MultActions" :key="index" type="Button"
                                :theme="action.Theme || ''" :title="action.Title"
                                :disabled="GetMultDisabled(action, scope.data)"
                                @click="OnDoMultActions(action, scope.data)"></mx-element>
                        </div>


                        <mx-element v-if="scope.pageSize && scope.data.length > 0" class="flex:1"
                            style="margin-top: 10px; font-size: 16px" type="Pager" :value="scope.page" :config="{
                                MaxSize: scope.total,
                                PageSize: scope.pageSize
                            }" @change="handlePager" :key='scope.page'></mx-element>
                    </template>
                    <mx-element type="Loading" slot="loading"></mx-element>
                </mx-column-data>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        config: Object,
        index: Number,
        dataconfig: Object,
        panel: String,
        parent: Object,
        selected: Object
    },
    data() {
        return {
            Condition: {},
            Key: new Date().getTime(),
            TreeValue: {},
            currentTab: null,
            TabDatas: {}
        }
    },
    computed: {
        MultActions() {
            if (this.config.MultActions) {
                return this.config.MultActions.map(act => {
                    return Object.assign({}, { Role: this.config.Role, RoleField: this.config.RoleField }, act)
                }).filter(act => {
                    return this.$matrix.CheckRoleByConfig(this.Context, act, act.Name || act.Type, 'add')
                })
            }
        },
        TypeActions() {
            if (this.config.TypeActions) {
                return this.config.TypeActions.map(act => {
                    return Object.assign({}, { Role: this.config.Role, RoleField: this.config.RoleField }, act)
                }).filter(act => {
                    return this.$matrix.CheckRoleByConfig(this.Context, act, act.Name || act.Type, 'add')
                })
            }
        },
        ConditionConfig() {
            let columns = this.$matrix.GetForm(
                this.Context,
                'condition',
                this.config.Type,
                this.config,
                this.parent
            )
            columns.Columns = columns.Columns.filter((col) => {
                return (
                    col.Name !== this.config.TreeField &&
                    col.Title !== this.config.TreeField
                )
            })
            return columns
        },
        DataConfig() {
            let list = this.$matrix.GetForm(
                this.Context,
                'list',
                this.config.Type,
                this.config,
                this.parent
            )
            let res = {}
            list.Columns.forEach((col) => {
                if (['Array', 'Object', 'Link'].indexOf(col.Type) > -1) {
                    res['Get' + col.Name] = true
                }
            })
            if (this.config.ParentField) {
                this.dataconfig.Params['Get' + this.config.ParentField] = true
            }
            this.dataconfig.Params = Object.assign(this.dataconfig.Params, res)
            this.dataconfig.Params.GetCount = true
            return this.dataconfig
        },
        TreeField() {
            if (this.config.TreeField) {
                let entity = this.$matrix.Models[this.config.Type]
                if (entity) {
                    return entity.Fields.find((t) => {
                        return t.Name === this.config.TreeField
                    })
                }
            }
        },
        TitleTheme() {
            if (this.config.TitleTheme) {
                return this.config.TitleTheme
            }
            return 'listtitle'
        },
        ListHeadStyle() {
            if (this.$matrix.Config.ListHeadStyle) {
                return this.$matrix.Config.ListHeadStyle
            }
            return 'padding:0'
        },
        ListStyle() { 
            if (this.config.Style) {
                return this.config.Style
            }
        }
    },
    methods: {
        OnDoMultActions(act, datas) {
            let alllist = datas.filter(d => {
                return this.selected[d.Id] === true
            })

            let dolist = alllist.filter(d => {
                let model = this.$matrix.Models[act.Type || act.Name]
                if (model.ParentState && !this.$matrix.Include(model.ParentState, d)) return false
                return true
            })
            let type = act.Type || act.Name
            act.Type = type
            if (type) {
                this.$matrix.AddDatas(this.Context, {}, act, (res) => {
                    this.handlePager(1)
                }, dolist)
            }
        },
        GetMultDisabled(act, datas) {

            if (datas && datas.length > 0) {
                let alllist = datas.filter(d => {
                    return this.selected[d.Id] === true
                })

                let dolist = alllist.filter(d => {
                    let model = this.$matrix.Models[act.Type || act.Name]
                    if (model.ParentState && !this.$matrix.Include(model.ParentState, d)) return false
                    return true
                })

                return alllist.length !== dolist.length || alllist.length === 0

            }
            return true

        },
        OnDataChange(data) {
            if (this.MultActions && this.MultActions.length > 0) {
                let selected = {};
                data.Data.forEach(d => { selected[d.Id] = false })
                this.$emit('event', { selected: selected })
            }
        },
        OnSelectTab(e) {
            if (!e) { this.currentTab = null } else
                if (this.currentTab && this.currentTab.Title === e.Title) {
                    this.currentTab = null
                } else {
                    this.currentTab = e
                }
            this.handleSearch()
        },
        OnTreeChange(e) {
            //console.log(e)
            this.TreeValue = { [this.TreeField.Name]: e }
            this.handleSearch()
        },
        OnConditionChange(e) {
            //console.log(e)
            this.handleSearch()
        },
        handleReset() {
            this.Condition = {}
            this.Key = new Date().getTime()
            this.$nextTick(() => {
                this.handleSearch()
            })
        },
        GetDisabled(name) {
            if (name) {
                if (
                    this.config.Roles &&
                    this.config.Roles[this.config.RoleField]
                ) {
                    return (
                        false ===
                        this.config.Roles[this.config.RoleField][
                        this.config.Type + '_' + name
                        ]
                    )
                }
            }
            return false
        },
        handleSearch() {
            this.Condition = Object.assign(
                this.$refs.condition.GetValue(this.Condition),
                this.TreeValue
            )
            this.handlePager(1)
        },
        Reload() {
            this.handlePager(1)
        },
        handlePager(e) {
            this.$refs.loader.Load(
                e,
                Object.assign(
                    {},
                    this.Condition,
                    this.currentTab ? this.currentTab.Params : {}
                )
            )
            if (e === 1 || !e) {
                this.LadTabsData()
            }
        },
        handleLoadingChange(val) {
            if (val) {
                this.$refs.loader.LoadMore()
            }
        },
        OnAddClick(e) {
            this.$matrix.AddData(
                this.Context,
                {},
                this.config,
                (data) => {
                    this.$refs.loader.Load(1)
                },
                this.parent
            )
        },
        OnImportClick(e) {
            this.$matrix.ImportData(
                this.Context,
                this.config,
                (data) => {
                    this.$refs.loader.Load(1)
                },
                this.parent
            )
        },
        OnExportClick(e) {
            this.Condition = this.$refs.condition.GetValue(this.Condition)
            let params = Object.assign(
                {},
                this.$refs.loader.GetParams(),
                this.Condition
            )

            this.$matrix.ExportData(this.Context, params, this.config)
        },
        OnTypeActionClick(act) {
            let type = act.Type
            if (type) {
                this.$matrix.AddData(
                    this.Context,
                    {},
                    act,
                    (data) => {
                        if (act.Reset) {
                            this.$matrix.Reset(this)
                        } else {
                            this.$refs.loader.Load(1);
                        }
                    },
                    act.HasParent ? this.parent : null
                )
            } else if (act.View) {
                this.$emit('goview', act)
            } else {
                this.$matrix.ShowError(
                    '此功能正在开发中,请指定Type参数提交数据,或者指定View参数跳转到其他视图'
                )
            }
        },
        LadTabsData() {
            if (this.config.Tabs) {
                let counts = this.config.Tabs.filter(f => {
                    return f.GetCount
                })
                let shs = counts.map(c => {
                    return this.$matrix.AjaxGet3(this.config.Type, 'Count', c.Params, this.config.Role, this.config.RoleField)
                })
                Promise.all(shs).then(datas => {
                    let res = {}
                    datas.forEach((data, index) => {
                        res[counts[index].Title] = data.Count
                    })
                    this.TabDatas = res
                })
            }
        }

    },
    created() {
        this.LadTabsData()
    }
}
</script>
<style scoped>
.tabitem.activeditem::after {

    -webkit-transform: scale(1);
    transform: scale(1);
}

.tabitem {
    cursor: pointer;
    display: block;
    position: relative;
    padding: 8px 16px;
    font-size: 16px;
    font-weight: 600;
    color: #405189;
    background: 0 0;
    border: 0;
    -webkit-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.tabitem::after {
    content: "";
    background: #405189;
    height: 1px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -6px;
    right: 0;
    -webkit-transition: all 250ms ease 0s;
    transition: all 250ms ease 0s;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.bage {
    background-color: #fde8e4;
    color: rgba(240, 101, 72, 1);
    border-radius: 800px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1;
}
</style>
